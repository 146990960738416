import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";
import Safari_basic from "./Safari_basic";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, Space } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import schedule from "./schedule.js";
import { red } from "@material-ui/core/colors";
import { SettingOutlined } from "@ant-design/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const { Option } = Select;
// const selectBefore = (
//   <Select defaultValue="http://" className="select-before">
//     <Option value="INR">http://</Option>
//   </Select>
// );

function Safari_pricing() {
  const dispatch = useDispatch();
  const history = useHistory();
  const price_excel_ref = useRef(null);
  const { tour_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  const tour_operator_id = useSelector((state) => state.tour.tour_id);

  console.log("tour___IS_id", tour_operator_id);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
    getCurrencyType();
  }, [user]);
  const [updateForm, setUpdateForm] = useState(false);
  const [currency, setCurrency] = useState(null);

  const [price, setPrice] = useState({
    tour_package: tour_id,
    tour_slab: null,
    changes_allowed: true,
    itinerary_fit: null,
    inclusion_exclusion: '',
    price_adult: null,
    price_kid: null,
    allowed_age_kid: null,
    price_excel: null,
  });
  const [imagePreview, setImagePreview] = React.useState({
    price_excel_change: false,
    price_excel: null,
  });
  const [operatorId, setoperatorId] = useState(null);
  function getFormDetails() {
    axios
      .get(`partners/tour-pricing-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package price get",
          res.data
        );
        if (res.data != null) {
          setUpdateForm(true);
          setoperatorId(res.data.id);
        }
        setPrice({ ...price, ...res.data });
        setImagePreview({ ...imagePreview, price_excel: res.data.price_excel });  
        console.log(res.data.price_excel, imagePreview.price_excel);
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package price", err);
      });
  }

  function uploadFileHandler(key, e) {
    setPrice({ ...price, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null
  }

  function delUploadFileHandler(key) {
    setPrice({ ...price, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }
  const classes = useStyles();

  const handleChange = (event) => {
    setPrice({ ...price, [event.target.name]: event.target.value });
  };

  function submitPrice() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(price)) {
      if (key != "price_excel") {
        formData.append(key, value);
      } else {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    axios
      .post("partners/tour-pricing-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package price create",
          res.data
        );
        history.push("/tour-package/facility/4/" + tour_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating tour package pricing", err);
      });
  }

  function updatePrice() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(price)) {
      if (key != "price_excel") {
        formData.append(key, value);
      } else {
        if (value != null && imagePreview[key + "_change"]) {
          formData.append(key, value);
        }
      }
    }
    axios
      .put(`partners/tour-package-pricing-back-update/${price.id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package basics update",
          res.data
        );
        history.push("/tour-package/facility/4/" + tour_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from update accomodation basics", err);
      });
  }

  const getCurrencyType = async () => {
    await axios
      .get(`partners/tour-operator-currency/${tour_operator_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error of currency type", err);
        } else {
          setCurrency(response.data.currency);
          console.log("currency type data", response.data);
        }
      });
  };

  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Features & Pricing</h3>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
            <li><u>It is recommended to provide all informations.</u> </li>
              <li>
              <b>Tour Price Range:</b> Please select one option as indicating budget range
                of tour package.{" "}
              </li>
              <li>
                <b>Minor Customization / Changes allowed:</b> Please indicate that you
                allow in tour package.{" "}
              </li>
              <li>
                {" "}
                <b>Itinerary Fit:</b> Please indicate that tour package is target for
                with respect to number of participant.{" "}
              </li>
              <li>
                <b>Inclusions and Exclusions:</b> Please indicate that which are things
                included in package. <br></br>First Inclusions and with a line gap and
                Exclusions.{" "}
              </li>
              <li>
                <b>It is recommended to provide appropriate price / cost (with Local Tax)
                against each label of 'Price'.</b>{" "}
              </li>
              {/* <li>
                Upload Price Excel / CSV Sheet: You may upload your package
                pricing details in form of sheet (CSV / Excelx) <br></br>format with Max.
                allowed size 1 MB.{" "}
              </li> */}
            </ul>
          </p>
        </div>
      </div>

      <br></br>

      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Tour Price Range{" "}
            <span style={{ color: "red", marginLeft: "1%" }}>  </span>{" "}
          </p>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              value={price.tour_slab}
              onChange={handleChange}
              name="tour_slab"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={"Economy"}>Economy</MenuItem>
              <MenuItem value={"Mid-Range"}>Mid-Range</MenuItem>
              <MenuItem value={"Premium"}>Premium</MenuItem>
              <MenuItem value={"Luxury"}>Luxury</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Minor Customization / Changes Allowed
            <span style={{ color: "red", marginLeft: "1%" }}>*</span>{" "}
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              value={price.changes_allowed}
              onChange={handleChange}
              name="changes_allowed"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={false}>Yes</MenuItem>
              <MenuItem value={true}>No</MenuItem>
            </Select>
          </FormControl>
        </form>

        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Itinerary Fit
            <span style={{ color: "red", marginLeft: "1%" }}></span>{" "}
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              value={price.itinerary_fit}
              onChange={handleChange}
              name="itinerary_fit"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={"Solo"}>Solo</MenuItem>
              <MenuItem value={"Group"}>Group</MenuItem>
              <MenuItem value={"Family Age"}>Family age</MenuItem>
              <MenuItem value={"All Age"}>All age</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            Inclusions and Exclusions{" "}
            <i
              style={{
                fontWeight: "light",
                marginLeft: "1%",
                fontSize: "small",
              }}
            ></i>{" "}
          </p>

          <TextArea
            placeholder="For Example: 
                       Inclusion: Lunch, High Tea
                      Exclusion: parking fee, entry fee at location. "
            value={price.inclusion_exclusion}
            onChange={handleChange}
            name="inclusion_exclusion"
            rows={5}
            // placeholder="Inclusions"
          />

          <br />
          <br />
          {/* <TextArea 
          value={price.itinerary_fit}
          onChange={handleChange}
          name="itinerary_fit"
          rows={2} placeholder="Exclusions" /> */}
        </form>
      </div>

      <br />

      <div className="basic_company">
        <form
          noValidate
          autoComplete="off"
          style={{ width: "80%" }}
          className="company_name About"
        >
          <p style={{ display: "flex" }}>
            Price per Adult
            <span style={{ marginLeft: "1%", color: "red" }}> * </span>
          </p>
          <div className="input-group">
            <div className="input-group-prepend priceres" style={{ width: "20%" }}>
              <span
                className="input-group-text"
                style={{ width: "100%", display: "flow-root" }}
                id="inputGroupPrepend"
              >
                {currency != null ? currency : "Currency"}
              </span>
            </div>
            <input
              type="text"
              value={price.price_adult}
              onChange={handleChange}
              name="price_adult"
              className="form-control"
              id="disabledTextInput"
              style={{ background: "White" }}
              placeholder="00.00"
              aria-describedby="inputGroupPrepend"
              required
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        </form>
        <br />
        <form
          noValidate
          autoComplete="off"
          className="company_name About"
          style={{ width: "80%" }}
        >
          <p style={{ display: "flex" }}>
            Price per Kid{" "}
            <span style={{ marginLeft: "1%", color: "red" }}> * </span>
          </p>
          <div className="input-group">
            <div className="input-group-prepend priceres" style={{ width: "20%" }}>
              <span
                className="input-group-text"
                style={{ width: "100%", display: "flow-root" }}
                id="inputGroupPrepend"
              >
                {currency != null ? currency : "Currency"}
              </span>
            </div>
            <input
              value={price.price_kid}
              onChange={handleChange}
              name="price_kid"
              type="text"
              className="form-control"
              id="disabledTextInput"
              style={{ background: "White" }}
              placeholder="00.00"
              aria-describedby="inputGroupPrepend"
              required
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        </form>

        <br />
        <form
          noValidate
          autoComplete="off"
          className="company_name About"
          style={{ width: "80%" }}
        >
          <p style={{ display: "flex" }}>
            Allowed Age for Kid{" "}
            <span style={{ marginLeft: "1%", color: "red" }}> * </span>{" "}
          </p>
          <div className="input-group">
            <input
              value={price.allowed_age_kid}
              onChange={handleChange}
              name="allowed_age_kid"
              type="text"
              className="form-control"
              id="disabledTextInput"
              style={{ background: "White" }}
              placeholder="For Example: Up to 12 years"
              aria-describedby="inputGroupPrepend"
              required
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        </form>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <div className="Policies_document_main">
          <div>
            <h6
              style={{ display: "flex", width: "60%", fontWeight: "bolder" }}
            >
              Upload Price Excel / CSV Sheet{" "}
              <i
                style={{
                  fontWeight: "light",
                  fontSize: "small",
                  marginLeft: "1%",
                }}
              ></i>{" "}
            </h6>
            {/* <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
          </div>
        </div>
        <input
          className="d-none"
          type="file"
          ref={price_excel_ref}
          onChange={(e) => uploadFileHandler("price_excel", e)}
        />
        <Button
          variant="contained"
          onClick={() => {
            price_excel_ref.current.click();
          }}
          className="Policies_button"
          color="primary"
        >
          Upload file
        </Button>
                  <p className="my-1 text-success ">
                    {" "}
                    {imagePreview.price_excel != null
                      ? imagePreview.price_excel.name + " Selected"
                      : null}{" "}
                  </p>
                  <div className="multiple-image">
                    <div
                      onClick={() => delUploadFileHandler("price_excel")}
                      className="multiple-image-del px-1"
                    >
                      x
                    </div>
                  <a href={imagePreview.price_excel} target="_blank">
                    {imagePreview.price_excel != null ? (
                      // <img
                      //   src={
                      //     imagePreview.price_excel
                      //       ? (imagePreview.price_excel)
                      //       : "/images/download2.png"
                      //   }
                      //   className="img-responsive m-2"
                      //   style={{ width: "30px", height: "30px" }}
                      // />
                      <img
                        src="/images/xml.png"
                        className="img-responsive m-2"
                        style={{ width: "50px", height: "30px" }}
                      />
                    ) : (
                      <img
                        src="/images/download2.png"
                        alt="No file"
                        style={{
                          width: "50px",
                          height: "40px",
                          paddingRight: "10px",
                        }}
                      ></img>
                    )}
                  </a>
                </div>
      </div>

      <div className="text-center my-4 mb-2">
        <Link to={"/tour-package/day-plan/2/" + tour_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {updateForm ? (
          <button onClick={updatePrice} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button onClick={submitPrice} className="btn btn-primary mx-2">
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_pricing;
