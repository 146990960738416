import { LOGIN, SIGNUP, LOGOUT, ERR_SIGNUP } from "../actions/auth"

const initialState = {
    user:null,
    signupuser:null,
    error:null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            sessionStorage.setItem('user',JSON.stringify(action.user));
            //localStorage.setItem('user',JSON.stringify(action.user));
            return{
                user:action.user
            };
        case SIGNUP:
            return {
                signupuser:action.user
            }
        case LOGOUT:{
            return {
               user:null
            }
        }
        case ERR_SIGNUP:
            return {
                error:action.payload
            }
        default:
            return state
    }
}