import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router";
import axios from "../../axios";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  stylePaper: {
    background: "white",
    background:
      "linear-gradient(to bottom right,rgba(255,255,255,0.7), rgba(255,255,255,0.3))",
  },
  styleContainer: {
    //minHeight:'100vh',
    //background: 'linear-gradient(to right top, #65dfc9, #6cdbed)',
  },
}));

function AdminRegisterForm() {
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [adminComments, setadminComments] = useState(null);

  useEffect(() => {
    getAdminComments();
  }, [user]);

  async function getAdminComments() {
    await axios
      .get(`partners/tour-operator-all-admin-comments/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log(
            "error in ADMIN COMMENT IN SAFARI PACKAGE view form",
            err
          );
        } else {
          console.log(
            "data on ADMIN COMMENTS I SAFARI PACKAGE view form",
            response.data
          );
          setadminComments(response.data);
        }
      });
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }

  return (
    <div className="header-top-space">
      <Container sm={12} md={12} lg={12} className={classes.styleContainer}>
        <Box pb={4} pt={2}>
          <Typography
            component="h1"
            variant="h6"
            color="secondary"
            noWrap
            style={{ textTransform: "uppercase" }}
          >
            {`Tour Partner : Admin Review Comments `}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {adminComments != null &&
            Object.entries(adminComments).map(([keys, values]) => (
              <Grid item sm={12} spacing={1} key={keys}>
                <Paper
                  style={{ padding: "1rem" }}
                  className={classes.stylePaper}
                >
                  <Typography
                    component="h3"
                    variant="h6"
                    gutterBottom
                    style={{ textTransform: "uppercase" }}
                    align="left"
                    pd={2}
                  >
                    {replaceAll(keys, "_", " ")}
                  </Typography>
                  <Typography component="p" style={{ color: "grey" }}>
                    {values}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
        <button
          class="btn btn-primary"
          onClick={() => history.goBack()}
          style={{ marginTop: "2rem" }}
        >
          Back
        </button>
      </Container>
    </div>
  );
}

export default AdminRegisterForm;
