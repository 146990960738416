
import React, { Component, useState, useEffect, useRef } from 'react';
import axios from '../../axios';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MenuItem from '@material-ui/core/MenuItem';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
//import SafariReg_policies from './GroundReg_Policies'
import './SafariReg_basic.css';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';
import TextArea from 'antd/lib/input/TextArea';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const currencies = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'BTC',
    label: 'BTC',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'RAND',
    label: 'RAND',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'BRL',
    label: 'BRL',
  },
  {
    value: 'ILS',
    label: 'ILS',
  },



];




function GroundReg_basic(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const [update, setupdate] = useState(false);
  //console.log('received ',props.location.state.groundOperator)
  //const groundOperator =   ;
  //groundOperator = groundOperator ? groundOperator : id;
  useEffect(() => {
    window.scrollTo(0,0);

    const getBasicData = async () => {
      if (props.location.state && props.location.state.groundOperator) {
        await axios.get(`partners/ground-logistic-basic-info-detail/${props.location.state.groundOperator}`, {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
          }
        }).then(res => {
          if (res.data != null) {
            setupdate(true)
          }
          setBasic({ ...res.data });
          setContactDetail([...res.data.contact_details])
          //setOffices([...res.data[0].office_details]);
          console.log('response from getting  the GL basics get', res.data)
        }).catch(err => {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(' ' + item[0] + ':' + item[1][0])
          })
          console.log('ERROR  from getting tour package basics', err);
        })
      }
    }
    getBasicData();
    //getCurrencyList();
  }, [user])

  const [basic, setBasic] = useState({
    partner: cookies.get('id'),
    // partner: user.id,
    business: 4,
    name: null,
    overview: null,
    open_van: false,
    sedan: false,
    hatch_back: false,
    suv: false,
    boat: false,
    bus: false,
    currency: null,
  })

  const [contact_detail, setContactDetail] = useState([
    {
      name: null,
      number: null,
      //role: null,
      number_alter: null,
      email: null
    }
  ])


  const onChangeContactForm = (index, event) => {
    let arr = contact_detail;
    arr[index][event.target.name] = event.target.value;
    setContactDetail([...arr]);
  }


  function addContactDetails() {
    let arr = [...contact_detail];
    let obj = {
      name: null,
      number: null,
      //role: null,
      number_alter: null,
      email: null
    }
    arr.push(obj);
    setContactDetail(arr);
  }

  function removeContactDetail(itemDel, index) {
    let arr = [...contact_detail];
    arr.splice(index, 1);
    setContactDetail(arr);
  }


  function submitGroundBasic() {
    //let formData = new FormData();
    let stateUpdate = basic;
    stateUpdate.contact_detail = contact_detail;
    console.log('state update', stateUpdate);

    axios.post('partners/ground-logistic-basic-info-create', stateUpdate, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,

      }
    }).then(res => {
      console.log('response from submitting the GL register basics create', res.data)
      history.push('/ground-logistics-registration/Vehicle/2/' + res.data.id)

    }).catch(err => {
      // let obj = err.response.data.errors;
      // Object.entries(obj).map((item, key) => {
      //   toast(' ' + item[0] + ':' + item[1][0])
      // })
      console.log('ERROR  from creating GL registration basics', err);
      //history.push('/ground-logistics-registration/Vehicle/2/7')
    })

  }


  const [checkUpdate, setcheckUpdate] = useState(false);
  const handleChange = (event) => {
    setBasic({ ...basic, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setBasic({ ...basic, [event.target.name]: event.target.checked });
  };




  const updateTourBasic = async () => {
    axios.put(`partners/ground-logistic-basic-info-update/${basic.id}`, basic, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the GL REG BASIC  UPDATE', res.data)

      history.push('/ground-logistics-registration/Vehicle/2/' + basic.id);
    }).catch(err => {
      let obj = err.response.data.errors;
      // Object.entries(obj).map((item, key) => {
      //   toast(' ' + item[0] + ':' + item[1][0])
      // })
      console.log('ERROR  from UPDATING REG GL BASIC', err);
    })

  }


  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Ground Logistic Partner Registration: Basic Information</h4></div>

      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                        {/* <li>
                          Please enter a non-zero number in 'Accommodation
                          Distribution' against various listed options which
                          will be reflected in next stages of forms for
                          selection{" "}
                        </li> */}
                        <li>
                          {" "}
                          <b>Currency:</b> The selected currency will be applicable to
                          all of Ground Logistics pricing.{" "}
                        </li>
                        {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
                      </ul>
                    </p>
                  </div>
                </div>



      <div className="basic_company">

        <form noValidate autoComplete="off" className="company_name">
          <p>Name of Guide / Company<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <TextField
            value={basic.name}
            onChange={handleChange}
            name="name"
            id="outlined-basic" placeholder="Name" variant="outlined" />
        </form>
        <br />
        <form noValidate autoComplete="off" >
          <p>Overview of Guide / Company<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <textarea
            value={basic.overview}
            onChange={handleChange}
            name="overview"
            className="form-control"
            rows="4" placeholder="Max. 350 Characters"></textarea>

        </form>
        <br />
      </div>

      <br />
      <br />
      <br />
      <div className="company_contact">

        <h5 style={{ display: 'flex', color: "#949494" }}>Contact Details<span style={{ color: "red", marginLeft: "1%", display: 'fle' }}></span> </h5>
        <br />
        {contact_detail && contact_detail.map((item, index) =>
          <div>
            <div className="text-right w-100">
              {contact_detail.length > 1 &&
                <span className="cursor-pointer" onClick={() => removeContactDetail(item, index)}>X </span>
              }
            </div>
            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <p>Contact Name <span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span></p>
                <TextField
                  onChange={(e) => onChangeContactForm(index, e)}
                  value={item.name}
                  name="name"
                  id="outlined-basic" placeholder="Name" variant="outlined" />
              </form>
            </div>
            <br />

            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <p>Contact Number <span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span></p>
                <PhoneInput
                  onChange={(e) => {
                    let arr = contact_detail;
                    arr[index]['number'] = e;
                    setContactDetail([...arr]);
                  }}
                  value={item.number}
                  name="number"
                  placeholder="Enter phone number"
                />
              </form>

              <form noValidate autoComplete="off" className="company_year seconds">
                <p>Alternate Contact Number</p>
                <PhoneInput
                  onChange={(e) => {
                    let arr = contact_detail;
                    arr[index]['number_alter'] = e;
                    setContactDetail([...arr]);
                  }}
                  value={item.number_alter}
                  name="number_alter"
                  placeholder="Enter phone number"
                />
              </form>

            </div>

            <br />

            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <p>Email ID<span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span></p>
                <TextField
                  onChange={(e) => onChangeContactForm(index, e)}
                  value={item.email}
                  name="email"
                  id="outlined-basic" placeholder="Email ID" variant="outlined" />
                <br />
              </form>



            </div>

            <hr />

          </div>
        )}
        <h6 onClick={addContactDetails} style={{ color: "#007BC3", display: 'flex', fontWeight: 'bold' }}><a> + ADD ANOTHER CONTACT DETAIL</a> </h6>
      </div>
      <br />

      <div className="basic_company">
        <h4 style={{ display: "flex", fontWeight: "bold" }}>Select Special Type of Vehicle You Own </h4>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.open_van}
                onChange={handleChangeCheck}
                checked={basic.open_van}
                name="open_van"
                color=""
              />
            }
            label="Open Van"
          />

        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.sedan}
                onChange={handleChangeCheck}
                checked={basic.sedan}
                name="sedan"
                color=""
              />
            }
            label="Sedan"
          />

        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.hatch_back}
                onChange={handleChangeCheck}
                checked={basic.hatch_back}
                name="hatch_back"
                color=""
              />
            }
            label="Hatch Back"
          />

        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.suv}
                onChange={handleChangeCheck}
                checked={basic.suv}

                name="suv"
                color=""
              />
            }
            label="SUV"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.boat}
                onChange={handleChangeCheck}
                checked={basic.boat}

                name="boat"
                color=""
              />
            }
            label="Boat"
          />

        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.bus}
                onChange={handleChangeCheck}
                checked={basic.bus}

                name="bus"
                color=""
              />
            }
            label="Bus"
          />

        </div>

        <br />
        {/*<div >
        <form noValidate autoComplete="off" className="company_name">
          <p>Others (specify)<span style={{ marginLeft: "1%", color: "red" }}></span></p>
          <TextField
            //value={basic.name}
            //onChange={handleChange}
            name="name"
            placeholder='Type of vehicle'
            id="outlined-basic" variant="outlined" />
        </form>
        </div>*/}
      </div>

      <br />


      <br />
      <div className="Currency_picker" style={{ background: 'white', padding: '4%' }}>
        <div className="text-left">
          <h4 style={{ color: 'grey', marginBottom: '1rem' }}>Currency Selection </h4>
        </div>
        {/* <p style={{ marginBottom: '5px' }}>Select the currency</p> */}
        {currencies != null &&

          <TextField
            id="outlined-select-currency-native"
            select
            name="currency"
            placeholder="Currency"
            value={basic.currency}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}

            // helperText="Please select your currency"
            variant="outlined"
            style={{ display: 'flex', backgroundColor: 'white' }}
          >
            <option>
              --Select Currency--
            </option>
            {/* {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))} */}
            {currencies.map((option) => (
              //Object.entries(option).map((label,value) =>
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
              //  )

            ))}
          </TextField>
        }
        <br />

      </div>

      <br />


      <div className="my-4 mb-2">


        {update ? <button onClick={updateTourBasic} className="btn btn-primary mx-2">UPDATE</button> :
          <button className="btn btn-primary mx-2" onClick={submitGroundBasic}>CONTINUE</button>
        }

      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default GroundReg_basic
