import React from 'react'
import './Safari_basic.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import './Safari_basic.css';
import DateFnsUtils from '@date-io/date-fns';
import { Input } from 'antd';
import 'date-fns';
import moment from 'moment';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, Space } from 'antd';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

function schedule() {
    
        return(
            
   <h1>helloo</h1>
    )
}

export default schedule
