import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import * as logisticsActions from "../../store/actions/logistics";

import FormControl from "@material-ui/core/FormControl";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const currencies = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "BTC",
    label: "BTC",
  },
  {
    value: "JPY",
    label: "JPY",
  },
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "GBP",
    label: "GBP",
  },
  {
    value: "RAND",
    label: "RAND",
  },
  {
    value: "AUD",
    label: "AUD",
  },
  {
    value: "NZD",
    label: "NZD",
  },
  {
    value: "CNY",
    label: "CNY",
  },
  {
    value: "SGD",
    label: "SGD",
  },
  {
    value: "BRL",
    label: "BRL",
  },
  {
    value: "ILS",
    label: "ILS",
  },
];

function GLPackage_basic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { gl_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  const logistic_package_id = useSelector(
    (state) => state.logistic.logistic_package_id
  );

  const fleetid = useSelector((state) => state.logistic.fleet_id);

  useEffect(() => {
    window.scrollTo(0, 0);

    getCurrencyList();
    getFormDetails();
  }, [user]);

  useEffect(() => {
    console.log(
      "get the logistic package id",
      logistic_package_id,
      "fleetid",
      fleetid
    );
  }, [logistic_package_id]);

  const [vehicle, setVehicle] = useState({
    logistics_operator: gl_id,
    partner: 4,
    contact_name: null,
    contact_number: null,
    contact_alter_number: null,
    serving_area: null,
    national_part: null,
    currency: null,
  });

  const handleChange = (event) => {
    setVehicle({ ...vehicle, [event.target.name]: event.target.value });
  };

  function getFormDetails() {
    axios
      .get(`partners/ground-logistic-fleet-basic-info/${fleetid}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log("response from getting form details", res.data);
        setVehicle({ ...res.data });
        if (res.data) {
          setcheckUpdate(true);
        }
      })
      .catch((err) => {
        console.log("error on getting form details", err);
      });
  }

  function submitVehicle() {
    axios
      .post("partners/ground-logistic-fleet-basic-info-create", vehicle, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        try {
          dispatch(logisticsActions.fleetid(res.data.id));
          history.push(
            "/ground-logistics-package-forms/basic-information/1/" + logisticsId
          );
        } catch (err) {
          console.log("error accomodation actions", err);
        }
        console.log(
          "response from submitting the safari package vehicle create",
          res.data
        );

        history.push(
          "/ground-logistics-package-forms/route/2/" + gl_id + "/" + res.data.id
        );
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating vehicle safari package", err);
      });
  }
  function updateRouteBasic() {
    axios
      .put(`partners/fleet-basic-info-back-update/${fleetid}`, vehicle, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        try {
          dispatch(logisticsActions.fleetid(res.data.id));
          history.push(
            '/ground-logistics-package-forms/route-overview/3/' + gl_id+'/'+fleetid
          );
        } catch (err) {
          console.log("error accomodation actions", err);
        }
        console.log(
          "response from submitting the safari package vehicle create",
          res.data
        );

        // history.push(
        //   "/ground-logistics-package-forms/route/2/" + gl_id + "/" + res.data.id
        // );
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating vehicle safari package", err);
      });
  }

  const [satellite_city, setsatellite_city] = useState(null);
  const [currencyList, setcurrencyList] = useState([]);
  const [nationalParkList, setnationalParkList] = useState([]);
  const getCurrencyList = async () => {
    await axios
      .get(`partners/currency-national-park-list`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
          console.log("error in CURRENCY LIST", err);
        } else {
          console.log("CURRENCY LIST DATA ", response.data);
          setcurrencyList(response.data.currency);
          setnationalParkList(response.data.national_park);
          setsatellite_city(response.data.satellite_city);
        }
      });
  };

  const [checkUpdate, setcheckUpdate] = useState(false);

  {
    /*const getVehicleData = async () => {
    await axios.get(`partners/safari-vehicle-detail/${safari_id}`, {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then((response, err) => {
      if (err) {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log('error in VEHICLE GET DATA ', err);
      }
      else {
        console.log('CURRENCY VEHICLE GET DATA ', response.data);
        if (response.data && response.data.id) {
          setcheckUpdate(true);
          setVehicle(response.data);
        }
        
      }
    })
  }

  const updateTourBasic = async () => {
    axios.put(`partners/safari-package-vehicle-back-update/${safari_id}`, vehicle, {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the safari package vehicle  UPDATE', res.data)

      history.push('/safari-package/service/2/' + safari_id)
    }).catch(err => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('ERROR  from UPDATING vehicle safari package', err);
    })
  }*/
  }

  return (
    <div className="TourReg_basic">
      <div className="container text-left">
        <h4>Ground Logistics Package: Basic Information</h4>
      </div>
      <br></br>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
            <li><u>It is recommended to provide all informations.</u> </li>
              {/* <li>
                          Please enter a non-zero number in 'Accommodation
                          Distribution' against various listed options which
                          will be reflected in next stages of forms for
                          selection{" "}
                        </li> */}
              {/* <li>
                {" "}
                <b>Currency:</b> Select same currency as of Ground Logistics Partner
                Registration.{" "}
              </li> */}
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>
      <div className="company_contact">
        <h5 style={{ display: "flex", color: "#949494" }}>
          Contact Details
          <span
            style={{ color: "red", marginLeft: "1%", display: "fle" }}
          ></span>{" "}
        </h5>
        <br />

        <div>
          <div className="text-right w-100"></div>
          <div className="company_year_number">
            <form noValidate autoComplete="off" className="company_year">
              <p>
                Contact Name<span style={{ color: "red" }}>*</span>
              </p>
              <TextField
                onChange={handleChange}
                value={vehicle.contact_name}
                name="contact_name"
                id="outlined-basic"
                placeholder="Name"
                variant="outlined"
              />
            </form>
          </div>
          <br />

          <div className="company_year_number">
            <form noValidate autoComplete="off" className="company_year">
              <p>
                Contact Number <span style={{ color: "red" }}>*</span>
              </p>
              <PhoneInput
                onChange={(e) => {
                  let arr = vehicle;
                  arr["contact_number"] = e;
                  setVehicle({ ...arr });
                }}
                value={vehicle.contact_number}
                //onChange={handleChange}
                name="contact_number"
                placeholder="Enter phone number"
              />
            </form>

            <form
              noValidate
              autoComplete="off"
              className="company_year seconds"
            >
              <p>Alternate Contact Number</p>
              <PhoneInput
                onChange={(e) => {
                  let arr = vehicle;
                  arr["contact_alter_number"] = e;
                  setVehicle({ ...arr });
                }}
                value={vehicle.contact_alter_number}
                name="contact_alter_number"
                //onChange={handleChangeContact}
                placeholder="Enter phone number"
              />
            </form>
          </div>

          <br />

          <hr />
        </div>
      </div>
      <br />
      <br />
      <br />

      <div
        className="Currency_picker"
        style={{ background: "white", padding: "4%", paddingBottom: "0" }}
      >
        <p style={{ marginBottom: "5px" }}>
          Serving Area (Satellite City){" "}
          <span style={{ color: "red" }}> * </span>
        </p>
        {currencies != null && (
          <TextField
            id="outlined-select-currency-native"
            select
            name="serving_area"
            placeholder="Currency"
            value={vehicle.serving_area}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            // helperText="Please select your city"
            variant="outlined"
            style={{ display: "flex", backgroundColor: "white" }}
          >
            <option>--Select Satellite City--</option>
            {satellite_city != null &&
              satellite_city.length &&
              satellite_city.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </TextField>
        )}
      </div>
      <div
        className="Currency_picker"
        style={{ background: "white", padding: "4%", paddingBottom: "0" }}
      >
        <p style={{ marginBottom: "5px" }}>
          Nearest National Park <span style={{ color: "red" }}> * </span>{" "}
        </p>
        {currencies != null && (
          <TextField
            id="outlined-select-currency-native"
            select
            name="national_park"
            placeholder="National Park"
            value={vehicle.national_park}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            // helperText="Please select your National Park"
            variant="outlined"
            style={{ display: "flex", backgroundColor: "white" }}
          >
            <option>--Select National Park--</option>
            {nationalParkList != null &&
              nationalParkList.length &&
              nationalParkList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </TextField>
        )}
        <br />
      </div>
      <br />
      <br />
      <br />

      <div className="my-4 mb-2">
        {/* <Link to={"/tour-package/basic/1"}>
          <button className="btn btn-grey border border-primary mx-2">Back </button>
        </Link> */}
        {checkUpdate ? (
          <button onClick={updateRouteBasic} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitVehicle}>
            CONTINUE
          </button>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default GLPackage_basic;
