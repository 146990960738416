import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_daybyday() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { tour_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  // let tourActionState = useSelector((state) => state.tour.tour);

  useEffect(() => {
    window.scrollTo(0, 0);

    getFormDetails();
  }, [user]);

  const [plan, setPlan] = useState({
    tour_package: tour_id,
    day_no: 1,
    main_destination: null,
    other_destination: null,
    accommodation: null,
    breakfast: false,
    lunch: false,
    dinner: false,
    packed_food: false,
    drink: false,
    highlights: null,
  });
  const [planArray, setPlanArray] = useState([]);
  console.log("plan array length", planArray.length);

  const [addDay, setAddDay] = useState(false);
  const [editItem, setEditItem] = useState(false);

  const [updateForm, setUpdateForm] = useState(false);

  const [state, setState] = React.useState({
    breakfast: "",
    lunch: "",
    dinner: "",
    packedfood: "",
    drink: "",
  });
  function addDayFunc() {
    setAddDay(true);
  }

  function addAnotherDay() {
    setPlan({
      tour_package: tour_id,
      day_no: planArray.length + 1,
      main_destination: null,
      other_destination: null,
      accommodation: null,
      breakfast: false,
      lunch: false,
      dinner: false,
      packed_food: false,
      drink: false,
      highlights: null,
    });
    setAddDay(true);
  }

  const handleChange = (event) => {
    setPlan({ ...plan, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setPlan({ ...plan, [event.target.name]: event.target.checked });
  };

  function getFormDetails() {
    axios
      .get(`partners/tour-package-plan-list/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package day by day get",
          res.data
        );
        if (res.data.detail != "Not found") {
          setUpdateForm(true);
          setPlanArray([...res.data]);
        }
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package day by day", err);
      });
  }

  function editItemFunc(item) {
    setEditItem(true);
    setPlan({ ...item });
    setAddDay(true);
  }

  function submitPlan() {
    axios
      .post("partners/tour-package-plan-create", plan, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        let arr = [...planArray];
        arr.push(plan);
        console.log(
          "response from storing accomdoation detail create",
          res.data
        );
        console.log("this is the arr", arr);
        getFormDetails();
        // setPlanArray(arr);
        setPlan({
          tour_package: tour_id,
          day_no: 1,
          main_destination: null,
          other_destination: null,
          accommodation: null,
          breakfast: false,
          lunch: false,
          dinner: false,
          packed_food: false,
          drink: false,
          highlights: null,
        });
        setAddDay(false);
        console.log(
          "response from submitting the tour package day to day create",
          res.data
        );

        // history.push('/tour-package/pricing/3/'+tour_id)
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating day by day plan tour package", err);
      });
  }

  function updatePlan() {
    console.log("plan data", plan);
    axios
      .put(`partners/tour-package-plan-back-update/${plan.id}`, plan, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package day plan update",
          res.data
        );
        setEditItem(false);
        setAddDay(false);
        getFormDetails();
        // history.push('/tour-package/pricing/3/' + tour_id)
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from update tour day plan", err);
      });
  }

  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Day By Day Plan</h3>
      <div className="p-3 mb-3 bg-white">
        {/* <p><span style={{ color: "red" }}>*</span> mandatory field</p> */}
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
              <li>
                The Day by Day plan from Day 1 to Day N from top to down
                sequence is given below.{" "}
              </li>
              {/* <li>Please enter a non-zero number in 'Accommodatio Distribution' against various listed options 
                             which will be reflected in next stages of forms for selection </li>
                           <li> Currency: The selected currency will be applicable to all of accommodation pricing. </li> */}
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>

      <br></br>
      {!addDay && (
        <div>
          {planArray.map((item, itemIndex) => (
            <div className="bg-white">
              <div className="d-flex p-2">
                <div className="text-left  w-100 p-2">
                  <h5 className="mb-0">Day {itemIndex + 1} </h5>
                </div>
                <div className="text-right w-100 p-2">
                  <span
                    onClick={() => editItemFunc(item)}
                    className="text-info"
                  >
                    edit
                  </span>
                </div>
              </div>
              <hr />
              <div className="row m-0 bg-white mb-4 ">
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Main Destination:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b> {item.main_destination} </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Other Destination:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b> {item.other_destination} </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Accommodation:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b> {item.accommodation} </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Breakfast:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b>
                      {" "}
                      {item.breakfast ? (
                        <span className="text-success">true</span>
                      ) : (
                        "false"
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Lunch:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b>
                      {" "}
                      {item.lunch ? (
                        <span className="text-success">true</span>
                      ) : (
                        "false"
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Dinner:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b>
                      {" "}
                      {item.dinner ? (
                        <span className="text-success">true</span>
                      ) : (
                        "false"
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Packed Food:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b>
                      {" "}
                      {item.packed_food ? (
                        <span className="text-success">true</span>
                      ) : (
                        "false"
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="float-left mx-2 my-1">Drink:</p>
                  <p className="float-right  mx-2 my-1">
                    {" "}
                    <b>
                      {" "}
                      {item.drink ? (
                        <span className="text-success">true</span>
                      ) : (
                        "false"
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-sm-12">
                  <p className="float-left mx-2 my-1">
                    Day's Itinerary: <b> {item.highlights} </b>
                  </p>
                </div>
              </div>
            </div>
          ))}

          <div className="my-4 text-center">
            <button
              className="btn btn-warning text-white mx-auto"
              onClick={addAnotherDay}
            >
              +Add Another Day
            </button>
          </div>

          <div>
            <Link to={"/tour-package/basic/1/" + tour_id}>
              <button className="btn btn-grey border border-primary mx-2">
                BACK{" "}
              </button>
            </Link>
            {/* {updateForm ?
              <button onClick={updatePlan} className="btn btn-primary mx-2">UPDATE</button>
              : */}
            <Link to={"/tour-package/pricing/3/" + tour_id}>
              <button className="btn btn-primary mx-2">CONTINUE</button>
            </Link>
            {/* } */}
          </div>
        </div>
      )}

      {addDay && (
        <div>
          <div className="p-3 mb-3 bg-white">
            <p>
              <span style={{ color: "red" }}>*</span> mandatory field
            </p>
            <div className="text-left">
              <h5>Note:</h5>
              <p>
                <ul>
                  <li>
                    <u>It is recommended to provide all informations.</u>{" "}
                  </li>
                  <li>
                    <b>Main Destination:</b> Package main destination.{" "}
                  </li>
                  <li>
                    <b>Any Other Destination:</b> Apart from Main destination.
                  </li>
                  <li>
                    {" "}
                    <b>Accommodation:</b> Package Includes / Excludes any
                    Accommodation in day plan.{" "}
                  </li>
                  <li>
                    <b>Day's Highlights:</b> Short text about days activities.{" "}
                  </li>
                  {/* <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
                </ul>
              </p>
            </div>
          </div>
          <div className="basic_company">
            <div>
              <form noValidate autoComplete="off" className="company_name">
                <p>
                  Main Destination
                  <span style={{ marginLeft: "1%", color: "red" }}>*</span>
                </p>

                <TextField
                  value={plan.main_destination}
                  onChange={handleChange}
                  name="main_destination"
                  id="outlined-basic"
                  placeholder="Location / City / Park / Spot"
                  variant="outlined"
                />
              </form>
              <br />
              <form noValidate autoComplete="off" className="company_name">
                <p>
                  Any Other Destination
                  <span style={{ marginLeft: "1%", color: "red" }}></span>
                </p>
                <TextField
                  value={plan.other_destination}
                  onChange={handleChange}
                  name="other_destination"
                  id="outlined-basic"
                  placeholder="Any other destination"
                  variant="outlined"
                />
              </form>
              <br />
              <form noValidate autoComplete="off" className="company_name">
                <p>
                  Accommodation{" "}
                  <span style={{ marginLeft: "1%", color: "red" }}>*</span>
                </p>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: "80%" }}
                >
                  <Select
                    value={plan.accommodation}
                    onChange={handleChange}
                    name="accommodation"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={acc}
                    // onChange={handleChange}
                    placeholder="Ad Operation"
                  >
                    <MenuItem value={"none"}>None</MenuItem>
                    <MenuItem value={"tent"}>Tent</MenuItem>
                    <MenuItem value={"permanent_structure"}>
                      Permanent Structure
                    </MenuItem>
                    <MenuItem value={"tree_house"}>Tree House</MenuItem>
                    <MenuItem value={"cottage"}>Cottage</MenuItem>
                    <MenuItem value={"camp"}>Camp</MenuItem>
                    <MenuItem value={"villa"}>Villa</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </form>
              <br />
              <br />
              <h5 style={{ display: "flex" }}>Meal</h5>
              <br />
              <div className="basic-company_checkboxes">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={plan.breakfast}
                      onChange={handleChangeCheck}
                      name="breakfast"
                      checked={plan.breakfast}
                      // onChange={handlecheck}
                      // name="breakfast"
                      color="primary"
                    />
                  }
                  label="Breakfast"
                />
              </div>

              <br />

              <div className="basic-company_checkboxes">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={plan.lunch}
                      onChange={handleChangeCheck}
                      name="lunch"
                      checked={plan.lunch}
                      color="primary"
                    />
                  }
                  label="Lunch"
                />
              </div>
              <br />
              <div className="basic-company_checkboxes">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={plan.dinner}
                      onChange={handleChangeCheck}
                      checked={plan.dinner}
                      // onChange={handlecheck}
                      name="dinner"
                      color="primary"
                    />
                  }
                  label="Dinner"
                />
              </div>
              <br />
              <div className="basic-company_checkboxes">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={plan.packed_food}
                      onChange={handleChangeCheck}
                      checked={plan.packed_food}
                      // onChange={handlecheck}
                      name="packed_food"
                      color="primary"
                    />
                  }
                  label="Packed Food"
                />
              </div>

              <br />
              <div className="basic-company_checkboxes">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={plan.drink}
                      onChange={handleChangeCheck}
                      checked={plan.drink}
                      // onChange={handlecheck}
                      name="drink"
                      color="primary"
                    />
                  }
                  label="Drink"
                />
              </div>
              <br></br>
              <br />
              <form
                noValidate
                autoComplete="off"
                className="company_name About"
              >
                <h5 style={{ display: "flex" }}>
                  Day's Itinerary <span style={{ color: "red" }}> </span>
                </h5>
                <br />
                <TextArea
                  value={plan.highlights}
                  onChange={handleChange}
                  name="highlights"
                  rows={4}
                  placeholder="Maximum 350 Characters"
                />
              </form>
            </div>
          </div>
          <div className="my-4 mb-2">
            {/* <Link to={"/tour-package/basic/1/"+tour_id}> */}
            <button
              onClick={() => setAddDay(false)}
              className="btn btn-grey border border-primary mx-2"
            >
              BACK{" "}
            </button>
            {/* </Link> */}
            {editItem ? (
              <button onClick={updatePlan} className="btn btn-primary mx-2">
                UPDATE
              </button>
            ) : (
              <button className="btn btn-primary mx-2" onClick={submitPlan}>
                CONTINUE
              </button>
            )}
          </div>
        </div>
      )}

      <br />

      <br />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_daybyday;
