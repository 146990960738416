import React, {useEffect, useState} from 'react';

import axios from "../../axios";
import Cookies from 'universal-cookie';
import { Link, useHistory } from 'react-router-dom';
import { Button,DropdownButton,Dropdown } from 'react-bootstrap';
import { CompareArrowsOutlined } from '@material-ui/icons';

const cookies = new Cookies();

const EditInventoryLog = (props) => {
    const[updateLog,setUpdateLog]=useState('');
    const[accomodationInfo,setAccomodationInfo]=useState([]);
    const[accomodationId,setAccomodationId]=useState(null)
    const[currentAvailable,setCurrentAvailable]=useState([]);
    const[accommodation,setAccommodation]=useState(null);
    const[showForm,setShowForm]=useState(false);
   
   
    const[accLog,setAccLog]=useState([]);

const[uniqueDate,setUniqueDate]=useState([])
const[dropAccommodation,setDropAccommodation]=useState([]);
const[dropAccommodationCategory,setDropAccommodationCategory]=useState([]);

    useEffect(() => {
        axios.get(`partners/accommodation-basic-info-detail-for-distribution/${props.match.params.id}`,{
            headers: {
                'Authorization': 'Token ' + cookies.get('token')
            }
        })
        .then(res => {
            console.log(res.data);
            setUpdateLog(res.data)
            console.log(res.data.id,"iddd")
           
          
            


        })
        .catch(err => {
            console.log(err);
        })

        axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`,{
          headers: {
              'Authorization': 'Token ' + cookies.get('token')
          }
      })
      .then(res => {
          console.log(res.data);
          setAccomodationInfo(res.data)
           setAccommodation(res.data[0].accommodation)
            // setAccomodationId(res.data)
            console.log(res.data.map((item)=>item.type_of_accommodation));
            setDropAccommodation(res.data.map((item)=>item.type_of_accommodation))
            setDropAccommodationCategory(res.data.map((item)=>item.category_name))
          
      })
      .catch(err => {
          console.log(err);
      })

      axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`,{
        headers: {
            'Authorization': 'Token ' + cookies.get('token')
        }
    })
    .then(res => {
        console.log(res.data);
        setAccLog(res.data);
        // console.log(...new Set(res.data.map((item)=>item.created_date)))
         console.log(...new Set(res.data.map((item)=>item.created_date)))
        // setUniqueDate(...new Set(res.data.map((item)=>item.created_date)));
      
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;    
        }
        var unique =(res.data.map((item)=>item.created_date)).filter(onlyUnique);
        console.log(unique);
        setUniqueDate(unique)
    })
    .catch(err => {
        console.log(err);
    })
 
    }, [])




    let available = []



const onchangehandlee=(event,id)=>{
   //e.preventDefault();
  setCurrentAvailable(event.target.value)
  setAccomodationId(id);

}



    function updateBasic(id) {
      console.log("clicled")
      console.log(currentAvailable);
    console.log(accommodation);
    console.log(accomodationId);

      let formData = new FormData();

      formData.append('accommodation',accommodation)
      formData.append('currently_available',currentAvailable)
      
  
      axios
        .put(
          `partners/accommodation-detail-availability-single-update/${accomodationId}`,
          formData,
  
          {
            headers: {
              'Authorization': 'Token'+' '+cookies.get('token')
            },
          }
        )
        .then((res) => {
          console.log(
            "response from submitting the form successful",
            res.data
          );
          // setContent(res.data)
          // history.goBack();
          location.reload();
           
           
        })
        .catch((err) => {
        
          console.log("ERROR  from update in form", err);
           alert("Allocated number is exceeding currently available. Please check")
        });
      
    }




    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      }
    
    function replaceAll(str, term, replacement) {
        return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
      }


const showTheForm =()=>{
  setShowForm(true)

}
const HideTheForm=()=>{
  setShowForm(false)
}



const filterAccomodation = async (g, acc_name=null) => {
  const tempData = []
  if(acc_name) {
    await  axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {
      res.data.map(item => {
        if(item.type_of_accommodation===acc_name) {
          tempData.push(item);
        }
      })
   
console.log(tempData)
setAccomodationInfo(tempData)

    })
    .catch(err => {
      console.log("error in server", err);
    })
  }
  else {
    await  axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {        
     
      setAccomodationInfo(res.data)
    })
    .catch(err => {
      console.log("server error", err);
    })
  }

  const tempData2 = []

  if(acc_name) {
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {
      res.data.map(item => {
        if(item.type_of_accommodation===acc_name) {
          tempData2.push(item);
        }
      })
   
console.log(tempData)

setAccLog(tempData2);

    })
    .catch(err => {
      console.log("error in server", err);
    })
  }
  else {
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {        
    
      setAccLog(res.data);
      
    })
    .catch(err => {
      console.log("server error", err);
    })
  }
}



const filterCategory = async (g, acc_category=null) => {
  const tempData = []
  if(acc_category) {
    await  axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {
      res.data.map(item => {
        if(item.category_name===acc_category) {
          tempData.push(item);
        }
      })
   
console.log(tempData)
setAccomodationInfo(tempData)

    })
    .catch(err => {
      console.log("error in server", err);
    })
  }
  else {
    await  axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {        
     
      setAccomodationInfo(res.data)
    })
    .catch(err => {
      console.log("server error", err);
    })
  }

  const tempData2 = []

  if(acc_category) {
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {
      res.data.map(item => {
        if(item.category_name===acc_category) {
          tempData2.push(item);
        }
      })
   
console.log(tempData)

setAccLog(tempData2);

    })
    .catch(err => {
      console.log("error in server", err);
    })
  }
  else {
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {        
    
      setAccLog(res.data);
      
    })
    .catch(err => {
      console.log("server error", err);
    })
  }
}


const filterDate = async (g, acc_date=null) => {
 

  const tempData2 = []
  console.log(acc_date)
  if(acc_date) {
    
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {
      res.data.map(item => {
        if(item.created_date===acc_date) {
          tempData2.push(item);
        }
      })
   
// console.log(tempData)

setAccLog(tempData2);

    })
    .catch(err => {
      console.log("error in server", err);
    })
  }
  else {
    await  axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`, {
      headers: {
        'Authorization': 'Token'+' '+cookies.get('token')
        }
    })
    .then(res => {   
           
    
      setAccLog(res.data);
      
    })
    .catch(err => {
      console.log("server error", err);
    })
  }
}


const resetButton =()=>{

  axios.get(`partners/accommodation-basic-info-detail-for-distribution/${props.match.params.id}`,{
    headers: {
        'Authorization': 'Token ' + cookies.get('token')
    }
})
.then(res => {
    console.log(res.data);
    setUpdateLog(res.data)
    console.log(res.data.id,"iddd")
    


})
.catch(err => {
    console.log(err);
})

axios.get(`partners/accommodation-distribution-detail/${props.match.params.id}`,{
  headers: {
      'Authorization': 'Token ' + cookies.get('token')
  }
})
.then(res => {
  console.log(res.data);
  setAccomodationInfo(res.data)
   setAccommodation(res.data[0].accommodation)
    // setAccomodationId(res.data)
  
})
.catch(err => {
  console.log(err);
})

axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`,{
headers: {
    'Authorization': 'Token ' + cookies.get('token')
}
})
.then(res => {
console.log(res.data);
// setUpdateLog(res.data)
 console.log(res.data)
setAccLog(res.data);



})
.catch(err => {
console.log(err);
})


}

      



    return (
        <div style={{paddingTop:"10px"}}>
        <div className="header-top-space">
      <div className="containerrr p-3">
      <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
        Manage Inventory 
        </h3>


        <div className="row-lg-6" >
        {updateLog != null &&   
          <div className="p-2 bg-white">    

           <h4 style={{marginBottom:"20px"}}>Total capacity in accommodation Distribution</h4>
          <div className="row">
          <div className="col-sm-4">
            <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
              Tent
            </p>
            <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {updateLog.tent}  </b> </span>
          </div>
          <div className="col-sm-4">
            <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
             Villa
            </p>
            <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {updateLog.villa} </b> </span>
          </div>
          <div className="col-sm-4">
            <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
             Tree House
            </p>
            <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {updateLog.tree_house} </b> </span>
          </div>
          <div className="col-sm-4">
            <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
            Permanent Structure
            </p>
            <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {updateLog.permanent_structure} </b> </span>
          </div>
          <div className="col-sm-4">
            <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
            cottage
            </p>
            <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}>{updateLog.cottage}  </b> </span>
          </div>
          <div className="col-sm-4">
          <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
          Camping Site
          </p>
          <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}>{updateLog.camping_site}  </b> </span>
        </div>
        </div>
              </div>
      }
      


  
    

    <h2 style={{textAlign:"left",padding:"10px",background:'lightgreen',color:'black',padding:'.5rem',fontWeight:'500',width:"100px"}}>Filter</h2>

      


      {accomodationInfo &&

      <div className="table-responsive">
                <table class="table table-hover my-2 text-left" style={{fontSize:"1.1rem"}}>
                <thead className="tableHeadCategory ">
                        <tr>
                            <th className="fixedTableHead"><DropdownButton id="dropdown-basic-button" title="Accomodation Type">
                            {dropAccommodation &&  dropAccommodation.map((item)=>
                            <Dropdown.Item  onClick={(e)=>filterAccomodation(e,item)}>{item}</Dropdown.Item>
                          )}
                          </DropdownButton></th>
                            
                            <th className="fixedTableHead"><DropdownButton id="dropdown-basic-button" title="Accomodation Category">
                            {dropAccommodationCategory &&  dropAccommodationCategory.map((item)=>
                            <Dropdown.Item   onClick={(e)=>filterCategory(e,item)}>{item}</Dropdown.Item>
                          )}
                          </DropdownButton></th>
                            <th className="fixedTableHead"></th>
                            <th className="fixedTableHead"></th>

                            <th className="fixedTableHead"><DropdownButton id="dropdown-basic-button" title="Date">
                            {uniqueDate && uniqueDate.map(  (item)=>
                            <Dropdown.Item   onClick={(e)=>filterDate(e, item)}>{item}</Dropdown.Item>
                            
                             ) }
                          </DropdownButton></th>
                          <th><button className="btn btn-primary mx-2 mt-1" onClick={resetButton}>Reset</button></th>

                        </tr>
                    </thead>
                    <thead className="tableHeadCategory ">
                        <tr>
                            <th className="fixedTableHead">Accommodation Type</th>
                            
                            <th className="fixedTableHead">Accommodation Category</th>
                            <th className="fixedTableHead">Allocated Rooms</th>
                            <th className="fixedTableHead">Presently Available</th>

                            <th className="fixedTableHead">Date</th>

                        </tr>
                    </thead>
                    <tbody>
                    {accomodationInfo &&
                      
                      accomodationInfo.map((item)=>
                      
                            <tr className="tableRowCategory spacer">
                            
     
                        <td> 
                        {item.type_of_accommodation}
                        </td>
                        <td>
                        {item.category_name}
                        </td>
                        <td>{item.no_of_room_this_type}</td>
                        {(showForm==false)?
                          <>
                        <td>
                        {(item.currently_available)==null?0:item.currently_available}  
                        </td>
                        </>:<></>
                      }
                     
                      
                        <td>
                        
                        
                        {showForm?
                          
                        <form className="showStyle">
                        
                        
                        <input type="text"
                          // onChange={handleonChange}  
                           //value={item.currently_available}
                       
                         onChange={(e)=>onchangehandlee(e,item.id)}
                           name='currently_available'
                           placeholder="Number"/>

                      </form>:null
                        }
                        
                        </td>
                        
                      
                        <td>
                       <button className="btn btn-primary mx-2 mt-1" onClick={showTheForm}>Edit</button>
                 <button className="btn btn-primary mx-2 mt-1" onClick={HideTheForm}>Cancel</button>
                 {(showForm==true)?
                 <button className="btn btn-primary mx-2 mt-1" onClick={updateBasic}>Submit</button> :<div></div>
                 }
                        </td>


                       


                                
                               
                               
                            </tr>
                       ) }


                       
                    </tbody>
                    <h4 style={{textAlign:"left",padding:"10px",background:'lightgreen',color:'black',padding:'.5rem',fontWeight:'500',width:"80px"}}>Logs</h4>


                    <tbody>

                    {accLog.map((item)=>
                      


                      <tr className="tableRowCategory spacer">
                      <td>{item.type_of_accommodation}</td>
                      <td>{item.category_name}</td>
                      <td> </td>
                      <td>{item.availability}</td>
                     
                     
                     
                      <td>{item.created_date}</td>


                      
                      
                      
                      
                      
                      
                      
                      </tr>
)}
                    
                    
                    
                    </tbody>
                </table>
                
            </div>
      }






        
        
            </div>
            
            </div>
            </div>
        </div>
    )
}

export default EditInventoryLog
