import React, { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import I1 from "./template.png";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import { RadioGroup } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./accom_finance.css";
import createPalette from "@material-ui/core/styles/createPalette";
import Visit from 'react-visit';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});
export default function AccomodationFinance() {
  const classes = useStyles();
  const { accomodation_id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [update, setUpdate] = React.useState(false);

  const [Plug, setPlug] = useState("");
  const handlePlug = (event) => {
    setPlug(event.target.value);
  };

  const [paymentOptionArr, setPaymentOptionArr] = React.useState([]);
  const [finance, setFinance] = React.useState({
    accommodation: accomodation_id,
    account_name: null,
    country_detail: null,
    intermediary_bank_charge: null,
    intermediary_bank_name: null,
    intermediary_bank_address: null,
    intermediary_swift_address: null,
    intermediary_country_detail: null,
    intermediary_account: null,
    account_number: null,
    confirm_account_number: null,
    swift_code: null,
    ifsc_code: null,
    bank_name: null,
    branch_address: null,
    payment_option: null,
    trade_name: null,
    gstin: null,
    pan: null,
    payment_link: null,
    upiid: null,
  });

  const [error, setError] = useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    getAccommodationFinance();
  }, [user]);

  function getAccommodationFinance() {
    axios
      .get(`partners/accommodation-finance-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          let obj = JSON.parse(res.data[0].payment_option);
          // let obj = res.data[0].payment_option;
          // console.log('obje of payment opton',obj);
          setFinance(res.data[0]);
          setPaymentOptionArr(obj);
        } else {
          setUpdate(false);
        }

        console.log("response from get finance", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation finance list", err);
      });
  }
  const handleChange = (event) => {
    setFinance({ ...finance, [event.target.name]: event.target.value });
  };

  function handelChangePaymentOptions(e) {
    console.log("e ", e.target.value, "e name", e.target.name);
    let arr = paymentOptionArr;
    if (paymentOptionArr.includes(e.target.value)) {
      let arrIndex = paymentOptionArr.indexOf(e.target.value);
      paymentOptionArr.splice(arrIndex, 1);
    } else {
      paymentOptionArr.push(e.target.value);
    }
    // typesArr = [];
    setPaymentOptionArr([...paymentOptionArr]);
    console.log("type arr", paymentOptionArr);
  }

  const handleChangeCheckbox = (event) => {
    setFinance({ ...finance, [event.target.name]: event.target.checked });
  };

  const submitAccommodationFinance = () => {
    let financeObj = finance;
    financeObj.payment_option = JSON.stringify(paymentOptionArr);
    if(finance.confirm_account_number === finance.account_number){
    axios
      .post("partners/accommodation-finance-create", financeObj, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation finanace create",
          res.data
        );
        history.push("/accomodation/documents/7/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("error from creating accomodation finance", err);
      });
    }else{
      toast('Account Number and Confirm Account Number is not same');
    }
  };

  function updateAccommodationFinance() {
    // if (finance.confirm_account_number === finance.account_number) {
      let financeObj = finance;
      financeObj.payment_option = JSON.stringify(paymentOptionArr);
      //if(finance.confirm_account_number === finance.account_number){
      axios
        .put(
          `partners/accommodation-finance-back-update/${financeObj.id}`,
          financeObj,
          {
            headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
            },
          }
        )
        .then((res) => {
          console.log(
            "response from submitting the accomodation finanace create",
            res.data
          );
          history.push("/accomodation/documents/7/" + accomodation_id);
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
          console.log("error from creating accomodation finance", err);
        });
      setError(false);
    // } else {
    //   setError({
    //     message: "account number and confirm account number doesn't match",
    //   });
    // }
     // }
      //else{
        //toast('Account Number and Confirm Account Number is not same');
      //}
  }
  console.log(
    "change in password",
    finance.account_number,
    finance.confirm_account_number
  );
const handlevisit = () => {
  window.scrollTo(0,0);
}
  return (
    <div className="container">
      <Visit 
        onVisit={() => handlevisit()}
      />
      <div className="text-left">
        <br></br>
        <h3> Accommodation: Finance </h3>
        <div className="p-3 mb-3 bg-white">
          <p><span style={{ color: "red" }}>*</span> mandatory field</p>
          <div>
            <h5>Note:</h5>
            <p>
              <ul>
              <li><u>It is recommended to provide all informations.</u> </li>
                <li>
                  <b>Address of Bank Branch:</b> The full address or the bank along
                  with the pin code of the area has to be mentioned. For
                  countries which do not have 'PIN' codes, the area code to be
                  mentioned.{" "}
                </li>
                {/* <li> This allows to create categories for various combination of pricing range and accomodation type. </li>
                           <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
              </ul>
            </p>
          </div>
        </div>
        {error && <div className="confirm_error">{error.message}</div>}
      </div>
      <div className="Container_box">
        <div className="payment_logo d-flex mb-2">
          <div className="d-flex">
            <div className="p-1">
              {/* <img className="c_logo" src={I1} /> */}
            </div>
            <div className="p-1 my-auto">
              <h5>Account Detail</h5>
            </div>
          </div>
        </div>

        <Grid xs p={2}>
          <div>
            <label>Name of Account Holder <span style={{ color: "red" }}> * </span></label>
            <TextField
              placeholder="your full name as in bank account"
              variant="outlined"
              fullWidth
              name="account_name"
              color="primary"
              value={finance.account_name}
              style={{ marginBottom: "1rem" }}
              onChange={handleChange}
              Default
            />
            <label>Account Number / IBAN <span style={{ color: "red" }}> * </span></label>
            <TextField
              placeholder="your account number (only numbers are allowed) "
              variant="outlined"
              fullWidth
              name="account_number"
              style={{ marginBottom: "1rem" }}
              color="primary"
              value={finance.account_number}
              onChange={handleChange}
              Default
            />
            <br></br>
            {!update&&
            <span>
            <label>Confirm Account Number / IBAN <span style={{ color: "red" }}> * </span></label>
            <br></br>
            <TextField
              placeholder="confirm your account number (only numbers are allowed) "
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              fullWidth
              name="confirm_account_number"
              color="primary"
              value={finance.confirm_account_number}
              onChange={handleChange}
              Default
            />
            </span>
            }
            <br />
            <label> Name of Bank<span style={{ color: "red" }}> * </span></label>
            <br></br>
            <TextField
              placeholder="Full Name of Bank"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="bank_name"
              color="primary"
              value={finance.bank_name}
              onChange={handleChange}
              Default
            />
            <br />
            <label> Complete Address of Bank Branch<span style={{ color: "red" }}> * </span></label>
            <br></br>
            <TextField
              placeholder="Address as in Bank Account"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="branch_address"
              color="primary"
              value={finance.branch_address}
              onChange={handleChange}
              Default
            />

            <label>Country Detail<span style={{ color: "red" }}> * </span></label>
            <br></br>
            <TextField
              placeholder="Country Name "
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="country_detail"
              color="primary"
              value={finance.country_detail}
              onChange={handleChange}
              Default
            />

            <label>SWIFT Code</label>
            <br></br>
            <TextField
              placeholder="8-11 Digit/Character SWIFT CODE"
              variant="outlined"
              fullWidth
              name="swift_code"
              style={{ marginBottom: "1rem" }}
              color="primary"
              value={finance.swift_code}
              onChange={handleChange}
              Default
            />

            <label>IFSC Code</label>

            <TextField
              placeholder="11 Digit/Character IFSC CODE (for Indian Banks)"
              variant="outlined"
              fullWidth
              name="ifsc_code"
              color="primary"
              style={{ marginBottom: "1rem" }}
              value={finance.ifsc_code}
              onChange={handleChange}
              Default
            />

            <label>Is Intermediary Bank Charge Applicable</label>
            <br></br>
            <Select
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_bank_charge"
              color="primary"
              value={finance.intermediary_bank_charge}
              onChange={handleChange}
              Default
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
            {/* <TextField
              variant="outlined"
              fullWidth
              name="intermediary_bank_charge"
              color="primary"
              value={finance.intermediary_bank_charge}
              onChange={handleChange}
              Default
            placeholder="Yes/No" />  */}

            <label>Intermediary Account Number</label>
            <br></br>
            <TextField
              placeholder="intermediary account number (only numbers are allowed)"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_account"
              color="primary"
              value={finance.intermediary_account}
              onChange={handleChange}
              Default
            />
            <label>Intermediary Bank Name</label>
            <br></br>
            <TextField
              placeholder="intermediary bank full name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_bank_name"
              color="primary"
              value={finance.intermediary_bank_name}
              onChange={handleChange}
              Default
            />
            <label>Intermediary Bank Address</label>
            <br></br>
            <TextField
              placeholder="intermediary bank full address"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_bank_address"
              color="primary"
              value={finance.intermediary_bank_address}
              onChange={handleChange}
              Default
            />
            <label>Intermediary SWIFT Code</label>
            <br></br>
            <TextField
              placeholder="8-11 Digit/Character SWIFT CODE"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_swift_address"
              color="primary"
              value={finance.intermediary_swift_address}
              onChange={handleChange}
              Default
            />
            <label>Intermediary Country Detail</label>
            <br></br>
            <TextField
              placeholder="intermediary country name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
              name="intermediary_country_detail"
              color="primary"
              value={finance.intermediary_country_detail}
              onChange={handleChange}
              Default
            />
          </div>
        </Grid>
      </div>
      <br></br>
      
      <div className="finance_account_details">
        <div className="finance_account_details_header">
          {/* <img src={I2}></img> */}
          <h5>Payment Options Available</h5>
        </div>
        <br />
        <RadioGroup >
        <div className="Main">
          <div className="Components">
            <FormControlLabel
              control={
                <Checkbox
                  value="credit_card"
                  name="credit_card"
                  color="primary"
                  checked={paymentOptionArr.includes('credit_card') ? true : false}
                  onChange={handelChangePaymentOptions}
                />
              }
              label="Credit Card"
            />
          </div>
        </div>

        <div className="Main">
          <div className="Components">
            <FormControlLabel
              control={
                <Checkbox
                    value="PayPal"
                    name="PayPal"
                    color="primary"
                    checked={paymentOptionArr.includes('PayPal') ? true : false}
                    onChange={handelChangePaymentOptions}
                />
              }
              label="Pay Pal"
            />
          </div>
        </div>
        <div className="Main">
          <div className="Components">
            <FormControlLabel
              control={
                <Checkbox
                    value="BankTransfer"
                    name="BankTransfer"
                    color="primary"
                    checked={paymentOptionArr.includes('BankTransfer') ? true : false}
                    onChange={handelChangePaymentOptions}
                />
              }
              label="Bank Transfers / Online Banking"
            />
          </div>
        </div>
        <div className="Main">
          <div className="Components">
            <FormControlLabel
              control={
                <Checkbox
                  value="UPI"
                  name="UPI"
                  color="primary"
                  checked={paymentOptionArr.includes('UPI') ? true : false}
                  onChange={handelChangePaymentOptions}
                />
              }
              label="UPI"
            />
          </div>
        </div>
      </RadioGroup>
    </div>
      <br></br>
      <div className="bg-white p-3 text-left">
        <div className="payment_logo d-flex mb-2">
          <div className="d-flex">
            <div className="p-1">
              {/* <img className="c_logo" src={I1} /> */}
            </div>
            <div className="p-1 my-auto">
              <h5>Tax & Services</h5>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <Grid xs>
            <div>
              <label>Trade Name</label>

              <br></br>
              <TextField
                placeholder="name of your business "
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
                name="trade_name"
                color="primary"
                value={finance.trade_name}
                onChange={handleChange}
                Default
              />
              <label>GSTIN</label>
              <br></br>
              <TextField
                placeholder="15 Digit/Character GSTIN"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
                name="gstin"
                color="primary"
                value={finance.gstin}
                onChange={handleChange}
                Default
              />
              
              
              <label>PAN</label>
              <br></br>
              <TextField
                placeholder="10 Digit/Character PAN Number"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
                Default
                name="pan"
                color="primary"
                value={finance.pan}
                onChange={handleChange}
              />

              <label>Payment Link</label>
              <br></br>

              <TextField
                placeholder="3rd party secure payment link "
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
                Default
                name="payment_link"
                color="primary"
                value={finance.payment_link}
                onChange={handleChange}
              />

              <label>UPI ID</label>
              <br></br>
              <TextField
                placeholder="valid upi id"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
                Default
                name="upiid"
                color="primary"
                value={finance.upiid}
                onChange={handleChange}
              />
            </div>
          </Grid>
        </div>
      </div>
      <br></br>
      <div className="text-center my-4 mb-2">
        {/* <Link to={"/accomodation/policy/6/" + accomodation_id}> */}
          <button onClick = {() => history.goBack()} className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        {/* </Link> */}
        {update ? (
          <button
            onClick={updateAccommodationFinance}
            className="btn btn-primary mx-2"
          >
            UPDATE
          </button>
        ) : (
          <button
            onClick={submitAccommodationFinance}
            className="btn btn-primary mx-2"
          >
            CONTINUE
          </button>
        )}
      </div>
      {/* <button id="fla">BACK</button>
 &nbsp;
      <button onClick={submitAccommodationFinance} id="fla-1">CONTINUE</button> */}
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        
      />
   
    </div>
  );
}
