import React, { Component, useState, useEffect, useRef } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import I2 from "./assests/I2.png";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;
function Safari_policy() {
  const document_ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { safari_id, id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [uploadingMedia, setuploadingMedia] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);

    getPolicyData();
  }, [user]);

  const [policy, setPolicy] = useState({
    safari: id,
    document: null,
    other: null,
    customers_review: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    document_change: false,
    document: null,
  });

  const [extension, setextension] = useState(null);
  function uploadFileHandler(key, e) {
    setPolicy({ ...policy, [key]: e.target.files[0] });
    if (e.target.files[0] != null) {
      setextension(e.target.files[0].name.split(".").pop());
    }
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setPolicy({ ...policy, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  const handleChange = (event) => {
    setPolicy({ ...policy, [event.target.name]: event.target.value });
  };

  function submitPolicy() {
    let formData = new FormData();

    for (const [key, value] of Object.entries(policy)) {
      if (key != "document") {
        formData.append(key, value);
      } else {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/safari-package-policy-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package policy create",
          res.data
        );
        setuploadingMedia(false);

        history.push("/home/");
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating policy safari package", err);
      });
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  // const [id, setid] = useState(null);

  const getPolicyData = async () => {
    await axios
      .get(`partners/safari-package-policy-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in POLICY GET DATA ", err);
        } else {
          console.log("CURRENCY POLICY GET DATA ", response.data);

          if (response.data && response.data.id) {
            setcheckUpdate(true);
            setPolicy(response.data);
            setImagePreview({
              ...imagePreview,
              document: response.data.document,
            });
            // setid(response.data.id);
          }
        }
      });
  };

  const updateTourBasic = async () => {
    let formData = new FormData();
    formData.append("safari", policy.safari);
    formData.append("customers_review", policy.customers_review);
    formData.append("other", policy.other);
    if (imagePreview.document_change) {
      formData.append("document", policy.document);
    }
    setuploadingMedia(true);
    axios
      .put(`partners/safari-package-policy-back-update/${policy.id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package POLICY  UPDATE",
          res.data
        );
          setuploadingMedia(false);
        history.push("/home/safari-package");
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from UPDATING POLICY safari package", err);
      });
  };

  return (
    <div className="Tour_policies">
      <h4 style={{ display: "flex", color: "#949494" }}>Safari / Game Drive Package: Policies</h4>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
            <li><u>It is recommended to provide all informations.</u> </li>
              <li>
                Your Own Policy Document: You may upload detail policy in pdf
                (Max 2MB Size).{" "}
              </li>
              <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) wherever used. </b> </li>
              <li>
                {" "}
                Customer Review: Format must be as - Name, Review Text and One
                line space between two review.{" "}
              </li>
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>
      
      
      
      <div className="Policies_document">
        <div className="Policies_document_main">
          {/* <img src={I2}></img> */}
          <h7>Policy Document (If Any) </h7>
          {/* <p style={{ color: "#a7a7a7" }}>
            (image resolution should be 900*600 with 3:2 aspect ratio and max
            2MB size)
          </p> */}
        </div>
        <input
          className="d-none"
          type="file"
          ref={document_ref}
          onChange={(e) => uploadFileHandler("document", e)}
        />
        <Button
          onClick={() => {
            document_ref.current.click();
          }}
          variant="contained"
          className="Policies_button"
          color="primary"
        >
          Upload file
        </Button>
        <p className="my-1 text-success">
          {" "}
          {policy.document != null ? "File Selected" : null}{" "}
        </p>
        <div className="multiple-image">
          {(imagePreview.document != null || policy.document != null) && (
            <div
              onClick={() => delUploadFileHandler("document")}
              className="multiple-image-del px-1"
            >
              x
            </div>
          )}
          {(() => {
            //var fileName = imagePreview.document;
            //var fileExtension = fileName.split('.').pop();
            if (
              extension == "pdf" ||
              (policy.document != null &&
                imagePreview.document != null &&
                imagePreview.document.split(".").pop() === "pdf" &&
                policy.document.split(".").pop()) == "pdf"
            ) {
              return (
                <a href={imagePreview.document} target="_blank">
                  {imagePreview.document != null ? (
                    <img
                      src="/images/download.png"
                      className="img-responsive m-2"
                      style={{ width: "50px", height: "30px" }}
                    />
                  ) : (
                    <img
                      src="/images/download2.png"
                      alt="No file"
                      style={{
                        width: "50px",
                        height: "40px",
                        paddingRight: "10px",
                      }}
                    ></img>
                  )}
                </a>
              );
            } else {
              return (
                <a href={imagePreview.document} target="_blank">
                  <img
                    src={
                      imagePreview.document
                        ? imagePreview.document
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "60px", height: "40px" }}
                  />
                </a>
              );
            }
          })()}
        </div>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            About Organization / Company
            <span style={{ marginLeft: "1%", color: "red" }}></span>
          </p>
          <TextField
            value={policy.other}
            onChange={handleChange}
            name="other"
            id="outlined-basic"
            placeholder="Max. 350 Characters"
            variant="outlined"
          />
        </form>
      </div>
      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            Customers Review
            <span style={{ marginLeft: "1%", color: "red" }}></span>
          </p>
          <TextField
            value={policy.customers_review}
            onChange={handleChange}
            name="customers_review"
            id="outlined-basic"
            placeholder="Max. 350 Characters"
            variant="outlined"
          />
        </form>
      </div>
      <div className="my-4 mb-2">
        <Link to={"/safari-package/amenities/4/" + safari_id+'/'+id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>

        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2" disabled={uploadingMedia}>
            {uploadingMedia ? 'UPLOADING...' : 'UPDATE'} 
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitPolicy} disabled={uploadingMedia}>
             {uploadingMedia ? 'UPLOADING...' : 'CONTINUE'} 
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_policy;
