import React, { Component, useState, useEffect } from "react";
import axios from "../../axios";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import "./forgot_pass.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [otp, setOtp] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [token, setToken] = useState(null);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("email");
  const history = useHistory();
  const [otpRecieved, setotpRecieved] = useState(null);
  const [error, seterror] = useState(null);
  const [isSend, setIsSend] = useState(true)

  const resendOtp = () => {
    axios.post('auth/resend/otp', {}, 
    {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(res => {
      console.log(res.data);
      alert('otp sent successfully')
      setIsSend(false)
      setTimeout(() => {
        setIsSend(true)
      }, 60000);
    })
    .catch(err => {
      alert('error in sending otp')
      console.log(err)
    })
  }

  function forgotPassword() {
    axios
      .post("auth/forgot/password", {
        email,
      })
      .then((res) => {
        setToken(res.data.token);
        setForgotPasswordStatus("otp");
        console.log("response from the res email ", res.data);
        setotpRecieved(res.data.otp);
      });
  }

  function forgotPasswordOTP() {
      axios
      .post(
        "auth/forgot/password/verify",
        {
          email,
          otp,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((res) => {
        console.log("response from the res forgot passowrd ", res.data);
        if(res.data.error==='The verification code entered does not match') {
          alert('The verification code entered does not match')
          setForgotPasswordStatus('otp')
        }
        else{
          setForgotPasswordStatus("reset");
          // history.push
        }
      });
      seterror(null);
    
  }

  function resetPassword() {
    if(password === confirmPassword && password){
      axios
      .post(
        "auth/reset/password",
        {
          password,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((res) => {
        history.push("/login");
        console.log("response from the res ", res.data);
        
      });
      seterror(null);
    }
    else{
      seterror({message:'Password not Matched!'})
    }
    
  }

  return (
    <div
      className="header-top-space"
      style={{ height: "100vh", width: "100%" }}
    >
      <div className="d-flex justify-content-center   h-100 w-100 bg-light">
        <div className="p-2 border border-primary mb-5 authCard mt-5">
          <CssBaseline />
          <div className={classes.paper}>
            <div className="image_logo">
              <img className="image_logo" src="images/logo/Logo 003.png" style={{objectFit:'contain'}}/>
            </div>
<br></br>
            <div>Forgot Password</div>
            <form className={classes.form} noValidate>
              <Grid container spacing={0.5}>
                {forgotPasswordStatus == "email" && (
                  <span style={{ width: "100%" }}>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Button
                        id="bit"
                        fullWidth
                        onClick={forgotPassword}
                        variant="outlined"
                        color="primary"
                      >
                        NEXT
                      </Button>
                      <br></br>
                    </Grid>
                  </span>
                )}
                {forgotPasswordStatus == "otp" && (
                  <span style={{ width: "100%" }}>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setOtp(e.target.value)}
                        id="otp"
                        label="Enter OTP"
                        name="OTP"
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Button
                        className="but"
                        fullWidth
                        onClick={forgotPasswordOTP}
                        variant="outlined"
                        color="primary"
                        margin="normal"
                      >
                        Verify OTP
                      </Button>
                    </Grid>                    
                  <Grid item xs={11} style={{marginTop:"5%"}}>
                    { isSend ?
                    <a style={{color: "blue", cursor:"pointer"}} onClick={resendOtp}>Resend OTP</a>
                    :
                    <a style={{color: "black", cursor:"default"}} >Resend OTP (You can resend after One Minute)</a>                  
                    }
                  </Grid>
                    {error && <Box p={1} m={1} style={{background:'lightpink',color:'red'}}>{error.message}</Box>}
                  </span>
                )}
                {forgotPasswordStatus == "reset" && (
                  <span style={{ width: "100%" }}>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        name="password"
                        label=" Confirm Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Button
                        className="but"
                        fullWidth
                        onClick={resetPassword}
                        variant="outlined"
                        color="primary"
                        margin="normal"
                      >
                        Reset Password
                      </Button>
                    </Grid>
                    {error && <Box p={1} m={2} style={{background:'lightpink',color:'red'}}>{error.message}</Box>}

                  </span>
                )}
                <br></br>

                <br />
                <p>
                  <small>
                    {/* By using our service you agree with our */}
                    By using our services, you agree with following 'Terms of Use' & 'Partner Agreement'<br />
                    <strong style={{ marginLeft: "0.5%", color: "#4A8CFF" }}>
                      {/* terms and conditions */}
                         <a className="darkPrimaryColor" href='https://travelunbound-staging.s3.ap-south-1.amazonaws.com/media/Terms/Terms+of+Service+.pdf' target="_blank">Terms Of Use</a>
                         <br />
                         <a className="darkPrimaryColor" href='https://travelunbound-staging.s3.ap-south-1.amazonaws.com/media/Terms/Terms+of+Service+.pdf' target="_blank">Partner Agreement</a>
                    </strong>
                  </small>
                </p>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
