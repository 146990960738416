import React, { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./accomodation_facility.css";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});

export default function AccomodationFacility() {
  const classes = useStyles();
  const { accomodation_id } = useParams();
  const [update, setUpdate] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const [amenities, setAmenities] = React.useState({
    accommodation: accomodation_id,
    desk: false,
    locker: false,
    cloakroom: false,
    storage_table: false,
    electric_kettle: false,
    coffee_maker: false,
    mosquito_net: false,
    universal_plug_point: 1,
    in_room_phone: false,
    wakeup_service: false,
    doctor_on_call: false,
    wheel_chair_access: false,
    private_bath: false,
    health_faucet: false,
    toiletries: false,
    towel: false,
    cleaning_products: false,
    sleeper: false,
    toilet_paper: false,
    cloths_rack: false,
    laundry: false,
    hair_dryer: false,
    steam_iron: "No",
  });

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    getAccomodationAmenities();
  }, [user]);

  function getAccomodationAmenities() {
    axios
      .get(`partners/accommodation-amenity-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          console.log(res.data[0].toilet_paper);
          setAmenities(res.data[0]);
        } else {
          setUpdate(false);
        }
        console.log("response from get amenities", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation amenities list", err);
      });
  }

  const handleChange = (event) => {
    setAmenities({ ...amenities, [event.target.name]: event.target.checked });
  };

  const handleChangeRadio = (event) => {
    setAmenities({ ...amenities, [event.target.name]: event.target.value });
  };

  function submitAccomodationAmenities() {
    axios
      .post("partners/accommodation-amenity-create", amenities, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // console.log('response from submitting the accomodation amineties create', res.data)
        history.push("/accomodation/policy/5/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
        console.log("error from creating accomodation ameities", err);
      });
  }

  function updateAccomodationAmenities() {
    axios
      .put(
        `partners/accommodation-amenity-back-update/${amenities.id}`,
        amenities,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from updating the accomodation amineties create",
          res.data
        );
        history.push("/accomodation/policy/5/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
        console.log("error from updating accomodation ameities", err);
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            <br></br>
            <h3 style={{ color: "#818181", marginBottom: "4%" }}>
              Accommodation: Amenities
            </h3>
            <div className="p-3 mb-3 bg-white">
          {/* <p><span style={{ color: "red" }}>*</span> mandatory field</p> */}
          <div>
            <h5>Note:</h5>
            <p>
              <ul>
              <li><u>It is recommended to provide all informations.</u> </li>
              {/* <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against label where prices to be mentioned.</b> </li> */}
                {/* <li> This allows to create categories for various combination of pricing range and accomodation type. </li>
                           <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
              </ul>
            </p>
          </div>
        </div>



            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="desk"
                        color="primary"
                        checked={amenities.desk}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Desk"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="cloakroom"
                        color="primary"
                        checked={amenities.cloakroom}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Cloakroom"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="locker"
                        color="primary"
                        checked={amenities.locker}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Locker"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="storage_table"
                        color="primary"
                        checked={amenities.storage_table}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Storage Table"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="electric_kettle"
                        color="primary"
                        checked={amenities.electric_kettle}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Electric Kettle"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="coffee_maker"
                        color="primary"
                        checked={amenities.coffee_maker}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Coffee Maker"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="mosquito_net"
                        color="primary"
                        checked={amenities.mosquito_net}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Mosquito Net"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "70px" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="Plug-native-simple">
                    Universal Plug Point
                  </InputLabel>
                  <Select
                    native
                    name="universal_plug_point"
                    color="primary"
                    value={amenities.universal_plug_point}
                    onChange={handleChangeRadio}
                  >
                    <option value={"Not Available"}>Not Available</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </Select>
                </FormControl>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="in_room_phone"
                        color="primary"
                        checked={amenities.in_room_phone}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="In-room Phone"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="wakeup_service"
                        color="primary"
                        checked={amenities.wakeup_service}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Wake-up Service"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="doctor_on_call"
                        color="primary"
                        checked={amenities.doctor_on_call}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Doctor On Call"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="wheel_chair_access"
                        color="primary"
                        checked={amenities.wheel_chair_access}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Wheel Chair Access"
                />
              </div>
            </div>

            <br />

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="private_bath"
                        color="primary"
                        checked={amenities.private_bath}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Private Bath"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="health_faucet"
                        color="primary"
                        checked={amenities.health_faucet}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Health Faucet"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="toiletries"
                        color="primary"
                        checked={amenities.toiletries}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Toiletries"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="towel"
                        color="primary"
                        checked={amenities.towel}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Towel"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="cleaning_products"
                        color="primary"
                        checked={amenities.cleaning_products}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Cleaning Products"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="sleeper"
                        color="primary"
                        checked={amenities.sleeper}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Slippers"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox 
                      name="toilet_paper" 
                      color="primary"
                      checked={amenities.toilet_paper}
                      onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Toilet Paper"
                />
              </div>
            </div>
            <br />
            <br />

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="cloths_rack"
                        color="primary"
                        checked={amenities.cloths_rack}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Cloths Rack"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="laundry"
                        color="primary"
                        checked={amenities.laundry}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Laundry"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="hair_dryer"
                        color="primary"
                        checked={amenities.hair_dryer}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Hair Dryer"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div
                  className="Components question"
                  style={{ marginRight: "1%" }}
                >
                  Steam Iron
                </div>
                <div className="Components option">
                  <ThemeProvider theme={outerTheme}>
                    <RadioGroup
                      row
                      name="steam_iron"
                      onChange={handleChangeRadio}
                      value={amenities.steam_iron}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                </div>
              </div>
            </div>
            <br />
            <div className="text-center my-4 mb-2">
              <Link to={"/accomodation/facility/3/" + accomodation_id}>
                <button className="btn btn-grey border border-primary mx-2">
                  BACK{" "}
                </button>
              </Link>
              {update ? (
                <button
                  onClick={updateAccomodationAmenities}
                  className="btn btn-primary mx-2"
                >
                  UPDATE
                </button>
              ) : (
                <button
                  onClick={submitAccomodationAmenities}
                  className="btn btn-primary mx-2"
                >
                  CONTINUE
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
