import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import "../home.css";
import { Link, NavLink } from "react-router-dom";
import "./groundLogisticPackage.css";
import Cookies from 'universal-cookie';
import * as logisticsActions from "../../store/actions/logistics";

const cookies = new Cookies();

export default function GroundPackage() {
  const [email, setEmail] = useState(null);
  const [accommodationList, setAccommodationList] = useState(null);
  const [tourOperator, setTourOperator] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [inComplete, setInComplete] = useState(false);
  const user = useSelector((state) => state.auth.user);
  let fleetId = useSelector((state) => state.logistic.fleet_id);

  //   console.log('accommodation action',accommodationAction);

  useEffect(() => {
    getAccomodationBasicsList();
    //getTourSafariOperator()
  }, [user]);

  // if (!user) {
  //   history.push("/login");
  // }
  if(!cookies.get('token'))
    history.push('/login')

  {
    /*function getTourSafariOperator() {
    axios.get(`partners/partner-tour-safari-detail`, {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      //   setAccommodationList(res.data);
      setTourOperator(res.data.tour_registration)
      console.log('response from get tour safari operator', res.data);
    }).catch(err => {
      console.log('error getting tour safari operator', err);
    })
  }*/
  }
  const [logisticsId, setlogisticsId] = useState(null);
  function getAccomodationBasicsList() {
    //console.log('outside fleets ids');
    console.log("inside fleets ids");
    axios
      .get(`partners/ground-logistic-fleet-basic-info-list`, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((res) => {
        setAccommodationList(res.data);
        console.log("response from get GROUND FLEET DAHSBOARD", res.data);
        console.log("length ", typeof res.data);
      })
      .catch((err) => {
        console.log("error getting GROUND FLLET DASHBOARD list", err);
      });
  }

  function statusBgColor(status) {
    if (status == "Getting Reviewed") {
      return "yellow";
    } else if (status == "Incomplete") {
      return "lightblue";
    } else if (status == "Published") {
      return "green";
    } else if (status == "Approval Denied") {
      return "red";
    }
  }

  function createTourOperator() {
    history.push("/ground-logistics-registration/basic/1");
  }

  function addAccommodation() {
    try {
      dispatch(logisticsActions.removefleetid());
      history.push(
        "/ground-logistics-package-forms/basic-information/1/" + logisticsId
      );
    } catch (err) {
      console.log("error removing fleet id actions", err);
    }
  }

  function editAccommodation(item, g_id) {
    try {
      dispatch(logisticsActions.fleetid(item.id));
      history.push(`/ground-logistics-package-forms/basic-information/1/${g_id}`);
    } catch (err) {
      console.log("error logging into signin actions", err);
    }
  }

  function viewAccommodation(item) {
    try {
      dispatch(accommodationActions.accommodation(item));
      history.push("/accomodation-view-form/" + item.id);
    } catch (err) {
      console.log("error logging into signin actions", err);
    }
  }
  const [registration, setRegistration] = useState(null);
  const [reviewed, setReviewed] = useState(false);
  const [denied, setDenied] = useState(false);
  const [published, setPublished] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    const getRegistrationData = async () => {
      await axios
        .get(`partners/ground-logistic-all-detail`, {
          headers: {
            // Authorization: "Token " + user.token,
            Authorization: "Token " + cookies.get('token'),
        },
        })
        .then((resp, err) => {
          if (err) {
            console.log("error in registration data", err);
          } else {
            console.log("data on registration data", resp.data);
            setRegistration(resp.data[0]);
            if (resp.data != null && resp.data.length) {
              setlogisticsId(resp.data[0].id);
              if (resp.data[0].status == "Incomplete"|| resp.data.status == "Approved and Edit Incomplete") {
                setInComplete(true);
              } else if (
                resp.data[0].status == "Getting Reviewed" ||
                resp.data.status == "Approved and Edit Getting Reviewed" ||
                resp.data.status == "Rejected and Edit Getting Reviewed"
              ) {
                setReviewed(true);
              } else if (resp.data[0].status == "Approval Denied") {
                setDenied(true);
              } else if (resp.data[0].status == "Published") {
                setPublished(true);
              } else if (
                resp.data.status == "Approved"
              ) {
                setApproved(true);
              }
            }
          }
        });
    };
    getRegistrationData();
  }, [user]);

  function unPublishPartnerHandler(unPublishId) {
    axios
      .put(`partners/ground-logistic-unpublish/${unPublishId}`, {}, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("Error in Unpublishing accomdation package ", err);
        } else {
          console.log(
            "after Puvlishingi the acoomoadatin package ",
            response.data
          );
          alert('Successfully Sent Request To Admin')
        }
      });
  }
  function unPublishPackageHandler(unPublishId) {
    axios
      .put(`partners/ground-logistic-fleet-unpublish/${unPublishId}`, {},{
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("Error in Unpublishing accomdation package ", err);
        } else {
          console.log(
            "after Puvlishingi the acoomoadatin package ",
            response.data
          );
          alert('Successfully Sent Request To Admin')
        }
      });
  }

  function completePackage(item) {
    try {
      dispatch(logisticsActions.fleetid(item.id));
      history.push(
        "/ground-logistics-package-forms/basic-information/1/" + logisticsId
      );
    } catch (err) {
      console.log("error accomodation actions", err);
    }
  }

  return (
    <div>
      <div
        className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white"
        style={{ position: "relative" }}
      >
        <span>
          {" "}
          <img
            src={
              registration && registration.banner_logo
                ? registration.banner_logo
                : "/images/image.png"
            }
            style={{ width: "9rem", borderRadius: "50%", height: "100%" }}
          />
        </span>
        <span className="tour-name">
          {" "}
          <p style={{ font: "normal normal normal 28px/34px Lato" }}>
            {registration != null && registration.name
              ? registration.name
              : "Welcome Ground Logistics Partner"}
          </p>
          <p>
            {registration != null && registration.id
              ? ""
              : 'To register as "Ground Logistics Partner", proceed by clicking button below.'}
          </p>
          <p
            style={{
              background: "linear-gradient(to right top, #65dfc9, #0fe63f)",
              WebkitBackgroundClip: "text",
              fontWeight: "bold",
              WebkitTextFillColor: "transparent",
              marginBottom: "0",
            }}
          >
            Status :{" "}
            {registration != null && registration.status
              ? registration.status
              : " Not Registered"}
          </p>
          {denied && (
            <p style={{ marginTop: "0", fontSize: "12px" }}>
              (refer Admin Comment for details)
            </p>
          )}
        </span>
        <br />
        {/*<div className="tour-btn">*/}
        <div
          className="text-right mt-auto bg-yellow"
          style={{ right: "0", bottom: "1rem" }}
        >
          {inComplete && (
            <Link
              to={{
                pathname: `/ground-logistics-registration/basic/1`,
                state: {
                  groundOperator: registration.id,
                },
              }}
            >
              <button
                //onClick={() => createTourOperator()}
                className="btn btn-primary mx-2"
              >
                {" "}
                Complete Profile
              </button>
            </Link>
          )}
          {reviewed && (
            <Link to={`/ground-logistics-view-form/${registration.id}`}>
              <button className="btn btn-primary mx-2"> View Profile</button>
            </Link>
          )}
          {denied && (
            <>
              <Link to={`/ground-logistics-view-form/${registration.id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <Link
                to={{
                  pathname: `/ground-logistics-registration/basic/1`,
                  state: {
                    groundOperator: registration.id,
                  },
                }}
              >
                <button className="btn btn-primary mx-2">
                  {" "}
                  Edit & Re-Submit
                </button>
              </Link>
              <Link to={"/ground-logistics-admin-form/" + registration.id}>
                <button className="btn btn-primary mx-2"> Admin Comment</button>
              </Link>
            </>
          )}
          {published && (
            <>
              <Link
                to={{
                  pathname: `/ground-logistics-registration/basic/1`,
                  state: {
                    groundOperator: registration.id,
                  },
                }}
              >
                <button className="btn btn-primary mx-2"> Edit Profile</button>
              </Link>
              <Link to={`/ground-logistics-view-form/${registration.id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <button
                onClick={() => unPublishPartnerHandler(registration.id)}
                className="btn btn-primary mx-2"
              >
                {" "}
                UnPublish
              </button>
            </>
          )}
          {approved && (
            <>
              <Link
                to={{
                  pathname: `/ground-logistics-registration/basic/1`,
                  state: {
                    groundOperator: registration.id,
                  },
                }}
              >
                <button className="btn btn-primary mx-2"> Edit Profile</button>
              </Link>
              <Link to={`/ground-logistics-view-form/${registration.id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <button
                onClick={() => unPublishPartnerHandler(registration.id)}
                className="btn btn-primary mx-2"
              >
                {" "}
                UnPublish
              </button>
            </>
          )}
        </div>
      </div>

      <div className="d-flex p-2 justify-content-between">
        <div>
          <h4>Ground Logistics Packages</h4>
        </div>

        {
          //(tourOperator&&!setTourOperator)

          !logisticsId ? (
            <div className="ml-auto">
              <Link
                to={{
                  pathname: `/ground-logistics-registration/basic/1`,
                  state: {
                    groundOperator: 0,
                  },
                }}
              >
                <button
                  //onClick={() => createTourOperator()}
                  className="btn btn-primary"
                  hidden={inComplete || reviewed || denied || published}
                >
                  Register as Partner
                </button>
              </Link>
            </div>
          ) : (
            <div className="ml-auto">
              <button
                onClick={() => addAccommodation()}
                className="btn btn-primary"
                hidden={inComplete || denied}
              >
                Add Ground Logistic Package
              </button>
            </div>
          )
        }
      </div>
      <div>
        {/*<div>
      <Link to={{
                pathname:`/ground-logistics-registration/basic/1`,
                state:{
                  groundOperator:0
                }}}>
              <button 
              onClick={() => createTourOperator()} 
              className="btn btn-primary" 
              
              >Create GroundLogistic Partner</button></Link>

            </div>
            <div className="ml-auto">
            <button onClick={() => addAccommodation()} className="btn btn-primary" 
            
            >Add GroundLogistic Package</button>
              </div>*/}

        {accommodationList != null &&
          accommodationList.map((item) => (
            <div className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white">
              <div
                className="accommodation-list-item-tag d-flex"
                style={{ backgroundColor: statusBgColor(item.status) }}
              >
                <p className="m-auto">{item.status}</p>
              </div>
              <div>
                <img
                  className="img-responsive"
                  src="/images/assets/dummy-home.png"
                  style={{
                    width: "300px",
                    height: "300px",
                    backgroundColor: "green",
                  }}
                />
              </div>
              <div className="p-2 px-3 d-flex align-item-strech justify-content-between w-100">
                <div className="text-left w-50 overflow-auto widthmediaquery">
                  <h4
                    className="accommodation-home-title"
                    style={{
                      background: "lightpink",
                      color: "black",
                      padding: ".5rem",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {item.contact_name}{" "}
                  </h4>
                  {item.national_park && (
                    <p
                      style={{
                        background: "lightpink",
                        color: "black",
                        padding: ".5rem",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      National park: {item.national_park}{" "}
                    </p>
                  )}
                  {item.serving_area && (
                    <p
                      style={{
                        background: "lightpink",
                        color: "black",
                        padding: ".5rem",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Serving Area: {item.serving_area}{" "}
                    </p>
                  )}
                
                  {item.status != "Approval Denied" &&
                    item.status != "Getting Reviewed" &&
                    item.status != "Incomplete" && (
                      <Fragment>
                        <button
                          onClick={() => unPublishPackageHandler(item.id)}
                          className="btn btn-primary mx-2"
                        >
                          {" "}
                          Unpublish
                        </button>
                        {/* <Link
                          to={
                            "/ground-logistics-package-forms/basic-information/1/" +
                            item.id
                          }
                        > */}
                          <button 
                          onClick={() => editAccommodation(item, item.id)}
                          className="btn btn-primary mx-2">
                            {" "}
                            Edit
                          </button>
                        {/* </Link> */}
                        <Link to={`/ground-fleet-view-form/${item.id}`}>
                          <button className="btn btn-primary mx-2">
                            {" "}
                            View
                          </button>
                        </Link>
                      </Fragment>
                    )}
                  {item.status == "Approval Denied" && (
                    <>
                      <Link
                        to={
                          "/ground-logistics-package-forms/basic-information/1/" +
                          item.id
                        }
                      >
                        <button className="btn btn-primary mx-2">
                          {" "}
                          Edit & Re-Submit
                        </button>
                      </Link>
                      <Link to={"/ground-fleet-admin-form/" + item.id}>
                        <button className="btn btn-primary mx-2">
                          {" "}
                          Admin comment
                        </button>
                      </Link>
                    </>
                  )}
                  {item.status != "Approval Denied" &&
                    item.status != "Incomplete" &&
                    item.status != "Published" && (
                      <>
                        {/*<Link to={"/tour-package/basic/1/" + item.id}>
                      <button className="btn btn-primary mx-2"> Edit</button>
              </Link>*/}
                        <Link to={`/ground-fleet-view-form/${item.id}`}>
                          <button className="btn btn-primary mx-2">
                            {" "}
                            View
                          </button>
                        </Link>
                      </>
                    )}
                  {item.status == "Incomplete" && (
                    <Link
                      to={
                        "/ground-logistics-package-forms/basic-information/1/" +
                        logisticsId
                      }
                    >
                      <button
                        onClick={() => completePackage(item)}
                        className="btn btn-primary mx-2"
                      >
                        {" "}
                        Complete
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
