import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import axios from '../../axios';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function TourViewForm() {
    const {id} = useParams();
    const user = useSelector((state) => state.auth.user);
    const [basicInfo, setbasicInfo] = useState(null);
    const [imageList, setimageList] = useState([]);
    const [media,setMedia] = useState(null);
    const [policy, setpolicy] = useState(null);
    const [finance, setfinance] = useState(null);

    useEffect(() => {
        getBasicDetail(); 
        getImageList(); 
        getMediaData();
        getPolicyData();
        getFinanceData();
    }, [])

    const getBasicDetail = async() => {
        await axios.get(`partners/tour-operator-basic-info-detail/${id}`,{
            headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
              }
        }
        ).then((response,err) => {
            if(err){
                console.log('error in tour view form',err);
            }
            else{
                console.log('data on tour view form',response.data);
                setbasicInfo(response.data);
            }
        })
    }

    const getImageList = async() => {
      await axios.get(`partners/tour-operator-image-list/${id}`,{
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
            }
      }
      ).then((response,err) => {
          if(err){
              console.log('error in tour view IMAGELIST form',err);
          }
          else{
              console.log('data on tour view IMAGELIST form',response.data);
              setimageList(response.data);
          }
      })
  }

  const getMediaData = async() => {
    await axios.get(`partners/tour-operator-banner-logo-detail/${id}`,{
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
          }
    }
    ).then((response,err) => {
        if(err){
            console.log('error in tour view MEDIADATA form',err);
        }
        else{
            console.log('data on tour view MEDIADATA form',response.data);
            setMedia(response.data);
        }
    })
}

const getPolicyData = async() => {
  await axios.get(`partners/tour-operator-policy-detail/${id}`,{
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
        }
  }
  ).then((response,err) => {
      if(err){
          console.log('error in tour view POLICY form',err);
      }
      else{
          console.log('data on tour view POLICY form',response.data);
          setpolicy(response.data);
      }
  })
}

const getFinanceData = async() => {
  await axios.get(`partners/tour-operator-finance-detail/${id}`,{
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
        }
  }
  ).then((response,err) => {
      if(err){
          console.log('error in tour view FINANCE form',err);
      }
      else{
          console.log('data on tour view FINANCE form',response.data);
          setfinance(response.data);
      }
  })
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}
    return (
        <div className="header-top-space">
            <div className="container p-3">
       <h3 style={{marginBottom:'2rem',textAlign:'center'}}>Tour Partner View</h3>
        {basicInfo!=null&&
        <div className="p-2 bg-white">
          <h4 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Basic Information</h4>
          <div className="row">
          {basicInfo && 
            Object.entries(basicInfo).map(([key, item]) => (
              <>
                {(() => {  
                                      
                  if (
                    key !== "id" &&
                    key !== "admin_comment" &&
                    key !== "tour_operator_id" &&
                    key !== "approved_status" &&
                    key !== "business" &&
                    key !== "contact_details" &&
                    key !== "created_at" &&
                    key !== "office_details" &&
                    key !== "approved_status" &&
                    key !== "updated_at" &&
                    key !== "address_line_1" &&
                    key !== "address_line_2" &&
                    key !== "partner" &&
                    key !== "zip_code" &&
                    key !== "is_rejected" &&
                    key !== "is_published" &&
                    key !== "final_submission" &&
                    key !== "edit_mode" 
                    ){
                      return (
                        <div className="col-sm-6">
                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                            flexWrap: "wrap",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>
                            {replaceAll(key, "_", " ")}
                          </b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                  </div>
                      );
                    }
                })()} 
                </>
          ))}
            {/* <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>Name of the Company : &nbsp; <b> {basicInfo.name} </b> </p>
            </div>
            
            <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>Year of Establishment : &nbsp; <b> {basicInfo.year_established} </b> </p>
            </div>
            <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>Number of Employee : &nbsp; <b> {basicInfo.employee_no} </b> </p>
            </div>
            
            <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>Currency : &nbsp; <b> {basicInfo.currency} </b> </p>
            </div>
            <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>Offices In : &nbsp; <b> {basicInfo.office_details[0].office_address} </b> </p>
            </div>
            <div className="col-sm-12" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>About Organization/Company : &nbsp; <b> {basicInfo.about_company} </b> </p>
            </div> */}
            
          </div>
          <hr/>
          {/*<h5 style={{marginBottom:'1rem'}}>Escalation Details</h5>
          <div className="row">
            <div className="col-sm-6">
              <p> Escalation email: <b> {basicInfo.escalation_email} </b> </p>
            </div>
            <div className="col-sm-6">
              <p> Escalation name: <b> {basicInfo.escalation_name} </b> </p>
            </div>
            <div className="col-sm-6">
              <p> Escalation number: <b> {basicInfo.escalation_number}</b> </p>
            </div>
            <div className="col-sm-6">
              <p> Escalation number alternate: <b> {basicInfo.escalation_number_alter}</b> </p>
            </div>
          </div>
        <hr/>*/}
          <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Contact Details</h5>
            {basicInfo && basicInfo.contact_details && basicInfo.contact_details.length &&  basicInfo.contact_details.map((item)=>
          <div className="row">
            <div className="col-sm-6" 
            //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
            >
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}> Name : </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {item.name} </b> </span>
              </div>
              <div className="col-sm-6" 
              //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
              >
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}> Email :</p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {item.email} </b> </span>
              </div>
              <div className="col-sm-6" 
              //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
              >
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}> Number :</p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {item.number} </b> </span>
              </div>
              <div className="col-sm-6" 
              //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
              >
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}> Alternative Number :</p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {item.number_alter!==null ? item.number_alter:"No Alternative number"} </b> </span>
              </div>
              <div className="col-sm-6" 
              //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
              >
              <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}> Role :</p>
              <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {item.role} </b> </span>
              </div>
              <br/>
              <hr />
              <br />
              

            {/*Object.entries(item).map(([key,value])=>
              <div className="col-sm-6">
                  <p>{item}</p>
                <p> {key}: <b> {value} </b> </p>
              </div>
            )*/}
          </div>
            )}
                      <hr/>

            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Address</h5>
            <div className="row" style={{padding:'1rem'}}>
                    
                  <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>Address Line 1 :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.address_line_1} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>Address Line 2 :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.address_line_2} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>City :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.city} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>Country :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.country} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>Zip / Postal Code : &nbsp;{basicInfo.zip_code}</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.zip_code} </b> </span>
                    </div>
                     

            </div>
            <hr />
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Image / Video Details</h5>
            <br></br>
              {media && (
              <div className="row">
                <div className="col-sm-6" style={{textAlign:'left',fontWeight:'500'}}
                //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <span>Banner Image   : </span>
                 <a href={media.banner_image} target="_blank"> <img src={media.banner_image?media.banner_image:'/images/download2.png'} alt='banner pics' style={{width:'5rem'}}></img></a>
                </div>
                <div className="col-sm-6" style={{textAlign:'left',fontWeight:'500'}}
                //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <span>Logo Image : </span>
                  <a href={media.logo_image?media.logo_image:'/images/download2.png'} target="_blank">
                  <img src={media.logo_image?media.logo_image:'/images/download2.png'} alt='logo pics' style={{width:'5rem'}}></img>
                  </a>
                </div>
                <br></br>
                <div style={{marginTop:'3rem'}} className="col-sm-12 " 
                //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <a  href={media.tour_operator_video_link} target="_blank" >Video Link</a>
                </div>
              </div>
              )}
            
            <hr />
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Additional Images</h5>
            <div className="row">
              {imageList && imageList.map(item => (
                <div className="col-sm-3" 
                //style={{border: "1px solid grey" , borderRadius:"20px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <a href={item.tour_operator_image?item.tour_operator_image:'/images/download2.png'} target="_blank">
                  <img src={item.tour_operator_image?item.tour_operator_image:'/images/download2.png'} alt='additional pics' style={{width:'5rem'}}></img>
                  </a>
                </div>
              ))}
            </div>
            <hr />
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Policy Details</h5>
            {policy && 
            <div className="row">
                <div className="col-sm-12" 
                //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
            <a href={policy.policy_document} target="_blank"><img src={'/images/download.png'} alt='policy document' style={{width:'5rem'}}></img></a>
                </div>
                <div className="col-sm-12" 
                //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500',marginTop:'3rem'}}>Note : &nbsp; <b> {policy.note} </b> </p>
                </div>
            </div>
            }


            <hr />
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Finance Details</h5>
            {finance && 
            <div className="row">
                {Object.entries(finance).map(([key,item])=>
                 <div className='col-sm-6' >
                 {(()=> {
               
                   if(key != 'id' && key != 'admin_comment' && key != 'approved_status' && key != 'created_at' && key != 'updated_at' && key != 'tour_operator'){
                     return(
                       <p style={{display:'flex',marginRight:'1rem',background:'lightpink',textAlign:'center',padding:'.5rem .5rem .5rem 1rem',textTransform:'capitalize', flexWrap:'wrap'}}>
                         <b style={{flex:1,textAlign:'left'}}>{replaceAll(key,"_"," ")}</b> 
                         <b> {(item==true||item==false)?(item==true?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>):item} </b>  
                       </p>
                     )
                   }
                 })()}
                   </div>
                )}
                
            </div>
            }
            <hr />

       </div>
            }
        </div>
        <div>
            <NavLink to={'/home/tour-package'}>
                <button class="btn btn-primary">Back</button>
                </NavLink>
            </div>
        </div>
    )
}

export default TourViewForm
