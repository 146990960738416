import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import axios from '../../axios';
import { useHistory } from "react-router-dom";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function SafariViewForm() {
    const {id} = useParams();
    const user = useSelector((state) => state.auth.user);
    const [basicInfo, setbasicInfo] = useState(null);
    const [contact, setcontact] = useState(null);
    const [policy, setpolicy] = useState(null);
    const [finance, setfinance] = useState(null);
    const [imageList, setimageList] = useState([]);


    useEffect(() => {
        getBasicDetail(); 
        getPolicies();
        getFinance();
        getImageList();
    }, [])

    const getBasicDetail = async() => {
        await axios.get(`partners/safari-basic-info-detail/${id}`,{
            headers: {
                Authorization: "Token " + cookies.get('token'),
            //   'Authorization': 'Token ' + user.token
              }
        }
        ).then((response,err) => {
            if(err){
                console.log('error in tour view form',err);
            }
            else{
                console.log('data on tour view form',response.data);
                setbasicInfo(response.data);
                setcontact(response.data.safari_contact_details)
            }
        })
    }
    const getImageList = async() => {
        await axios.get(`partners/safari-image-list/${id}`,{
            headers: {
                Authorization: "Token " + cookies.get('token'),
            //   'Authorization': 'Token ' + user.token
              }
        }
        ).then((response,err) => {
            if(err){
                console.log('error in safari view IMAGELIST form',err);
            }
            else{
                console.log('data on safari view IMAGELIST form',response.data);
                setimageList(response.data);
            }
        })
    }
    const getPolicies = async() => {
        await axios.get(`partners/safari-policy-detail/${id}`,{
            headers: {
                Authorization: "Token " + cookies.get('token'),
        //   'Authorization': 'Token ' + user.token
              }
        }
        ).then((response,err) => {
            if(err){
                console.log('error in tour POLICY view form',err);
            }
            else{
                console.log('data on tour view POLICY form',response.data);
                setpolicy(response.data);
                
            }
        })
    }
    const getFinance = async() => {
        await axios.get(`partners/safari-finance-detail/${id}`,{
            headers: {
                Authorization: "Token " + cookies.get('token'),
        //   'Authorization': 'Token ' + user.token
              }
        }
        ).then((response,err) => {
            if(err){
                console.log('error in tour FINANCE view form',err);
            }
            else{
                console.log('data on tour view FINANCE form',response.data);
                setfinance(response.data);
                
            }
        })
    }
    let history = useHistory();

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      }
      
      function replaceAll(str, term, replacement) {
        return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
      }
    return (
        <div className="header-top-space">
            <div className="container p-3">
       <h3 style={{marginBottom:'2rem',textAlign:'center'}}>Safari / Game Drive Partner View</h3>
        {basicInfo!=null&&
        <div className="p-2 bg-white">
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Basic Information</h5>
            <br></br>
            <div className="row" >
                {basicInfo && Object.entries(basicInfo).map(([key,value]) => 
                <div className="col-sm-6 col-lg-6" >
                {(() => {
                    if(key ==='safari_contact_details' && key.length){
                        // return ( 
                        //    <p style={{background:'lightpink',padding:'.5rem'}}>{key} :<b style={{color:"green"}}>contact details are below</b></p>
                        // )
                    }
                    else if(key === 'guide_id_card' || key === 'guide_image' || key === 'guide_permit' || key === 'banner_image' ){
                        return (
                            <div style={{display:'flex',padding:'.5rem',background:'rgba(255,255,255,0.5)'}}>
                                <p style={{flex:1}}> {replaceAll(key,"_"," ")} : </p>
                                <a href={value} target="_blank"><img src={value?value:'/images/download2.png'} alt={key} style={{width:'5rem',marginLeft:"20px"}} className="hoverimagecard"></img></a>
                                
                                </div>
                        )
                    }
                    else if(key != 'id' && key != 'admin_comment' && key != 'approved_status' && key != 'created_at' && key != 'updated_at' && key != 'safari' && key != 'partner' && key != 'business' && key != 'is_published' && key != 'is_rejected' && key != 'final_submission' && key != 'edit_mode'){
                        return(
                          <p style={{display:'flex',marginRight:'1rem',background:'lightpink',textAlign:'center',padding:'.5rem .5rem .5rem 1rem',textTransform:'capitalize', flexWrap:'wrap'}}>
                            <b style={{flex:1,textAlign:'left'}}>{replaceAll(key,"_"," ")}</b> 
                            <b> {(value==true||value==false)?(value==true?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>):value} </b>  
                          </p>
                        )
                      }
                })()}
                </div>
                
                )}
            </div>
            <br></br><br></br>
            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Contact Details</h5>
            <div>
                        {contact!= null && contact.map(element => 
                        <div className="row">
                                {Object.entries(element).map(([keys,values]) => 
                                <div className="col-sm-6" >
                                    {(()=> {
                      
                      if(keys != 'id' && keys != 'admin_comment' && keys != 'approved_status' && keys != 'created_at' && keys != 'updated_at' && keys != 'accommodation' && keys != 'safari_id'){
                        return(
                                    
                                    <p style={{display:'flex',marginRight:'1rem',background:'lightpink',textAlign:'center',padding:'.5rem .5rem .5rem 1rem',textTransform:'capitalize', flexWrap:'wrap'}}>
                            <b style={{flex:1,textAlign:'left'}}>{replaceAll(keys,"_"," ")}</b> 
                            <b> {(values==true||values==false)?(values==true?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>):values} </b>  
                          </p>
                           )
                        }
                      })()}
                                    </div>
                                )}
                                </div>
                            )}
                            </div>
                            <b></b><br></br>
                            <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Additional Images</h5>
             <div className="row">
               {imageList && imageList.map(item => (
                 <div className="col-sm-3">
                     <b></b><br></br>
                   <a href={item.image} target="_blank"><img src={item.image} alt='additional pics' style={{width:'4rem'}}></img></a>
                 </div>
               ))}
             </div>
             <hr />
            </div>
             
            }
            <hr />
            <div className="p-2 bg-white">
            <b></b><br></br>
        <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Policies</h5>
        <div className="row">
            {//pricing && pricing.map(item => (
                policy && Object.entries(policy).map(([key,item]) => 
                <div className="col-sm-6" >
                {(() => {
                    if(key ==='document' ){
                        return ( 
                        <div style={{display:'flex'}}>
                            <p style={{flex:6}}>Document : </p>
                            
                            <a href={item} target="_blank"><img src={item?('/images/download.png'):'/images/download2.png'} style={{width:'5rem',height:'5rem'}}></img></a>  
                        </div>  
                        )
                    }else if(key != 'id' && key != 'admin_comment' && key != 'approved_status' && key != 'created_at' && key != 'updated_at' && key != 'safari'){
                        return(
                          <p style={{display:'flex',marginRight:'1rem',background:'lightpink',textAlign:'center',padding:'.5rem .5rem .5rem 1rem',textTransform:'capitalize', flexWrap:'wrap'}}>
                            <b style={{flex:1,textAlign:'left'}}>{replaceAll(key,"_"," ")}</b> 
                            <b> {(item==true||item==false)?(item==true?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>):item} </b>  
                          </p>
                        )
                      }
                })()}
                </div>
                    
                    )
            //))
            }  
            </div>
            
        </div>
        <hr />
        <div className="p-2 bg-white">
        <h5 style={{marginBottom:'1rem',color:'green',fontWeight:'bold'}}>Safari Registration Finance</h5>
        <div className="row">
            {//pricing && pricing.map(item => (
                finance && Object.entries(finance).map(([key,item]) => 
                        <div className='col-sm-6' >
                        {(()=> {
                      
                          if(key != 'id' && key != 'admin_comment' && key != 'approved_status' && key != 'created_at' && key != 'updated_at' && key != 'accommodation'){
                            return(
                              <p style={{display:'flex',marginRight:'1rem',background:'lightpink',textAlign:'center',padding:'.5rem .5rem .5rem 1rem',textTransform:'capitalize'}}>
                                <b style={{flex:1,textAlign:'left'}}>{replaceAll(key,"_"," ")}</b> 
                                <b> {(item==true||item==false)?(item==true?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>):item} </b>  
                              </p>
                            )
                          }
                        })()}
                          </div>
                    
                    )
            //))
            }  
            </div>
            
        </div>
        <hr />
        <div>
            <NavLink to={'/home/safari-package'}>
                <button class="btn btn-primary">Back</button>
                </NavLink>
            </div>
        </div>
        {/*<button onClick={() => history.goBack()}>Back</button>*/}
        
        </div>
    )
}

export default SafariViewForm
