import React, {useEffect, useState} from 'react';

import axios from "../../axios";
import Cookies from 'universal-cookie';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CompareArrowsOutlined } from '@material-ui/icons';
const cookies = new Cookies();

const ViewInventoryLog = (props) => {
    const[viewInventory,setViewInventoryLog]=useState([]);


    useEffect(() => {
        axios.get(`partners/accommodation-inventory-update-log/${props.match.params.id}`,{
            headers: {
                'Authorization': 'Token ' + cookies.get('token')
            }
        })
        .then(res => {
            console.log(res.data);
            setViewInventoryLog(res.data)
            //console.log(res.data.id,"iddd")
            // setAccomodationId(res.data.id)


        })
        .catch(err => {
            console.log(err);
        })

        
    }, [])

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      }
    
    function replaceAll(str, term, replacement) {
        return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
      }







    return (
        <div style={{paddingTop:'100px'}}>
        <h2> View Inventory Log</h2>

        <div style={{paddingTop:"30px"}}>
        <div className="header-top-space">
      <div className="container p-3">
      <div className="row">
      {viewInventory &&
        viewInventory.map((item) =>
          Object.entries(item).map(([key, item]) => (
            <>
              {(() => {
               
                if (
                  key != "id" 
                  
                ) {
                  return (
                   
            <div className="col-sm-6">
                    <p
                      style={{
                        display: "flex",
                        marginRight: "1rem",
                        background: "lightpink",
                        textAlign: "center",
                        padding: ".5rem .5rem .5rem 1rem",
                        textTransform: "capitalize",
                        flexWrap: "wrap",
                      }}
                    >
                      <b style={{ flex: 1, textAlign: "left" }}>
                        {replaceAll(key, "_", " ")}
                      </b>
                      <b>
                        {item}
                      </b>
                    </p>
                    </div>
                  
                  );
                }
              })()}
            </>
          ))
        )}





        
      
      
      
        </div>
      
      </div>
      </div>
      </div>
            
        </div>
    )
}

export default ViewInventoryLog
