import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import Safari_amneties from './Safari_amneties.js'
import Safari_basic from './Safari_basic.js'
import Safari_daybyday from './Safari_daybyday';
import Safari_features from './Safari_features'
import Safari_Tour_Guide from './Safari_Tour_Guide.js'
import Safari_policy from './Safari_policy.js'
import Safari_pricing from "./Safari_pricing.js";
import BasicAccommodationImages from './Basic_accommodation_images.js';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function TourReg() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    // let accommodationAction = useSelector((state) => state.accommodation.accommodation);
    // if (accommodationAction == null) {
    //     accommodationAction = {}
    //     accommodationAction['id'] = 0;
    // }

    useEffect(() => {
        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

    
    return (
        <div className="header-top-space">
            <div className="d-sm-block text-center  d-none">
                <div className="d-flex  justify-content-around step-form">
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="stepper-title" >Basic Information</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="stepper-title">Day-by-Day Plan</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="stepper-title">Feature & Pricing</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="stepper-title">Feature & Facilities</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 5) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 5) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 5</span>
                            </div>
                        </div>
                        <p className="stepper-title">Amenities</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 6) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 6) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 6</span>
                            </div>
                        </div>
                        <p className="stepper-title">Tour Guide</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 7) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 7) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 7</span>
                            </div>
                        </div>
                        <p className="stepper-title">Policies</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 8) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 8) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 8</span>
                            </div>
                        </div>
                        <p className="stepper-title">Images</p>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path="/tour-package/basic/1/:tour_id" exact component={Safari_basic} />
                <Route path="/tour-package/day-plan/2/:tour_id" exact component={Safari_daybyday} />
                <Route path="/tour-package/pricing/3/:tour_id" exact component={Safari_pricing} />
                <Route path="/tour-package/facility/4/:tour_id" exact component={Safari_features} />
                <Route path="/tour-package/amenities/5/:tour_id" exact component={Safari_amneties} />
                <Route path="/tour-package/tour-guide/6/:tour_id" exact component={Safari_Tour_Guide} />
                <Route path="/tour-package/policy/7/:tour_id" exact component={Safari_policy} />
                <Route path="/tour-package/accommodation-images/8/:tour_id" exact component={BasicAccommodationImages} />
            </Switch>
        </div>
    );
}