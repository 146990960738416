import React, { Component,useState, useEffect } from 'react';
import axios from '../../axios';
import { Link, NavLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './sign-up.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as authActions from '../../store/actions/auth';
import PhoneInput from "react-phone-number-input";
import question from "../../Images/question.png";
import iimage from "../../Images/iimage.jpg";
import { useHistory } from "react-router-dom";
import {Modal} from 'react-bootstrap';



import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Signup() {
  const classes = useStyles();
  const [name,setName] = useState(null);
  const [email,setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, seterrors] = useState("");
  const[phoneNumber,setPhoneNumber]=useState('');
  const[isToken,setIsToken]=useState('');


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  
  const user_detail = useSelector((state) => state.auth.user);

  // useEffect(()=>{

  //   console.log('SIGNUP ERROR user details',user_detail);
  // },[user_detail])

 function signup() {
    let formData = new FormData();
    if(password !== confirmPassword || password==null){
      seterrors('Confirm Password not Matched!');
    }
   if(email==null || name==null){
      seterrors('Enter Email/Name!')
    }
    if(phoneNumber==''){
      seterrors("Enter Phone Number")

    }
    if(confirmPassword === password && password!=null && email!=null && name!=null) {
      seterrors(null)
      let is_partner = '1';


      // formData.append("phone_number",phoneNumber);


      axios.post('auth/register', {
        name,
        email,
        password,
      phone_number:phoneNumber,
        is_partner
      }).then(res => {
        Cookies.set("token",res.data.token)
        Cookies.set("name",res.data.profile.name)
        Cookies.set("id",res.data.id)
       history.push('/email-verification')     
        console.log('response from the register res ', res.data)
        setIsToken(res.data.token)
        console.log('is token test',isToken)
        // sessionStorage.setItem('user',JSON.stringify(res.data));
        try{
          dispatch(authActions.signup(name,email,password,phoneNumber,response));
          console.log('Istoken',tokenTest)
        }catch(err){
          
          console.log('err signup ',err)
          seterrors(err);
        }
      }).catch((err)=>{
        let {data} = err;
          // seterrors(res.data.errors.email.map((err) => {return err}))
        console.log(data)
      })
    }
    
    
    // axios.post('auth/register',{
    //   name, 
    //   email,
    //   password
    // }).then(res=>{
    //   history.push('/email-verification')
    //   console.log('response from the register=>',res.data);
    // }).catch((err) => {
    //   console.log(err,"register errrrrrrrrr")
    // })
    
  }
  const {user,error} = useSelector((state) => state.auth);
    // useEffect(() => {
    //   if(user){
    //     history.push('/email-verification');
    //   }
    
    // },[user])

  return (
    <div className="header-top-space" style={{ height: '100vh', width: '100%' }}>
      <div className="d-flex justify-content-center   h-100 w-100 bg-light" >
        <div className="p-2 border border-primary authCard mt-5" >
          <CssBaseline />
          <div className={classes.paper}>

            <div className="image_logo" >
              <img className="image_logo" src="images/logo/Logo 003.png" style={{objectFit:'contain'}}/>
            </div>
<br></br>
            <div className="head">

              SIGN UP
          </div>
            <form className={classes.form} noValidate>
              {errors && 
              <Box p={1} m={1} style={{color:'red',background:'lightpink'}}>{errors}</Box>
              }
              {
                error && 
              <Box p={1} m={1} style={{color:'red',background:'lightpink'}}>{'Email is already Taken'}</Box>
              }
              <Grid container spacing={0.5}>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Fullname"
                    onChange={e=>setName(e.target.value)}
                    label="Full name"
                    type="text"
                    autoComplete="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setEmail(e.target.value)}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>
                <div className='toolTip' style={{marginTop:"30px",marginLeft:"3px"}}><img  src={iimage} style={{width:"22px",height:"20px"}}/>
                <span className="tooltiptext" >Please provide a valid & working email-id and duely check it for typos since your email-id will be primary id for your partner account and used for any future communications. </span>
                </div>
                <Grid item xs={11} style={{marginTop:"10px",marginBottom:"10px"}} >
               
              
                <PhoneInput
               style={{width:"280px",fontSize:"15px",fontFamily:'inherit'}}
                  value={phoneNumber}
                  className="phoneStyle"
                  onChange={setPhoneNumber}
                  label="Phone Number"
                  name="phone_number"
                  placeholder="Phone Number"
                  
                />
              
               
              
              </Grid>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setPassword(e.target.value)}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setConfirmPassword(e.target.value)}
                    name="password"
                    label=" Confirm Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={11}>

                  <Button id="bit"
                    fullWidth
                    onClick={handleShow}
                    variant="outlined"
                    margin="normal"
                    color="primary"
                  >
                    SIGN UP
            </Button>
                </Grid>
                

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>OTP CONSENT PHONE/EMAIL </Modal.Title>
        </Modal.Header>
        <Modal.Body>It is <b> Mandatory to accept OTP</b> consent for your account verification and resetting password in application. Upon accepting only you are allowed to create account with us. Upon cancelling you will be redirected to the Sign Up page.</Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="primary"  onClick={()=>{signup(), handleClose() }}>
           Accept
          </Button>
          <Button  variant="contained" color="primary" style={{marginLeft:'10px'}} onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

                <div className="maria d-flex">
                  <Link to="/login" >
                    {"Remember account? Sign In"}
                  </Link>
                </div>


                <br />
                <p>
                  <small>
                    {/* By using our service you agree with our */}
                    By using our services, you agree with following 'Terms of Use' & 'Partner Agreement'<br />
                    <strong style={{ marginLeft: "0.5%", color: "#4A8CFF" }}>
                      {/* terms and conditions */}
                      <a className="darkPrimaryColor"><Link to="test-terms"  target="_blank">Terms Of Use</Link></a>
                      <br />
                      <a className="darkPrimaryColor"><Link  to="PartnerAgreement" target="_blank">Partner Agreement</Link></a>
                    </strong>
                  </small>
                  
                </p>

              </Grid>
              <p style={{fontSize:"10px"}} className="darkPrimaryColor">By continuing you will receive a verification code to your phone number / Email-id by SMS / email.</p>
            </form>

          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}