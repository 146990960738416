import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios'
import './Safari_basic.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import './Safari_basic.css';
import DateFnsUtils from '@date-io/date-fns';
import { Input } from 'antd';
import 'date-fns';
import moment from 'moment';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, Space } from 'antd';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { tr } from 'date-fns/locale';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;



const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



function Safari_features() {

  
  const dispatch = useDispatch();
  const history = useHistory();
  const price_excel_ref = useRef(null);
  const { tour_id } = useParams();
  const classes = useStyles();
  const [uploadingMedia, setuploadingMedia] = useState(false);
  const user = useSelector((state) => state.auth.user);
 
  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails()
  }, [user])

  const [feature, setFeature] = useState({
    tour_package:tour_id, 
    pickup_point:null, 
    drop_point:null, 
    additional_fee:null, 
    vehicle_name:null, 
    seating_capacity:null, 
    vehicle_type:null,
  })

  const [updateForm, setUpdateForm] = useState(false);



  const handleChange = (event) => {
    if(event.target.name==='additional_fee' && event.target.value==='')
      setFeature({ ...feature, [event.target.name]: '0' });    
    else
      setFeature({ ...feature, [event.target.name]: event.target.value });
  };


  function getFormDetails(){
    axios.get(`partners/tour-facility-detail/${tour_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from getting  the tour package feature get', res.data)
      if(res.data.detail!='Not found'){
        setUpdateForm(true)
        setFeature({ ...feature, ...res.data });
      }
    }).catch(err => {
      console.log('ERROR  from getting tour package feature', err);
    })
  }


async function submitFeature(){
  setuploadingMedia(true);
  await axios.post('partners/tour-facility-create', feature, {
    headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
    }
  }).then(res => {
    console.log('response from submitting the tour package feature create', res.data);
    setuploadingMedia(false);
    history.push('/tour-package/amenities/5/'+tour_id)


  }).catch(err => {
    setuploadingMedia(false);
    let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
   
    console.log('ERROR  from creating tour package feature ', err);
  })
}
  
async function updateFeature(){
  setuploadingMedia(true);
  axios.put(`partners/tour-package-facility-back-update/${feature.id}`, feature, {
    headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
    }
  }).then(res => {
    console.log('response from submitting the tour package feature plan update', res.data);
    setuploadingMedia(false);
    history.push('/tour-package/amenities/5/'+tour_id)
  }).catch(err => {
    setuploadingMedia(false);
    let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
    console.log('ERROR  from update tour feature plan', err);
  })
}


  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Features & Facilities</h3>
      <div className="p-3 mb-3 bg-white">
                  <p><span style={{ color: "red" }}>*</span> mandatory field</p>
                  <div className="text-left">
                  <h5>Note:</h5>
                    <p>
                    <ul>
                    <li><u>It is recommended to provide all informations.</u> </li>
                    <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against label <br></br>of 'Additional Fee For Pickup & Drop '.</b> </li>
                        
                          
                    </ul>
                    </p>
                  </div>
                </div>
      
      
      <br></br>
      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>Pickup Point (One Way) <span style={{ color: "red" }}> * </span> </p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "80%" }}>
            <Select
              value={feature.pickup_point}
              onChange={handleChange}
              name="pickup_point"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
                 >
              {/* <InputLabel id="demo-simple-select-label">Pick up point</InputLabel> */}
              <MenuItem value={'Nearest Airport'}>Nearest Airport</MenuItem>
              <MenuItem value={'Nearest Railway'}>Nearest Railway Station</MenuItem>
              <MenuItem value={'Nearest AirRail'}>Nearest Airport / Railway Station</MenuItem>
              <MenuItem value={'Resort'}>Resort  / Hotel</MenuItem>
              <MenuItem value={'Others'}>Others</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Drop Point (One Way) <span style={{ color: "red" }}> * </span> </p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "80%" }}>
            <Select
             value={feature.drop_point}
             onChange={handleChange}
             name="drop_point"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
                 >
              {/* <InputLabel id="demo-simple-select-label">Drop point</InputLabel> */}
              <MenuItem value={'Nearest Airport'}>Nearest Airport</MenuItem>
              <MenuItem value={'Nearest Railway'}>Nearest Railway Station</MenuItem>
              <MenuItem value={'Nearest AirRail'}>Nearest Airport / Railway Station</MenuItem>
              <MenuItem value={'Resort'}>Resort / Hotel </MenuItem>
              <MenuItem value={'Others'}>Others</MenuItem>
            </Select>
          </FormControl>
        </form>

        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Additional Fee For Pickup & Drop (if any & one way) <span style={{ marginLeft: "1%", color: "red" }}>   </span></p>
          <TextField 
             value={feature.additional_fee}
             onChange={handleChange}
             name="additional_fee"
          id="outlined-basic" 
          placeholder="Additional Fee in prior selected currency only in numbers" 
          variant="outlined" />
        </form>
        <br />
      </div>

      <br />
      <br />

      <div className="basic_company">
        <h4 style={{ display: 'flex' }}>About Vehicle</h4>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p> Model / Name of Vehicle <span style={{ color: "red" }}> * </span></p>
          <TextField 
             value={feature.vehicle_name}
             onChange={handleChange}
             name="vehicle_name"
          id="outlined-basic" placeholder="For Example: Hatchback, Sedan, SUV etc." variant="outlined" />
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Seating Capacity (number of person per vehicle) <span style={{ color: "red" }}> * </span></p>
          <TextField 
             value={feature.seating_capacity}
             onChange={handleChange}
             name="seating_capacity"
          id="outlined-basic" placeholder="Only Numbers are allowed" variant="outlined" />
        </form>
        <br />
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Type of Vehicle<span style={{ color: "red" }}> * </span></p>
          <TextField 
            value={feature.vehicle_type}
            onChange={handleChange}
            name="vehicle_type"
          id="outlined-basic" placeholder="For Example: Open / Closed / Filming vehicle" variant="outlined" />
        </form>
        <br />
      </div>

      <br />


      <div className="text-center my-4 mb-2">
              <Link to={"/tour-package/pricing/3/"+tour_id}>
                <button className="btn btn-grey border border-primary mx-2">BACK </button>
              </Link>
              {updateForm ?
          <button onClick={updateFeature} className="btn btn-primary mx-2"
          disabled={uploadingMedia}>{uploadingMedia ? 'UPLAODING' : 'UPDATE'}</button>
          :
                <button onClick={submitFeature} className="btn btn-primary mx-2"
                disabled={uploadingMedia}>{uploadingMedia ? 'UPLAODING' : 'CONTINUE'}</button>
               } 
            </div>

            <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
<ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default Safari_features

