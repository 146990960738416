import React, { Component, useState, useEffect, useRef } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import I2 from "./assests/I2.png";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { RadioGroup } from "@material-ui/core";
import Select from "@material-ui/core/Select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Banks = [
  {
    value: "Canara Bank",
    label: "Canara Bank",
  },
  {
    value: "State Bank of India",
    label: "State Bank Of India",
  },
  {
    value: "ICICI",
    label: "ICICI",
  },
  {
    value: "HDFC",
    label: "HDFC",
  },
  {
    value: "Bank of India",
    label: "Bank Of India",
  },
  {
    value: "PNB",
    label: "PNB",
  },
  {
    value: "IDFC",
    label: "IDFC",
  },
];

function Tour_finance() {
  const history = useHistory();
  const { tour_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
  }, [user]);
  const [updateForm, setUpdateForm] = useState(false);

  const [paymentOptionArr, setPaymentOptionArr] = React.useState([]);
  const [finance, setFinance] = useState({
    safari: tour_id,
    account_name: null,
    account_number: null,
    confirm_account_number: null,
    swift_code: null,
    ifsc_code: null,
    bank_name: null,
    country_detail: null,
    intermediary_bank_charge: null,
    intermediary_account: null,
    intermediary_bank_address: null,
    intermediary_swift_address: null,
    intermediary_country_detail: null,
    branch_address: null,
    payment_option: null,
    trade_name: null,
    gstin: null,
    pan: null,
    payment_link: null,
    upiid: null,
  });

  const handleChange = (event) => {
    setFinance({ ...finance, [event.target.name]: event.target.value });
  };

  function handelChangePaymentOptions(e) {
    let arr = paymentOptionArr;
    if (paymentOptionArr.includes(e.target.value)) {
      let arrIndex = paymentOptionArr.indexOf(e.target.value);
      paymentOptionArr.splice(arrIndex, 1);
    } else {
      paymentOptionArr.push(e.target.value);
    }
    setPaymentOptionArr([...paymentOptionArr]);
  }

  const [Bank, setBanks] = React.useState("Canara Bank");
  // const handleChange = (event) => {
  //   setBanks(event.target.value);
  // };

  function getFormDetails() {
    axios
      .get(`partners/safari-finance-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        if (res.data) {
          setUpdateForm(true);
        }
        console.log(
          "response from getting  the tour operator finance get",
          res.data
        );
        setFinance({ ...res.data });
        let arr = JSON.parse(res.data.payment_option);
        setPaymentOptionArr([...arr]);
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package basics", err);
      });
  }

  function submitToursFinance() {
    if (finance.account_number == finance.confirm_account_number) {
      let fin = finance;
      fin.payment_option = JSON.stringify(paymentOptionArr);
      axios
        .post("partners/safari-finance-create", fin, {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        })
        .then((res) => {
          history.push("/safari-registration/accommodation_image/4/"+tour_id);
          console.log(
            "submit safari registration finance form create",
            res.data
          );
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
          console.log("ERROR  from creating accomodation basics", err);
        });
    }
    else{
      toast('Account Number and Confirm Account Number is not same');
    }
  }

  function updateToursFinance() {
    console.log(finance.account_number, finance.confirm_account_number);
    if (finance.account_number == finance.confirm_account_number) {
      let fin = finance;
      fin.payment_option = JSON.stringify(paymentOptionArr);
      axios
        .put(`partners/safari-operator-finance-back-update/${fin.id}`, fin, {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        })
        .then((res) => {
          history.push("/safari-registration/accommodation_image/4/"+tour_id);
          console.log("submit finance form update", res.data);
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
          console.log("ERROR  from updating finance operator form", err);
        });
    }
    else{
      toast('Account Number and Confirm Account Number is not same');
    }
  }

  return (
    <div className="Tour_finance">
      <h4 style={{ display: "flex", color: "#949494" }}>
        Safari / Game Drive Partner Registration: Finance
      </h4>
      <div className="p-3 mb-3 bg-white">
        <p><span style={{ color: "red" }}>*</span> mandatory field</p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
          <ul>
              <li><u>It is recommended to provide all informations.</u> </li>
                <li>
                  <b>Address of Bank Branch:</b> The full address or the bank along
                  with the pin code of the area has to be mentioned. For
                  countries which do not have 'PIN' codes, the area code to be
                  mentioned.{" "}
                </li>
                {/* <li> This allows to create categories for various combination of pricing range and accomodation type. </li>
                           <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
              </ul>
          </p>
        </div>
      </div>

      <div className="finance_account_details">
        <div className="finance_account_details_header">
          {/* <img src={I2}></img> */}
          <h4>Account Detail</h4>
        </div>
        <br />
        <div className="finance_account_details_textfields">
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Name of Account Holder<span style={{ color: "red" }}> * </span></p>
            <TextField
              value={finance.account_name}
              onChange={handleChange}
              name="account_name"
              id="outlined-basic"
              placeholder="your full name as in bank account"
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Account Number / IBAN <span style={{ color: "red" }}> * </span></p>
            <TextField
              value={finance.account_number}
              onChange={handleChange}
              name="account_number"
              id="outlined-basic"
              placeholder="your acoount number (only numbers are allowed) "
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Confirm Account Number/ IBAN <span style={{ color: "red" }}> * </span></p>
            <TextField
              value={finance.confirm_account_number}
              onChange={handleChange}
              name="confirm_account_number"
              id="outlined-basic"
              placeholder="confirm your acoount number (only numbers are allowed) "
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Name of Bank<span style={{ color: "red" }}> * </span></p>
            <TextField
              id="outlined-select-currency-native"
              value={finance.bank_name}
              onChange={handleChange}
              name="bank_name"
              // value={Bank}
              // onChange={handleChange}
              placeholder="Full Name of Bank"
              //helperText="Full Name of Bank"
              variant="outlined"
            ></TextField>
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Complete Address of Bank Branch<span style={{ color: "red" }}> * </span></p>
            <TextField
              value={finance.branch_address}
              onChange={handleChange}
              name="branch_address"
              id="outlined-basic"
              placeholder="Address as in Bank Account"
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Country Detail<span style={{ color: "red" }}> * </span></p>
            <TextField
              value={finance.country_detail}
              onChange={handleChange}
              name="country_detail"
              id="outlined-basic"
              placeholder="Country Name "
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>SWIFT Code</p>
            <TextField
              value={finance.swift_code}
              onChange={handleChange}
              name="swift_code"
              id="outlined-basic"
              placeholder="8-11 Digit/Character SWIST CODE"
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>IFSC Code</p>
            <TextField
              value={finance.ifsc_code}
              onChange={handleChange}
              name="ifsc_code"
              id="outlined-basic"
              placeholder="11 Digit/Character IFSC CODE (for Indian Banks)"
              variant="outlined"
            />
          </form>
          <br />

          <br />
          <br />
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Is Intermediary Bank Charge Applicable</p>
            <Select
              variant="outlined"
              fullWidth
              name="intermediary_bank_charge"
              color="primary"
              value={finance.intermediary_bank_charge}
              onChange={handleChange}
              Default
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Intermediary Account Number</p>
            <TextField
              value={finance.intermediary_account}
              onChange={handleChange}
              name="intermediary_account"
              id="outlined-basic"
              placeholder="intermediary acoount number (only numbers are allowed)"
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Intermediary Bank Name</p>
            <TextField
              value={finance.intermediary_bank_name}
              onChange={handleChange}
              name="intermediary_bank_name"
              id="outlined-basic"
              placeholder="intermediary bank full name"
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Intermediary Bank Address</p>
            <TextField
              value={finance.intermediary_bank_address}
              onChange={handleChange}
              name="intermediary_bank_address"
              id="outlined-basic"
              placeholder="intermediary bank full address"
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Intermediary SWIFT Code</p>
            <TextField
              value={finance.intermediary_swift_address}
              onChange={handleChange}
              name="intermediary_swift_address"
              id="outlined-basic"
              placeholder="8-11 Digit/Character SWIFT CODE"
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Intermediary Country Detail</p>
            <TextField
              value={finance.intermediary_country_detail}
              onChange={handleChange}
              name="intermediary_country_detail"
              id="outlined-basic"
              placeholder="intermediary country name"
              variant="outlined"
            />
          </form>
        </div>
        <br />
      </div>
      <br />
      <br />

      <div className="finance_account_details">
        <div className="finance_account_details_header">
          {/* <img src={I2}></img> */}
          <h5>Payment Options Available</h5>
        </div>
        <br />
        <RadioGroup>
          <div className="Main">
            <div className="Components">
              <FormControlLabel
                control={
                  <Checkbox
                    value="credit_card"
                    name="cedit_card"
                    color="primary"
                    checked={
                      paymentOptionArr.includes("credit_card") ? true : false
                    }
                    onChange={handelChangePaymentOptions}
                  />
                }
                label="Credit Card"
              />
            </div>
          </div>

          <div className="Main">
            <div className="Components">
              <FormControlLabel
                control={
                  <Checkbox
                    value="pay_pal"
                    name="pay_pal"
                    color="primary"
                    checked={
                      paymentOptionArr.includes("pay_pal") ? true : false
                    }
                    onChange={handelChangePaymentOptions}
                  />
                }
                label="Pay Pal"
              />
            </div>
          </div>
          <div className="Main">
            <div className="Components">
              <FormControlLabel
                control={
                  <Checkbox
                    value="bank_transfer_online_banking"
                    name="bank_transfer_online_banking"
                    color="primary"
                    checked={
                      paymentOptionArr.includes("bank_transfer_online_banking")
                        ? true
                        : false
                    }
                    onChange={handelChangePaymentOptions}
                  />
                }
                label="Bank Transfers / Online Banking"
              />
            </div>
          </div>

          <div className="Main">
            <div className="Components">
              <FormControlLabel
                control={
                  <Checkbox
                    value="upi"
                    color="primary"
                    checked={paymentOptionArr.includes("upi") ? true : false}
                    onChange={handelChangePaymentOptions}
                    name="upi"
                  />
                }
                label="UPI"
              />
            </div>
          </div>
        </RadioGroup>
      </div>

      <br />
      <br />

      <div className="finance_account_details">
        <div className="finance_account_details_header">
          {/* <img src={I2}></img> */}
          <h5>Tax & Services</h5>
        </div>
        <br />
        <div className="finance_account_details_textfields">
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Trade Name</p>
            <TextField
              value={finance.trade_name}
              onChange={handleChange}
              name="trade_name"
              id="outlined-basic"
              placeholder="name of your business "
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>GSTIN</p>
            <TextField
              value={finance.gstin}
              onChange={handleChange}
              name="gstin"
              id="outlined-basic"
              placeholder="15 Digit GSTIN"
              variant="outlined"
            />
          </form>
          <br />

          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>PAN</p>
            <TextField
              value={finance.pan}
              onChange={handleChange}
              name="pan"
              id="outlined-basic"
              placeholder="10 Digit PAN Number"
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>Payment Link</p>
            <TextField
              value={finance.payment_link}
              onChange={handleChange}
              name="payment_link"
              id="outlined-basic"
              placeholder="3rd party secure payment link "
              variant="outlined"
            />
          </form>
          <br />
          <form
            className="Tour_Finance_textfield"
            noValidate
            autoComplete="off"
          >
            <p>UPI ID</p>
            <TextField
              value={finance.upiid}
              onChange={handleChange}
              name="upiid"
              id="outlined-basic"
              placeholder="valid upi id"
              variant="outlined"
            />
          </form>
          <br />
        </div>
        <br />
      </div>

      <br />
      <br />

      <br />
      <br />

      <div className="text-center my-4 mb-2">
        {/*<Link to={"/safari-registration/day-plan/3/" + tour_id}>*/}
          <button onClick={() => history.goBack()} className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        {/*</Link>*/}
        {updateForm ? (
          <button onClick={updateToursFinance} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button onClick={submitToursFinance} className="btn btn-primary mx-2">
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Tour_finance;
