import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from '../../axios';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function GroundReg_vehicle() {

  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const history = useHistory();

  const [state, setstate] = useState({
    logistics: id,
    open_van_no:null, 
    open_van_capacity:null, 
    suv_no:null, 
    suv_capacity:null, 
    boat_no:null, 
    boat_capacity:null, 
    bus_no:null, 
    bus_capacity:null, 
    sedan_no:null, 
    sedan_capacity:null, 
    hatch_back_no:null, 
    hatch_back_capacity:null, 
    other_no:null, 
    other_capacity:null,

  })

  const [currency, setcurrency] = useState(null);

  useEffect(() => {
    window.scrollTo(0,0);

    getVehicleData();
    getCurrency();
    getAllInfo();
  }, [user])

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: Number(e.target.value) });
    //console.log('onchange',state)
  }
  const handleChangeAfter = (e) => {
    setdataAfter({ ...dataAfter, [e.target.name]: Number(e.target.value) });
    //console.log('onchange',state)
  }

  const [vehicles, setvehicles] = useState(null);


  const getCurrency = () => {
    axios.get(`partners/ground-logistics-currency/${id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        console.log('goud losgistic vehicle data err', err);
      }
      else {
        console.log('data of logistic currency ', response);
        setcurrency(response.data.currency);
      }
    })
  }

  const getVehicleData = async () => {
    await axios.get(`partners/ground-logistic-vehicle`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        console.log('goud losgistic vehicle data err', err);
      }
      else {
        console.log('data of logistic vehicle ', response.data.vehicle_list);
        setvehicles(response.data.vehicle_list);
      }
    })
  }
  let vehicleNo = null;
  let vehicleCapacity = null;
  let vehicleKm = null;
  let vehicleFullDay = null;
  let vehicleHalfDay = null;
  const arrayList = ['open_van_no', 'suv_no', 'sedan_no', 'bus_no', 'boat_no', 'hatch_back_no']

  let z = 0;
  const [update, setupdate] = useState(false);
  const [postId, setpostId] = useState(null);
  const getAllInfo = async () => {
    await axios.get(`partners/ground-logistic-vehicle-detail-list/${id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      if (res.data != null && res.data.length) {
        setupdate(true)
        z = 1;
      }
      console.log('this is to chekc id ',res.data)
      setstate({ ...res.data[0]});
      setdataAfter({ ...res.data[0] });
      setpostId(res.data[0].id);
      //setContactDetail([...res.data.contact_details])
      //setOffices([...res.data[0].office_details]);
      console.log('response from getting  the GL basics get', res.data)
    }).catch(err => {
      console.log('ERROR  from getting tour package basics', err);
    })
  }
  console.log('state ', state);
  const postVehicleData = async () => {
    state['logistics']=id;
    await axios.post(`partners/ground-logistic-vehicle-detail-create`, state, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        console.log('goud losgistic PPOSTED vehicle data err', err);
        //history.push(`/ground-logistics-registration/image-document/3/2`)
      }
      else {
        console.log('data of logistic POSTED vehicle ', response.data);
        history.push(`/ground-logistics-registration/policy/3/${id}`)
      }
    })
  }
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
  }

  const updateVehicleData = async () => {
    // await axios.put(`partners/ground-logistic-vehicle-update/${postId}`, dataAfter, {
    await axios.put(`partners/ground-logistic-vehicle-update/${postId}`, state, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log('goud losgistic UPDATE vehicle data err', err);
        //history.push(`/ground-logistics-registration/image-document/3/2`)
      }
      else {
        console.log('data of logistic UPDATE vehicle ', response.data);
        history.push(`/ground-logistics-registration/policy/3/${id}`)
      }
    })
  }

  const [dataAfter, setdataAfter] = useState({});
  return (
    <div className="TourReg_basic">
      
      <div className="text-left"><h4>Ground Logistic Partner Registration: Vehicle Detail</h4></div>
      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                        {/* <li>
                          Please enter a non-zero number in 'Accommodation
                          Distribution' against various listed options which
                          will be reflected in next stages of forms for
                          selection{" "}
                        </li> */}
                        <li>
                          {" "}
                          <b>Currency:</b> The selected currency will be applicable to
                          all of Ground Logistics Vechicle pricing.{" "}
                        </li>
                        <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against each lable of 'Prices'.</b> </li>
                      </ul>
                    </p>
                  </div>
                </div>

      <div className="basic_company">
        {!dataAfter.length && vehicles != null && vehicles.length && vehicles.map((item) => (
          Object.entries(item).map(([key, value]) =>
            <div>
              {(() => {
                if (value && z == 0) {
                  vehicleNo = key + '_no';
                  vehicleCapacity = key + '_capacity';
                  vehicleKm = key + '_charge_km';
                  vehicleFullDay = key + '_charge_full_day';
                  vehicleHalfDay = key + '_charge_half_day';
                  return (
                    <div>
                      <h3 style={{ textAlign: 'left', fontWeight: '400', marginBottom: '1rem', textTransform: 'capitalize' }} >{replaceAll(key, '_', " ")}</h3>
                      <form noValidate autoComplete="off" className="company_name">
                        <p>Number of Vehicles You Own<span style={{ marginLeft: "1%", color: "red" }}></span></p>
                        {/* <TextField
                          onChange={handleChange}
                          value={state.vehicleNo}
                          type='number'
                          name={key + '_no'}
                          id="outlined-basic" placeholder="Enter number of suv" variant="outlined" /> */}
                          <TextField
                          onChange={handleChange}
                          value={state[key+'_no']}
                          type='number'
                          name={key + '_no'}
                          id="outlined-basic" placeholder="Enter Number Of Vechicle in This Category" variant="outlined" />
                      </form>
                      <br />
                      <form noValidate autoComplete="off" className="company_name">
                        <p>Seating Capacity in Each Vehicle<span style={{ marginLeft: "1%", color: "red" }}></span></p>
                        <TextField
                          value={state[key + '_capacity']}
                          onChange={handleChange}
                          type='number'
                          name={key + '_capacity'}
                          id="outlined-basic" placeholder="only number are allowed" variant="outlined" />
                      </form>
                      <br />
                      <form noValidate autoComplete="off" style={{ width: '80%' }} className="company_name About">

                        <p style={{ display: "flex" }} >Price per KM<span style={{ marginLeft: "1%", color: "red" }} ></span></p>
                        <div className="input-group" >
                          <div className="input-group-prepend" style={{ width: '20%' }}>
                            <span className="input-group-text" style={{ width: '100%', display: 'flow-root' }} id="inputGroupPrepend"> {currency} </span>
                          </div>
                          <input type="text"

                            value={state[key + '_charge_km']}
                            onChange={handleChange}
                            type='number'
                            name={key + '_charge_km'}
                            className="form-control" id="disabledTextInput" style={{ background: 'White' }} placeholder="00.00" aria-describedby="inputGroupPrepend" required />
                          <div className="invalid-feedback">
                            Please choose a username.
                </div>
                        </div>


                      </form>
                      <br />
                      <form noValidate autoComplete="off" style={{ width: '80%' }} className="company_name About">

                        <p style={{ display: "flex" }} >Charges for Full Day<span style={{ marginLeft: "1%", color: "red" }} ></span></p>
                        <div className="input-group" >
                          <div className="input-group-prepend" style={{ width: '20%' }}>
                            <span className="input-group-text" style={{ width: '100%', display: 'flow-root' }} id="inputGroupPrepend">{currency}</span>
                          </div>
                          <input type="text"

                            value={state[key + '_charge_full_day']}
                            onChange={handleChange}
                            type='number'
                            name={key + '_charge_full_day'}
                            className="form-control" id="disabledTextInput" style={{ background: 'White' }} placeholder="00.00" aria-describedby="inputGroupPrepend" required />
                          <div className="invalid-feedback">
                            Please choose a username.
                </div>
                        </div>


                      </form>
                      <br />
                      <form noValidate autoComplete="off" style={{ width: '80%' }} className="company_name About">

                        <p style={{ display: "flex" }} >Charges for Half Day<span style={{ marginLeft: "1%", color: "red" }} ></span></p>
                        <div className="input-group" >
                          <div className="input-group-prepend" style={{ width: '20%' }}>
                            <span className="input-group-text" style={{ width: '100%', display: 'flow-root' }} id="inputGroupPrepend">{currency}</span>
                          </div>
                          <input type="text"

                            value={state[key + '_charge_half_day']}
                            onChange={handleChange}
                            type='number'
                            name={key + '_charge_half_day'}
                            className="form-control" id="disabledTextInput" style={{ background: 'White' }} placeholder="00.00" aria-describedby="inputGroupPrepend" required />
                          <div className="invalid-feedback">
                            Please choose a username.
                </div>
                        </div>


                      </form>
                      <br />

                      <br />
                      <br />
                      <br />
                    </div>
                  )
                }
              })()}

            </div>
          )))}
      </div>
      {/* <div className='basic_company'>
          dataAfter != null ? Object.entries(dataAfter).map(([key, value]) =>
            <div key={key}>

              {(() => {
                if (value && (key != 'admin_comment' && key != 'approved_status' && key != 'id' && key != 'logistics' && key != 'updated_at' && key != 'created_at')) {
                  { console.log('vehicles inside if data after DATA AFTER ', key, value) }
                  return (
                    <div>
                      {arrayList.includes(key) &&
                        <h3 style={{ textAlign: 'left', fontWeight: '400', marginBottom: '1rem', textTransform: 'capitalize' }}>{key.split('_no')[0].replace('_', ' ')}  </h3>
                      }

                      <form noValidate autoComplete="off" className="company_name">
                        <p style={{ textTransform: 'capitalize' }}>{replaceAll(key, '_', " ")}<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
                        <TextField
                          onChange={handleChangeAfter}
                          value={dataAfter.key}
                          type='number'
                          name={key}
                          id="outlined-basic" placeholder={value} variant="outlined" />
                      </form>
                      <br />

                    </div>
                  )
                }
              })()}

            </div>
            //))
          )
            : <div>NO Data</div>}
      </div> */}



      <div className="text-center my-4 mb-2">
        <Link to={{
          pathname: `/ground-logistics-registration/basic/1`,
          state: {
            groundOperator: id
          }
        }}>
          <button className="btn btn-grey border border-primary mx-2">BACK </button>
        </Link>
        {update ? <button onClick={updateVehicleData} className="btn btn-primary mx-2">UPDATE</button> :

          <button onClick={postVehicleData} className="btn btn-primary mx-2">CONTINUE</button>

        }
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default GroundReg_vehicle
