import React, { Component,useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../src/axios';
// import Cookies  from 'universal-cookie';
import PhoneInput from "react-phone-number-input";
import Cookies from 'js-cookie';
// const cookies = new Cookie();





const UpdateProfile = () => {
    const history = useHistory()
    const[name,setName]=useState('')
    const[nav,setnav]=useState(false)
    const[phoneNumber,setPhoneNumber]=useState('')

    useEffect(() => {
        axios.get(`auth/profile`,{
            headers: {
                'Authorization': 'Token ' + Cookies.get('token')
            }
        })
        .then(res => {
            console.log(res.data);
            setName(res.data.user.profile.name)
            setPhoneNumber(res.data.user.profile.phone_number)
            
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

  
    
    function updateBasic() {
        console.log("clicled")
        let formData = new FormData();
       
     
        formData.append("name",name);
        formData.append('phone_number',phoneNumber)
    
    
        axios
          .put(
            `auth/profile/update`,
            formData,
    
            {
              headers: {
                'Authorization': 'Token'+' '+Cookies.get('token')
              },
            }
          )
          .then((res) => {
            Cookies.set("name",res.data.user.profile.name)
            // setContent(res.data)  
              history.goBack('/home');
             
          })
          .catch((err) => {
          
            console.log("ERROR  from update in form", err);
            // alert("Skills is required")
          });
        
      }
const cancelButton =()=>{
    history.goBack();
}
    return (
        <div>
        
        <h1 style={{paddingTop:'100px'}}>Edit Profile</h1>
        <div className="navStyleing">
        <center>       
        <div className="container border rounded my-3 p-3 bg-white text-left">
      
        <div class="form-group col-md-6">
        <p for="address">Name</p>
        <input
          type="text"
          class="form-control"
          id="address"
          placeholder="Name"
          value={name}
         
           onChange={(e) => setName(e.target.value)}
          name="content"
        />
      </div>
      <div class="form-group col-md-6">
      <p for="address">Phone Number</p>
      

      <PhoneInput
      style={{width:"280px",fontSize:"15px",fontFamily:'inherit'}}
         value={phoneNumber}
         className="phoneStyle"
         onChange={setPhoneNumber}
         label="Phone Number"
         name="phone_number"
         placeholder="Phone Number"
         
       />
    </div>
      
        <div className="my-2 text-right">

            <button className="btn adminButtonStyleOne px-3 mx-2  py-1" onClick={updateBasic} >
                Save
                                </button>
                                <button className="btn adminButtonStyleOne px-3 mx-2  py-1" onClick={cancelButton}>
                Cancel
                                </button>
        </div>
    </div>
      </center>
        </div>

     
            
        </div>
    )
}

export default UpdateProfile
