import React, { Component, useState, useEffect, Fragment } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import "./accomodation_basic.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as accommodationActions from "../../store/actions/accommodation";
import AccomodationBasicNewForm from "./Accommodation_Basic/accommodation_basic_new";
import { Paper, Typography } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccomodationBasic() {
  const classes = useStyles();
  const [accommodationType, setAccommodationType] = useState(null);
  const [accommodationList, setAccommodationList] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [addNewForm, setAddNewForm] = useState(false);
  const [nationalPark, setNationalPark] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [currency, setCurrency] = React.useState("INR");

  const [typeArr, setTypeArr] = React.useState([
    "tent",
    "permanent_structure",
    "tree_house",
    "cottage",
    "villa",
    "camping_site",
  ]);

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  let accommodationAction = useSelector(
    (state) => state.accommodation.accommodation
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    getNationalPark();
    if (
      accommodationAction != null &&
      accommodationAction.id != undefined &&
      accommodationAction != {}
    ) {
      setActiveForm(accommodationAction);
    }
  }, [user]);

  const [contact_detail, setContactDetail] = useState([
    {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null,
    },
  ]);
  const [update, setUpdate] = React.useState(false);

  const [state, setState] = useState({
    name: "",
    website: "",
    existing_rating: null,
    trip_adviser_rating: null,
    property_url_trip_adviser: null,
    property_url_other: null,
    other_rating: null,
    contactName: null,
    contactNumber: null,
    escalation_name: null,
    escalation_number: null,
    escalation_number_alter: null,
    escalation_email: null,
    role: null,
    alternateNumber: null,
    contactEmail: null,
    street: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    zip_code: "",
    currency: "",
    national_park: null,
    // partner: user.id,
    partner: cookies.get('id'),
    business: 1,
    tent: 0,
    permanent_structure: 0,
    tree_house: 0,
    cottage: 0,
    villa: 0,
    camping_site: 0,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onChangeContactForm = (index, event) => {
    let arr = contact_detail;
    arr[index][event.target.name] = event.target.value;
    setContactDetail([...arr]);
  };

  function addContactDetails() {
    let arr = [...contact_detail];
    let obj = {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null,
    };
    arr.push(obj);
    setContactDetail(arr);
  }

  function removeContactDetail(itemDel, index) {
    let arr = [...contact_detail];
    arr.splice(index, 1);
    setContactDetail(arr);
  }

  function getNationalPark() {
    axios
      .get("partners/currency-national-park-list", {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log("national park", res.data);
        setNationalPark(res.data.national_park);
        setCurrencies(res.data.currency);
        // setState({ ...state, ...filterObj });
      })
      .catch((err) => {
        console.log("error getting accommodation type list", err);
      });
  }

  // function getAccommodationTypeList() {
  // axios.get('partners/accommodation-type-list', {
  //   headers: {
  //     'Authorization': 'Token ' + user.token
  //   }
  // }).then(res => {
  //   setAccommodationType(res.data[0]);
  //   let obj = res.data[0];
  //   let filterObj = {};
  //   Object.entries(obj).map((element, key) => {
  //     if (element[1] == true) {
  //       filterObj[element[0]] = 0
  //     }
  //   });
  //   setState({ ...state, ...filterObj });
  //   setAddNewForm(true);
  //   console.log('error on response from accommodation type list', res.data);
  // }).catch(err => {
  //   console.log('error getting accommodation type list', err);
  // })
  // }

  function submitAccomodationBasic() {
    if (
      state.tent > 0 ||
      state.permanent_structure > 0 ||
      state.tree_house > 0 ||
      state.cottage > 0 ||
      state.villa > 0 ||
      state.camping_site > 0
    ) {
      let stateUpdate = state;
      stateUpdate.contact_detail = contact_detail;
      stateUpdate.partner = cookies.get('id');
      // stateUpdate.partner = user.id;
      stateUpdate.business = 1;
      console.log("stateUpdat", stateUpdate);
      axios
        .post("partners/accommodation-basic-info-create", stateUpdate, {
          headers: {
            Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        })
        .then((res) => {
          console.log(
            "response from submitting the accomodation basics create",
            res.data
          );
          try {
            dispatch(accommodationActions.accommodation(res.data));
            history.push("/accomodation/pricing/2/" + res.data.id);
          } catch (err) {
            console.log("error action storing accommodation actions", err);
          }
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
          console.log(
            "ERROR  from creating accomodation basics",
            err.response.data.errors
          );
        });
    } else {
      setAccDistribution({ message: "Atleast one of the following field under 'Accommodation Distribution' must be Non-zero" });
    }
  }

  const [accDistribution, setAccDistribution] = useState(false);
  function updateAccomodationBasic() {
    if (
      state.tent > 0 ||
      state.permanent_structure > 0 ||
      state.tree_house > 0 ||
      state.cottage > 0 ||
      state.villa > 0 ||
      state.camping_site > 0
    ) {
      console.log(
        "sate accomdodaiton distruibutin ",
        state.tent,
        state.tree_house,
        state.cottage,
        state.villa
      );
      let stateUpdate = state;
      stateUpdate.contact_detail = contact_detail;
      console.log("POST UPDATE ISSUE", stateUpdate);
      axios
        .put(
          `partners/accommodation-basic-info-back-update/${stateUpdate.id}`,
          stateUpdate,
          {
            headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
            },
          }
        )
        .then((res) => {
          console.log(
            "response from submitting the accomodation basics UPDATE",
            res.data
          );
          try {
            dispatch(accommodationActions.accommodation(res.data));
            history.push("/accomodation/pricing/2/" + res.data.id);
          } catch (err) {
            console.log("error action storing accommodation actions", err);
          }
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
          console.log(
            "ERROR  from creating accomodation basics",
            err.response.data.errors
          );
        });
    } else {
      setAccDistribution({ message: "Atleast one field should be Non-Zero" });
    }
  }

  function setActiveForm(item) {
    axios
      .get(`partners/accommodation-basic-info-detail/${item.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting the accomodation basics get data",
          res.data
        );
        // history.push('/accomodation/pricing/3/' + res.data.id);
        setSelectedForm(res.data);
        setState({ ...state, ...res.data });
        console.log("New State", state);
        setContactDetail(res.data.contact_detail);
        // setAddNewForm(false);
        console.log("after history purs");
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log(
          "ERROR  from creating accomodation basics",
          err.response.data.errors
        );
      });
  }

  function addNewFormFunc() {
    setSelectedForm(null);
    let stateObj = {
      name: "",
      website: "",
      existing_rating: null,
      trip_adviser_rating: "",
      property_url_trip_adviser: null,
      property_url_other: null,
      other_rating: null,
      contactName: "",
      contactNumber: "",
      escalation_name: "",
      escalation_number: "",
      escalation_number_alter: "",
      escalation_email: "",
      role: "",
      alternateNumber: "",
      contactEmail: "",
      street: "",
      address_line_1: "",
      address_line_2: "",
      country: "",
      city: "",
      zip_code: "",
      currency: "",
      national_park: "",
      partner: user.id,
      business: 1,
      tent: 0,
      permanent_structure: 0,
      tree_house: 0,
      cottage: 0,
      villa: 0,
      camping_site: 0,
    };
    console.log("stateObj", stateObj);

    // setState({...stateObj})
    console.log("new form to create state", state);
    let obj = accommodationType;
    let filterObj = {};
    Object.entries(obj).map((element, key) => {
      if (element[1] == true) {
        filterObj[element[0]] = 0;
      }
    });
    setState({ ...stateObj, ...filterObj });
    setContactDetail([
      {
        name: "",
        number: "",
        role: "",
        number_alter: "",
        email: "",
      },
    ]);
    setAddNewForm(true);
  }

  function stringCap(str) {
    return str.replace(/[^A-Z0-9]/gi, " ");
  }

  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            {/* {accommodationList.map((item, index) =>
              <div className="d-flex border border-primary m-2 justify-content-between p-2">
                <div> <h3> Form  {index + 1} </h3> </div>
                <div> <button onClick={() => { setActiveForm(item) }} className="btn btn-primary p-2 px-5"> Continue </button> </div>
              </div>
            )} */}
            {/* {!addNewForm &&
              <div className="d-flex border border-primary m-2 justify-content-center p-2">
                <div> <button onClick={addNewFormFunc} className="btn btn-primary p-2 px-5"> +Add Form </button> </div>
              </div>
            } */}
            {/* <div>
              <AccomodationBasicNewForm 
                user={user} 
                accommodationType={accommodationType}
                submitAccomodationBasic={(state)=>submitAccomodationBasic(state)}
              />
              
            </div>
            <hr/>
            <hr/>
            <hr/> */}
            {/* {(selectedForm != null || accommodationList.length == 0 || addNewForm) && */}
            <div>
              <div>
                <h4>Accommodation: Basic Information</h4>
                <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                        <li><u>It is recommended to provide all informations.</u> </li>
                        <li>
                          Please enter a <b>non-zero number</b> in <b>'Accommodation
                          Distribution'</b> against various listed options which
                          will be reflected in next stages of forms for
                          selection.{" "}
                        </li>
                        <li>
                          {" "}
                          <b>Currency:</b> The selected currency will be applicable to
                          all of accommodation pricing.{" "}
                        </li>
                        {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="p-3 bg-white">
                  <div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          Name Of Hotel / Camp / Resort{" "}
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          className="form-control"
                          value={state.name}
                          onChange={handleChange}
                          name="name"
                          placeholder="Registered Legal Name"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          Website <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="form-control"
                          value={state.website}
                          onChange={handleChange}
                          name="website"
                          placeholder="Valid Internet Address of Website"
                        />
                      </div>
                      <div className="col-sm-6">
                        <br></br>
                        <label>Existing Rating On </label>
                        <select
                          value={state.existing_rating}
                          onChange={handleChange}
                          name="existing_rating"
                          className="form-control"
                        >
                          <option>None</option>
                          <option value="trip_adviser">Trip Advisor</option>
                          <option value="other">Other Website</option>
                        </select>
                        {/* <input className="form-control" value={state.trip_adviser_rating} onChange={handleChange} name="trip_adviser_rating" /> */}
                      </div>
                      {state.existing_rating == "trip_adviser" && (
                        <Fragment>
                          <div className="col-sm-6">
                            <br></br>
                            <label>Trip Advisor Rating </label>
                            <input
                              className="form-control"
                              value={state.trip_adviser_rating}
                              onChange={handleChange}
                              name="trip_adviser_rating"
                              placeholder="Only Numbers are Allowed"
                            />
                          </div>
                          <div className="col-sm-6">
                            <br></br>
                            <label>Property URL On Trip Advisor </label>
                            <input
                              className="form-control"
                              value={state.property_url_trip_adviser}
                              onChange={handleChange}
                              name="property_url_trip_adviser"
                              placeholder="Valid Internet Address of Property Rating on Trip Advisor"
                            />
                          </div>
                        </Fragment>
                      )}
                      {state.existing_rating == "other" && (
                        <Fragment>
                          <div className="col-sm-6">
                            <br></br>
                            <label>Rating On Other Website</label>
                            <input
                              className="form-control"
                              value={state.other_rating}
                              onChange={handleChange}
                              name="other_rating"
                              placeholder="Only Numbers are Allowed"
                              
                            />
                          </div>
                          <div className="col-sm-6">
                            <br></br>
                            <label>Enter Property URL On Other Website </label>
                            <input
                              className="form-control"
                              value={state.property_url_other}
                              onChange={handleChange}
                              name="property_url_other"
                              placeholder="Valid Internet Address of Property Rating on Other Website"
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-3 mt-3 bg-white">
                  {accDistribution && (
                    <Paper
                      p={1}
                      style={{ marginBottom: "1rem", background: "lightpink" }}
                    >
                      <Typography
                        component="small"
                        style={{ color: "red", padding: "1rem" }}
                      >
                        {accDistribution.message}
                      </Typography>
                    </Paper>
                  )}
                  <h5 className="text-dark">
                    Accommodation Distribution
                    <span style={{ color: "red" }}> * </span>
                  </h5>
                  <div>
                    {/* {accommodationType != null && */}
                    <div className="row">
                      {typeArr.map((element) => (
                        <div className="col-sm-6">
                          <br></br>
                          <label> {stringCap(element).capitalize()}</label>
                          <input
                            className="form-control"
                            value={state[element]}
                            onChange={handleChange}
                            name={element}
                          />
                        </div>
                      ))}
                    </div>
                    {/* } */}
                  </div>
                </div>
              </div>
              <div>
                <div className="p-3 mt-3 bg-white">
                  <h5 className="text-dark">
                    Contact Detail For Escalation
                    <span style={{ color: "red" }}> </span>
                  </h5>
                  <div>
                    <div>
                      <div className="row">
                        <div className="col-6">
                          <label>
                            Contact Name{" "}
                            <span style={{ color: "red" }}> * </span>
                          </label>
                          <input
                            className="form-control"
                            onChange={handleChange}
                            value={state.escalation_name}
                            name="escalation_name"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-6 ">
                          <form
                            noValidate
                            autoComplete="off"
                            className="company_year"
                          >
                            <p>
                              Contact Number{" "}
                              <span style={{ marginLeft: "1%", color: "red" }}>
                                {" "}
                                *{" "}
                              </span>
                            </p>
                            <PhoneInput
                              //onChange={(e) => {
                              //let arr = contact_detail;
                              //arr[index]['number'] = e;
                              //setContactDetail([...arr]);
                              //}}
                              value={state.escalation_number}
                              onChange={(e) =>
                                setState({ ...state, ["escalation_number"]: e })
                              }
                              name="escalation_number"
                              placeholder="Phone Number with Country Code"
                            />
                          </form>
                          {/* <label>
                            Contact Number{" "}
                            <span style={{ color: "red" }}> * </span>{" "}
                          </label>
                          <input
                            className="form-control"
                            onChange={handleChange}
                            value={state.escalation_number}
                            name="escalation_number"
                            placeholder="Phone Number with Country Code"
                         />*/}
                        </div>

                        <div className="col-6 ">
                          <br></br>

                          <label>
                            Email <span style={{ color: "red" }}> * </span>
                          </label>
                          <input
                            className="form-control"
                            onChange={handleChange}
                            value={state.escalation_email}
                            name="escalation_email"
                            placeholder="Email ID"
                          />
                        </div>
                        <div className="col-6 ">
                          <br></br>
                          <form
                            noValidate
                            autoComplete="off"
                            className="company_year"
                          >
                            <p>
                              Alternative Contact Number{" "}
                              <span style={{ marginLeft: "1%", color: "red" }}>
                                {" "}
                              </span>
                            </p>
                            <PhoneInput
                              //onChange={(e) => {
                              //let arr = contact_detail;
                              //arr[index]['number'] = e;
                              //setContactDetail([...arr]);
                              //}}
                              value={state.escalation_number_alter}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  ["escalation_number_alter"]: e,
                                })
                              }
                              name="escalation_number_alter"
                              placeholder="Phone Number with Country Code"
                            />
                          </form>
                          {/*<label>Alternative Contact Number</label>
                          <input
                            className="form-control"
                            onChange={handleChange}
                            value={state.escalation_number_alter}
                            name="escalation_number_alter"
                            placeholder="Phone Number with Country Code"
                          />*/}
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-3 mt-3 bg-white">
                  <h5 className="text-dark">
                    Contact Detail <span style={{ color: "red" }}> </span>
                  </h5>
                  <div>
                    {contact_detail.map((item, index) => (
                      <div>
                        <div className="text-right w-100">
                          {contact_detail.length > 1 && (
                            <span
                              className="cursor-pointer"
                              onClick={() => removeContactDetail(item, index)}
                            >
                              X{" "}
                            </span>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <label>
                              Contact Name
                              <span style={{ color: "red" }}> * </span>{" "}
                            </label>
                            <input
                              className="form-control"
                              onChange={(e) => onChangeContactForm(index, e)}
                              value={item.name}
                              name="name"
                              placeholder="Name"
                            />
                          </div>
                          <div className="col-6 ">
                            <form
                              noValidate
                              autoComplete="off"
                              className="company_year"
                            >
                              <p>
                                Contact Number{" "}
                                <span
                                  style={{ marginLeft: "1%", color: "red" }}
                                >
                                  {" "}
                                  *{" "}
                                </span>
                              </p>
                              <PhoneInput
                                onChange={(e) => {
                                  let arr = contact_detail;
                                  arr[index]["number"] = e;
                                  setContactDetail([...arr]);
                                }}
                                value={item.number}
                                //onChange={(e) => onChangeContactForm(index,e)}
                                name="number"
                                placeholder="Phone Number with Country Code"
                              />
                            </form>
                            {/*<label>
                              Contact Number{" "}
                              <span style={{ color: "red" }}> * </span>
                            </label>
                            <input
                              className="form-control"
                              onChange={(e) => onChangeContactForm(index, e)}
                              value={item.number}
                              name="number"
                              placeholder="Phone Number with Country Code"
                            />*/}
                          </div>
                          <div className="col-6 ">
                            <br></br>

                            <label>
                              Email <span style={{ color: "red" }}> * </span>{" "}
                            </label>
                            <input
                              className="form-control"
                              onChange={(e) => onChangeContactForm(index, e)}
                              value={item.email}
                              name="email"
                              placeholder="Email ID"
                            />
                          </div>

                          <div className="col-6 ">
                            <br></br>
                            <form
                              noValidate
                              autoComplete="off"
                              className="company_year"
                            >
                              <p>
                                Alternative Contact Number{" "}
                                <span
                                  style={{ marginLeft: "1%", color: "red" }}
                                >
                                  {" "}
                                </span>
                              </p>
                              <PhoneInput
                                onChange={(e) => {
                                  let arr = contact_detail;
                                  arr[index]["number_alter"] = e;
                                  setContactDetail([...arr]);
                                }}
                                value={item.number_alter}
                                //onChange={(e) => onChangeContactForm(index,e)}
                                name="number_alter"
                                placeholder="Phone Number with Country Code"
                              />
                            </form>
                            {/*<label>Alternative Contact Number</label>
                            <input
                              className="form-control"
                              onChange={(e) => onChangeContactForm(index, e)}
                              value={item.number_alter}
                              name="number_alter"
                              placeholder="Phone Number with Country Code"
                            />*/}
                          </div>
                          <div className="col-6 ">
                            <br></br>
                            <label>
                              Role <span style={{ color: "red" }}> * </span>
                            </label>
                            <input
                              className="form-control"
                              onChange={(e) => onChangeContactForm(index, e)}
                              value={item.role}
                              name="role"
                              placeholder="Manager / Staff / Reception .. "
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                  {/* {selectedForm == null && */}
                  <button onClick={addContactDetails} className="btn btn-info">
                    +Add Another Contact Detail
                  </button>
                  {/* } */}
                </div>
              </div>

              <div>
                <div className="p-3 mt-3 bg-white">
                  <h5 className="text-dark">
                    Address<span style={{ color: "red" }}> </span>{" "}
                  </h5>
                  <div>
                    <div className="row">
                      {/* <div className="col-sm-6">
                          <label>Street<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.street} onChange={handleChange} name="street" />
                        </div> */}
                      <div className="col-sm-6">
                        <label>
                          Address Line 1{" "}
                          <span style={{ color: "red" }}> * </span>{" "}
                        </label>
                        <input
                          className="form-control"
                          value={state.address_line_1}
                          onChange={handleChange}
                          name="address_line_1"
                          placeholder="#Office No "
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          Address Line 2{" "}
                        </label>
                        <input
                          className="form-control"
                          value={state.address_line_2}
                          onChange={handleChange}
                          name="address_line_2"
                          placeholder="Street / Locality"
                        />
                      </div>
                      <div className="col-sm-6">
                        <br></br>
                        <label>
                          Country <span style={{ color: "red" }}> * </span>{" "}
                        </label>
                        <input
                          className="form-control"
                          value={state.country}
                          onChange={handleChange}
                          name="country"
                          placeholder="Country"
                        />
                      </div>
                      <div className="col-sm-6">
                        <br></br>
                        <label>
                          City <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          className="form-control"
                          value={state.city}
                          onChange={handleChange}
                          name="city"
                          placeholder="City"
                        />
                      </div>
                      <div className="col-sm-6">
                        <br></br>
                        <label>
                          Zip Code / Postal Code / Area Code{" "}
                          <span style={{ color: "red" }}> * </span>{" "}
                        </label>
                        <input
                          className="form-control"
                          value={state.zip_code}
                          onChange={handleChange}
                          name="zip_code"
                          placeholder="Only numbers are allowed "
                        />
                      </div>
                      <div className="col-sm-6">
                        <br></br>
                        {/*<label>National Park</label>
                       <select className="form-control">
                          <option value={state.national_park} onChange={handleChange} name="national_park">-- Select Park --</option>
                          {nationalPark!=null&&
                            <Fragment>
                              {nationalPark.map(item=>
                                <option value={item}> {item} </option>
                              )}
                            </Fragment>
                          }
                        </select>*/}
                        <h6 className="text-dark">
                          National Park{" "}
                          <span style={{ color: "red" }}> * </span>{" "}
                        </h6>

                        {nationalPark != null && (
                          <div className="col-sm-6">
                            {/* <label>Select Currency</label> */}
                            <TextField
                              id="outlined-select-national-native"
                              select
                              placeholder="National Park"
                              onChange={handleChange}
                              value={state.national_park}
                              name="national_park"
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                              }}
                            >
                              <option>-- Select National Park --</option>
                              {nationalPark.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </TextField>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="p-3 mt-3 bg-white">
                  <h5 className="text-dark">
                    Currency Selection <span style={{ color: "red" }}> * </span>{" "}
                  </h5>
                  <div>
                    <div className="row">
                      {currencies != null && (
                        <div className="col-sm-6">
                          {/* <label>Select Currency</label> */}
                          <TextField
                            id="outlined-select-currency-native"
                            select
                            placeholder="Currency"
                            onChange={handleChange}
                            value={state.currency}
                            name="currency"
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                            style={{
                              display: "flex",
                              backgroundColor: "white",
                            }}
                          >
                            <option>-- Select Currency --</option>
                            {currencies.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      )}
                      {/* <div className="col-sm-6">
                      <label>Select Currency</label>
                      <input className="form-control" onChange={handleChange} name="currency" />
                    </div>
                    <div className="col-sm-6">
                      <label>Business</label>
                      <input className="form-control" onChange={handleChange} name="business" />
                    </div>
                    <div className="col-sm-6">
                      <label>Partner</label>
                      <input className="form-control" onChange={handleChange} name="partner" />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* } */}
          </div>
        </div>
      </div>
      <div className="my-4 mb-2">
        {selectedForm ? (
          <button
            onClick={updateAccomodationBasic}
            className="btn btn-primary mx-2"
          >
            UPDATE
          </button>
        ) : (
          <button
            className="btn btn-primary mx-2"
            onClick={submitAccomodationBasic}
          >
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
