import React, { Component, useState, useEffect, useRef } from "react";
import axios from "../../axios";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import SafariReg_policies from "./SafariReg_Policies";
import "./SafariReg_basic.css";
import Button from "@material-ui/core/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function TourReg_basic() {
  const classes = useStyles();
  const guide_id_card_ref = useRef(null);
  const guide_permit_ref = useRef(null);
  const guide_image_ref = useRef(null);
  const banner_image_ref = useRef(null);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const [uploadingMedia, setuploadingMedia] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);

    getRegBasicData();
  }, [user]);

  const [basic, setBasic] = useState({
    partner: null,
    business: 2,
    name: '',
    overview: '',
    guide_id_card: null,
    guide_permit: null,
    guide_image: null,
    banner_image: null,
  });
  const [imagePreview, setImagePreview] = React.useState({
    guide_id_card_change: false,
    guide_id_card: null,
    guide_permit_change: false,
    guide_permit: null,
    guide_image_change: false,
    guide_image: null,
    banner_image_change: false,
    banner_image: null,
  });

  const [contact_detail, setContactDetail] = useState([
    {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null,
    },
  ]);
  const [extension, setextension] = useState({
    guide_id_card: null,
    guide_permit: null,
    guide_image: null,
  });
  const [policy, setPolicy] = useState({
    safari: id,
    document: null,
    guide_id_card: null,
    other: null,
    guide_permit: null,
    customers_review: null,
    guide_image: null,
  });

  function uploadFileHandler(key, e) {
    setBasic({ ...basic, [key]: e.target.files[0] });
    setPolicy({ ...policy, [key]: e.target.files[0] });
    let obj = {};
    if (e.target.files[0] != null) {
      setextension({
        ...extension, 
        [key]: e.target.files[0].name.split(".").pop()
      });
    }
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setBasic({ ...basic, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  const handleChange = (event) => {
    setBasic({ ...basic, [event.target.name]: event.target.value });
  };

  const onChangeContactForm = (index, event) => {
    let arr = contact_detail;
    arr[index][event.target.name] = event.target.value;
    setContactDetail([...arr]);
  };

  function addContactDetails() {
    let arr = [...contact_detail];
    let obj = {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null,
    };
    arr.push(obj);
    setContactDetail(arr);
  }

  function removeContactDetail(itemDel, index) {
    let arr = [...contact_detail];
    arr.splice(index, 1);
    setContactDetail(arr);
  }

  function submitTourBasic() {
    let formData = new FormData();
    let stateUpdate = basic;
    stateUpdate.contact_detail = JSON.stringify(contact_detail);
    console.log("state update", stateUpdate);

    for (const [key, value] of Object.entries(stateUpdate)) {
      if (
        key != "guide_id_card" &&
        key != "guide_permit" &&
        key != "guide_image" &&
        key != "banner_image"
      ) {
        formData.append(key, value);
      } else if (key != "contact_detail") {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/safari-basic-info-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari register basics create",
          res.data
        );
        setuploadingMedia(false);
        history.push(
          "/safari-registration/day-plan/2/" + res.data.id
        );
        // try {
        //   dispatch(tourAction.tour(res.data));

        //   // history.push('/tour-registration/media/2/' + res.data.id)
        // } catch (err) {
        //   console.log('error tour actions', err)
        // }
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data && err.response.data.errors;
        obj && Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating safari registration basics", err);
      });
  }

  const [checkUpdate, setcheckUpdate] = useState(false);

  const getRegBasicData = async () => {
    await axios
      .get(`partners/safari-basic-info-list`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in REG BASIC GET DATA ", err);
        } else {
          console.log(
            "safari operator dtaisl REG BASIC GET DATA ",
            response.data
          );
          if (response.data && response.data.length > 0) {
            setcheckUpdate(true);
            setBasic({ ...response.data[0] });
            setContactDetail([...response.data[0].safari_contact_details]);
            setImagePreview({
              ...imagePreview,
              guide_id_card: response.data[0].guide_id_card,
              guide_permit: response.data[0].guide_permit,
              guide_image: response.data[0].guide_image,
              banner_image: response.data[0].banner_image,
            });
            setextension({
              ...extension,
              guide_id_card: response.data[0].guide_id_card.split(".").pop(),
              guide_permit: response.data[0].guide_permit.split(".").pop(),
              guide_image: response.data[0].guide_image.split(".").pop()
            })
            // if (response.data.safari_contact_details) {
            //   setContacts(response.data.safari_contact_details);
            //   setContactDetail([...response.data.safari_contact_details]);
            // }

            //setVehicle(response.data);
          }
          {
            /*Object.entries(data).map((item) => {
            console.log(' value ',item[0],item[1]);
              handleUpdate(item[0],item[1]);
        }
          )
      }*/
          }
        }
      });
  };

  const updateTourBasic = async () => {
    let formData = new FormData();
    formData.append("partner", basic.partner);
    formData.append("business", basic.business);
    formData.append("name", basic.name);
    formData.append("overview", basic.overview);
    formData.append("safari_contact_details", JSON.stringify(contact_detail));
    if (imagePreview.guide_id_card_change) {
      formData.append("guide_id_card", basic.guide_id_card);
    }
    if (imagePreview.guide_permit_change) {
      formData.append("guide_permit", basic.guide_permit);
    }
    if (imagePreview.guide_image_change) {
      formData.append("guide_image", basic.guide_image);
    }
    if (imagePreview.banner_image_change) {
      formData.append("banner_image", basic.banner_image);
    }

    console.log("form data ", formData);
    setuploadingMedia(true);
    axios
      .put(
        `partners/safari-operator-basic-info-back-update/${basic.id}`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the safari REG BASIC  UPDATE",
          res.data
        );
          setuploadingMedia(false);
        history.push("/safari-registration/day-plan/2/" + basic.id);
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from UPDATING REG BASIC", err);
      });
  };

  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Safari / Game Drive Partner Registration: Basic Information</h4></div>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                        <li>
                          <b>Identity Card of Guide, Guiding Permit:</b>  Allowed file format are either <b>pdf (
                            Max. 2MB File Size)</b> or Image as per image guidelines.
                          
                        </li>
                        <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
                        {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
                      </ul>
                    </p>
                  </div>
                </div>
      <br></br>
      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Name of Guide / Company
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <TextField
            value={basic.name}
            onChange={handleChange}
            name="name"
            id="outlined-basic"
            placeholder="Name"
            variant="outlined"
          />
        </form>
        <br />
        <form noValidate autoComplete="off">
          <p>
            Overview of Guide / Company
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <textarea
            value={basic.overview}
            onChange={handleChange}
            name="overview"
            className="form-control"
            rows="4"
            placeholder="Max. 350 Characters"
          ></textarea>
        </form>
        <br />
      </div>

      <br />
      <br />
      <br />
      <div className="company_contact">
        <h5 style={{ display: "flex", color: "#949494" }}>
          Contact Details
          <span style={{ color: "red", marginLeft: "1%", display: "fle" }}>
            
          </span>{" "}
        </h5>
        <br />
        {contact_detail &&
          contact_detail.map((item, index) => (
            <div>
              <div className="text-right w-100">
                {contact_detail.length > 1 && (
                  <span
                    className="cursor-pointer"
                    onClick={() => removeContactDetail(item, index)}
                  >
                    X{" "}
                  </span>
                )}
              </div>
              <div className="company_year_number">
                <form noValidate autoComplete="off" className="company_year">
                  <p>Contact Name<span style={{ color: "red", marginLeft: "1%", display: "fle" }}>
            *
          </span></p>
                  <TextField
                    onChange={(e) => onChangeContactForm(index, e)}
                    value={item.name}
                    name="name"
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                  />
                </form>
              </div>
              <br />

              <div className="company_year_number">
                <form noValidate autoComplete="off" className="company_year">
                  <p>Contact Number<span style={{ color: "red", marginLeft: "1%", display: "fle" }}>
            *
          </span></p>
                  <PhoneInput
                    onChange={(e) => {
                      let arr = contact_detail;
                      arr[index]["number"] = e;
                      setContactDetail([...arr]);
                    }}
                    value={item.number}
                    name="number"
                    placeholder="Enter phone number"
                  />
                </form>

                <form
                  noValidate
                  autoComplete="off"
                  className="company_year seconds"
                >
                  <p>Alternate Contact Number</p>
                  <PhoneInput
                    onChange={(e) => {
                      let arr = contact_detail;
                      arr[index]["number_alter"] = e;
                      setContactDetail([...arr]);
                    }}
                    value={item.number_alter}
                    name="number_alter"
                    placeholder="Enter phone number"
                  />
                </form>
              </div>

              <br />

              <div className="company_year_number">
                <form noValidate autoComplete="off" className="company_year">
                  <p>Email ID <span style={{ color: "red", marginLeft: "1%", display: "fle" }}>
            *
          </span></p>
                  <TextField
                    onChange={(e) => onChangeContactForm(index, e)}
                    value={item.email}
                    name="email"
                    id="outlined-basic"
                    placeholder="Email"
                    variant="outlined"
                  />
                  <br />
                </form>
              </div>

              <hr />
            </div>
          ))}
        <h6
          onClick={addContactDetails}
          style={{ color: "#007BC3", display: "flex", fontWeight: "bold" }}
        >
          <a> + ADD ANOTHER CONTACT DETAIL</a>{" "}
        </h6>
      </div>
      <br />

      <div className="Main media">
        <div className="media_content text-left">
          <h5>
            Upload Identity Card of Guide{" "}
            <span style={{ color: "red" }}></span>
          </h5>
          {/* <p style={{ color: "#a7a7a7" }}>
            (Allowed File Formats: PDF, JPEG, PNG of Max. 2MB File Size)
          </p> */}
        </div>
        <div className="button_media">
          <input
            className="d-none"
            type="file"
            ref={guide_id_card_ref}
            onChange={(e) => uploadFileHandler("guide_id_card", e)}
          />
          <Button
            onClick={() => {
              guide_id_card_ref.current.click();
            }}
            variant="contained"
            color="#4A8CFF"
          >
            Upload file
          </Button>
          <p className="my-1 text-success">
            {" "}
            {basic.guide_id_card != null ? "File Selected" : null}{" "}
          </p>
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("guide_id_card")}
              className="multiple-image-del px-1"
            >
             X 
            </div>
            {(() => {
            if (
              extension['guide_id_card'] == "pdf" ||
              (policy.guide_id_card != null &&
                imagePreview.guide_id_card != null &&
                imagePreview.guide_id_card.split(".").pop() === "pdf" &&
                policy.guide_id_card.split(".").pop()) == "pdf"
            ) {
              return (
                <a href={imagePreview.guide_id_card} target="_blank">
                  {imagePreview.guide_id_card != null ? (
                    <img
                      src="/images/download.png"
                      className="img-responsive m-2"
                      style={{ width: "50px", height: "30px" }}
                    />
                  ) : (
                    <img
                      src="/images/download2.png"
                      alt="No file"
                      style={{
                        width: "50px",
                        height: "40px",
                        paddingRight: "10px",
                      }}
                    ></img>
                  )}
                </a>
              );
            } else {
              return (
                <a href={imagePreview.guide_id_card} target="_blank">
                  <img
                    src={
                      imagePreview.guide_id_card
                        ? imagePreview.guide_id_card
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "60px", height: "40px" }}
                  />
                </a>
              );
            }
          })()}
             






            {/* <a href={imagePreview.guide_id_card} target="_blank">
            <img
              src={
                imagePreview.guide_id_card
                  ? imagePreview.guide_id_card
                  : "/images/download2.png"
              }
              className="img-responsive m-2"
              style={{ width: "30px", height: "30px" }}
            />
            </a> */}
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="Main media">
        <div className="media_content text-left">
          <h5>
            Upload Guiding Permit <span style={{ color: "red" }}></span>
          </h5>
          {/* <p style={{ color: "#a7a7a7" }}>
            (Allowed File Formats: PDF, JPEG, PNG of Max. 2MB File Size)
          </p> */}
        </div>
        <div className="button_media">
          <input
            className="d-none"
            type="file"
            ref={guide_permit_ref}
            onChange={(e) => uploadFileHandler("guide_permit", e)}
          />
          <Button
            onClick={() => {
              guide_permit_ref.current.click();
            }}
            variant="contained"
            color="#4A8CFF"
          >
            Upload file
          </Button>
          <p className="my-1 text-success">
            {" "}
            {basic.guide_permit != null ? "File Selected" : null}{" "}
          </p>
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("guide_permit")}
              className="multiple-image-del px-1"
            >
              X
            </div>
            {(() => {
            if (
              extension['guide_permit'] == "pdf" ||
              (policy.guide_permit != null &&
                imagePreview.guide_permit != null &&
                imagePreview.guide_permit.split(".").pop() === "pdf" &&
                policy.guide_permit.split(".").pop()) == "pdf"
            ) {
              return (
                <a href={imagePreview.guide_permit} target="_blank">
                  {imagePreview.guide_permit != null ? (
                    <img
                      src="/images/download.png"
                      className="img-responsive m-2"
                      style={{ width: "50px", height: "30px" }}
                    />
                  ) : (
                    <img
                      src="/images/download2.png"
                      alt="No file"
                      style={{
                        width: "50px",
                        height: "40px",
                        paddingRight: "10px",
                      }}
                    ></img>
                  )}
                </a>
              );
            } else {
              return (
                <a href={imagePreview.guide_permit} target="_blank">
                  <img
                    src={
                      imagePreview.guide_permit
                        ? imagePreview.guide_permit
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "60px", height: "40px" }}
                  />
                </a>
              );
            }
          })()}
          {/* <a href={imagePreview.guide_permit} target="_blank">
            <img
              src={
                imagePreview.guide_permit
                  ? imagePreview.guide_permit
                  : "/images/download2.png"
              }
              className="img-responsive m-2"
              style={{ width: "30px", height: "30px" }}
            />
            </a> */}
          </div>
        </div>
      </div>

      <br />
      <div className="Main media">
        <div className="media_content text-left">
          <h5>
            Guide Photo (passport size) <span style={{ color: "red" }}></span>
          </h5>
          {/* <p style={{ color: "#a7a7a7" }}>
            (Allowed File Formats: PDF, JPEG, PNG of Max. 2MB File Size)
          </p> */}
        </div>
        <div className="button_media">
          <input
            className="d-none"
            type="file"
            ref={guide_image_ref}
            onChange={(e) => uploadFileHandler("guide_image", e)}
          />
          <Button
            onClick={() => {
              guide_image_ref.current.click();
            }}
            variant="contained"
            color="#4A8CFF"
          >
            Upload file
          </Button>
          <p className="my-1 text-success">
            {" "}
            {basic.guide_image != null ? "File Selected" : null}{" "}
          </p>
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("guide_image")}
              className="multiple-image-del px-1"
            >
              X
            </div>
            
            {(() => {
            if (
              extension['guide_image'] == "pdf" ||
              (policy.guide_image != null &&
                imagePreview.guide_image != null &&
                imagePreview.guide_image.split(".").pop() === "pdf" &&
                policy.guide_image.split(".").pop()) == "pdf"
            ) {
              return (
                <a href={imagePreview.guide_image} target="_blank">
                  {imagePreview.guide_image != null ? (
                    <img
                      src="/images/download.png"
                      className="img-responsive m-2"
                      style={{ width: "50px", height: "30px" }}
                    />
                  ) : (
                    <img
                      src="/images/download2.png"
                      alt="No file"
                      style={{
                        width: "50px",
                        height: "40px",
                        paddingRight: "10px",
                      }}
                    ></img>
                  )}
                </a>
              );
            } else {
              return (
                <a href={imagePreview.guide_image} target="_blank">
                  <img
                    src={
                      imagePreview.guide_image
                        ? imagePreview.guide_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "60px", height: "40px" }}
                  />
                </a>
              );
            }
          })()}
            {/* <a href={imagePreview.guide_image} target="_blank">
            <img
              src={
                imagePreview.guide_image
                  ? imagePreview.guide_image
                  : "/images/download2.png"
              }
              className="img-responsive m-2"
              style={{ width: "30px", height: "30px" }}
            />
            </a> */}


          </div>
        </div>
      </div>
      <br />

      <div className="Main media">
        <div className="media_content text-left">
          <h4>
            Banner Image <span style={{ color: "red" }}>*</span>
          </h4>
          {/* <p style={{ color: "#a7a7a7" }}>
            (image resolution should be 900*600 with 3:2 aspect ratio and max
            2MB size)
          </p> */}
        </div>
        <div className="button_media">
          <input
            className="d-none"
            type="file"
            ref={banner_image_ref}
            onChange={(e) => uploadFileHandler("banner_image", e)}
          />
          <Button
            onClick={() => {
              banner_image_ref.current.click();
            }}
            variant="contained"
            color="#4A8CFF"
          >
            Upload Image
          </Button>
          <p className="my-1 text-success">
            {" "}
            {basic.banner_image != null ? "Image Selected" : null}{" "}
          </p>
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("banner_image")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            <a href={imagePreview.banner_image} target="_blank">
            <img
              src={
                imagePreview.banner_image
                  ? imagePreview.banner_image
                  : "/images/download2.png"
              }
              className="img-responsive m-2"
              style={{ width: "30px", height: "30px" }}
            />
            </a>
          </div>
        </div>
      </div>

      <br />

      <div className="my-4 mb-2">
        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2" disabled={uploadingMedia}>
            {uploadingMedia ? 'UPLOADING...':'UPLOAD'}
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitTourBasic} disabled={uploadingMedia}>
            {uploadingMedia ? 'UPLOADING...':'CONTINUE'}
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default TourReg_basic;
