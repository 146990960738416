import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios'
import './Safari_basic.css'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import MenuItem from '@material-ui/core/MenuItem';
import './Safari_basic.css';
import { Input } from 'antd';
import 'date-fns';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { TextArea } = Input;
import * as logisticActions from '../../store/actions/logistics';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



function Safari_daybyday() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { gl_id, package_id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const logistic_package_id = useSelector((state) => state.logistic.logistic_package_id);
  const location = useLocation();

  useEffect(() => {
    //getServiceData();
    window.scrollTo(0,0);

    getCurrency();
    getVehicles()
  }, [user])

  useEffect(() => {
    console.log('PATH NAMEW',location.pathname); // result: '/secondpage'
    console.log('SEARCH IN PARAMS',location.search); // result: '?query=abc'
    console.log(location.state); // result: 'some_value'
    if(location.state){
          getEditItem()
        }
    getVehicles()
 }, [location]);

  // useEffect(()=>{
  //  
  // },[logistic_package_id])

  const [currency ,setcurrency] = useState(null);
  const [editItem, setEditItem] = useState(false);

  const [vehicleList, setVehicleList] = useState(null);
  const [service, setService] = useState({
    fleet: package_id,
    pickup_point: null,
    charging_type:null,
    drop_point: null,
    suv_price: null,
    sedan_price: null,
    bus_price: null,
    boat_price: null,
    hatch_back_price: null,
    additional_fee: null,
    other: null,
  })

  const handleChange = (event) => {
    setService({ ...service, [event.target.name]: event.target.value });
  };


  const getCurrency = () => {
    axios.get(`partners/ground-logistics-currency/${gl_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        console.log('goud losgistic fleet currecncy data err', err);
      }
      else {
        console.log('data of logistic fleet currency ', response);
        setcurrency(response.data.currency);
      }
    })
  }

  const getVehicles = () => {
    axios.get(`partners/ground-logistic-vehicle`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,
      }
    }).then((response, err) => {
      if (err) {
        console.log('goud losgistic fleet vehicles err', err);
      }
      else {
        console.log('data of logistic fleet vehicles ', response);
        setVehicleList(response.data.vehicle_list[0])
      }
    })
  }

  function getEditItem(){
    axios.get(`partners/ground-logistic-fleet-route/${location.state.packageId}`,{
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res=>{
      console.log('response from getting edit item logistics',res.data);
      setEditItem(true)
      setService(res.data);
    })
  }

  function submitService() {
    axios.post('partners/ground-logistic-fleet-route-create', service, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the GL package service create', res.data)

      history.push('/ground-logistics-package-forms/route-overview/3/' + gl_id+'/'+package_id)
    }).catch(err => {
      console.log('ERROR  from creating service GL package', err);
    })
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  const [id, setid] = useState(null);
  const getServiceData = async () => {
    await axios.get(`partners/safari-service-detail/${gl_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then((response, err) => {
      if (err) {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log('error in SERVICE GET DATA ', err);
      }
      else {
        console.log('CURRENCY SERVICE GET DATA ', response.data);


        if (response.data && response.data.id) {
          setcheckUpdate(true);
          setService(response.data);
          setid(response.data.id);
          console.log('id of the pagae', id);
        }
        {/*Object.entries(data).map((item) => {
              console.log(' value ',item[0],item[1]);
                handleUpdate(item[0],item[1]);
          }
            )
        }*/}
      }
    })
  }

  function updateStore(){
    try {
      console.log('this. is called')
      let id = null;
      dispatch(logisticActions.setLogisticId(id));
      console.log('this. is called after')

      history.push('/ground-logistics-package-forms/route-overview/3/'+gl_id+'/'+package_id);
    }catch(err){
      console.log('error setting logistics edit package id into actions',err)
    }

  }

  const updateRouteDetails = async () => {
    axios.put(`partners/fleet-route-back-update/${logistic_package_id}`, service, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from updating the fleet item', res.data)
      updateStore();
     
      history.push('/ground-logistics-package-forms/route-overview/3/' + gl_id+'/'+package_id)
    }).catch(err => {
      let obj = err.response.data.errors;
      // Object.entries(obj).map((item, key) => {
      //   toast(' ' + item[0] + ':' + item[1][0])
      // })
      console.log('ERROR  from UPDATING SERVICE safari package', err);
    })
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
  }


  return (
    <div className="TourReg_basic">
      
      <div className="container text-left"><h4>Ground Logistics Package: Route & Services</h4></div>
      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                      <li>
                          <b>Vehicle Charges per Km or Per Day:</b> It is recommended to appropriate option to indicate Vehicle charges in below mentioned fields.

                        </li>
                        <li>
                        <b>Charges per Vehicle:</b> It is recommended to provide this information across shown vehicle(s).

                        </li>
                        <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against each label of 'Prices/Fee'.</b> </li>
                        {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
                      </ul>
                    </p>
                  </div>
                </div>


      <div className="basic_company">

        <form noValidate autoComplete="off" className="company_name">
          <p>Vehicle Charges per Km or Per Day <span style={{ marginLeft: "1%", color: "red" }}> * </span> </p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "75%" }}>
            <Select
              value={service.charging_type}
              onChange={handleChange}
              name="charging_type"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={'Per KM'}>Per KM</MenuItem>
              <MenuItem value={'Per Day'}>Per Day</MenuItem>
              {/* <MenuItem value={'Resort'}>Resort</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem> */}
            </Select>
          </FormControl>
        </form>
        <br />
        {/* <form noValidate autoComplete="off" className="company_name">
          <p>Drop Point <span style={{ marginLeft: "1%", color: "red" }}> * </span> </p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "75%" }}>
            <Select
              value={service.drop_point}
              onChange={handleChange}
              name="drop_point"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={'Nearest airport'}>Nearest Airport</MenuItem>
              <MenuItem value={'Railway station'}>Railway Station</MenuItem>
              <MenuItem value={'Resort'}>Resort</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
        </form> */}
        <br />
        {vehicleList && (Object.entries(vehicleList)).map(([key,value])=>
         <>
         {value&&
          <form noValidate autoComplete="off" style={{ width: '80%' }} className="company_name About">
            <h4 style={{ textAlign: 'left', marginTop: '2rem',textTransform:'capitalize'}}>
              {replaceAll(key,"_"," ")}
              </h4>
            <p style={{ display: "flex" }} >Charge per Vehicle<span style={{ marginLeft: "1%", color: "red" }} > </span></p>
            <div className="input-group" >
              <div className="input-group-prepend resp" style={{ width: '20%' }}>
                <span className="input-group-text" style={{ width: '100%', display: 'flow-root' }} id="inputGroupPrepend"> {currency} </span>
              </div>
              <input type="text"
                type="number"
                value={service[key+'_price']}
                onChange={handleChange}
                name={key+'_price'}
                className="form-control" id="disabledTextInput" style={{ background: 'White' }} placeholder="00.00" aria-describedby="inputGroupPrepend" required />
              
            </div>


          </form>
         }
        </>
        )}
        
        <br />
        <br />
        <br />
        <form noValidate autoComplete="off" style={{ width: '80%' }} className="company_name About">
          <p style={{ display: "flex" }} >Any Additional Fee (Toll Tax)<span style={{ marginLeft: "1%", color: "red" }} ></span></p>
          <div className="input-group" >
            <div className="input-group-prepend resp" style={{ width: '20%' }}>
            <span className="input-group-text" style={{ width: '100%', display: 'flow-root' }} id="inputGroupPrepend"> {currency} </span>
            </div>
            <input type="text"
              type="number"
              value={service.additional_fee}
              onChange={handleChange}
              name="additional_fee"
              className="form-control" id="disabledTextInput" style={{ background: 'White' }} placeholder="00.00" aria-describedby="inputGroupPrepend" required />
            <div className="invalid-feedback">
              Please choose a username.
        </div>
          </div>


        </form>
        <br />
      </div>
      <br />
      <br />
      <br />
      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>Any Other Specific Policies / Company Disclaimer <span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <TextareaAutosize
            rowsMin={5}
            value={service.other}
            onChange={handleChange}
            name="other"
            id="outlined-basic" placeholder="Max 350 Characters" variant="outlined" />
        </form>
      </div>

      <div className="my-4 mb-2">
      {editItem ?
        <Link to={'/ground-logistics-package-forms/route-overview/3/' + gl_id+'/'+package_id}>
          <button className="btn btn-grey border border-primary mx-2">BACK </button>
        </Link>
        :
        <Link to={"/ground-logistics-package-forms/basic-information/1/" + gl_id}>
        <button className="btn btn-grey border border-primary mx-2">BACK </button>
      </Link>
      }
        {editItem ?<button onClick={updateRouteDetails} className="btn btn-primary mx-2">UPDATE</button>: 
          <button className="btn btn-primary mx-2"
            onClick={submitService}
          >CONTINUE</button>
        }

      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default Safari_daybyday