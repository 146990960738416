import React, { Component, useState, useEffect, Fragment } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import "./accomodation_basic.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as accommodationActions from "../../store/actions/accommodation";
import AccomodationBasicNewForm from "./Accommodation_Basic/accommodation_basic_new";
import { Paper, Typography } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const EditInventory = (props) => {
    const history = useHistory()
    const[tent,setTent]=useState(null);
    const[treeHouse,setTreeHouse]=useState(null);

    const[villa,setVilla]=useState(null);

    const[campingSite,setCampingSite]=useState(null);

    const[PermenentStructure,setPermenentStructure]=useState(null);

    const[cottage,setCottage]=useState(null);



    useEffect(() => {
        axios.get(`/partners/accommodation-basic-info-detail-for-distribution/${props.match.params.id}`,{
            headers: {
                'Authorization': 'Token ' + cookies.get('token')
            }
        })
        .then(res => {
            console.log(res.data);
            // setSkillList(res.data)
            setCottage(res.data.cottage)
            setTent(res.data.tent)
            setVilla(res.data.villa)
            setCampingSite(res.data.camping_site)
            setPermenentStructure(res.data.permanent_structure)

            setTreeHouse(res.data.tree_house)

            

        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    
    function updateBasic() {
        console.log("clicled")
        let formData = new FormData();
      
     
        formData.append("tent",tent);
        formData.append("tree_house",treeHouse);
        formData.append("villa",villa);
        formData.append("cottage",cottage);
        formData.append("permanent_structure",PermenentStructure);
        formData.append("camping_site",campingSite);

        
    
        axios
          .patch(
            `/partners/accommodation-distribution-update/${props.match.params.id}`,
            formData,
    
            {
              headers: {
                'Authorization': 'Token'+' '+cookies.get('token')
              },
            }
          )
          .then((res) => {
            console.log(
              "response from submitting the form successful",
              res.data
            );
            // setContent(res.data)
            history.goBack();

             
             
          })
          .catch((err) => {
          
            console.log("ERROR  from update in form", err);
            // alert("Skills is required")
          });
        
      }
      const cancelButton =()=>{
          history.goBack();
      }



    return (
        <div style={{paddingTop:'100px'}}>
        <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
        <h2> Edit Inventory</h2>
        <div className="p-3 bg-white">
        <div>
        <h4>Accommodation Distribution</h4>
          <div className="row">
          
            <div className="col-sm-6">
            
              <label>
               Tent
                
              </label>
              <input
                className="form-control"
                 value={tent}
                 onChange={(e)=> setTent(e.target.value)}

                name="name"
                placeholder="Tent"
              />
            </div>
            <div className="col-sm-6">
              <label>
                Permenent Structure
              </label>
              <input
                className="form-control"
                 value={PermenentStructure}
                 onChange={(e)=> setPermenentStructure(e.target.value)}
                name="website"
                placeholder="Permenent Structure"
              />
            </div>
           
          
              <Fragment>
                <div className="col-sm-6">
                  <br></br>
                  <label>Tree House </label>
                  <input
                    className="form-control"
                     value={treeHouse}
                     onChange={(e)=> setTreeHouse(e.target.value)}
                    name="trip_adviser_rating"
                    placeholder="Tree House"
                  />
                </div>
                <div className="col-sm-6">
                  <br></br>
                  <label>Cottage</label>
                  <input
                    className="form-control"
                     value={cottage}
                     onChange={(e)=> setCottage(e.target.value)}
                    name="property_url_trip_adviser"
                    placeholder="Cottage"
                  />
                </div>
              </Fragment>
           
      
              <Fragment>
                <div className="col-sm-6">
                  <br></br>
                  <label>Villa</label>
                  <input
                    className="form-control"
                     value={villa}
                     onChange={(e)=> setVilla(e.target.value)}
                    name="other_rating"
                    placeholder="Villa"
                    
                  />
                </div>
                <div className="col-sm-6">
                  <br></br>
                  <label>Camping Site </label>
                  <input
                    className="form-control"
                     value={campingSite}
                     onChange={(e)=> setCampingSite(e.target.value)}
                    name="property_url_other"
                    placeholder="Camping Site"
                  />
                </div>
              </Fragment>
              <button className="btn btn-primary mx-2 mt-3" onClick={updateBasic}>Submit</button>
              <button className="btn btn-primary mx-2 mt-3" onClick={cancelButton}>Cancel</button>
           
          </div>
        </div>
      </div>
      
            
        </div>
        </div>
        </div>
        </div>
    )
}

export default EditInventory
