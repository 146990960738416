import React from 'react'

const PartnerAgreement = () => {
    return (
        <div>
       
        <div className="pagewrap" style={{padding: "50px",textAlign:"left",padding:"100px"}}>
          <div className="mainContent">
            <h1 style={{textAlign:"center"}}>BUSINESS CONTRACT</h1>
            <p>
              Travel Unbounded World Pvt Ltd, with a business address at No 10, 8th
              Main, 12th Cross, Vinayaka Nagara, B block, Konena Agrahara, Bangalore
              56017, India, under the brand name ‘AND BEYOND ROADS’ (“Client”), and [CONTRACTOR NAME], with a
              business address at [ADDRESS] (“Provider”), enter into this Business
              Contract (this “Business Contract” or this “Agreement”) for the
              performance of services as set forth in the statement of work (each, a
              “Statement of Work”) attached to and made part of this Agreement, from
              time to time as Exhibits, on the following terms and conditions:
            </p>
            <h2>SCOPE OF WORK</h2>
            <p>
              To provide all details about the property owned / leased by the
              ‘Provider’, safari vehicles, cabs, make it eligible to market them and
              sell it to potential customers on the online platform, ‘And Beyond
              Roads’, the online platform owned solely by Travel Unbounded World Pvt
              Ltd. The Provider to render the best of its services to the customers
              who have availed the services of stay, safari, game drives, boarding
              and lodging, logistics, internal transfers etc. The work to be
              performed by Provider under this Business Contract (“Services”) shall
              be as set forth in Provider’s Statements of Work. Provider will
              exercise its best efforts to complete the Services in a professional
              and diligent manner, on the schedule and at the price stated and
              agreed for the contract period, in each Statement of Work. Provider
              shall cater, at Provider’s sole expense, all necessary expertise, to
              perform the Services to the customers of the Client. If Client
              provides any specific requirement of its customers, it will be used
              exclusively catered as per the requirement for customers of the
              Client. No subcontractors or consultants shall be engaged to carry out
              any part of the Services without prior written permission of Client.
              Client shall have the right at any time to question the quality of
              services rendered by the Provider’s personnel.
            </p>
            <h2>TERM</h2>
            <p>
              The term of this Agreement is for [NUMBER] year from the date of its
              execution by Provider or until the completion of the work described in
              any Statement of Work, whichever is later.
            </p>
            <h2>PAYMENT</h2>
            <p>
              Provider will provide an invoice to the customers of the Client on
              booking. This invoice would be considered as a confirmation voucher
              for Services performed within [NUMBER] days of performance. Client
              will pay Provider as set forth in each Statement of Work within
              [NUMBER] days of receipt and acceptance of such invoice. It is
              understood that payments to Provider for services rendered shall be
              made in full as agreed, without any deductions with applicable taxes,
              in conformity with Provider’s status as an independent contractor.
            </p>
            <h2>ACCEPTANCE</h2>
            <p>
              The Deliverables, as defined in each Statement of Work, shall be
              deemed accepted by Client upon completion of the following acceptance
              test:
            </p>
            <li>
              immediately upon receipt of said Deliverables ( services to be
              rendered as per policy of the ‘Provider’ ), Client shall promptly, but
              in any event not more than 10 business days (“Acceptance Period”), to
              confirm that the Deliverables perform in accordance with the
              documentation or other standards applicable thereto as set forth in
              the applicable Statement of Work;
            </li>
            <li>
              Within 2 business days following the end of the Acceptance Period,
              Client shall either provide Provider with written acceptance of the
              Deliverables via e-mail to the contact provided by the Provider, or
              deliver to Provider a detailed written statement of nonconformities to
              be corrected prior to Client’s acceptance of the Deliverables.
            </li>
            <p>
              Any such written statement of nonconformities shall provide sufficient
              detail to enable Provider to remedy the failure to conform to the
              completion criteria contained in the applicable Statement of Work.
              Unless otherwise agreed to in writing by the parties, Provider will
              refund the amount spent by the customer in receipt of such statement
              of nonconformities.
            </p>
            <h2>INTELLECTUAL PROPERTY</h2>
            <li>
              1. Client will retain ownership of any data, information or
              intellectual property furnished to Provider in connection with this
              Agreement. Client will own any intellectual property that results from
              the Services, including without limitation software (in both
              machine-readable form and in source code form), data, and other
              information, excluding any intellectual property generated by Provider
              in performing the Services that relates solely to Provider’s business,
              which shall be the property of Provider; provided that Client shall
              have the right to use such Provider intellectual property in
              accordance with the following license terms:
            </li>
            <p>
              1. Client may use Provider Intellectual Property solely in connection
              with the services, for the purpose for which those services were
              originally agreed upon.
            </p>
            <p>
              2. Client may not transfer, sell, or otherwise dispose of any Provider
              Proprietary Items without the prior written consent of Provider.
            </p>
            <p>
              3. This license gives no title or ownership rights in Provider
              Intellectual Property or related intellectual property to Client.
            </p>
            <p>
              4. The software source code is owned by the Client under this license,
              Provider or its employees agree to keep the source code strictly
              confidential.
            </p>
            <p>
              5. Client agrees to retain or reproduce on all copies of any Provider
              Intellectual Property all copyright notices and other proprietary
              legends and all trademarks or service marks of Provider
            </p>
            <p>
              6. Client will have no rights to assign or sell the Provider’s
              personal or official details granted herein to others.
            </p>
            <p>
              7. Separate licensing agreements will be negotiated for any of
              Provider’s additional services not mentioned initially, if such items
              are ordered by Client.
            </p>
            <p>
              8. Provider or its employees are strictly banned from interfering with
              the software provided to the Provider for inventory uploading purpose.
            </p>
            <h2>CONFIDENTIALITY</h2>
            <p>
              For purposes of this Agreement, the term “Information” shall mean any
              information that Provider may receive from Client pursuant to this
              Agreement. Provider shall maintain the Information in confidence with
              the same degree of care it holds its own confidential information.
              Provider shall not use the Information except to perform the Services.
              Provider will disclose the Information only to its officers and
              employees directly concerned with the Statements of Work, but will
              neither disclose the Information to any third party nor use the
              Information for any other purpose. Provider’s obligation of
              nondisclosure and the limitations upon the right to use the
              Information shall not apply to the extent that Provider can
              demonstrate that the Information:
            </p>
            <li>
              was in the possession of Provider prior to the time of disclosure; or
            </li>
            <li>
              is or becomes public knowledge through no fault or omission of
              Provider; or
            </li>
            <li>
              is obtained by Provider from a third party under no obligation of
              confidentiality to Client.
            </li>
            <p>
              All Information will be returned or, in the case of the Statements of
              Work, delivered to Client upon termination of this Business Contract
              for any reason, except for one copy, which Provider may use for the
              sole purpose of determining its continuing confidentiality obligation
              to Client under this Agreement. All obligations of Provider under this
              Section shall survive the termination of this Agreement for a period
              of five (5) years.
            </p>
            <h2>INDEMNIFICATION</h2>
            <p>
              Provider understands that, since Provider is an independent
              contractor, any personal injury or property damage suffered by
              Provider or its property during a customer visit, will have to be felt
              as per Privider’s policy and Client is no way responsible for the
              same. No worker’s compensation insurance shall be obtained by Client
              concerning Provider or any of Provider’s employees. Provider shall
              comply with worker’s compensation laws and shall provide a certificate
              of worker’s compensation insurance, health department certification,
              Govt licenses, where applicable. Client agrees to indemnify and hold
              Provider harmless against all claims, demands, suits, liabilities,
              losses, damages or injuries (collectively “Liabilities”) that arise
              out of Client’s use of the Services, except to the extent such
              Liabilities result from the negligence or wrong doing of Provider.
              Provider agrees to indemnify and hold Client harmless against all
              claims, demands, suits, liabilities, losses, damages or injuries that
              arise out of Provider’s performance of this Agreement, except to the
              extent such Liabilities result from the negligence or wrong doing of
              Client.
            </p>
            <h2>LIMITATION OF LIABILITY</h2>
            <p>
              In no event will either party be liable for any SPECIAL, INDIRECT,
              CONSEQUENTIAL, OR INCIDENTAL DAMAGES.
            </p>
            <h2>NOTICES</h2>
            <p>
              Any notices permitted or required pursuant to this Agreement shall be
              deemed effective if made in writing and sent, postage prepaid, return
              receipt requested, or by overnight delivery, as follows: If to Client:
              [ADDRESS] Invoices should be sent to the following address: [ADDRESS]
              If to Provider: [ADDRESS]
            </p>
            <h2>ENTIRE AGREEMENT</h2>
            <p>
              This Agreement sets forth the entire agreement between Client and
              Provider as to its subject matter. None of the terms of this Business
              Contract shall be amended except in writing signed by both parties.
            </p>
            <h2>TERMINATION FOR CONVENIENCE</h2>
            <p>
              Client may terminate this Agreement or any Statement of Work without
              cause by giving [NUMBER] days notice to Provider in writing. If Client
              terminates this Agreement, Client’s only obligation shall be to pay
              Provider for the Services performed up to the date of termination, at
              the rate provided, for Time and Material-type Statements of Work,
              under the applicable Statements of Work. For Fixed Price type
              Statements of Work, Client shall be obligated to pay for all completed
              Deliverables ( services agreed to be rendered by the Provider ) plus
              any work-in-progress up to the date of termination. Upon termination
              or expiration of this Agreement, Provider will assemble and turn over
              in an orderly fashion to authorized representatives of Client all
              documents, write-ups, notes, computer programs, and other material
              related to the Services. Client may terminate this Agreement
              immediately for cause and shall incur no liability for Services not
              satisfactorily performed.
            </p>
            <h2>TERMINATION FOR BREACH</h2>
            <p>
              If either party breaches this Agreement, the other may terminate this
              Agreement if the breaching party does not cure the breach within
              thirty (30) days of written notice of same. Termination shall be
              without prejudice to any rights which may have been accrued to either
              party before termination.
            </p>
            <h2>FORCE MAJEURE</h2>
            <p>
              Neither Client nor Provider shall be liable for failure of or delay in
              performing obligations set forth in this Agreement, and neither shall
              be deemed in breach of its obligations, if such failure or delay is
              due to natural disasters or any causes reasonably beyond the control
              of Client or Provider.
            </p>
            <h2>AUTHORITY AND COMPLIANCE</h2>
            <p>
              Provider has the right and authority to enter into and perform its
              obligations under this Agreement. Provider will perform all of its
              obligations under this Agreement in accordance with all applicable
              governmental laws, rules, and regulations.
            </p>
            <h2>CHOICE OF LAW</h2>
            <p>
              This Agreement shall be construed in accordance with the laws of the
              State of Karnataka in the city of Bangalore, India. IN WITNESS WHEREOF
              the parties have caused this Business Contract to be executed and
              delivered by their duly authorized representatives.
            </p>
          </div>
        </div>
        <h2 style={{textAlign:"center"}}>END OF BUSINESS CONTRACT</h2>
            
        </div>
    )
}

export default PartnerAgreement
