import React, { Component, useState, useEffect, useRef } from 'react'
import axios from '../../axios';

import { Link, NavLink } from 'react-router-dom';

import I2 from './assests/I2.png'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function Tour_policies() {
  const policy_document_ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { tour_id } = useParams();
  const [updateForm, setUpdateForm] = useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);
    getFormDetails()
  }, [user])

  const [toursMedia, setToursMedia] = React.useState({
    tour_operator: tour_id,
    policy_document: null,
    note: null,
  })
  const [imagePreview, setImagePreview] = React.useState({
    policy_document_change: false,
    policy_document: null,
  })

  const [extension, setextension] = useState(null);

  function uploadFileHandler(key, e) {
    setToursMedia({ ...toursMedia, [key]: e.target.files[0] });
    if(e.target.files[0]!=null){
      setextension(e.target.files[0].name.split('.').pop());
    } 
    let obj = {};
    obj[key + '_change'] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj })
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setToursMedia({ ...toursMedia, [key]: null });
    let obj = {};
    obj[key + '_change'] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj })
  }

  function handleChange(e) {
    setToursMedia({ ...toursMedia, [e.target.name]: e.target.value });
  }

  const [loading, setloading] = useState(false);

  function getFormDetails(){
    axios.get(`partners/tour-operator-policy-detail/${tour_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from getting  the tour operator policy get', res.data)
      if(res.data){
        setUpdateForm(true)
      }
      setToursMedia({...res.data});
      setImagePreview({...imagePreview, 'policy_document':res.data.policy_document});
    }).catch(err => {
      console.log('ERROR  from getting tour package basics', err);
    })
  }

  function submitToursPolicy(){
    let formData = new FormData();
    formData.append('tour_operator', toursMedia.tour_operator);
    if (imagePreview.policy_document_change) {
      formData.append('policy_document', toursMedia.policy_document);
    }
    formData.append('note',toursMedia.note);
    

   
   setloading(true);
    axios.post('partners/tour-operator-policy-create', formData, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setloading(false)
      history.push('/tour-registration/finance/3/' + tour_id);
      console.log('response from submitting the tours  policy  create', res.data)
    }).catch(err => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('error from creating accomodation policy create', err);
    })

  }

  function updateToursPolicy(){
    let formData = new FormData();
    formData.append('tour_operator', toursMedia.tour_operator);
    if (imagePreview.policy_document_change) {
      formData.append('policy_document', toursMedia.policy_document);
    }
    formData.append('note',toursMedia.note);
    

   
   setloading(true);
    axios.put(`partners/tour-operator-policy-back-update/${toursMedia.id}`, formData, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setloading(false)
      history.push('/tour-registration/finance/3/' + tour_id);
      console.log('response from submitting the tours  policy  update', res.data)
    }).catch(err => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('error from tours operator policy update', err);
    })
  }

  return (
    <div className="Tour_policies">
      <h4 style={{ display: 'flex', color: "#949494" }}>Tour Partner Registration: Policies</h4>
      
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h6>Note:</h6>
          <p>
            <ul>
              
              <li><u>It is recommended to provide all informations.</u> </li>
                {/* <li><b>Cancellation:</b> It is recommended to provide slab as <b>'Days Prior
                Vs Percentile of Loss' </b> in slabs such as <br></br> <b>30 days prior / 15 to 30
                days / 7 to 15 days / less than 7 days </b> and mention the
                percentile of loss against each one of them.{" "}</li>
                <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) wherever used. </b> </li> */}
              
              <li>
                Your Own Policy Document: You may upload detail policy in pdf
                (Max 2MB Size).{" "}
              </li>
              {/* <li>
                {" "}
                Customer Review: Format must be as - Name, Review Text and One
                line space between two reviews.{" "}
              </li> */}
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>
      
      
      <div className="Policies_document">
        <div className="Policies_document_main">
          {/* <img src={I2}></img> */}
          <h5>Policy Document (If Any) </h5>
        </div>
        <input className="d-none" type="file" ref={policy_document_ref} onChange={(e) => uploadFileHandler('policy_document', e)} />
        <Button onClick={() => { policy_document_ref.current.click() }} variant="contained" className="Policies_button" color="primary">
          Upload file
      </Button>
        {/*<p className="my-1 text-success"> {toursMedia.policy_document != null ? 'File Selected' : null} </p>*/}
        <div className="multiple-image">
          <div onClick={() => delUploadFileHandler('policy_document')} className="multiple-image-del px-1">x</div>
         {/* <img src={imagePreview.policy_document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          {(() => {
            //var fileName = imagePreview.document;
            //var fileExtension = fileName.split('.').pop();
              if(extension == 'pdf' || ( toursMedia.policy_document!=null && (imagePreview.policy_document!=null && imagePreview.policy_document.split('.').pop() === 'pdf') && toursMedia.policy_document.split('.').pop()) == 'pdf'){
                return (
                  <>
                  <p className="my-1 text-success"> {toursMedia.policy_document != null ? `File-->${toursMedia.policy_document.name} Selected` : null} </p>
                <a href={imagePreview.policy_document} target="_blank">
                  {imagePreview.policy_document!=null ? (
                  <img src='/images/download.png' className="img-responsive m-2" style={{ width: '50px', height: '30px' }} />
                  ):(<img src='/images/download2.png' alt='No file' style={{ width: '50px', height: '40px',paddingRight:'10px' }}></img>)}
                </a>  
                </>
                  )
              }else{
                return (
                  <>
                  <p className="my-1 text-success"> {toursMedia.policy_document != null ? `Image --> ${toursMedia.policy_document.name}  Selected` : null} </p>
                  <a href={imagePreview.policy_document} target="_blank">
                    <img src={imagePreview.policy_document ? imagePreview.policy_document : '/images/download2.png'} className="img-responsive m-2" style={{ width: '60px', height: '40px' }} />
                  </a> 
                  </>
                )
              }
          })()}
        </div>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>Any Other Specific Policies / Company Disclaimer<span style={{ marginLeft: "1%", color: "red" }}></span></p>
          <textarea name="note" inputProps={{
              maxlength: 350
            }} value={toursMedia.note!=='null' ? toursMedia.note : ''} onChange={handleChange} id="outlined-basic" rows="4" placeholder="Max. 350 Characters" className="form-control" variant="outlined" />
        </form>
      </div>

      <div className="text-center my-4 mb-2">
        {/*<Link to={"/tour-registration/media/2/"+tour_id}>*/}
          <button onClick={() => history.goBack()} className="btn btn-grey border border-primary mx-2">BACK </button>
        {/*<Link>*/}
        {updateForm ?
                <button onClick={updateToursPolicy} className="btn btn-primary mx-2">UPDATE</button>
                :
        <button onClick={submitToursPolicy} className="btn btn-primary mx-2" disabled={loading}>CONTINUE</button>

         }
        {/* {loading && <div style={{textAlign:'center',color:'red',width:'100%',background:'lightpink',padding:'1rem',marginTop:'1rem'}}>Uploading...</div>} */}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default Tour_policies
