import React, { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../axios";
import Cookies from 'universal-cookie';
import * as authActions from "../store/actions/auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./header.css";

const cookies = new Cookies();

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  
  const user = useSelector((state) => state.auth.user);
  // if(user==null){
    //   const userGet = JSON.parse(sessionStorage.getItem('user'))
    // }
    // useEffect(() => {
    // console.log("user data", user);
    // if(user) {
    //   setEmail(user.email);
    //   cookies.set("token", user.token);
    //   cookies.set("id", user.id);
    //   cookies.set("email", user.email);
    //   cookies.set("name", user.profile.name);
    // }})
    // try {
    //   dispatch(authActions.login(userGet.email, userGet.password,userGet));
    //   // history.push('/home/');
    // }catch(err){
    //   console.log('error logging into signin actions',err)
    // }
  // }, [user]);
  // if(user==null){
  //   console.log('user from session storga',sessionStorage.getItem('user'));
  // }



  const logout = () => {
    axios
      .post(
        "auth/logout",
        {},
        {
          headers: {
            // Authorization: "Token " + user.token,
            Authorization: "Token " + cookies.get('token'),
          },
        }
      )
      .then((res) => {
        toast("successfull");
          cookies.remove("token");
          cookies.remove('email');
          cookies.remove('name');
          history.push("/login");
          window.location.reload();
          // dispatch(authActions.logout());
      })
      .catch((err) => {
        // const notify = () => toast("Wow so easy !");
        toast("Error loggin out ");
      });
  };

  return (
    <div className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <Link to="/">
          <img
            src="/images/logo/Logo 003.png"
            width="150"
            height="50"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto"></ul>
          <div className="flex-grow-1 text-right">
            {/* if(!cookies.get('user')) */}
              {(email==='' && !cookies.get('token')) ? (
              <span>
                <Link to="/login">
                  <button className="btn btn-primary mx-2">SIGN IN</button>
                </Link>
                <Link to="/signup">
                  <button className="btn btn-primary mx-2">SIGN UP</button>
                </Link>
              </span>
            ) : (
              <ul className="navbar-nav flex-grow-1 text-right">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* {user.email} */}
                    {cookies.get('name')}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item pointer" to="/home">
                      Home
                    </Link>
                    <Link className="dropdown-item pointer" to="/update-profile" >Edit Profile</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item pointer" to="/support">
                      Support
                    </Link>
                    <p onClick={logout} class="dropdown-item pointer">
                      Logout
                    </p>
                  </div>
                </li>
                <li className="nav-item">
                  <img
                    src="/images/assets/profile_dummy_image.png"
                    className="profile-image-nav img-responsive"
                  />
                  {/* <a class="nav-link disabled" href="#">Disabled</a> */}
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
