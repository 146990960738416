

import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
// import * as authActions from '../../store/actions/auth';
import TourBasic from './TourReg_basic.js';
import TourMedia from './Tour_media.js';
import TourPolicies from './Tour_Policies.js';
import TourFinance from './Tour_finance.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function TourReg() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    // let accommodationAction = useSelector((state) => state.accommodation.accommodation);
    // if (accommodationAction == null) {
    //     accommodationAction = {}
    //     accommodationAction['id'] = 0;
    // }

    useEffect(() => {
        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

  
    
    return (
        <div className="header-top-space">
            <div className="d-sm-block text-center  d-none">
                <div className="d-flex  justify-content-around step-form">
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="stepper-title" >Basic Information</p>
                    </div>
                   
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="stepper-title">Policies</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="stepper-title">Finance</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="stepper-title">Image & Video</p>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path="/tour-registration/basic/1" exact component={TourBasic} />
                <Route path="/tour-registration/media/4/:tour_id" exact component={TourMedia} />
                <Route path="/tour-registration/policy/2/:tour_id" exact component={TourPolicies} />
                <Route path="/tour-registration/finance/3/:tour_id" exact component={TourFinance} />
                {/* <Route path="/accomodation/media/5/:accomodation_id" exact component={AccomodationMedia} />
                <Route path="/accomodation/policy/6/:accomodation_id" exact component={AccomodationPolicy} />
                <Route path="/accomodation/finance/7/:accomodation_id" exact component={AccomodationFinance} />
                <Route path="/accomodation/documents/8/:accomodation_id" exact component={AccomodationDocuments} />
                <Route path="/accomodation/view-form/:accommodation_id" exact component={AccomodationFormView} /> */}
                {/* <Route path="/accomodation/submit/:accomodation_id" exact component={AccomodationSubmit}/> */}
            </Switch>
        </div>
    );
}