import axios from '../../axios';

export const SAFARIID = 'SAFARIID';


export const safariid = (safari_id) => {
  return async dispatch => {
        dispatch({
          type: SAFARIID,
          safari_id: safari_id
        })
  };
}

