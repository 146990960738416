import React, { Component, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./accomodation_facility.css";
import { Box } from "@material-ui/core";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});

export default function AccomodationFacility() {
  const classes = useStyles();
  const logo_image_ref = useRef(null);
  const banner_image_ref = useRef(null);
  const accommodation_video_ref = useRef(null);
  const accommodation_image_ref = useRef(null);
  const property_image_ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { accomodation_id } = useParams();
  const [update, setUpdate] = React.useState(false);
  const [uploadingMedia, setuploadingMedia] = useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0, 0);

    getAccomodationMedia();
  }, [user]);

  const [accomodationImage, setAccomodationImage] = React.useState({
    accommodation: accomodation_id,
    accommodation_image: [],
    property_image: null,
  });

  const [accomodationBanner, setAccomodationBanner] = React.useState({
    accommodation: accomodation_id,
    banner_image: null,
    accommodation_video: "",
    accommodation_video_link: "",
    logo_image: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    accommodation_image_change: false,
    accommodation_image: [],
    property_image_change: false,
    property_image: null,
    banner_image_change: false,
    banner_image: null,
    accommodation_video_change: false,
    accommodation_video: null,
    logo_image_change: false,
    logo_image: null,
  });

  const [
    accommodation_image_list,
    set_accommodation_image_list,
  ] = React.useState(null);
  async function getAccomodationMedia() {
    await axios
      .get(`partners/accommodation-banner-logo-list/${accomodation_id}`, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          setAccomodationBanner(res.data[0]);
          //setImagePreview({ ...imagePreview, ...res.data[0] });
          if (res.data != null && res.data.length) {
            imagePreview["logo_image"] = res.data[0].logo_image;
            imagePreview["banner_image"] = res.data[0].banner_image;
          }

          //setImagePreview({...imagePreview,['logo_image']:res.data[0].logo_image});
        } else {
          setUpdate(false);
        }
        console.log(
          "response fromaccommodation-banner-logo-list",
          res.data,
          "IMAGE PREVIEW",
          imagePreview
        );
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });

    await axios
      .get(`partners/accommodation-image-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          let arr_image = [];
          let i = 0;
          for (i = 0; i < res.data.length; i++) {
            arr_image.push(res.data[i].accommodation_image);
          }
          setAccomodationImage({
            ...accomodationImage,
            accommodation_image: arr_image,
          });
          set_accommodation_image_list(res.data);
          setImagePreview({ ...imagePreview, accommodation_image: arr_image });
        } else {
          setUpdate(false);
        }
        console.log("response accommodation-image-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });
  }
  console.log("image preview ", imagePreview);

  function uploadFileHandler(key, e) {
    if (key == "banner_image") {
      setError2(false);
    }
    if (key == "logo_image") {
      setError(false);
    }
    setAccomodationBanner({ ...accomodationBanner, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null
  }
  function delUploadFileHandler(key) {
    setAccomodationBanner({ ...accomodationBanner, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function uploadImageFileHandler(key, e) {
    setAccomodationImage({ ...accomodationImage, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
  }
  function delUploadImageFileHandler(key, e) {
    setAccomodationImage({ ...accomodationImage, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function uploadMultipleImageHandler(key, e) {
    let i = 0;
    let imagePreviewArr = [];
    let imageFileArr = [];
    for (i = 0; i < e.target.files.length; i++) {
      imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      imageFileArr.push(e.target.files[i]);
    }
    if (
      imagePreviewArr.length + imagePreview["accommodation_image"].length >
      4
    ) {
      toast("Max 4 images allowed");
    } else {
      setImagePreview({
        ...imagePreview,
        accommodation_image: [
          ...imagePreview["accommodation_image"],
          ...imagePreviewArr,
        ],
        accommodation_image_change: true,
      });
      setAccomodationImage({
        ...accomodationImage,
        accommodation_image: [
          ...accomodationImage["accommodation_image"],
          ...imageFileArr,
        ],
      });
    }
    e.target.value=null
  }

  function deleteMultipleImage(key, indexitem) {
    const imagesFilter = accomodationImage[key].filter(
      (item, index) => index !== indexitem
    );
    console.log("images fileter accommodation images", imagesFilter);
    setAccomodationImage({ ...accomodationImage, [key]: imagesFilter });
    let obj = {};
    obj[key + "_change"] = true;
    const imagesPreviewFilter = imagePreview[key].filter(
      (item, index) => index !== indexitem
    );
    obj[key] = imagesPreviewFilter;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function handleChange(e) {
    setAccomodationBanner({
      ...accomodationBanner,
      [e.target.name]: e.target.value,
    });
  }
  let x = 0;
  function submitAccomodationMedia() {
    let formData = new FormData();
    formData.append("accommodation", accomodationImage.accommodation);
    // if (imagePreview.accommodation_image_change) {
    //   formData.append('accommodation_image[]', accomodationImage.accommodation_image);
    // }
    // if (imagePreview.property_image_change) {
    //   formData.append('property_image', accomodationImage.property_image);
    // }
    let formData2 = new FormData();
    formData2.append("accommodation", accomodationBanner.accommodation);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
      setError2(false);
    } else {
      setError2({ message: "Banner Image is Mandatory!" });
    }
    if (imagePreview.accommodation_video_change) {
      formData2.append(
        "accommodation_video",
        accomodationBanner.accommodation_video
      );
    }
    formData2.append(
      "accommodation_video_link",
      accomodationBanner.accommodation_video_link
    );
    if (imagePreview.logo_image_change) {
      formData2.append("logo_image", accomodationBanner.logo_image);
      setError(false);
    } else {
      setError({ message: "Logo Image is Mandatory!" });
    }

    let i = 0;
    for (
      i = 0;
      i <
      (accomodationImage.accommodation_image != null &&
        accomodationImage.accommodation_image.length);
      i++
    ) {
      if (imagePreview.accommodation_image_change) {
        formData.append(
          `accommodation_image_${i}`,
          accomodationImage.accommodation_image[i]
        );
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/accommodation-image-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation media create",
          res.data
        );
        if (x == 1) {
          setuploadingMedia(false);
        }
        x = 1;
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });

    // if (!error) {

    axios
      .post("partners/accommodation-banner-logo-create", formData2, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation banner logo create",
          res.data
        );
        if (x == 1) {
          setuploadingMedia(false);
        }
        x = 1;
        // history.push("/accomodation/policy/6/" + accomodation_id);
        history.push("/home/");
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
    // }
  }

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  let z = 0;
  function updateAccomodationMedia() {
    let bannerUpdate = false;
    let mulImageUpdate = false;
    let formData = new FormData();
    formData.append("accommodation", accomodationImage.accommodation);
    // if (imagePreview.accommodation_image_change) {
    //   formData.append('accommodation_image', accomodationImage.accommodation_image);
    // }
    // let i = 0;
    // for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
    //   if (imagePreview.accommodation_image_change) {
    //     formData.append(
    //       `accommodation_image_${i}`,
    //       accomodationImage.accommodation_image[i]
    //     );
    //   }
    // }
    if (imagePreview.accommodation_image_change) {
      let i = 0;
      for (i = 0; i < 4; i++) {
        if (accomodationImage.accommodation_image.length > i) {
          formData.append(
            `image_${i}`,
            accomodationImage.accommodation_image[i]
          );
        }
        if (accommodation_image_list.length > i) {
          formData.append(`image_${i}_id`, accommodation_image_list[i].id);
        }
      }
    }
    // if (imagePreview.property_image_change) {
    //   formData.append('property_image', accomodationImage.property_image);
    // }
    let formData2 = new FormData();
    formData2.append("accommodation", accomodationBanner.accommodation);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
    }

    if (imagePreview.accommodation_video_change) {
      formData2.append(
        "accommodation_video",
        accomodationBanner.accommodation_video
      );
    }
    formData2.append(
      "accommodation_video_link",
      accomodationBanner.accommodation_video_link
    );
    if (imagePreview.logo_image_change) {
      formData2.append("logo_image", accomodationBanner.logo_image);
    }

    axios
      .put(`partners/accommodation-image-back-update`, formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation media create",
          res.data
        );
        z = 1;
        mulImageUpdate = true;
        history.push("/home/");

        // history.push('/accomodation/media/6/'+accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });

    axios
      .put(
        `partners/accommodation-banner-back-update/${accomodationBanner.id}`,
        formData2,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the accomodation banner logo create",
          res.data
        );
        // if(z==1){
        //   history.push("/accomodation/policy/6/" + accomodation_id);
        // }
        history.push("/home/");
        bannerUpdate = true;
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
    // if (bannerUpdate && mulImageUpdate) {
    //   // history.push("/accomodation/policy/6/" + accomodation_id);
    //   history.push("/accomodation-submit/" + accomodation_id);
    // }
  }

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            <br></br>
            <h3 style={{ color: "#818181", marginBottom: "4%" }}>
              Accommodation: Image and Video
            </h3>
            <div className="p-3 mb-3 bg-white">
              <p>
                <span style={{ color: "red" }}>*</span> mandatory field
              </p>
              <div>
                <h5>Note:</h5>
                <p>
                  <ul>
                    <li>
                      <b>'Logo of Business'</b> will represent your business in site.{" "}
                    </li>
                    <li>
                      <b>'Banner Image'</b> will be the main image of your property, it
                      will on top of all images.{" "}
                    </li>
                    <li>
                      {" "}
                      <b>'Accommodation Image'</b> will be additional images of your
                      property (<b>Maximum 4 images</b> are allowed).
                    </li>
                    <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
                    <li>
                      <b>'Video'</b> will be short video of your property and put URL
                      in the label text.
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="Main media">
              <div className="media_content">
                <h4>
                  Logo of Business <span style={{ color: "red" }}> * </span>
                </h4>
                {/* <p>The logo will reprent buisness in our site  </p>
                <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={logo_image_ref}
                  onChange={(e) => uploadFileHandler("logo_image", e)}
                />
                <Button
                  onClick={() => {
                    logo_image_ref.current.click();
                  }}
                  variant="contained"
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {error && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error.message}
                  </Box>
                )}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.logo_image != null
                    ? accomodationBanner.logo_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("logo_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <a href={imagePreview.logo_image} target="_blank">
                    <img
                      src={
                        //update?(accomodationBanner.logo_image):
                        imagePreview.logo_image
                          ? imagePreview.logo_image
                          : "/images/download2.png"
                      }
                      className="img-responsive m-2"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <div className="Main media">
              <div className="media_content">
                <h4>
                  Banner Image<span style={{ color: "red" }}> * </span>
                </h4>
                {/* <p>This will be the main image of your property, it will on top of all image</p> */}
                {/* <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={banner_image_ref}
                  onChange={(e) => uploadFileHandler("banner_image", e)}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    banner_image_ref.current.click();
                  }}
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {error2 && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error2.message}
                  </Box>
                )}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.banner_image != null
                    ? accomodationBanner.banner_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("banner_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <img
                    src={
                      //update?(accomodationBanner.banner_image):
                      imagePreview.banner_image
                        ? imagePreview.banner_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="Main image">
              <div className="row bg-white">
                <div className="col-sm-5">
                  <div className="media_content">
                    <h4 style={{ marginBottom: "4%" }}>
                      Accommodation Image(s)
                    </h4>
                    <div className="media_content_video">
                      <div className="button_media videoButton ">
                        <input
                          className="d-none"
                          type="file"
                          multiple
                          ref={accommodation_image_ref}
                          onChange={(e) =>
                            uploadMultipleImageHandler("accommodation_image", e)
                          }
                        />
                        <Button
                          variant="contained"
                          onClick={() => {
                            accommodation_image_ref.current.click();
                          }}
                          color="#4A8CFF"
                        >
                          Upload Image(s)
                        </Button>
                        <p className="my-1 text-success">
                          {" "}
                          {accomodationImage.accommodation_image != null
                            ? " "
                            : null}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="d-flex" style={{  }}>
                    {imagePreview["accommodation_image"] &&
                      accomodationImage["accommodation_image"].map(
                        (item, index) => (
                          <div className="multiple-image">
                            <div
                              onClick={() =>
                                deleteMultipleImage(
                                  "accommodation_image",
                                  index
                                )
                              }
                              className="multiple-image-del px-1"
                            >
                              x
                            </div>
                            {typeof item == "string" ? (
                              <a href={item} target="_blank">
                                <img
                                  src={item}
                                  className="img-responsive m-2"
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </a>
                            ) : (
                              <a href={item} target="_blank">
                                <img
                                  src={URL.createObjectURL(item)}
                                  className="img-responsive m-2"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </a>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
              {/* <p style={{ marginTop: "2%" }}>Upload your accommodation image</p> */}
              {/* <p style={{ color: '#a7a7a7' }}>(Maximum 4 images are allowed, image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
            </div>
            <br />
            <div className="Main image">
              <div className="media_content">
                <h4 style={{ marginBottom: "4%" }}>Video</h4>
                <div className="media_content_video">
                  {/* <div className="button_media videoButton ">
                    <input className="d-none" type="file" ref={accommodation_video_ref} onChange={(e) => uploadFileHandler('accommodation_video', e)} />
                    <Button variant="contained" onClick={() => { accommodation_video_ref.current.click() }} color="#4A8CFF" >
                      Upload file
                    </Button>
                <p className="my-1 text-success"> {accomodationBanner.accommodation_video!=null?'Image Selected':null} </p>

                  </div>
                  <h2>OR</h2> */}

                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                      id="outlined-basic"
                      name="accommodation_video_link"
                      value={
                        accomodationBanner.accommodation_video_link
                          ? accomodationBanner.accommodation_video_link
                          : ""
                      }
                      onChange={handleChange}
                      label="YouTube URL"
                      variant="outlined"
                    />
                    {/* <Button variant="contained" style={{ width: '50%', height: '30%' }}>
                      Update
                    </Button> */}
                  </form>
                </div>
                {/* <p style={{ marginTop: '2%' }}>Put a youtube link or url of short video about your property</p> */}
              </div>
            </div>
            <div className="text-center my-4 mb-2">
              {/* <Link to={"/accomodation/amenities/4/" + accomodation_id}> */}
              <button
                onClick={() => history.goBack()}
                className="btn btn-grey border border-primary mx-2"
              >
                BACK{" "}
              </button>
              {/* </Link> */}
              {update ? (
                <button
                  onClick={updateAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={uploadingMedia}
                >
                  {uploadingMedia ? "UPLOADING..." : "UPDATE"}
                </button>
              ) : (
                <button
                  onClick={submitAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={uploadingMedia}
                >
                  {uploadingMedia ? "UPLOADING..." : "CONTINUE"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
