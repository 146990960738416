// import React, { Component, useState, useEffect, useRef } from "react";
// import axios from "../../axios";

// import { Link, NavLink } from "react-router-dom";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import "../Accomodation/accomodation_facility.css";
// // import * as authActions from '../../store/actions/auth';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(7),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//       width: "25ch",
//     },
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "80%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// const outerTheme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: "#009688",
//     },
//   },
// });

// function Tour_media() {
//   const classes = useStyles();
//   const transport_permit_ref = useRef(null);
//   const banner_image_ref = useRef(null);
//   const vehicle_image_ref = useRef(null);
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const { id } = useParams();

//   const [Plug, setPlug] = useState("");

//   const user = useSelector((state) => state.auth.user);
//   useEffect(() => {
//     getFormDetails();
//   }, [user]);
//   const [updateForm, setUpdateForm] = useState(false);

//   const [glmedia, setglmedia] = React.useState({
//     logistics: id,
//     banner_image: null,
//     transport_permit: null,
//     vehicle_image: [],
//   });
//   const [glmedia_list, set_glmedia_list] = React.useState(null);
//   const [imagePreview, setImagePreview] = React.useState({
//     banner_image_change: false,
//     banner_image: null,
//     transport_permit_change: false,
//     transport_permit: null,
//     vehicle_image_change: false,
//     vehicle_image: [],
//   });

//   const [mulImagePreview, setMulImagePreview] = React.useState({
//     vehicle_image_change: false,
//     vehicle_image: [],
//   });

//   const [vehicleImageList, setVehicleImageList] = React.useState(null);

//   const handlePlug = (event) => {
//     setPlug(event.target.value);
//   };
//   const [extension, setextension] = useState(null);

//   function uploadFileHandler(key, e) {
//     setglmedia({ ...glmedia, [key]: e.target.files[0] });
//     if (e.target.files[0] != null) {
//       setextension(e.target.files[0].name.split(".").pop());
//     }
//     let obj = {};
//     obj[key + "_change"] = true;
//     obj[key] = URL.createObjectURL(e.target.files[0]);
//     setImagePreview({ ...imagePreview, ...obj });
//   }

//   function delUploadFileHandler(key) {
//     setglmedia({ ...glmedia, [key]: null });
//     let obj = {};
//     obj[key + "_change"] = false;
//     obj[key] = null;
//     setImagePreview({ ...imagePreview, ...obj });
//   }

//   function uploadMultipleImageHandler(key, e) {
//     let i = 0;
//     let imagePreviewArr = [];
//     let imageFileArr = [];
//     for (i = 0; i < e.target.files.length; i++) {
//       imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
//       imageFileArr.push(e.target.files[i]);
//     }
//     console.log("key", key, "e", e, "IMAGEPreview", mulImagePreview);
//     if (imagePreviewArr.length > 4) {
//       toast("Max 4 images allowed");
//     } else {
//       setMulImagePreview({
//         ...mulImagePreview,
//         key: [...mulImagePreview[key], ...imagePreviewArr],
//         [key + "_change"]: true,
//       });
//       setglmedia({ ...glmedia, key: [...glmedia[key], ...imageFileArr] });
//     }
//   }

//   function deleteMultipleImage(key, indexitem) {
//     const imagesFilter = glmedia[key].filter(
//       (item, index) => index !== indexitem
//     );
//     setglmedia({ ...glmedia, [key]: imagesFilter });
//     let obj = {};
//     obj[key + "_change"] = true;
//     const imagesPreviewFilter = mulImagePreview[key].filter(
//       (item, index) => index !== indexitem
//     );
//     obj[key] = imagesPreviewFilter;
//     setMulImagePreview({ ...mulImagePreview, ...obj });
//   }

//   function handleChange(e) {
//     setglmedia({ ...glmedia, [e.target.name]: e.target.value });
//   }

//   const [loading, setloading] = useState(false);

//   function getFormDetails() {
//     axios
//       .get(`partners/ground-logistic-image-list/${id}`, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         // if (res.data) {
//         //   setupdate(true);
//         // }
//         console.log(
//           "response from getting  the tour operator media get list",
//           res.data
//         );
//         if (res.data.length > 0) {
//           setUpdateForm(true);
//           // let arr = [];
//           // let i = 0;
//           // for (i = 0; i < res.data.length; i++) {
//           //   arr.push(res.data[i].vehicle_image);
//           // }
//           // setglmedia({
//           //   ...glmedia,
//           //   vehicle_image: arr,
//           // });
//           // setVehicleImageList(res.data);
//           // setImagePreview({ ...imagePreview, vehicle_image: arr });

//           let arr_image = [];
//           let i = 0;
//           for (i = 0; i < res.data.length; i++) {
//             arr_image.push(res.data[i].vehicle_image);
//           }
//           setglmedia({
//             ...glmedia,
//             vehicle_image: arr_image,
//           });
//           set_glmedia_list(res.data);
//           setMulImagePreview({ ...mulImagePreview, vehicle_image: arr_image });
//         }
//         // setglmedia({ ...res.data[0] });
//         // setImagePreview({ ...res.data[0] });
//       })
//       .catch((err) => {
//         console.log("ERROR  from getting tour package basics", err);
//       });
//     axios
//       .get(`partners/ground-logistic-banner-logo-list/${id}`, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         if (res.data.length > 0) {
//           setupdate(true);
//           setpostId(res.data[0].id);
//         }
//         console.log(
//           "response from getting  the tour operator media get",
//           res.data
//         );
//         setglmedia({ ...res.data[0] });
//         setImagePreview({ ...res.data[0] });
//       })
//       .catch((err) => {
//         console.log("ERROR  from getting tour package basics", err);
//       });
//   }

//   // console.log("image preview ", imagePreview);

//   const [postId, setpostId] = useState(null);
//   const [update, setupdate] = useState(false);
//   function submitglmedia() {
//     let formData = new FormData();
//     let formData2 = new FormData();
//     formData.append("logistics", id);
//     formData2.append("logistics", id);
//     //if (imagePreview.vehicle_image_change) {
//     //formData.append('vehicle_image', glmedia.vehicle_image);
//     //}

//     if (imagePreview.transport_permit_change) {
//       formData.append("transport_permit", glmedia.transport_permit);
//     }
//     if (imagePreview.banner_image_change) {
//       formData.append("banner_image", glmedia.banner_image);
//     }
//     //formData.append('tour_operator_video_link',glmedia.logistics_video_link);

//     let i = 0;
//     for (i = 0; i < glmedia.vehicle_image.length; i++) {
//       if (imagePreview.vehicle_image_change) {
//         formData2.append(`logistics_image_${i}`, glmedia.vehicle_image[i]);
//       }
//     }
//     setloading(true);
//     axios
//       .post("partners/ground-logistic-banner-logo-create", formData, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         setloading(false);
//         console.log("response from submitting the GL  media create", res.data);
//         history.push("/ground-logistics-registration/policy/4/" + id);
//       })
//       .catch((err) => {
//         console.log("error from creating GL media", err);
//         setloading(false);
//         history.push("/ground-logistics-registration/policy/4/" + id);
//       });
//     //setloading(true);

//     axios
//       .post("partners/ground-logistic-image-create", formData2, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         setloading(false);
//         console.log(
//           "response from submitting the tours accomodation banner logo create",
//           res.data
//         );
//         history.push("/ground-logistics-registration/policy/4/" + id);
//       })
//       .catch((err) => {
//         console.log("error from creating tours accomodation media", err);
//       });
//   }

//   function updateglmedia() {
//     history.push("/ground-logistics-registration/policy/4/" + id);

//     let formData = new FormData();
//     let formData2 = new FormData();
//     formData.append("logistics", id);
//     if (imagePreview.vehicle_image_change) {
//       formData2.append("logistics", id);
//     }
//     //formData.append('accommodation', glmedia.accommodation);
//     if (imagePreview.transport_permit_change) {
//       formData.append("transport_permit", glmedia.transport_permit);
//     }
//     if (imagePreview.banner_image_change) {
//       formData.append("banner_image", glmedia.banner_image);
//     }

//     if (imagePreview.vehicle_image_change) {
//       let i = 0;
//       for (i = 0; i < 4; i++) {
//         if (imagePreview.vehicle_image_change) {
//           if (glmedia.vehicle_image.length > i) {
//             formData2.append(`image_${i}`, glmedia.vehicle_image[i]);
//           }
//           if (vehicleImageList.length > i) {
//             formData2.append(`image_${i}_id`, vehicleImageList[i].id);
//           }
//         }
//       }
//     }
//     setloading(true);
//     axios
//       .put(`partners/ground-logistic-banner-logo-update/${postId}`, formData, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         setloading(false);
//         // history.push("/ground-logistics-registration/policy/4/" + id);
//         console.log(
//           "response from submitting the tours  media update",
//           res.data
//         );
//       })
//       .catch((err) => {
//         console.log("error from creating accomodation media", err);
//       });

//     axios
//       .put(`partners/ground-logistics-image-back-update`, formData2, {
//         headers: {
//           Authorization: "Token " + user.token,
//         },
//       })
//       .then((res) => {
//         setloading(false);
//         history.push("/ground-logistics-registration/policy/4/" + id);
//         console.log(
//           "response from submitting the tours  media update",
//           res.data
//         );
//       })
//       .catch((err) => {
//         console.log("error from creating accomodation media", err);
//       });
//     setloading(true);
//   }

//   return (
//     <div
//       id="Tour_media"
//       style={{
//         padding: "10%",
//         paddingTop: "4%",
//         width: "100%",
//       }}
//     >
//       <div className="d-flex justify-content-center bg-light">
//         <div className="p-2 justify-content-center  align-self-center w-100">
//           <div className="container text-left">
//             <h4 style={{ color: "#818181", marginBottom: "4%" }}>
//               Ground Logistic Partner Registration: Image and Document
//             </h4>
//             <div className="p-3 mb-3 bg-white">
//               <p>
//                 <span style={{ color: "red" }}>*</span> mandatory field
//               </p>
//               <div>
//                 <h5>Note:</h5>
//                 <p>
//                   <ul>
//                     <li>
//                       'Logo of Business' will represent your business in site.{" "}
//                     </li>
//                     <li>
//                       'Banner Image' will be the main image of your groung
//                       logistics business, it will on top of all image.{" "}
//                     </li>
//                     <li>
//                       {" "}
//                       'Additional Image of Vehicles' will be additional images
//                       of your vechicles (Maximum 4 images are allowed).
//                     </li>
//                     <li>
//                       Image Guideline: Image resolution should be 900*600 with
//                       3:2 aspect ratio and max 2MB size.{" "}
//                     </li>
//                     <li>
//                       Transport Permit or Registration: Allowed format either
//                       pdf (2 MB Max) or image as per image guideline.
//                     </li>
//                   </ul>
//                 </p>
//               </div>
//             </div>
//             <div className="Main media">
//               <div className="media_content">
//                 <h5>
//                   Upload Transport Permit or Registration Certificate{" "}
//                   <span style={{ color: "red" }}>*</span>
//                 </h5>
//                 {/* <p>Please upload document in PDF Format</p>
//                 <p style={{ color: "#a7a7a7" }}>
//                   (image resolution should be 900*600 with 3:2 aspect ratio and
//                   max 2MB size)
//                 </p> */}
//               </div>
//               <div className="button_media">
//                 <input
//                   className="d-none"
//                   type="file"
//                   ref={transport_permit_ref}
//                   onChange={(e) => uploadFileHandler("transport_permit", e)}
//                 />
//                 <Button
//                   onClick={() => {
//                     transport_permit_ref.current.click();
//                   }}
//                   variant="contained"
//                   color="#4A8CFF"
//                 >
//                   Upload File
//                 </Button>
//                 {/*<p className="my-1 text-success"> {glmedia.transport_permit != null ? 'File Selected' : null} </p>*/}
//                 <div className="multiple-image">
//                   <div
//                     onClick={() => delUploadFileHandler("transport_permit")}
//                     className="multiple-image-del px-1"
//                   >
//                     x
//                   </div>
//                   {/*<img src={imagePreview.transport_permit} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
//                   {(() => {
//                     //var fileName = imagePreview.document;
//                     //var fileExtension = fileName.split('.').pop();
//                     if (
//                       extension == "pdf" ||
//                       (glmedia.transport_permit != null &&
//                         imagePreview.transport_permit != null &&
//                         imagePreview.transport_permit.split(".").pop() ===
//                           "pdf" &&
//                         glmedia.transport_permit.split(".").pop()) == "pdf"
//                     ) {
//                       return (
//                         <>
//                           <p className="my-1 text-success">
//                             {" "}
//                             {glmedia.transport_permit != null
//                               ? `File-->${glmedia.transport_permit.name} Selected`
//                               : null}{" "}
//                           </p>
//                           <a
//                             href={imagePreview.transport_permit}
//                             target="_blank"
//                           >
//                             {imagePreview.transport_permit != null ? (
//                               <img
//                                 src="/images/download.png"
//                                 className="img-responsive m-2"
//                                 style={{ width: "50px", height: "30px" }}
//                               />
//                             ) : (
//                               <img
//                                 src="/images/download2.png"
//                                 alt="No file"
//                                 style={{
//                                   width: "50px",
//                                   height: "40px",
//                                   paddingRight: "10px",
//                                 }}
//                               ></img>
//                             )}
//                           </a>
//                         </>
//                       );
//                     } else {
//                       return (
//                         <>
//                           <p className="my-1 text-success">
//                             {" "}
//                             {glmedia.transport_permit != null
//                               ? `Image --> ${glmedia.transport_permit.name}  Selected`
//                               : null}{" "}
//                           </p>
//                           <a
//                             href={imagePreview.transport_permit}
//                             target="_blank"
//                           >
//                             <img
//                               src={
//                                 imagePreview.transport_permit
//                                   ? imagePreview.transport_permit
//                                   : "/images/download2.png"
//                               }
//                               className="img-responsive m-2"
//                               style={{ width: "60px", height: "40px" }}
//                             />
//                           </a>
//                         </>
//                       );
//                     }
//                   })()}
//                 </div>
//               </div>
//             </div>

//             <br />
//             <div className="Main media">
//               <div className="media_content">
//                 <h5>
//                   Banner Image<span style={{ color: "red" }}>*</span>
//                 </h5>
//                 {/* <p>
//                   This will be the main image as your Logistic. It will be on
//                   top of all image(image must not exceed 1MB).{" "}
//                 </p>
//                 <p style={{ color: "#a7a7a7" }}>
//                   (image resolution should be 900*600 with 3:2 aspect ratio and
//                   max 2MB size)
//                 </p> */}
//               </div>
//               <div className="button_media">
//                 <input
//                   className="d-none"
//                   type="file"
//                   ref={banner_image_ref}
//                   onChange={(e) => uploadFileHandler("banner_image", e)}
//                 />
//                 <Button
//                   variant="contained"
//                   onClick={() => {
//                     banner_image_ref.current.click();
//                   }}
//                   color="#4A8CFF"
//                 >
//                   Upload File
//                 </Button>
//                 <p className="my-1 text-success">
//                   {" "}
//                   {glmedia.banner_image != null
//                     ? glmedia.banner_image.name + " Selected"
//                     : null}{" "}
//                 </p>
//                 <div className="multiple-image">
//                   <div
//                     onClick={() => delUploadFileHandler("banner_image")}
//                     className="multiple-image-del px-1"
//                   >
//                     x
//                   </div>
//                   <img
//                     src={
//                       imagePreview.banner_image
//                         ? imagePreview.banner_image
//                         : "/images/download2.png"
//                     }
//                     className="img-responsive m-2"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                 </div>
//               </div>
//             </div>

//             <br />

//             <br />

//             <div className="Main image">
//               <div className="row bg-white">
//                 <div className="col-sm-5">
//                   <div className="media_content">
//                     <h5 style={{ marginBottom: "4%" }}>
//                       Additional Image of Vehicles
//                     </h5>
//                     <div className="media_content_video">
//                       <div className="button_media videoButton ">
//                         <input
//                           className="d-none"
//                           type="file"
//                           multiple
//                           ref={vehicle_image_ref}
//                           onChange={(e) =>
//                             uploadMultipleImageHandler("vehicle_image", e)
//                           }
//                         />
//                         <Button
//                           variant="contained"
//                           onClick={() => {
//                             vehicle_image_ref.current.click();
//                           }}
//                           color="#4A8CFF"
//                         >
//                           Upload Image(s)
//                         </Button>
//                         <p className="my-1 text-success">
//                           {" "}
//                           {glmedia.vehicle_image != null
//                             ? "Image Selected"
//                             : null}{" "}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-sm-7">
//                   <div className="d-flex">
//                     {mulImagePreview["vehicle_image"] &&
//                       mulImagePreview["vehicle_image"].map(
//                         (item, index) => (
//                           <div className="multiple-image">
//                             <div
//                               onClick={() =>
//                                 deleteMultipleImage("vehicle_image", index)
//                               }
//                               className="multiple-image-del px-1"
//                             >
//                               x
//                             </div>
//                             <img
//                               src={item}
//                               className="img-responsive m-2"
//                               style={{ width: "100px", height: "100px" }}
//                             />
//                           </div>
//                         )
//                         // <div className="multiple-image">
//                         //   <div onClick={() =>
//                         //   //deleteMultipleImage('vehicle_image',index
//                         //   delUploadFileHandler('vehicle_image'
//                         //   )} className="multiple-image-del px-1">x</div>
//                         //   <img src={
//                         //     //item
//                         //     imagePreview.vehicle_image} className="img-responsive m-2" style={{ width: '100px', height: '100px' }} />
//                         // </div>
//                       )}
//                   </div>
//                 </div>
//               </div>
//               {/* <p style={{ marginTop: "2%" }}>Upload your tour image(s)</p> */}
//               {/* <p style={{ color: "#a7a7a7" }}>
//                 (Maximum 4 images are allowed, image resolution should be
//                 900*600 with 3:2 aspect ratio and max 2MB size)
//               </p> */}
//             </div>

//             <br />

//             <div className="text-center my-4 mb-2">
//               <Link to={"/ground-logistics-registration/Vehicle/2/" + id}>
//                 <button className="btn btn-grey border border-primary mx-2">
//                   Back{" "}
//                 </button>
//               </Link>
//               {updateForm ? (
//                 <button
//                   onClick={updateglmedia}
//                   className="btn btn-primary mx-2"
//                 >
//                   UPDATE
//                 </button>
//               ) : (
//                 <button
//                   onClick={submitglmedia}
//                   className="btn btn-primary mx-2"
//                   disabled={loading}
//                 >
//                   CONTINUE
//                 </button>
//               )}
//               {loading && (
//                 <div
//                   style={{
//                     textAlign: "center",
//                     color: "red",
//                     width: "100%",
//                     background: "lightpink",
//                     padding: "1rem",
//                     marginTop: "1rem",
//                   }}
//                 >
//                   Loading...
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Tour_media;



import React, { Component, useState, useEffect, useRef } from "react";
import axios from "../../axios";

import { Link, NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../Accomodation/accomodation_facility.css";
// import * as authActions from '../../store/actions/auth';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@material-ui/core";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});

export default function Tour_media() {
  const classes = useStyles();
  const logo_image_ref = useRef(null);
  const banner_image_ref = useRef(null);
  const accommodation_video_ref = useRef(null);
  const accommodation_image_ref = useRef(null);
  const property_image_ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [update, setUpdate] = React.useState(false);
  const [uploadingMedia, setuploadingMedia] = useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    getAccomodationMedia();
  }, [user]);

  const [accomodationImage, setAccomodationImage] = React.useState({
    accommodation: id,
    accommodation_image: [],
    property_image: null,
  });

  const [accomodationBanner, setAccomodationBanner] = React.useState({
    accommodation: id,
    banner_image: null,
    accommodation_video: '',
    accommodation_video_link: "",
    logo_image: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    accommodation_image_change: false,
    accommodation_image: [],
    property_image_change: false,
    property_image: null,
    banner_image_change: false,
    banner_image: null,
    accommodation_video_change: false,
    accommodation_video: null,
    logo_image_change: false,
    logo_image: null,
  });


  const [accommodation_image_list, set_accommodation_image_list] = React.useState(null);
  async function getAccomodationMedia() {
    await axios
      .get(`partners/ground-logistic-banner-logo-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          let resObj = res.data[0];
          let obj = {};
          obj.id = resObj.id;
          obj.banner_image = resObj.banner_image;
          obj.logo_image = resObj.transport_permit;
          obj.accommodation_video = null;
          obj.accommodation_video_link = null;
          obj.accommodation = resObj.logistics;
          setAccomodationBanner(obj);
          //setImagePreview({ ...imagePreview, ...res.data[0] });
          if(res.data!=null && res.data.length){
            imagePreview['logo_image']=res.data[0].transport_permit;
            imagePreview['banner_image']=res.data[0].banner_image;
          }
          
          //setImagePreview({...imagePreview,['logo_image']:res.data[0].logo_image});
        } else {
          setUpdate(false);
        }
        console.log("response fromaccommodation-banner-logo-list", res.data,'IMAGE PREVIEW',imagePreview);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });

    await axios
      .get(`partners/ground-logistic-image-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          let arr_image = [];
          let i = 0;
          for (i = 0; i < res.data.length; i++) {
            arr_image.push(res.data[i].vehicle_image);
          }
          setAccomodationImage({
            ...accomodationImage,
            accommodation_image: arr_image,
          });
          set_accommodation_image_list(res.data);
          setImagePreview({ ...imagePreview, accommodation_image: arr_image });
        } else {
          setUpdate(false);
        }
        console.log("response accommodation-image-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });
  }
  console.log('image preview ',imagePreview);

  function uploadFileHandler(key, e) {
    if(key == 'logo_image'){
      setError(false);
    }
    if(key == 'banner_image'){
      setError2(false);
    }
    setAccomodationBanner({ ...accomodationBanner, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }
  function delUploadFileHandler(key) {
    setAccomodationBanner({ ...accomodationBanner, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }


  function uploadMultipleImageHandler(key, e) {
    let i = 0;
    let imagePreviewArr = [];
    let imageFileArr = [];
    for(i=0;i<e.target.files.length;i++){
      imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      imageFileArr.push(e.target.files[i]);
    }
    if(imagePreviewArr.length+imagePreview['accommodation_image'].length>4){
      toast("Max 4 images allowed")
    }else{
      setImagePreview({...imagePreview,'accommodation_image':[...imagePreview['accommodation_image'], ...imagePreviewArr],'accommodation_image_change':true});
      setAccomodationImage({...accomodationImage,'accommodation_image':[...accomodationImage['accommodation_image'], ...imageFileArr]});
    }
    e.target.value=null;
  }

  function deleteMultipleImage(key, indexitem) {
    const imagesFilter = accomodationImage[key].filter((item, index) => index !== indexitem);
    console.log('images fileter accommodation images',imagesFilter);
    setAccomodationImage({...accomodationImage, [key]:imagesFilter});
    let obj = {};
    obj[key + "_change"] = true;
    const imagesPreviewFilter = imagePreview[key].filter((item, index) => index !== indexitem);
    obj[key] = imagesPreviewFilter;
    setImagePreview({ ...imagePreview,...obj});
  }

  function handleChange(e) {
    setAccomodationBanner({
      ...accomodationBanner,
      [e.target.name]: e.target.value,
    });
  }
  const [loading, setloading] = useState(false);
  let x=0;
  async function submitAccomodationMedia() {
    let formData = new FormData();
    formData.append("logistics", accomodationImage.accommodation);
    let formData2 = new FormData();
    formData2.append("logistics", accomodationBanner.accommodation);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
      setError2(false);
    } else {
      setError2({ message: "Banner Image is Mandatory!" });
    }
    if (imagePreview.accommodation_video_change) {
      formData2.append(
        "accommodation_video",
        accomodationBanner.accommodation_video
      );
    }
    formData2.append(
      "accommodation_video_link",
      accomodationBanner.accommodation_video_link
    );
    if (imagePreview.logo_image_change) {
      formData2.append("transport_permit", accomodationBanner.logo_image);
      setError(false);
    } else {
      setError({ message: "Logo Image is Mandatory!" });
    }

    let i = 0;
    for (
      i = 0;
      i <
      (accomodationImage.accommodation_image != null &&
        accomodationImage.accommodation_image.length);
      i++
    ) {
      if (imagePreview.accommodation_image_change) {
        formData.append(
          `logistics_image_${i}`,
          accomodationImage.accommodation_image[i]
        );
      }
    }
    if(accomodationBanner.banner_image && accomodationBanner.logo_image){

    setloading(true);
    await axios
      .post("partners/ground-logistic-image-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation media create",
          res.data
        );
        if(x==1){
          setloading(false);
        }
        x=1
        
      })
      .catch((err) => {
        setloading(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });

    // if (!error) {
      await axios
        .post("partners/ground-logistic-banner-logo-create", formData2, {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        })
        .then((res) => {
          console.log(
            "response from submitting the accomodation banner logo create",
            res.data
          );
          if(x==1){
            setloading(false);
          }
          x=1;
          history.push("/home/ground-logistic-package");
        })
        .catch((err) => {
          setloading(false);
          let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
          console.log("error from creating accomodation media", err);
        });
    // }
      }
      else{
        toast('Banner Image and transport Permit are Mandatory!');
      }
  }

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
let z=0;
async  function updateAccomodationMedia() {
    let formData = new FormData();
    formData.append("logistics", accomodationImage.accommodation);
    if (imagePreview.accommodation_image_change) {
      let i = 0;
      for (i = 0; i < 4; i++) {
        if (accomodationImage.accommodation_image.length > i) {
          formData.append(`image_${i}`, accomodationImage.accommodation_image[i]);
        }
        if (accommodation_image_list.length > i) {
          formData.append(`image_${i}_id`, accommodation_image_list[i].id);
        }
      }
    }
    let formData2 = new FormData();
    formData2.append("logistics", accomodationBanner.accommodation);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
    }

    // if (imagePreview.accommodation_video_change) {
    //   formData2.append(
    //     "accommodation_video",
    //     accomodationBanner.accommodation_video
    //   );
    // }
    // formData2.append(
    //   "accommodation_video_link",
    //   accomodationBanner.accommodation_video_link
    // );
    if (imagePreview.logo_image_change) {
      formData2.append("transport_permit", accomodationBanner.logo_image);
    }
    setloading(true);
    await axios
      .put(
        `partners/ground-logistics-image-back-update`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log("response from submitting the accomodation media create",res.data);
        if(z==1){
          setloading(false)
        }
        z=1;
        
        // history.push('/accomodation/media/6/'+accomodation_id);
      })
      .catch((err) => {
        setloading(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
      
    await axios
      .put(
        `partners/ground-logistic-banner-logo-update/${accomodationBanner.id}`,
        formData2,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the accomodation banner logo create",
          res.data
        );
        
        if(z==1){
          setloading(false);
          history.push("/home/ground-logistic-package");
        }
        z=1;
        
      })
      .catch((err) => {
        setloading(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            <br></br>
            <h3 style={{ color: "#818181", marginBottom: "4%" }}>
            Ground Logistic Partner Registration: Images and Documents
            </h3>
            <div className="p-3 mb-3 bg-white">
              <p>
                <span style={{ color: "red" }}>*</span> mandatory field
              </p>
              <div>
                <h5>Note:</h5>
                <p>
                  <ul>
                  <li><u>It is recommended to provide all informations.</u> </li>
                    <li>
                      <b>'Transport Permit / Registration Certificate / Fitnees Certificate'</b>, upload whichwever applicable.{" "}
                    </li>
                    <li>
                      <b>'Banner Image'</b> will be the main image of your business, it
                      will on top of all image.{" "}
                    </li>
                    <li>
                      {" "}
                      <b>'Additional Image'</b> will be additional images of your
                      business (Maximum 4 images are allowed).
                    </li>
                    <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with <br></br>PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
                    {/* <li>
                      'Video' will be short video of your property and put URL
                      in the label text.
                    </li> */}
                  </ul>
                </p>
              </div>
            </div>

            <div className="Main media">
              <div className="media_content">
                <h5>
                Transport Permit / Registration Certificate / Fitness Certificate <span style={{ color: "red" }}> * </span>
                </h5>
                {/* <p>The logo will reprent buisness in our site  </p>
                <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={logo_image_ref}
                  onChange={(e) => uploadFileHandler("logo_image", e)}
                />
                <Button
                  onClick={() => {
                    logo_image_ref.current.click();
                  }}
                  variant="contained"
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {/* {error && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error.message}
                  </Box>
                )} */}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.logo_image != null
                    ? accomodationBanner.logo_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("logo_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <a href={imagePreview.logo_image} target="_blank">
                  <img
                    src={//update?(accomodationBanner.logo_image):
                      imagePreview.logo_image
                        ? imagePreview.logo_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <div className="Main media">
              <div className="media_content">
                <h4>
                  Banner Image<span style={{ color: "red" }}> * </span>
                </h4>
                {/* <p>This will be the main image of your property, it will on top of all image</p> */}
                {/* <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={banner_image_ref}
                  onChange={(e) => uploadFileHandler("banner_image", e)}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    banner_image_ref.current.click();
                  }}
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {/* {error2 && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error2.message}
                  </Box>
                )} */}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.banner_image != null
                    ? accomodationBanner.banner_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("banner_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <a href={imagePreview.banner_image} target="_blank">
                  <img
                    src={//update?(accomodationBanner.banner_image):
                      imagePreview.banner_image
                        ? imagePreview.banner_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "30px", height: "30px" }}
                  /></a>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="Main image">
              <div className="row bg-white">
                <div className="col-sm-5">
                  <div className="media_content">
                    <h4 style={{ marginBottom: "4%" }}>
                    Additional Image of Vehicles
                    </h4>
                    <div className="media_content_video">
                      <div className="button_media videoButton ">
                        <input
                          className="d-none"
                          type="file"
                          multiple
                          ref={accommodation_image_ref}
                          onChange={(e) =>
                            uploadMultipleImageHandler("accommodation_image", e)
                          }
                        />
                        <Button
                          variant="contained"
                          onClick={() => {
                            accommodation_image_ref.current.click();
                          }}
                          color="#4A8CFF"
                        >
                          Upload Image(s)
                        </Button>
                        <p className="my-1 text-success">
                          {" "}
                          {accomodationImage.accommodation_image != null
                            ? " "
                            : null}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="d-flex py-3" style={{}}>
                    {imagePreview["accommodation_image"] &&
                      accomodationImage["accommodation_image"].map((item, index) => (
                        <div className="multiple-image">
                          <div
                            onClick={() =>
                              deleteMultipleImage("accommodation_image", index)
                            }
                            className="multiple-image-del px-1"
                          >
                            x
                          </div>
                          {(typeof item)=='string'?
                          <a href={item} target="_blank">
                          <img
                            src={item}
                            className="img-responsive m-2"
                            style={{ width: "100px", height: "100px" }}
                          /></a>
                          :
                          <img
                            src={URL.createObjectURL(item)}
                            className="img-responsive m-2 imgresp"
                            style={{ width: "100px", height: "100px" }}
                          />
                          }
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {/* <p style={{ marginTop: "2%" }}>Upload your accommodation image</p> */}
              {/* <p style={{ color: '#a7a7a7' }}>(Maximum 4 images are allowed, image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
            </div>
            <div className="text-center my-4 mb-2">
            {/*<Link to={"/ground-logistics-registration/Vehicle/2/" + id}>*/}
                <button onClick={() => history.goBack()} className="btn btn-grey border border-primary mx-2">
                  BACK{" "}
                </button>
              {/*</Link>*/}
              {update ? (
                <button
                  onClick={updateAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={loading}
                >
                  {loading ? "UPLOADING..." : "UPDATE"}
                </button>
              ) : (
                <button
                  onClick={submitAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={loading}
                >
                  {loading ? "UPLOADING..." : "CONTINUE"}
                </button>
              )}
                {/* {loading && (
                 <div
                   style={{
                     textAlign: "center",
                     color: "red",
                     width: "100%",
                     background: "lightpink",
                     padding: "1rem",
                     marginTop: "1rem",
                   }}
                 >
                   Loading...
                 </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
