import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link, NavLink } from 'react-router-dom';
import { useParams } from "react-router-dom";
import './acc_pricing.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccomodationPricing() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [accommodationType, setAccommodationType] = useState(null);
  const [currencyType, setCurrencyType] = useState(null);
  const [updateButton, setUpdateButton] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(null);
  const { accomodation_id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const accommodationAction = useSelector((state) => state.accommodation.accommodation);
  useEffect(() => {
    
    window.scrollTo(0,0);

    getAccomodationPricing()
    getAccommodationTypeList()
    getCurrencyType()
  }, [user])
  const [addAccomodationType, setAddAccomodationType] = useState(false);
  const [categoryObject, setCategoryObject] = useState({
    accommodation: accomodation_id,
    category_name: null,
    sleeps: null,
    view: null,
    type_of_accommodation: null,
    budget: null,
    smoking_policy: null,
    guest_capacity: null,
    no_of_room_this_type: null,
    price_per_accommodation_on_season: null,
    price_per_accommodation_off_season: null,
    single_occupancy_supplement: null,
    price_per_person: null,
    currency: null,
    approved_status: false
  });
  const [categoryArray, setCategoryArray] = useState([]);


  function getAccomodationPricing() {
    axios.get(`partners/accommodation-detail-list/${accomodation_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setCategoryArray(res.data);
      console.log('response from get pricing', res.data);
    }).catch(err => {
      console.log('error getting accommodation type list', err);
    })
  }

  function getAccommodationTypeList() {
    axios.get(`partners/accommodation-type-list/${accomodation_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      // setAccommodationType(res.data[0]);
      // let obj = res.data[0];
      // let filterObj = [];
      // Object.entries(obj).map((element, key) => {
      //   if (element[1] == true) {
      //     // filterObj[element[0]] = 'new;'
      //     filterObj.push(element[0]);
      //   }
      // });
      setAccommodationType(res.data.accommodation_distribution);
      // setState({ ...state, ...filterObj });
      console.log('error on response from accommodation type list', res.data.accommodation_distribution);
    }).catch(err => {
      console.log('error getting accommodation type list', err);
    })
  }
  function getCurrencyType() {
    axios.get(`partners/accommodation-currency/${accomodation_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setCurrencyType(res.data);
      console.log('response from getting accomdoation currency type', res.data);
    }).catch(err => {
      toast('error in getting currency type')
    })
  }


  function addAccomodationTypeFunc() {
    setAddAccomodationType(true);

  }


  const handleChange = (event) => {
    setCategoryObject({ ...categoryObject, [event.target.name]: event.target.value });
  };


  const addCategoryObject = () => {
    // console.log('add object ot arr')
    // let arr = [...categoryArray];
    // arr.push(categoryObject);
    axios.post('partners/accommodation-detail-create', categoryObject, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('add object ot arr')
      let arr = [...categoryArray];
      // arr.push(categoryObject);
      console.log('response from storing accomdoation detail create', res.data);
      console.log('this is the arr', arr)
      arr.push(res.data);
      setCategoryArray(arr);
      setCategoryObject({
        accommodation: accomodation_id,
        category_name: null,
        sleeps: null,
        view: null,
        type_of_accommodation: null,
        budget: null,
        smoking_policy: null,
        guest_capacity: null,
        no_of_room_this_type: null,
        price_per_accommodation_on_season: null,
        price_per_accommodation_off_season: null,
        single_occupancy_supplement: null,
        price_per_person: null,
        currency: null,
        approved_status: false
      });

      setAddAccomodationType(false);
    }).catch((err) => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('err in createing accomodtation detials', err)
    })
    // console.log('this is the arr', arr)
    // setCategoryArray(arr);
    // setCategoryObject({
    //   accommodation: accomodation_id,
    //   category_name: null,
    //   sleeps: null,
    //   view: null,
    //   type_of_accommodation: null,
    //   budget: null,
    //   smoking_policy: null,
    //   guest_capacity: null,
    //   no_of_room_this_type: null,
    //   price_per_accommodation_on_season: null,
    //   price_per_accommodation_off_season: null,
    //   single_occupancy_supplement: null,
    //   price_per_person: null,
    //   currency: null,
    //   approved_status: false
    // });

    // setAddAccomodationType(false);
  }

  function addAnotherPriceCategory() {
    setCategoryObject({
      accommodation: accomodation_id,
      category_name: null,
      sleeps: null,
      view: null,
      type_of_accommodation: null,
      budget: null,
      smoking_policy: null,
      guest_capacity: null,
      no_of_room_this_type: null,
      price_per_accommodation_on_season: null,
      price_per_accommodation_off_season: null,
      single_occupancy_supplement_on_season: null,
      single_occupancy_supplement_off_season: null,
      price_per_person: null,
      currency: null,
      approved_status: false
    });
    setAddAccomodationType(true);
  }

  function editCategoryItem(item) {
    console.log('item',item)
    axios.get(`partners/accommodation-detail/${item}`,{
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res=>{
      console.log('get accommodation details',res.data);
      setCategoryObject(res.data);
      setAddAccomodationType(true);
      setUpdateButton(true);
      console.log('category object',categoryObject);
    }).catch(err=>{
      console.log('error in getting accommodation detail',err);
    })
    // setCategoryObject(null);
    // setCategoryArray['item']=item.id;
    // if(categoryObject.id){
     
    // }
  }

  // useEffect(()=>{

  // },[addAccomodationType])

  function updateCategoryObject() {
    axios.put(`partners/accommodation-detail-back-update/${categoryObject.id}`, categoryObject, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      getAccomodationPricing()
      setUpdateButton(false);
      setAddAccomodationType(false);
      history.push('/accomodation/pricing/2/' + accomodation_id);
    }).catch((err) => {
      console.log('err in createing accomodtation detials', err)
      // alert(err.response.data)
      let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
          //   alert(
            
          //   err.response.data.errors
          // );
    })
  }

  function submitPricing() {
    history.push('/accomodation/facility/3/' + accomodation_id);
  }

  function stringCap(str) {
    return str.replace(/[^A-Z0-9]/ig, " ");
  }

  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  }

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      {(addAccomodationType == false && categoryArray.length <= 0) &&
        <div className="d-flex justify-content-center   h-100 w-100 bg-light" >
          <div className="p-2  align-self-center h-100 w-100" >
            <div className="d-flex flex-wrap h-100 w-100 flex-column justify-content-around">
              <div className="flex-1">
                <button onClick={addAccomodationTypeFunc} className="btn btn-primary px-6 py-2">ADD ACCOMMODATION TYPE</button>
                <Link to='/accomodation/basic/1'>
                  <button className="btn btn-grey border border-primary mx-2">BACK </button>
                </Link>

              </div>
            </div>
          </div>
        </div>
      }
      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <h4> Accommodation: Category & Pricing - Overview
                    
                  </h4>
                  </div>
      {(addAccomodationType == false && categoryArray.length > 0) &&
        <div className="container text-left mb-3">
          <br></br>
          {categoryArray.map(item =>
            <div className="bg-white mb-4">
              <h5 className="float-left mx-2">Accommodation Category Name: {item.category_name} </h5>
              <p className="float-right mx-2" onClick={()=>editCategoryItem(item.id)} style={{cursor:"pointer"}}> 
              <span style={{ color: "blue" }}>EDIT</span></p>
              <div className="clearfix"></div>
              <div className="row m-0">
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Accommodation ID</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.accommodation} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Category Name</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.category_name} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Sleep Type</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.sleeps} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">View Type</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.view} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Type of Accommodation</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.type_of_accommodation} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Smoking Policy</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.smoking_policy} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Guest Capacity</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.guest_capacity} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Number of Accommodation</p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.no_of_room_this_type} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Price Per Accommodation (Off Season) </p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.price_per_accommodation_off_season} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Price Per Accommodation (On Season) </p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.price_per_accommodation_on_season} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Single Occupancy Supplement (on season) </p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.single_occupancy_supplement_on_season} </b> </p>
                </div>
                <div className="col-sm-5 mx-3 my-2 bg-info">
                  <p className="float-left text-white mx-2 my-1">Single Occupancy Supplement (off season) </p>
                  <p className="float-right text-white mx-2 my-1"> <b> {item.single_occupancy_supplement_off_season} </b> </p>
                </div>
              </div>
            </div>
          )}
          <div className="my-4 text-center">
            <button className="btn btn-warning text-white mx-auto" onClick={addAnotherPriceCategory}>+Add Another Price Category</button>
          </div>
          <div className="text-center mb-4">
            <Link to={"/accomodation/basic/1"}>
              <button className="btn btn-grey border border-primary mx-2">BACK </button>
            </Link>
            <button className="btn btn-primary mx-2" onClick={submitPricing}>CONTINUE</button>
          </div>
        </div>
      }
      {addAccomodationType &&
        <div className="mb-3">
          <div className="d-flex justify-content-center bg-light" >
            <div className="p-2 justify-content-center  align-self-center w-100" >
              <div className="container text-left">
                <h4>Accommodation: Category & Pricing</h4>
                <div className="p-3 mb-3 bg-white">
                  <p><span style={{ color: "red" }}>*</span> mandatory field</p>
                  <div>
                    <h5>Note:</h5>
                    <p>
                      <ul>
                        
                        <li><u>It is recommended to provide all informations.</u> </li>
                       <li> This form page allows to create categories for various combination of pricing range & accommodation type. </li>
                        <li>It is recommended to provide a <b>Unique Name</b> to identify under text field of label 'Category Name'. </li>
                        <li>The selected Non-zero fields under 'Accommodation Distrubution' in 'Basic Information' page will be shown as <br></br>
                             dropdown options under label 'Type of Accommodation'. </li>
                        
                        <li>In the case of default text in dropdown fields is <b>'None' then please select any other option apart from 'None'.</b> </li>
                        <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against each lable of 'Price Per Accommodation'.</b> </li>


                      </ul>
                    </p>
                  </div>
                </div>
                <div className="mb-4">
                  {/* <div className="p-3 bg-white">
                    <div className="px-3">
                         
                    </div>
                  </div> */}
                </div>
                <div className="mb-3">
                  {/* <h4>Category</h4> */}
                  <div className="p-3 bg-white">
                    <div>
                      <div className="row" >
                        <div className="col-sm-12">
                          <label>Category Name <span style={{ color: "red" }}> * </span> </label>
                          <input className="form-control mb-3" value={categoryObject.category_name} onChange={handleChange} name="category_name" />
                        </div>
                        <div className="col-sm-12">
                          <label>Type of Accommodation<span style={{ color: "red" }}> * </span> </label>
                          <select className="form-control mb-3" value={categoryObject.type_of_accommodation} name="type_of_accommodation" onChange={handleChange}>
                            <option></option>
                            {accommodationType.map(value =>
                              <option value={value}> {stringCap(value).capitalize()}</option>
                            )}
                          </select>
                        </div>
                        <div className="col-sm-12">
                          <label>Sleep Type<span style={{ color: "red" }}> * </span></label>
                          <select className="form-control mb-3" name="sleeps" value={categoryObject.sleeps} onChange={handleChange}>
                            <option>None</option>
                            <option>Single</option>
                            <option>Double</option>
                            <option>Twin</option>
                            <option>King</option>
                            <option>Tripple</option>
                            <option>Quad</option>
                            <option>Dormitory</option>
                            <option>Others</option>
                          </select>
                        </div>
                        <div className="col-sm-12">
                          <label>View Type<span style={{ color: "red" }}> * </span></label>
                          <select className="form-control mb-3" name="view" value={categoryObject.view} onChange={handleChange}>
                            <option>None</option>
                            <option>Garden</option>
                            <option>Pool View</option>
                            <option>Lake Front</option>
                            <option>Sea View</option>
                            <option>Scape View</option>
                            <option>Mountain View</option>
                            <option>Others</option>
                          </select>
                        </div>
                        {/* <div className="col-sm-12">
                          <label>Type of room(comfort)</label>
                          <select className="form-control" name="type_of_accommodation" onChange={handleChange}>
                            <option>None</option>
                            <option>Budget</option>
                            <option>Economy</option>
                            <option>Standard</option>
                            <option>Semi-Luxury</option>
                            <option>Luxury</option>
                            <option>Deluxe</option>
                          </select>
                        </div> */}
                        <div className="col-sm-12">
                          <label>Budget of Accommodation (With Respect to Comfort & Budget)<span style={{ color: "red" }}> * </span> </label>
                          <select className="form-control mb-3" name="budget" value={categoryObject.budget} onChange={handleChange}>
                            <option>None</option>
                            <option>Budget</option>
                            <option>Economy</option>
                            <option>Standard</option>
                            <option>Semi-Luxury</option>
                            <option>Luxury</option>
                            <option>Deluxe</option>
                            <option>Others</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {/* <h4>Category</h4> */}
                  <div className="p-3 bg-white">
                    <div>
                      <div className="row" >
                        <div className="col-sm-12">
                          <label>Smoking Policy <span style={{ color: "red" }}> * </span> </label>
                          <select className="form-control mb-3" name="smoking_policy" value={categoryObject.smoking_policy} onChange={handleChange}>
                            <option>None</option>
                            <option>Allowed</option>
                            <option>Not Allowed</option>
                            <option>On Request</option>
                          </select>
                        </div>
                        <div className="col-sm-12">
                          <label>Guest Capacity (no of persons per 'Type of Accommodation' )<span style={{ color: "red" }}> * </span> </label>
                          <input className="form-control mb-3" onChange={handleChange} value={categoryObject.guest_capacity} name="guest_capacity" placeholder="Enter a number" />
                        </div>
                        <div className="col-sm-12">
                          <label>Number Of Accommodation (in this 'Type of Accommodation')<span style={{ color: "red" }}> * </span></label>
                          <input className="form-control mb-3" onChange={handleChange} value={categoryObject.no_of_room_this_type} name="no_of_room_this_type" placeholder="Enter a number" />
                        </div>
                        <div className="col-sm-12">
                          <label>Price Per Accommodation (Peak-Season)<span style={{ color: "red" }}> * </span> </label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{currencyType.currency}</div>
                            </div>
                            <input type="text" className="form-control" onChange={handleChange} value={categoryObject.price_per_accommodation_on_season} name="price_per_accommodation_on_season" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Price Per Accommodation (Off-Season)<span style={{ color: "red" }}> * </span> </label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text"> {currencyType.currency} </div>
                            </div>
                            <input type="text" className="form-control" onChange={handleChange} value={categoryObject.price_per_accommodation_off_season} name="price_per_accommodation_off_season" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Single Occupancy Supplement (Peak season) </label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{currencyType.currency}</div>
                            </div>
                            <input type="text" className="form-control" onChange={handleChange} value={categoryObject.single_occupancy_supplement_on_season} name="single_occupancy_supplement_on_season" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Single Occupancy Supplement (Off season) </label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{currencyType.currency}</div>
                            </div>
                            <input type="text" className="form-control" onChange={handleChange} value={categoryObject.single_occupancy_supplement_off_season} name="single_occupancy_supplement_off_season" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
          <div className=" py-4 pb-5">
            <button onClick={() => setAddAccomodationType(false)} className="btn btn-grey border border-primary mx-2">BACK </button>
            {updateButton ?
              <button className="btn btn-primary mx-2" onClick={updateCategoryObject} >UPDATE</button>
              :
              <button className="btn btn-primary mx-2" onClick={addCategoryObject} >CONTINUE</button>
            }
          </div>

        </div>
      }
      <div>

      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}