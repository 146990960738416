import { TOUR, TOURID } from "../actions/tour"

const initialState = {
    tour:null,
    tour_id:null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case TOUR:
            return{
                tour:action.tour
            };
        case TOURID:
            return{
                tour_id:action.tour_id
            }
        default:
            return state
    }
}