export const SETLOGISTICID = 'SETLOGISTICID';
export const FLEETID = 'FLEETID';
export const REMOVEFLEETID = 'REMOVEFLEETID';


export const setLogisticId = (logistic_package_id) => {
  return async dispatch => {
    if (logistic_package_id != null) {
      dispatch({
        type: SETLOGISTICID,
        logistic_package_id: logistic_package_id
      })

    }
  };
}

export const fleetid = (fleet_id) => {
  return async dispatch => {
        dispatch({
          type: FLEETID,
          fleet_id: fleet_id
        })
  };
}

export const removefleetid = () => {
  return async dispatch => {
    dispatch({
      type: REMOVEFLEETID,
    })
  }
}



