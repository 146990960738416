import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import listProducts from "../store/actions/Home";
import "./home.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as accommodationActions from "../store/actions/accommodation";

const Home = () => {
  const home = useSelector((state) => state.home);
  const { products } = home;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listProducts());
    return () => {
      //
    };

  }, []);

  useEffect(()=>{
    // getName
    // window.location.reload();
  },[])

  // const getName = () => {
  //   setUserName(`${cookies.get('name')}`)
  // }



  return (
    <div className="">
      <br />
      <div className="">
        <div className="Home">
          <div className="Home-page">
            <div>
              <p className="Home-heading">Accommodation Partner</p>
              <p>{products.accommodation_count} Property</p>
            </div>
            <div>
              <Link to="/home/accommodation">
                {" "}
                <button className="Home-btn">View / Add</button>
              </Link>
            </div>
          </div>
          <div className="Home-page ">
            <div>
              <p className="Home-heading">Tour Partner</p>
              <p>{products.tour_count} Tour Packages</p>
            </div>
            <div>
              <Link to="/home/tour-package">
                <button className="Home-btn">View / Add</button>
              </Link>
            </div>
          </div>
          <div className="Home-page ">
            <div>
              <p className="Home-heading">Safari / Game Drive Partner</p>
              <p>{products.safari_count} Safari Packages</p>
            </div>
            <div>
              <Link to="/home/safari-package">
                {" "}
                <button className="Home-btn">View / Add</button>
              </Link>
            </div>
          </div>
          <div className="Home-page ">
            <div>
              <p className="Home-heading">Ground Logistics Partner</p>
              <p>{products.logistics_count} Logistics Packages</p>
            </div>
            <div>
              <Link to="/home/ground-logistic-package">
                {" "}
                <button className="Home-btn">View / Add</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
