import axios from "axios";

const instance = axios.create({
  baseURL: "https://stgtu.karmaalab.com/",
  // baseURL: "https://tube.andbeyondroads.com",
});

// instance.defaults.headers.common['Authorization'] = 'Token '

export default instance;
