import logo from './logo.svg';
import React, { Component, useEffect, useState }  from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from '../src/Authentication/Login/login'
import Signup from '../src/Authentication/SignUp/signup';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { useHistory, useLocation } from "react-router-dom";
import ReduxThunk from  'redux-thunk';
import { Provider } from 'react-redux';
import LandingHome from '../src/Landing-home/landing-home';
import authReducer from './store/reducers/auth';
import accommodationReducer from './store/reducers/accommodation';
import tourReducer from './store/reducers/tour';
import safariReducer from './store/reducers/safari';
import homeReducer from './store/reducers/homeReducer';
import logisticReducer from './store/reducers/logistics';
import EmailVerification from '../src/Authentication/Email-Verification/email-verification';
import MobileVerification from '../src/Authentication/Mobile-Verification/mobile-verification';
import AddMobileNumber from '../src/Authentication/Mobile-Verification/mobile-number';
import ForgotPassword from '../src/Authentication/Forgot-Password/forgot_password';
import Accomodation from '../src/Forms/Accomodation/accomodation';
import AccommodationViewForm from '../src/Forms/Accomodation/accomodation_form_view';
import Header from './Header/header';
import Home from './Home/home';
import Support from './Support/support';
import TourRegistration from './Forms/TourRegistration/TourReg';
import TourPackage from './Forms/TourPackage/Safari.js'
import SafariRegistration from '../src/Forms/SafariRegistration/SafariReg.js'
import SafariPackage from '../src/Forms/SafariPackage/Safari.js'
import TourViewForm from './Forms/ViewForms/TourViewForm';
import SafariViewForm from './Forms/ViewForms/SafariViewForm';
import TourPackageViewForm from './Forms/ViewForms/TourPackageViewForm';
import SafariPackageViewForm from './Forms/ViewForms/SafariPackageViewForm';
import GLReg from './Forms/GroundLogisticRegistration/GLReg';
import GLPackages from './Forms/GroundLogisticPackage/GLPackages';
import AdminRegisterForm from './Forms/ViewForms/AdminRegisterForm';
import AdminSafariRegForm from './Forms/ViewForms/AdminSafariRegForm';
import LogisticRegFrom from './Forms/ViewForms/LogisticRegFrom';
import logistics from './store/reducers/logistics';
import LogisticFleetForm from './Forms/ViewForms/LogisticFleetForm';
import AdminSafariPackageForm from './Forms/ViewForms/AdminSafariPackageForm';
import AdminTourPackageForm from './Forms/ViewForms/AdminTourPackageForm';
import LogisticViewForm from './Forms/ViewForms/LogisticViewForm';
import FleetViewForm from './Forms/ViewForms/FleetViewForm';
import PartnerAgreement from './Authentication/SignUp/PartnerAgreement';
import Testterms from './Authentication/SignUp/Testterms';
import UpdateProfile from './UpdateProfile/UpdateProfile';
import EditInventory from './Forms/Accomodation/EditInventory';
import EditInventoryLog from './Forms/Accomodation/EditInventoryLog';
import ViewInventoryLog from './Forms/Accomodation/ViewInventoryLog';
//import GroundReg_basic from './Forms/GroundLogisticRegistration/GroundReg_basic';

const initialState = {
  auth:{
    user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
  }
}
const rootReducer = combineReducers({
  auth:authReducer,
  accommodation:accommodationReducer,
  tour:tourReducer,
  home:homeReducer,
  logistic:logisticReducer,
  safari:safariReducer
});

const store = createStore(rootReducer,initialState,applyMiddleware(ReduxThunk));
function App() {
  const history = useHistory();
  const [showHeader, setShowHeader] = useState(true)
  useEffect(()=>{
    if(window.location.pathname!='/'){
      setShowHeader(false);
    }else{
      setShowHeader(true);
    }

  },[window.location.pathname])
  


  return (

    <BrowserRouter>
    <Provider store={store}>
    <div className="App">
      <Header/>
      <Switch>
        <Route path="/" exact component={LandingHome}/>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} /> 
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/email-verification" component={EmailVerification}/>
        <Route path="/add-mobile-number" component={AddMobileNumber}/>
        <Route path="/mobile-verification" component={MobileVerification}/>
        <Route path="/home"  component={Home}/>
        <Route path="/support"  component={Support}/>
        <Route path="/accomodation"  component={Accomodation}/>

        <Route path="/accomodation-view-form/:accommodation_id"  component={AccommodationViewForm}/>
        <Route path='/tour-view-form/:id' component={TourViewForm}></Route>
        <Route path="/safari-view-form/:id" component={SafariViewForm}></Route>
        <Route path='/tour-package-view-form/:id' component={TourPackageViewForm}></Route>
        <Route path='/safari-package-view-form/:id' component={SafariPackageViewForm}></Route>
        <Route path='/admin-comment-reg-view-form/:id' component={AdminRegisterForm}></Route>
        <Route path='/admin-comment-safariReg-view-form/:id' component={AdminSafariRegForm}></Route>
        <Route path='/ground-logistics-admin-form/:id' component={LogisticRegFrom}></Route>
        <Route path='/ground-fleet-admin-form/:id' component={LogisticFleetForm}></Route>
        <Route path='/admin-comment-safari-package-form/:id' component={AdminSafariPackageForm}></Route>
        <Route path='/admin-comment-tour-package-form/:id' component={AdminTourPackageForm}></Route>
        <Route path='/ground-logistics-view-form/:id' component={LogisticViewForm}></Route>
        <Route path='/ground-fleet-view-form/:id' component={FleetViewForm}></Route>

        <Route path="/tour-registration" component={TourRegistration} />
        <Route path='/tour-package' component = {TourPackage} />

        <Route path='/ground-logistics-registration'  component={GLReg}></Route>
        <Route path='/ground-logistics-package-forms' component={GLPackages}></Route>

        <Route path="/safari-registration" component={SafariRegistration} />
        <Route path="/safari-package" component={SafariPackage} />
        <Route path="/PartnerAgreement" component={PartnerAgreement}/>
        <Route path="/test-terms" component={Testterms} />

        <Route path="/update-profile" component={UpdateProfile}></Route>

        <Route path="/edit-inventory/:id" component={EditInventory}></Route>
        <Route path="/edit-inventory-log/:id" component={EditInventoryLog}></Route>
        <Route path="/view-inventory-log/:id" component={ViewInventoryLog}></Route>

        {/* <Route path="/home"  component={Home}/>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/email-verification" component={EmailVerification}/>
        <Route path="/add-mobile-number" component={AddMobileNumber}/>
        <Route path="/mobile-verification" component={MobileVerification}/>
        <Route path="/tu-partner/" component={FormIndex}/>
        <Route path="/bussiness-type" component={dashboard}/>
        <Route path="/accommodation"  component={Accomodation}/>
        <Route path="/accomodation-submit/:accomodation_id"  component={AccomodationSubmit}/> */}
      </Switch>
    </div>
    </Provider>
    </BrowserRouter>
  );
}

export default App;
