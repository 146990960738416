import axios from '../../axios';

export const ACCOMMODATION = 'ACCOMMODATION';


export const accommodation = (accommodation) => {
  return async dispatch => {
    if (accommodation != null) {
        dispatch({
          type: ACCOMMODATION,
          accommodation: accommodation
        })
  
      }
  };
}

