

import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import Safarireg_Basic from './SafariReg_basic.js';
import Safarireg_Policies from './SafariReg_Policies.js';
import Safarireg_finance from './SafariReg_finance.js';
import BasicAccommodationImages from './SafariReg_acc_images.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function TourReg() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    useEffect(() => {
        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

    
    return (
        <div className="header-top-space">
            <div className="d-sm-block text-center  d-none">
                <div className="d-flex  justify-content-around step-form">
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="stepper-title" >Basic Information</p>
                    </div>
                   
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="stepper-title">Policy</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="stepper-title">Finance</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="stepper-title">Safari Images</p>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path="/safari-registration/basic/1" exact component={Safarireg_Basic} />
                <Route path="/safari-registration/basic/1/:id" exact component={Safarireg_Basic} />
                <Route path="/safari-registration/accommodation_image/4/:tour_id" exact component={BasicAccommodationImages} />
                <Route path="/safari-registration/day-plan/2/:tour_id" exact component={Safarireg_Policies} />
                <Route path="/safari-registration/pricing/3/:tour_id" exact component={Safarireg_finance} />
            </Switch>
        </div>
    );
}