import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function LogisticViewForm() {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [basicInfo, setbasicInfo] = useState(null);
  const [contact, setcontact] = useState(null);
  const [policy, setpolicy] = useState(null);
  const [finance, setfinance] = useState(null);
  const [imageList, setimageList] = useState([]);

  useEffect(() => {
    getBasicDetail();
    getPolicies();
    getFinance();
    getImageList();
  }, []);

  const getBasicDetail = async () => {
    await axios
      .get(`partners/ground-logistic-basic-info-detail/${id}`, {
        headers: {
            Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in LOGISTICS view form", err);
        } else {
          console.log("data on LOGISTICS view form", response.data);
          setbasicInfo(response.data);
          setcontact(response.data.contact_details);
        }
      });
  };
  const getImageList = async () => {
    await axios
      .get(`partners/ground-logistic-vehicle-detail-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in safari view VEHICLELIST form", err);
        } else {
          console.log("data on safari view VEHICLELIST form", response.data);
          setimageList(response.data);
        }
      });
  };
  const getPolicies = async () => {
    await axios
      .get(`partners/ground-logistic-policy-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in LOGISTIC POLICY view form", err);
        } else {
          console.log("data on LOGISTICS view POLICY form", response.data);
          setpolicy(response.data[0]);
        }
      });
  };
  const getFinance = async () => {
    await axios
      .get(`partners/ground-logistic-finance-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour FINANCE view form", err);
        } else {
          console.log("data on tour view FINANCE form", response.data);
          setfinance(response.data[0]);
        }
      });
  };
  let history = useHistory();
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }
  return (
    <div className="header-top-space">
      <div className="container p-3">
        <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
          Ground Logistics Partner View
        </h3>
        {basicInfo != null && (
          <div className="p-2 bg-white">
            <h5 style={{ marginBottom: "1rem" }}>Basic Information</h5>
            <div className="row">
              {basicInfo &&
                Object.entries(basicInfo).map(([key, item]) => (
                  <div className="col-sm-6 col-lg-6">
                    {(() => {
                      if (key == "contact_details" && key.length) {
                        return 
                      } else if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "business" &&
                        key != "partner" &&
                        key!= "is_published" &&
                          key!= "edit_mode" &&
                          key!= "is_rejected" &&
                          key != "final_submission"
                      ) {
                        return (
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                        );
                      }
                    })()}
                  </div>
                ))}
            </div>
            <h5 style={{ marginBottom: "1rem" }}>Contact Details</h5>
            <div>
              {contact != null &&
                contact.map((element) => (
                  <div className="row">
                    {Object.entries(element).map(([key, item]) => (
                      <>
                        {(() => {
                          if (
                            key != "id" &&
                            key != "admin_comment" &&
                            key != "approved_status" &&
                            key != "created_at" &&
                            key != "updated_at" &&
                            key != "business" &&
                            key != "partner"&&
                            key != "logistics_id"
                          ) {
                            return (
                              <div className="col-sm-6">
                                <p
                                  style={{
                                    display: "flex",
                                    marginRight: "1rem",
                                    background: "lightpink",
                                    textAlign: "center",
                                    padding: ".5rem .5rem .5rem 1rem",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <b style={{ flex: 1, textAlign: "left" }}>
                                    {replaceAll(key, "_", " ")}
                                  </b>
                                  <b>
                                    {" "}
                                    {item == true || item == false ? (
                                      item == true ? (
                                        <CheckBoxIcon
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <ClearIcon style={{ color: "red" }} />
                                      )
                                    ) : (
                                      item
                                    )}{" "}
                                  </b>
                                </p>
                              </div>
                            );
                          }
                        })()}
                      </>
                    ))}
                  </div>
                ))}
            </div>
            <h5 style={{ marginBottom: "1rem" }}>Vehicle Data</h5>
            <div className="row">
              {imageList &&
                imageList.map((item) =>
                  Object.entries(item).map(([key, item]) => (
                    <>
                      {(() => {
                        if (item) {
                          if (
                            key != "id" &&
                            key != "admin_comment" &&
                            key != "approved_status" &&
                            key != "created_at" &&
                            key != "updated_at" &&
                            key != "business" &&
                            key != "partner" &&
                            key != "logistics"
                          ) {
                            return (
                              <div className="col-sm-6 col-lg-6">
                                <p
                                  style={{
                                    display: "flex",
                                    marginRight: "1rem",
                                    background: "lightpink",
                                    textAlign: "center",
                                    padding: ".5rem .5rem .5rem 1rem",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <b style={{ flex: 1, textAlign: "left" }}>
                                    {replaceAll(key, "_", " ")}
                                  </b>
                                  <b>
                                    {" "}
                                    {item == true || item == false ? (
                                      item == true ? (
                                        <CheckBoxIcon
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <ClearIcon style={{ color: "red" }} />
                                      )
                                    ) : (
                                      item
                                    )}{" "}
                                  </b>
                                </p>
                              </div>
                            );
                          }
                        }
                      })()}
                    </>
                  ))
                )}
            </div>
            <hr />
          </div>
        )}
        <hr />
        <div className="p-2 bg-white">
          <h5 style={{ marginBottom: "1rem" }}>Policies</h5>
          <div className="row">
            {
              //pricing && pricing.map(item => (
              policy &&
                Object.entries(policy).map(([key, item]) => (
                  <div className="col-sm-6">
                    {(() => {
                      if (key === "policy_document") {
                        return (
                          <div style={{display:'flex'}}>
                            <p style={{flex:1}}>Document : </p>
                            <a href={item} target="_blank">
                              <img
                                src={
                                  item
                                    ? "/images/download.png"
                                    : "/images/download.png"
                                }
                                alt="document"
                                style={{ width: "5rem", height: "5rem" ,marginBottom:'1rem',marginRight:'1rem'}}
                              ></img>
                            </a>
                          </div>
                        );
                      } else if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "business" &&
                        key != "partner" &&
                        key != "logistics"
                      ) {
                        return (
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                        );
                      }
                    })()}
                  </div>
                ))
              //))
            }
          </div>
        </div>
        <hr />
        <div className="p-2 bg-white">
          <h5 style={{ marginBottom: "1rem" }}> Finance</h5>
          <div className="row">
            {
              //pricing && pricing.map(item => (
              finance &&
                Object.entries(finance).map(([key, item]) => (
                  <>
                    {(() => {
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "business" &&
                        key != "partner" &&
                        key != "logistics"
                      ) {
                        return (
                          <div className="col-sm-6">
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" ,whiteSpace:'wrap'}}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                          </div>
                        );
                      }
                    })()}
                  </>
                ))
              //))
            }
          </div>
        </div>
        <hr />
        <div>
          <button onClick={() => history.goBack()} className="btn btn-primary">
            Back
          </button>
        </div>
      </div>
      {/*<button onClick={() => history.goBack()}>Back</button>*/}
    </div>
  );
}

export default LogisticViewForm;
