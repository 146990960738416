import React, { Component, useState } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import "./login.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Cookies from "universal-cookie";
import Cookies from 'js-cookie'
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  // const cookies = Cookies();
  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove('email');
    Cookies.remove('name');
  }, [])



  async function signin() {
    console.log("hello world", email, password);

    axios
      .post("auth/login", {
        email,
        password,
      })
      .then((res) => {
        Cookies.set('token', res.data.token)
        Cookies.set('name', res.data.profile.name)
        Cookies.set('id', res.data.id)
        Cookies.set('email', res.data.email)
        // cookies.set("token",res.data.token)
        let userLoginResponse = res.data;
        console.log(userLoginResponse);
        try {
          dispatch(authActions.login(email, password, userLoginResponse));
          // console.log('response from dispatch',res);
          // history.push('/bussiness-type');
          history.push("/home/");
        } catch (err) {
          console.log("error logging into signin actions", err);
        }
      })
      .catch((err) => {
        // let obj = err.response.data.errors;
        // Object.entries(obj).map((item, key) => {
        //   toast(' ' + item[0] + ':' + item[1][0])
        // })
        alert("Email or password is incorrect")
        console.log("err logging in", err);
      });
  }

  return (
    <div
      className="header-top-space"
      style={{ height: "100vh", width: "100%" }}
    >
      <div className="d-flex justify-content-center   h-100 w-100 bg-light">
        <div className="p-2 border border-primary mb-5 authCard mt-5">
          <CssBaseline />
          <div className={classes.paper}>
            <div className="image_logo">
              <img
                className="image_logo"
                src="images/logo/Logo 003.png"
                style={{ objectFit: "contain" }}
              />
            </div>
            <br></br>
            <div className="head">SIGN IN</div>
            <form className={classes.form} noValidate>
              <Grid container spacing={0.5}>
                <Grid item xs={11}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>

                <Grid item xs={11}>
                  <Button
                    id="bit"
                    fullWidth
                    onClick={signin}
                    variant="outlined"
                    margin="normal"
                  >
                    SIGN IN
                  </Button>
                </Grid>

                <div className="left d-flex">
                  <Link to="/signup">{"Do not have an account? Sign Up"}</Link>
                  &nbsp; &nbsp;
                  <Link to="/forgot-password">{"Forgot Password"}</Link>
                </div>
                <br />
                <p>
                  <small>
                    {/* By using our service you agree with our */}
                    By using our services, you agree with following 'Terms of
                    Use' & 'Partner Agreement'
                    <br />
                    <strong style={{ marginLeft: "0.5%", color: "#4A8CFF" }}>
                      {/* terms and conditions */}
                      <a className="darkPrimaryColor"><Link to="test-terms" target="_blank">Terms Of Use</Link></a>
                      <br />
                      <a className="darkPrimaryColor"><Link to="PartnerAgreement" target="_blank">Partner Agreement</Link></a>
                    </strong>
                  </small>
                </p>
              </Grid>
            </form>
          </div>
        </div>
      </div>


      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
