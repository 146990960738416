import React, { useEffect } from 'react'

function PageTop() {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    return (
        <div>
            
        </div>
    )
}

export default PageTop
