// import React, { Component, useState, useEffect, useRef } from 'react';
// import axios from '../../axios';

// import { Link, NavLink } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { useHistory } from "react-router-dom";
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import { useParams } from "react-router-dom";
// import '../Accomodation/accomodation_facility.css'

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Box } from '@material-ui/core';
// // import * as authActions from '../../store/actions/auth';

// const useStyles = makeStyles((theme) => ({ 
//   paper: {
//     marginTop: theme.spacing(7),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '80%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// const outerTheme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: '#009688',
//     },
//   },
// });

// function Tour_media() {
//   const classes = useStyles();
//   const logo_image_ref = useRef(null);
//   const banner_image_ref = useRef(null);
//   const accommodation_image_ref = useRef(null);
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const { tour_id } = useParams();

//   const [Plug, setPlug] = useState('');

//   const user = useSelector((state) => state.auth.user);
//   useEffect(() => {
//     window.scrollTo(0,0);
//     getFormDetails();
//   }, [user])
//   const [updateForm, setUpdateForm] = useState(false);

//   const [toursMedia, setToursMedia] = React.useState({
    // tour_operator_video_link:'',
    // tour_operator: tour_id,
    // banner_image: null,
    // logo_image: null,
    // accommodation_image:[],
//   })
//   const [imagePreview, setImagePreview] = React.useState({
//     banner_image_change: false,
//     banner_image: null,
//     logo_image_change: false,
//     logo_image: null,
//     accommodation_image_change: false,
//     accommodation_image: [],
//   })

//   const [accommodatinImageList, setAccommodationImageList] = React.useState(null);

//   const handlePlug = (event) => {
//     setPlug(event.target.value);
//   };

//   function uploadFileHandler(key, e) {
//     if(key == 'banner_image'){
//       setError2(false);
//     }
//     if(key == 'logo_image'){
//       setError(false);
//     }
//     setToursMedia({ ...toursMedia, [key]: e.target.files[0] });
//     let obj = {};
//     obj[key + '_change'] = true;
//     obj[key] = URL.createObjectURL(e.target.files[0]);
//     setImagePreview({ ...imagePreview, ...obj })
//   }

//   function delUploadFileHandler(key) {
//     setToursMedia({ ...toursMedia, [key]: null });
//     let obj = {};
//     obj[key + '_change'] = false;
//     obj[key] = null;
//     setImagePreview({ ...imagePreview, ...obj })
//   }


//   // function uploadMultipleImageHandler(key, e) {
//   //   console.log('files', e.target.files)
//   //   let arrImage = [];
//   //   if (toursMedia[key] != null && (toursMedia[key].length > 0)) {
//   //     arrImage = toursMedia[key];
//   //   } else {
//   //     arrImage = [];
//   //   }
//   //   if (key == 'accommodation_image') {
//   //     let i = 0;
//   //     for (i = 0; i < e.target.files.length; i++) {
//   //       if (arrImage.length <= 4) {
//   //         arrImage.push(e.target.files[i]);
//   //       } else {
//   //         toast("Max 4 images allowed")
//   //         break;
//   //       }
//   //       // console.log('target files',e.target.files[i])
//   //     }
//   //   }
//   //   setToursMedia({ ...toursMedia, [key]: arrImage });
//   //   let obj = {};
//   //   obj[key + '_change'] = true;
//   //   // if(obj[key+'_change']!==obj[key])
//   //   let i = 0;
//   //   obj[key] = [];
//   //   if (imagePreview[key] != null && imagePreview[key].length > 0) {
//   //     obj[key] = imagePreview[key];
//   //   } else {
//   //     obj[key] = [];
//   //   }
//   //   for (i = 0; i < e.target.files.length; i++) {
//   //     // obj[key][i] = URL.createObjectURL(e.target.files[i]);
//   //     if (arrImage.length <= 4) {
//   //       obj[key].push(URL.createObjectURL(e.target.files[i]))
//   //     } else {
//   //       // toast("Max 4 images allowed")
//   //       break;
//   //     }
//   //   }
//   //   setImagePreview({ ...imagePreview, ...obj })

//   //   console.log('image preview', imagePreview);

//   // }

//   // function deleteMultipleImage(key, index) {
//   //   let arr = toursMedia[key];
//   //   arr.splice(index, 1);
//   //   setToursMedia({ ...toursMedia, [key]: arr });
//   //   let arrImagePreview = imagePreview[key];
//   //   arrImagePreview.splice(index, 1);
//   //   setImagePreview({ ...imagePreview, ...arrImagePreview })

//   // }

//   function uploadMultipleImageHandler(key, e) {
//     let i = 0;
//     let imagePreviewArr = [];
//     let imageFileArr = [];
//     for(i=0;i<e.target.files.length;i++){
//       imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
//       imageFileArr.push(e.target.files[i]);
//     }
//     if(imagePreviewArr.length+imagePreview[key].length>4){
//       toast("Max 4 images allowed")
//     }else{
//       setImagePreview({...imagePreview,key:[...imagePreview[key], ...imagePreviewArr],[key+'_change']:true});
//       setToursMedia({...toursMedia,key:[...toursMedia[key], ...imageFileArr]});
//     }
//     console.log('image preview',imagePreview,'tours media',toursMedia);
//   }

//   function deleteMultipleImage(key, indexitem) {
//     const imagesFilter = accomodationImage[key].filter((item, index) => index !== indexitem);
//     setToursMedia({...toursMedia, [key]:imagesFilter});
//     let obj = {};
//     obj[key + "_change"] = true;
//     const imagesPreviewFilter = imagePreview[key].filter((item, index) => index !== indexitem);
//     obj[key] = imagesPreviewFilter;
//     setImagePreview({ ...imagePreview,...obj});
//   }

//   function handleChange(e) {
//     setToursMedia({ ...toursMedia, [e.target.name]: e.target.value });
//   }

// const [loading, setloading] = useState(false);

// async function getFormDetails(){
//   console.log('getting called ');
//   await axios.get(`partners/tour-operator-banner-logo-detail/${tour_id}`, {
//     headers: {
//       'Authorization': 'Token ' + user.token
//     }
//   }).then(res => {
//     if(res.data){
//           setUpdateForm(true)
//         }
//     console.log('response from getting  the tour operator media get', res.data)
//     setToursMedia({...res.data});
//     setImagePreview({...res.data});
//     console.log('tour media updated after getting banner image',toursMedia,'image preview',imagePreview);
//   }).catch(err => {
//     console.log('ERROR  from getting tour package basics', err);
//   })


//   await axios.get(`partners/tour-operator-image-list/${tour_id}`, {
//     headers: {
//       'Authorization': 'Token ' + user.token
//     }
//   }).then(res => {
//     if(res.data.length>0){
//       setUpdateForm(true)
//       let arr = [];
//         let i=0;
//         for(i=0;i<res.data.length;i++){
//           arr.push(res.data[i].tour_operator_image)
//         }
//         setToursMedia({...toursMedia, 'accommodation_image':arr});
//         setAccommodationImageList(res.data);
//         setImagePreview({...imagePreview, 'accommodation_image':arr});
//     }
//     // setBasicTour({ ...res.data[0] });
//     // setContactDetail([...res.data[0].contact_details])
//     // setOffices([...res.data[0].office_details]);
    
    
    
//     console.log('response from getting  the tour operator media get', res.data)
//     // setImagePreview({...imagePreview,image:res.data.image})
//   }).catch(err => {
//     console.log('ERROR  from getting tour package basics', err);
//   })
// }
// const [error, setError] = useState(false);
// const [error2, setError2] = useState(false);
// //const [uploadingMedia, setuploadingMedia] = useState(false);
// //let x=0;
//   function submitToursMedia() {
//     let formData = new FormData();
//     let formData2 = new FormData();
//     formData.append('tour_operator', toursMedia.tour_operator);
//     formData2.append('tour_operator', toursMedia.tour_operator);
//     formData.append('accommodation', toursMedia.accommodation);
//     if (imagePreview.logo_image_change) {
//       formData.append('logo_image', toursMedia.logo_image);
//       setError(false);
//     }
//     else {
//       setError({ message: "Logo Image is Mandatory!" });
//     }
//     if (imagePreview.banner_image_change) {
//       formData.append('banner_image', toursMedia.banner_image);
//       setError2(false);
//     }
//     else {
//       setError2({ message: "Banner Image is Mandatory!" });
//     }
//     formData.append('tour_operator_video_link',toursMedia.tour_operator_video_link);
    

//     let i = 0;
//     for (i = 0; i < toursMedia.accommodation_image.length; i++) {
//       if (imagePreview.accommodation_image_change) {
//         formData2.append(`tour_operator_image_${i}`, toursMedia.accommodation_image[i]);
//       }
//     }
   
//     if(imagePreview.logo_image_change && imagePreview.banner_image_change){
//       setloading(true);
//     axios.post('partners/tour-operator-banner-logo-create', formData, {
//       headers: {
//         'Authorization': 'Token ' + user.token
//       }
//     }).then(res => {
//       setloading(false);
//       console.log('response from submitting the tours  media create', res.data)
//     }).catch(err => {
//       setloading(false);
//       let obj = err.response.data.errors;
//       Object.entries(obj).map((item, key) => {
//         toast(' ' + item[0] + ':' + item[1][0])
//       })
//       console.log('error from creating accomodation media', err);
//     })
  
//     setloading(true);
//     axios.post('partners/tour-operator-image-create', formData2, {
//       headers: {
//         'Authorization': 'Token ' + user.token
//       }
//     }).then(res => {
//       setloading(false);
//       console.log('response from submitting the tours accomodation banner logo create', res.data)
//       history.push('/home/');
//     }).catch(err => {
//       setloading(false);
//       let obj = err.response.data.errors;
//       Object.entries(obj).map((item, key) => {
//         toast(' ' + item[0] + ':' + item[1][0])
//       })
//       console.log('error from creating tours accomodation media', err);
//     })
   
//   }
//   }

//   function updateToursMedia(){
//     let formData = new FormData();
//     let formData2 = new FormData();
//     formData.append('tour_operator', toursMedia.tour_operator);
//     formData2.append('tour_operator', toursMedia.tour_operator);
//     formData.append('accommodation', toursMedia.accommodation);
//     if (imagePreview.logo_image_change) {
//       formData.append('logo_image', toursMedia.logo_image);
//     }
//     if (imagePreview.banner_image_change) {
//       formData.append('banner_image', toursMedia.banner_image);
//     }
//     formData.append('tour_operator_video_link',toursMedia.tour_operator_video_link);
    

//     // let i = 0;
//     // for (i = 0; i < toursMedia.accommodation_image.length; i++) {
//     //   if (imagePreview.accommodation_image_change) {
//     //     formData2.append(`tour_operator_image_${i}`, toursMedia.accommodation_image[i]);
//     //   }
//     // }
//     if (imagePreview.accommodation_image_change) {
//       let i = 0;
//       for (i = 0; i < 4; i++) {
//           if (toursMedia.accommodation_image.length > i) {
//             formData2.append(`image_${i}`, toursMedia.accommodation_image[i]);
//           }
//           if (accommodatinImageList.length > i) {
//             formData2.append(`image_${i}_id`, accommodatinImageList[i].id);
//           }
//       }
//     }
//     setloading(true);
//     axios.put(`partners/tour-operator-banner-logo-back-update/${toursMedia.id}`, formData, {
//       headers: {
//         'Authorization': 'Token ' + user.token
//       }
//     }).then(res => {
//       setloading(false);
//       // history.push('/tour-registration/policy/3/' + tour_id);
//       console.log('response from submitting the tours  media update', res.data)
//       history.push('/home/');

//     }).catch(err => {
//       setloading(false);
//       let obj = err.response.data.errors;
//       Object.entries(obj).map((item, key) => {
//         toast(' ' + item[0] + ':' + item[1][0])
//       })
//       console.log('error from creating accomodation media', err);
//     })
//     setloading(true);
//     if (imagePreview.accommodation_image_change) {
//     axios.put(`partners/tour-operator-image-back-update/${tour_id}`, formData2, {
//       headers: {
//         'Authorization': 'Token ' + user.token
//       }
//     }).then(res => {
//       setloading(false);
//       console.log('response from updating the tours accomodation list images create', res.data)
//       history.push('/home/');
//     }).catch(err => {
//       setloading(false)
//       console.log('error from creating tours accomodation media', err);
//     })
//   }
//   }


//   return (
//     <div id="Tour_media" style={{
//       padding: '10%',
//       paddingTop: '4%',
//       width: '100%'
//     }}>
//       <div className="d-flex justify-content-center bg-light" >
//         <div className="p-2 justify-content-center  align-self-center w-100" >
//           <div className="container text-left">
            // <h4 style={{ color: "#818181", marginBottom: '4%' }}>Tour Partner Registration: Image and Video</h4>
            // <div className="p-3 mb-3 bg-white">
            //       <p><span style={{ color: "red" }}>*</span> mandatory field</p>
            //       <div>
            //       <h5>Note:</h5>
            //         <p>
            //         <ul>
            //               <li>'Logo of Business' will reprent your business in site. </li>
            //                <li>'Banner Image' will be the main image of your tour business, it will on top of all image. </li>
            //                <li> 'Additional Image' will be additional images of your tour business (Maximum 4 images are allowed).</li>
            //                <li>Image Guideline: Image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size. </li>
            //                <li>'Video' will be short video of your tour business and put URL in the label text.</li>
                            
                          
            //               </ul>
            //         </p>
            //       </div>
            //     </div>


//             <div className="Main media">
//               <div className="media_content">
//                 <h5>Logo of Business <span style={{ color: "red" }}>*</span></h5>
//                 {/* <p>The logo will reprent tour business in our site</p>
//                 <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
//               </div>
//               <div className="button_media">
//               <input className="d-none" type="file" ref={logo_image_ref} onChange={(e) => uploadFileHandler('logo_image', e)} />
//                 <Button onClick={() => { logo_image_ref.current.click() }}  variant="contained" color="#4A8CFF" >
//                   Upload Image
//                 </Button>
//                 <p className="my-1 text-success"> {toursMedia.logo_image != null ? toursMedia.logo_image.name+' Selected' : null} </p>
//                 <div className="multiple-image">
//                   <div onClick={() => delUploadFileHandler('logo_image')} className="multiple-image-del px-1">x</div>
//                   <a href={imagePreview.logo_image} target="_blank">
//                   <img src={imagePreview.logo_image?imagePreview.logo_image:'/images/download2.png'} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />
//                   </a>
//                 </div>
//                 {error && (
//                   <Box p={1} style={{ color: "red", background: "lightpink" }}>
//                     {error.message}
//                   </Box>
//                 )}
//               </div>

//             </div>


//             <br />
//             <div className="Main media">
//               <div className="media_content">
//                 <h5>Banner Image<span style={{ color: "red" }}>*</span></h5>
//                 {/* <p>This will be the main image as your tour partner </p>
//                 <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
//               </div>
//               <div className="button_media">
//               <input className="d-none" type="file" ref={banner_image_ref} onChange={(e) => uploadFileHandler('banner_image', e)} />
//                 <Button variant="contained" onClick={() => { banner_image_ref.current.click() }} color="#4A8CFF"  >
//                   Upload Image
//                 </Button>
//                 <p className="my-1 text-success"> {toursMedia.banner_image != null ? toursMedia.banner_image.name+' Selected' : null} </p>
//                 <div className="multiple-image">
//                   <div onClick={() => delUploadFileHandler('banner_image')} className="multiple-image-del px-1">x</div>
//                   <a href={imagePreview.banner_image} target="_blank">
//                   <img src={imagePreview.banner_image?imagePreview.banner_image:'/images/download2.png'} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />
//                   </a>
//                 </div>
               
//                 {error2 && (
//                   <Box p={1} style={{ color: "red", background: "lightpink" }}>
//                     {error2.message}
//                   </Box>
//                 )}
//               </div>

//             </div>


//             <br />






//             <br />

//             <div className="Main image">
//               <div className="row bg-white">
//                 <div className="col-sm-5">
//                   <div className="media_content">
//                     <h5 style={{ marginBottom: "4%" }}>Additional Image(s)</h5>
//                     <div className="media_content_video">
//                       <div className="button_media videoButton ">
//                         <input className="d-none" type="file" multiple ref={accommodation_image_ref} onChange={(e) => uploadMultipleImageHandler('accommodation_image', e)} />
//                         <Button variant="contained" onClick={() => { accommodation_image_ref.current.click() }} color="#4A8CFF">
//                           Upload Image(s)
//                       </Button>
//                         <p className="my-1 text-success"> {toursMedia.accommodation_image != null ? ' ' : null} </p>

//                       </div>

//                     </div>

//                   </div>
//                 </div>
//                 <div className="col-sm-7">
//                   <div className="d-flex" style={{overflowX:'scroll'}}>
//                     {imagePreview["accommodation_image"] &&
//                       accomodationImage["accommodation_image"].map((item, index) => (
//                         <div className="multiple-image">
//                           <div
//                             onClick={() =>
//                               deleteMultipleImage("accommodation_image", index)
//                             }
//                             className="multiple-image-del px-1"
//                           >
//                             x
//                           </div>
//                           {(typeof item)=='string'?
//                           <a href={item} target="_blank">
//                           <img
//                             src={item}
//                             className="img-responsive m-2"
//                             style={{ width: "100px", height: "100px" }}
//                           /></a>
//                           :
//                           <a href={item} target="_blank">
//                           <img
//                             src={URL.createObjectURL(item)}
//                             className="img-responsive m-2"
//                             style={{ width: "100px", height: "100px" }}
//                           />
//                           </a>
//                           }
//                         </div>
//                       ))}
//                   </div>
//                 </div>
//                 <div className="col-sm-7">
//                   <div className="d-flex"  style={{overflowX:'scroll'}}>
//                     {imagePreview['accommodation_image'] && imagePreview['accommodation_image'].map((item, index) =>
//                       <div className="multiple-image">
//                         <div onClick={() => deleteMultipleImage('accommodation_image', index)} className="multiple-image-del px-1">x</div>
//                         <a href={item} target="_blank">
//                         <img src={item} className="img-responsive m-2" style={{ width: '100px', height: '100px' }} />
//                         </a>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               {/* <p style={{ marginTop: '2%' }}>Upload your tour image(s)</p>
//               <p style={{ color: '#a7a7a7' }}>(Maximum 4 images are allowed, image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}

//             </div>

           
//             <br />


//             <div className="Main image"  >
//               <div className="media_content">
//                 <h5 style={{ marginBottom: "4%" }}>Video<span style={{ color: "red" }}></span></h5>
//                 <div className="media_content_video">

//                   <form className={classes.root} noValidate autoComplete="off">

//                     <TextField name="tour_operator_video_link" value={toursMedia.tour_operator_video_link} onChange={handleChange} label="YouTube URL" id="outlined-basic"  variant="outlined" />
//                   </form>
//                 </div>
//                  {/* <p style={{ marginTop: '2%' }}>A short video URl / Link about your tour business</p> */}

//               </div>


//             </div>
















//             <div className="text-center my-4 mb-2">
//               {/*<Link to={"/tour-registration/basic/1"}>*/}
//                 <button onClick={() => history.goBack()} className="btn btn-grey border border-primary mx-2">BACK </button>
//               {/*</Link>*/}
//               {updateForm ?
//                 <button onClick={updateToursMedia} className="btn btn-primary mx-2">UPDATE</button>
//                 :
//                 <button onClick={submitToursMedia} className="btn btn-primary mx-2" disabled={loading}>CONTINUE</button>
//                }
//                 {loading && <div style={{textAlign:'center',color:'red',width:'100%',background:'lightpink',padding:'1rem',marginTop:'1rem'}}>Loading...</div>}
//             </div>

//           </div>
//         </div>
//       </div>
//       <ToastContainer
//         position="top-right"
//         autoClose={6000}
//         hideProgressBar
//         newestOnTop
//         closeOnClick
//         rtl
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//       />
//     </div>
//   )
// }

// export default Tour_media











import React, { Component, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./accomodation_facility.css";
import { Box } from "@material-ui/core";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const mediaStream = new MediaStream();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});

export default function Tour_media() {
  const classes = useStyles();
  const logo_image_ref = useRef(null);
  const banner_image_ref = useRef(null);
  const accommodation_video_ref = useRef(null);
  const accommodation_image_ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {tour_id}  = useParams();
  const [update, setUpdate] = React.useState(false);
  const [uploadingMedia, setuploadingMedia] = useState(false);
  const [Plug, setPlug] = useState('');

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    getAccomodationMedia();
  }, []);

  const [accomodationImage, setAccomodationImage] = React.useState({
    // accommodation: accomodation_id,
    // accommodation_image: [],
    // property_image: null,
    tour_operator_video_link:'',
    tour_operator: tour_id,
    banner_image: null,
    logo_image: null,
    accommodation_image:[],
  });

  const [accomodationBanner, setAccomodationBanner] = React.useState({
    tour_operator: tour_id,
    banner_image: null,
    accommodation_video: '',
    accommodation_video_link: "",
    logo_image: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    accommodation_image_change: false,
    accommodation_image: [],
    property_image_change: false,
    property_image: null,
    banner_image_change: false,
    banner_image: null,
    accommodation_video_change: false,
    accommodation_video: null,
    logo_image_change: false,
    logo_image: null,
  });

  const [accommodation_image_list, set_accommodation_image_list] = React.useState(null);
  async function getAccomodationMedia() {
    await axios
      .get(`partners/tour-operator-banner-logo-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
          setUpdate(true);
      
          imagePreview.logo_image = res.data.logo_image;
            imagePreview.banner_image = res.data.banner_image;

            // console.log(imagePreview.logo_image,"logggggggggggg")
          
          setImagePreview({...imagePreview,['logo_image']:res.data[0].logo_image});
        // console.log("response fromaccommodation-banner-logo-list", res.data,'IMAGE PREVIEW',imagePreview);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });

    await axios
      .get(`partners/tour-operator-image-list/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log("responsesssssssssss",res.data)
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          let arr_image = [];
          let i = 0;
          for (i = 0; i < res.data.length; i++) {
            arr_image.push(res.data[i].tour_operator_image);
          }
          setAccomodationImage({
            ...accomodationImage,
            accommodation_image: arr_image,
          });
          set_accommodation_image_list(res.data);
          setImagePreview({ ...imagePreview, accommodation_image: arr_image });
        } else {
          setUpdate(false);
        }
        console.log("response accommodation-image-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });
  }
  console.log('image preview ',imagePreview);

  function uploadFileHandler(key, e) {
    if(key == 'banner_image'){
      setError2(false);
    }
    if(key == 'logo_image'){
      setError(false);
    }
    setAccomodationBanner({ ...accomodationBanner, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null
  }
  function delUploadFileHandler(key) {
    setAccomodationBanner({ ...accomodationBanner, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function uploadImageFileHandler(key, e) {
    setAccomodationImage({ ...accomodationImage, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
  }
  function delUploadImageFileHandler(key, e) {
    setAccomodationImage({ ...accomodationImage, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function uploadMultipleImageHandler(key, e) {
    let i = 0;
    let imagePreviewArr = [];
    let imageFileArr = [];
    for(i=0;i<e.target.files.length;i++){
      imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      imageFileArr.push(e.target.files[i]);
    }
    if(imagePreviewArr.length+imagePreview['accommodation_image'].length>4){
      toast("Max 4 images allowed")
    }else{
      setImagePreview({...imagePreview,'accommodation_image':[...imagePreview['accommodation_image'], ...imagePreviewArr],'accommodation_image_change':true});
      setAccomodationImage({...accomodationImage,'accommodation_image':[...accomodationImage['accommodation_image'], ...imageFileArr]});
    }
    e.target.value=null;
  }

  function deleteMultipleImage(key, indexitem) {
    const imagesFilter = accomodationImage[key].filter((item, index) => index !== indexitem);
    console.log('images fileter accommodation images',imagesFilter);
    setAccomodationImage({...accomodationImage, [key]:imagesFilter});
    let obj = {};
    obj[key + "_change"] = true;
    const imagesPreviewFilter = imagePreview[key].filter((item, index) => index !== indexitem);
    obj[key] = imagesPreviewFilter;
    setImagePreview({ ...imagePreview,...obj});
  }

  function handleChange(e) {
    setAccomodationBanner({
      ...accomodationBanner,
      [e.target.name]: e.target.value,
    });
  }
  let x=0;
  function submitAccomodationMedia() {
    let formData = new FormData();
    formData.append("tour_operator", accomodationImage.tour_operator);
    // if (imagePreview.accommodation_image_change) {
    //   formData.append('accommodation_image[]', accomodationImage.accommodation_image);
    // }
    // if (imagePreview.property_image_change) {
    //   formData.append('property_image', accomodationImage.property_image);
    // }
    let formData2 = new FormData();
    formData2.append("tour_operator", accomodationBanner.tour_operator);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
      setError2(false);
    } else {
      setError2({ message: "Banner Image is Mandatory!" });
    }
    // if (imagePreview.accommodation_video_change) {
    //   formData2.append(
    //     "accommodation_video",
    //     accomodationBanner.accommodation_video
    //   );
    // }
    formData2.append(
      "tour_operator_video_link",
      accomodationBanner.accommodation_video_link
    );
    if (imagePreview.logo_image_change) {
      formData2.append("logo_image", accomodationBanner.logo_image);
      setError(false);
    } else {
      setError({ message: "Logo Image is Mandatory!" });
    }

    let i = 0;
    for (
      i = 0;
      i <
      (accomodationImage.accommodation_image != null &&
        accomodationImage.accommodation_image.length);
      i++
    ) {
      if (imagePreview.accommodation_image_change) {
        formData.append(
          `tour_operator_image_${i}`,
          accomodationImage.accommodation_image[i]
        );
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/tour-operator-image-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour media create",
          res.data
        );
        if(x==1){
          setuploadingMedia(false);
        }
        x=1;
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating tour media", err);
      });

    // if (!error) {
  
      axios
        .post("partners/tour-operator-banner-logo-create", formData2, {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        })
        .then((res) => {
          console.log(
            "response from submitting the tour banner logo create",
            res.data
          );
          if(x==1){
            setuploadingMedia(false);
          }
          x=1
          // history.push("/accomodation/policy/6/" + accomodation_id);
          history.push("/home/");
        })
        .catch((err) => {
          setuploadingMedia(false);
          let obj = err.response.data.errors;
          obj != null &&
            Object.entries(obj).map((item, key) => {
              toast(" " + item[0] + ":" + item[1][0]);
            });
          console.log("error from creating tour media", err);
        });
    // }
  }

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
let z=0;
  function updateAccomodationMedia() {
    let bannerUpdate = false;
    let mulImageUpdate = false;
    let formData = new FormData();
    formData.append("tour_operator", accomodationImage.tour_operator);
    // if (imagePreview.accommodation_image_change) {
    //   formData.append('accommodation_image', accomodationImage.accommodation_image);
    // }
    // let i = 0;
    // for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
    //   if (imagePreview.accommodation_image_change) {
    //     formData.append(
    //       `accommodation_image_${i}`,
    //       accomodationImage.accommodation_image[i]
    //     );
    //   }
    // }
    if (imagePreview.accommodation_image_change) {
      let i = 0;
      for (i = 0; i < 4; i++) {
        if (accomodationImage.accommodation_image.length > i) {
          formData.append(`image_${i}`, accomodationImage.accommodation_image[i]);
        }
        if (accommodation_image_list.length > i) {
          formData.append(`image_${i}_id`, accommodation_image_list[i].id);
        }
      }
    }
    // if (imagePreview.property_image_change) {
    //   formData.append('property_image', accomodationImage.property_image);
    // }
    let formData2 = new FormData();
    formData2.append("tour_operator", accomodationBanner.tour_operator);
    if (imagePreview.banner_image_change) {
      formData2.append("banner_image", accomodationBanner.banner_image);
    }

    // if (imagePreview.accommodation_video_change) {
    //   formData2.append(
    //     "accommodation_video",
    //     accomodationBanner.accommodation_video
    //   );
    // }
    formData2.append(
      "tour_operator_video_link",
      accomodationBanner.accommodation_video_link
    );
    if (imagePreview.logo_image_change) {
      formData2.append("logo_image", accomodationBanner.logo_image);
    }

    axios
      .put(
        `partners/tour-operator-image-back-update`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log("response from submitting the tours media create",res.data);
        z=1
        mulImageUpdate=true
        // history.push('/accomodation/media/6/'+accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });

    axios
      .put(
        `partners/tour-operator-banner-logo-back-update/${tour_id}`,
        formData2,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        history.push('/home/tour-package');
        console.log(
          "response from submitting the accomodation banner logo create",
          res.data
        );
        
        // if(z==1){
        //   history.push("/accomodation/policy/6/" + accomodation_id);
        // }
        // bannerUpdate = true
        
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
      // if(bannerUpdate&&mulImageUpdate){
        // history.push("/accomodation/policy/6/" + accomodation_id);
       
      // }
  }

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            <br></br>
            <h4 style={{ color: "#818181", marginBottom: '4%' }}>Tour Partner Registration: Image and Video</h4>
            <div className="p-3 mb-3 bg-white">
                  <p><span style={{ color: "red" }}>*</span> mandatory field</p>
                  <div>
                  <h5>Note:</h5>
                  <p>
                  <ul>
                    <li>
                      <b>'Logo of Business'</b> will represent your business in site.{" "}
                    </li>
                    <li>
                      <b>'Banner Image'</b> will be the main image of your tour business, it
                      will on top of all images.{" "}
                    </li>
                    <li>
                      {" "}
                      <b>'Additional Image'</b> will be additional images of your
                      tour business (<b>Maximum 4 images</b> are allowed).
                    </li>
                    <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
                    <li>
                      <b>'Video'</b> will be short video of your tour business and put URL
                      in the label text.
                    </li>
                  </ul>
                </p>
                  </div>
                </div>
            

            <div className="Main media">
              <div className="media_content">
                <h4>
                  Logo of Business <span style={{ color: "red" }}> * </span>
                </h4>
                {/* <p>The logo will reprent buisness in our site  </p>
                <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={logo_image_ref}
                  onChange={(e) => uploadFileHandler("logo_image", e)}
                />
                <Button
                  onClick={() => {
                    logo_image_ref.current.click();
                  }}
                  variant="contained"
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {/* {error && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error.message}
                  </Box>
                )} */}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.logo_image != null
                    ? accomodationBanner.logo_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("logo_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <a href={imagePreview.logo_image} target="_blank">
                  <img
                    src={
                      imagePreview.logo_image
                        ? imagePreview.logo_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <div className="Main media">
              <div className="media_content">
                <h4>
                  Banner Image<span style={{ color: "red" }}> * </span>
                </h4>
                {/* <p>This will be the main image of your property, it will on top of all image</p> */}
                {/* <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
              </div>
              <div className="button_media">
                <input
                  className="d-none"
                  type="file"
                  ref={banner_image_ref}
                  onChange={(e) => uploadFileHandler("banner_image", e)}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    banner_image_ref.current.click();
                  }}
                  color="#4A8CFF"
                >
                  Upload Image
                </Button>
                {/* {error2 && (
                  <Box p={1} style={{ color: "red", background: "lightpink" }}>
                    {error2.message}
                  </Box>
                )} */}
                <p className="my-1 text-success">
                  {" "}
                  {accomodationBanner.banner_image != null
                    ? accomodationBanner.banner_image.name + " selected"
                    : null}{" "}
                </p>
                <div className="multiple-image">
                  <div
                    onClick={() => delUploadFileHandler("banner_image")}
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <img
                    src={//update?(accomodationBanner.banner_image):
                      imagePreview.banner_image
                        ? imagePreview.banner_image
                        : "/images/download2.png"
                    }
                    className="img-responsive m-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="Main image">
              <div className="row bg-white">
                <div className="col-sm-5">
                  <div className="media_content">
                    <h4 style={{ marginBottom: "4%" }}>
                    Additional Image(s)
                    </h4>
                    <div className="media_content_video">
                      <div className="button_media videoButton ">
                        <input
                          className="d-none"
                          type="file"
                          multiple
                          ref={accommodation_image_ref}
                          onChange={(e) =>
                            uploadMultipleImageHandler("accommodation_image", e)
                          }
                        />
                        <Button
                          variant="contained"
                          onClick={() => {
                            accommodation_image_ref.current.click();
                          }}
                          color="#4A8CFF"
                        >
                          Upload Image(s)
                        </Button>
                        <p className="my-1 text-success">
                          {" "}
                          {accomodationImage.accommodation_image != null
                            ? " "
                            : null}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  {/* <div className="d-flex" style={{overflowX:'scroll'}}> */}
                  <div className="d-flex" style={{}}>
                    {
                    // imagePreview["accommodation_image"] &&
                      accomodationImage.accommodation_image.map((item, index) => (
                        <div className="multiple-image" key={index}>
                          <div
                            onClick={() =>
                              deleteMultipleImage("accommodation_image", index)
                            }
                            className="multiple-image-del px-1"
                          >
                            x
                          </div>
                          {/* <h1>hiiiiiiiiii{item}</h1>
                          <img
                                  src={item}
                                  className="img-responsive m-2"
                                  style={{ width: "100px", height: "100px" }}
                                /> */}
                            {typeof item === "string" ? (
                              <a href={item} target="_blank">
                                <img
                                  src={item}
                                  alt="images"
                                  className="img-responsive m-2"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </a>
                            ) : (
                              <a href={item} target="_blank">
                                <img
                                  src={window.URL.createObjectURL(new Blob([item], {type: "application/zip"}))}
                                  className="img-responsive m-2 imgresp"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </a>
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {/* <p style={{ marginTop: "2%" }}>Upload your accommodation image</p> */}
              {/* <p style={{ color: '#a7a7a7' }}>(Maximum 4 images are allowed, image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
            </div>
            <br />
            <div className="Main image">
              <div className="media_content">
                <h4 style={{ marginBottom: "4%" }}>Video</h4>
                <div className="media_content_video">
                  {/* <div className="button_media videoButton ">
                    <input className="d-none" type="file" ref={accommodation_video_ref} onChange={(e) => uploadFileHandler('accommodation_video', e)} />
                    <Button variant="contained" onClick={() => { accommodation_video_ref.current.click() }} color="#4A8CFF" >
                      Upload file
                    </Button>
                <p className="my-1 text-success"> {accomodationBanner.accommodation_video!=null?'Image Selected':null} </p>

                  </div>
                  <h2>OR</h2> */}

                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                      id="outlined-basic"
                      name="accommodation_video_link"
                      value={
                        accomodationBanner.accommodation_video_link
                          ? accomodationBanner.accommodation_video_link
                          : ""
                      }
                      onChange={handleChange}
                      label="YouTube URL"
                      variant="outlined"
                    />
                    {/* <Button variant="contained" style={{ width: '50%', height: '30%' }}>
                      Update
                    </Button> */}
                  </form>
                </div>
                {/* <p style={{ marginTop: '2%' }}>Put a youtube link or url of short video about your property</p> */}
              </div>
            </div>
            <div className="text-center my-4 mb-2">
              {/* <Link to={"/accomodation/amenities/4/" + accomodation_id}> */}
                <button onClick = {() => history.goBack()} className="btn btn-grey border border-primary mx-2">
                  BACK{" "}
                </button>
              {/* </Link> */}
              {update ? (
                <button
                  onClick={updateAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={uploadingMedia}
                >
                                {uploadingMedia ? "UPLOADING..." : "UPDATE"}

                </button>
              ) : (
                <button
                  onClick={submitAccomodationMedia}
                  className="btn btn-primary mx-2"
                  disabled={uploadingMedia}
                >
                                {uploadingMedia ? "UPLOADING..." : "CONTINUE"}

                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

