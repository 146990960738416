import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from "@material-ui/core/FormControl";
import * as safariActions from "../../store/actions/safari";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_basic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { safari_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  const safariid = useSelector((state)=>state.safari.safari_id);

  useEffect(() => {
    window.scrollTo(0,0);

    getCurrencyList();
  }, [user]);

  useEffect(() => {
    if(safariid!=null){
      console.log("safari id is called",safariid);
      getVehicleData();
    }
  }, [safariid]);

  const [vehicle, setVehicle] = useState({
    safari: safari_id,
    safari_type: "",
    zone: "",
    min_days: '0',
    documents_required: null,
    vehicle_type: null,
    vehicle_type_2: null,
    price_safari: null,
    price_safari_exclusive: null,
    four_four: false,
    gypsy: false,
    mini_van: false,
    canter: false,
    notes: null,
    currency: "",
    national_park: "",
  });

  const handleChange = (event) => {
    if(event.target.name==='min_days' && event.target.value==='')
      setVehicle({ ...vehicle, [event.target.name]: '0' });
    else
      setVehicle({ ...vehicle, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setVehicle({ ...vehicle, [event.target.name]: event.target.checked });
  };

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [state, setState] = React.useState({
    fourcrossfour: "",
    Gypsy: "",
    MiniVan: "",
    Canter: "",
  });

  function submitVehicle() {
    axios
      .post("partners/safari-vehicle-create", vehicle, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        try {
          dispatch(safariActions.safariid(res.data.id));
          history.push("/safari-package/service/2/" + safari_id+"/"+res.data.id);
        } catch (err) {
          console.log('error safari actions', err)
        }
        console.log(
          "response from submitting the safari package vehicle create",
          res.data
        );

        // history.push("/safari-package/service/2/" + safari_id+"/"+res.data.id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log("ERROR  from creating vehicle safari package", err);
      });
  }

  const [currencyList, setcurrencyList] = useState([]);
  const [nationalParkList, setnationalParkList] = useState([]);
  const getCurrencyList = async () => {
    await axios
      .get(`partners/currency-national-park-list`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in CURRENCY LIST", err);
        } else {
          console.log("CURRENCY LIST DATA ", response.data);
          setcurrencyList(response.data.currency);
          setnationalParkList(response.data.national_park);
        }
      });
  };

  const [checkUpdate, setcheckUpdate] = useState(false);

  const getVehicleData = async () => {
    await axios
      .get(`partners/safari-vehicle-detail/${safariid}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in VEHICLE GET DATA ", err);
        } else {
          console.log("CURRENCY VEHICLE GET DATA ", response.data);
          if (response.data && response.data.id) {
            setcheckUpdate(true);
            setVehicle(response.data);
          }
          {
            /*Object.entries(data).map((item) => {
              console.log(' value ',item[0],item[1]);
                handleUpdate(item[0],item[1]);
          }
            )
        }*/
          }
        }
      });
  };

  const updateTourBasic = async () => {
    axios
      .put(
        `partners/safari-package-vehicle-back-update/${safariid}`,
        vehicle,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the safari package vehicle  UPDATE",
          res.data
        );

        history.push("/safari-package/service/2/" + safari_id+'/'+res.data.id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log("ERROR  from UPDATING vehicle safari package", err);
      });
  };

  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Safari / Game Drive Package: Vehicle & Pricing</h4></div>
      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                        {/* <li>
                          Please enter a non-zero number in 'Accommodation
                          Distribution' against various listed options which
                          will be reflected in next stages of forms for
                          selection{" "}
                        </li> */}
                        <li>
                          {" "}
                          <b>Currency:</b> The selected currency will be applicable in
                          Safari / Game Drive pricing.{" "}
                        </li>
                        <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against each label of 'Prices'.</b> </li>
                      </ul>
                    </p>
                  </div>
                </div>


      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>Title of Safari <span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <TextField
            value={vehicle.safari_title}
            onChange={handleChange}
            name="safari_title"
            id="outlined-basic"
            placeholder="Title of safari service"
            variant="outlined"
          />
        </form>
        <br />

        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Type <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "75%" }}
          >
            <Select
              value={vehicle.safari_type}
              onChange={handleChange}
              name="safari_type"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={"Half Day"}>Half Day</MenuItem>
              <MenuItem value={"full day"}>Full day</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            National Park{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "75%" }}
          >
            <Select
              value={vehicle.national_park}
              onChange={handleChange}
              name="national_park"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              {nationalParkList &&
                nationalParkList.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Zones <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "75%" }}
          >
            <Select
              value={vehicle.zone}
              onChange={handleChange}
              name="zone"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={"National park"}>National park</MenuItem>
              <MenuItem value={"buffer"}>Buffer</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Minimum Number Of Days Required For Safari Booking  </p>
          <TextField
            fullWidth
            type="number"
            value={vehicle.min_days}
            onChange={handleChange}
            name="min_days"
            id="outlined-basic"
            placeholder="only numbers are allowed"
            variant="outlined"
          />
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Documents / ID Proofs Required For Safari Booking <span style={{ marginLeft: "1%", color: "red" }}>*</span> </p>
          <TextField
            value={vehicle.documents_required}
            onChange={handleChange}
            name="documents_required"
            id="outlined-basic"
            placeholder="Government doc/ NA"
            variant="outlined"
            fullWidth
            placeholder="PAN / Driving License / Employee Card "
          />
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Type Of Vehicle{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>{" "}
          </p>
          <TextField
            value={vehicle.vehicle_type}
            onChange={handleChange}
            name="vehicle_type"
            id="outlined-basic"
            placeholder=" van /suv /boat / bus / others ( specify )"
            variant="outlined"fullWidth
          />
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p style={{ color: "black" }}>Subtype Of Vehicle </p>
          <TextField
            value={vehicle.vehicle_type_2}
            onChange={handleChange}
            name="vehicle_type_2"
            id="outlined-basic"
            placeholder="Open / Closed / Filming vehicle"
            variant="outlined"
            fullWidth
          />
        </form>
        <br />
        {/* <form
          noValidate
          autoComplete="off"
          className="company_name About"
          style={{ width: "60%" }}
        > */}
          <p style={{ display: "flex" }}>
            Price Per Seat Per Safari{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <div className="input-group">
            <div className="input-group-prepend resp" style={{ width: "20%" }}>
              <span
                className="input-group-text"
                style={{ width: "100%", display: "flow-root" }}
                id="inputGroupPrepend"
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  placeholder="Currency"
                  onChange={handleChange}
                  value={vehicle.currency}
                  name="currency"
                  SelectProps={{
                    native: true,
                  }}
                  //helperText="Please select your currency"
                  variant="standard"
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    // width: "90%",
                    // padding: ".5rem",
                  }}
                >
                  <option>
                    --Select Currency--
                  </option>
                  {currencyList &&
                    currencyList.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                </TextField>
              </span>
            </div>
            <input
              value={vehicle.price_safari}
              onChange={handleChange}
              name="price_safari"
              type="text"
              className="form-control"
              id="disabledTextInput"
              style={{ background: "White" }}
              placeholder="00.00"
              aria-describedby="inputGroupPrepend"
              required
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        {/* </form> */}

        <br />
        {/* <form
          noValidate
          autoComplete="off"
          className="company_name About"
          style={{ width: "60%" }}
        > */}
          <p style={{ display: "flex" }}>
            Price Per Exclusive Vehicle Per Safari{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <div className="input-group">
            <div className="input-group-prepend resp" style={{ width: "20%" }}>
              <span
                className="input-group-text"
                style={{ width: "100%", display: "flow-root" }}
                id="inputGroupPrepend"
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  placeholder="Currency"
                  onChange={handleChange}
                  value={vehicle.currency}
                  name="currency"
                  SelectProps={{
                    native: true,
                  }}
                  //helperText="Please select your currency"
                  variant="standard"
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    // width: "90%",
                    // padding: ".5rem",
                  }}
                >
                  <option>
                    --Select Currency--
                  </option>
                  {currencyList &&
                    currencyList.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                </TextField>
              </span>
            </div>
            <input
              value={vehicle.price_safari_exclusive}
              onChange={handleChange}
              name="price_safari_exclusive"
              type="text"
              className="form-control"
              id="disabledTextInput"
              style={{ background: "White" }}
              placeholder="00.00"
              aria-describedby="inputGroupPrepend"
              required
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        {/* </form> */}

        <br />
      </div>

      <br />

      <br />

      <div className="basic_company">
        <h4 style={{ display: "flex", fontWeight: "bold" }}>
          Other Available Vehicle{" "}
        </h4>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={vehicle.four_four}
                onChange={handleChangeCheck}
                checked={vehicle.four_four}
                name="four_four"
                color=""
              />
            }
            label="4 x 4"
          />
        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={vehicle.gypsy}
                onChange={handleChangeCheck}
                checked={vehicle.gypsy}
                name="gypsy"
                color=""
              />
            }
            label="Gypsy"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={vehicle.mini_van}
                onChange={handleChangeCheck}
                checked={vehicle.mini_van}
                name="mini_van"
                color=""
              />
            }
            label="Mini Van"
          />
        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={vehicle.canter}
                onChange={handleChangeCheck}
                checked={vehicle.canter}
                name="canter"
                color=""
              />
            }
            label="Canter"
          />
        </div>

        <br />
      </div>

      <br />

      <br />
      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name About">
          <h5 style={{ display: "flex" }}>
            Any Other Specific Notes / Requirements
          </h5>
          <br />
          <TextArea
            value={vehicle.notes}
            onChange={handleChange}
            name="notes"
            placeholder="Max. 320 Characters"
          />
        </form>
      </div>
      <div className="my-4 mb-2">
        {/* <Link to={"/tour-package/basic/1"}>
          <button className="btn btn-grey border border-primary mx-2">Back </button>
        </Link> */}
        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitVehicle}>
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_basic;
