//"use strict";
import React, { useState, useEffect, Fragment, useRef } from 'react';
import axios from '../../axios';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Tour_policies from './Tour_Policies'
import './TourReg_basic.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as tourAction from '../../store/actions/tour';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const currencies = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'BTC',
    label: 'BTC',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'RAND',
    label: 'RAND',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'BRL',
    label: 'BRL',
  },
  {
    value: 'ILS',
    label: 'ILS',
  },



];



function TourReg_basic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currencies, setCurrencies] = useState(null)

  const user = useSelector((state) => state.auth.user);
  let tourActionState = useSelector((state) => state.tour.tour);

  useEffect(() => {
    window.scrollTo(0,0);
    getCurrencyList()
    getFormDetails()
  }, [user])

  const [updateForm, setUpdateForm] = useState(false);

  const [basicTour, setBasicTour] = useState({
    business: 2,
    partner: `${cookies.get("id")}`,
    name: '',
    year_established: '',
    employee_no: '',
    about_company: '',
    admin_comment:'comments',
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    zip_code: '',
    currency: '',
  });

  const [contact_detail, setContactDetail] = useState([
    {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null
    }
  ])

  const [offices, setOffices] = useState([{
    office_address: ''
  }]);

  

  function getCurrencyList(){
    axios.get('partners/currency-national-park-list', {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setCurrencies(res.data.currency)
    }).catch(err => {
      console.log('error getting accommodation type list', err);
    })
  }
  

  function addContactDetails() {
    let arr = [...contact_detail];
    let obj = {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null
    }
    arr.push(obj);
    setContactDetail(arr);
  }

  function removeContactDetail(itemDel, index) {
    let arr = [...contact_detail];
    arr.splice(index, 1);
    setContactDetail(arr);
  }


  function addOfficeAddress() {
    let arr = [...offices];
    let obj = {
      office_address: ''
    }
    arr.push(obj);
    setOffices(arr);
  }

  function removeOfficeAddress(itemDel, index) {
    let arr = [...offices];
    arr.splice(index, 1);
    setOffices(arr);
  }

  // const handleChange = (event) => {
  //   setCurrency(event.target.value);
  // };

  const handleChange = (event) => {
    setBasicTour({ ...basicTour, [event.target.name]: event.target.value });
  };

  

  // const handleChangeOffice = (event) => {
  //   setOffice({ ...office, [event.target.name]: event.target.value });
  // };

  const onChangeContactForm = (index, event) => {
    let arr = contact_detail;
    arr[index][event.target.name] = event.target.value;
    setContactDetail([...arr]);
  }

  const onChangeOfficeForm = (index, event) => {
    let arr = offices;
    arr[index][event.target.name] = event.target.value;
    setOffices([...arr]);
  }

  function getFormDetails(){
    axios.get(`partners/tour-operator-basic-info-list`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      if(res.data.length>0){
        setUpdateForm(true)
      }
      setBasicTour({ ...res.data[0] });
      setContactDetail([...res.data[0].contact_details])
      setOffices([...res.data[0].office_details]);
      console.log('response from getting  the tour operator basics get', res.data,'Basic tour',basicTour)
      // setImagePreview({...imagePreview,image:res.data.image})
    }).catch(err => {

      console.log('ERROR  from getting tour package basics', err);
    })
  }

  function submitTourBasic() {
    let stateUpdate = basicTour;
    stateUpdate.contact_detail = contact_detail;
    stateUpdate.offices = offices;
    stateUpdate.business= 2;
    // stateUpdate.partner= user.id;
    stateUpdate.partner= cookies.get('id');
    // stateUpdate.partner = user.id;
    // stateUpdate.business = 1;
    // console.log('stateUpdat', stateUpdate);
    axios.post('partners/tour-operator-basic-info-create', stateUpdate, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the tour operator basics create', res.data)
      try {
        dispatch(tourAction.tour(res.data));

        history.push('/tour-registration/policy/2/' + res.data.id)
      } catch (err) {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(' ' + item[0] + ':' + item[1][0])
        })
        console.log('error tour actions', err)
      }


    }).catch(err => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('ERROR  from creating accomodation basics', err);
    })
  }



  function updateTourBasic(){
    let stateUpdate = basicTour;
    stateUpdate.contact_detail = contact_detail;
    stateUpdate.office_address = offices;
    axios.put(`partners/tour-operator-basic-info-back-update/${stateUpdate.id}`, stateUpdate, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the tour operator basics update', res.data)
      // try {
      //   dispatch(tourAction.tour(res.data));

        history.push('/tour-registration/policy/2/' + stateUpdate.id)
      // } catch (err) {
      //   console.log('error tour actions', err)
      // }


    }).catch(err => {
      let obj = err.response.data.errors;
      obj!= null && Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('ERROR  from updating tour operation  basics',err);
    })
  }
  
  const doc = useRef(null);
  //console.log(doc.current.style);

  return (
    <div className="TourReg_basic" ref={doc}>
      <div className="text-left"><h4>Tour Partner Registration: Basic Information</h4></div>
      
      <div className="p-3 mb-3 bg-white">
                  <p><span style={{ color: "red" }}>*</span> mandatory field</p>
                  <div className="text-left">
                  <h5>Note:</h5>
                    <p>
                    <ul>
                           <li><u>It is recommended to provide all informations.</u> </li>
                           <li><b>Name of Company:</b> The legal full name of company / business.  </li>
                           <li> <b>Year of Establishment:</b> Registration/Formation year of company / business. </li>
                           <li><b>Offices In:</b> City, Country format and add use '+ ADD ANOTHER OFFICE LOCATION' if multiple offices. </li>
                           <li><b>Currency:</b> The selected currency will be applicable to all of tour packages. </li>
                            
                          
                          </ul>
                    </p>
                  </div>
                </div>
      <div className="basic_company">

        <form noValidate autoComplete="off" className="company_name">
          <p>Name of Company<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <TextField id="outlined-basic"
            placeholder="Registered Legal Name"
            value={basicTour.name}
            onChange={handleChange}
            name="name"
            
            variant="outlined" />
            
        </form>
        <br />

        <div className="company_year_number">
          <form noValidate autoComplete="off" className="company_year">
            <p>Year of Establishment<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
            <TextField id="outlined-basic"
              value={basicTour.year_established}
              onChange={handleChange}
              name="year_established"
              placeholder="YYYY Format e.g. 2021" variant="outlined" />
          </form>

          <form noValidate autoComplete="off" className="company_year second">
            <p>Number of Employee<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
            <TextField id="outlined-basic"
              value={basicTour.employee_no}
              onChange={handleChange}
              name="employee_no"
              placeholder="Number of Employee" variant="outlined" />
          </form>

        </div>
          <br/>
          {/* <div className="company_year_number">
          <form noValidate autoComplete="off" className="company_name">
            
            <p>Admin Comments<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
            <TextField id="outlined-basic"
              placeholder="Name"
              value={basicTour.admin_comment}
              onChange={handleChange}
              name="admin_comment"
              
              variant="outlined" />
          </form>

          </div> */}
        <br />
        {offices.map((item, index) =>
          <div>
            <div className="text-right w-100">
              {offices.length > 1 &&
                <span className="cursor-pointer" onClick={() => removeOfficeAddress(item, index)}>X </span>
              }
            </div>
            <form noValidate autoComplete="off" className="company_name">
              <p>Offices In<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
              <TextField id="outlined-basic"
                onChange={(e) => onChangeOfficeForm(index, e)}
                value={item.office_address}
                name="office_address"
                placeholder="Office Location City, Country" variant="outlined" />
            </form>
          </div>
        )}
        <br />
        <h6 onClick={addOfficeAddress} style={{ color: "#007BC3", display: 'flex', fontWeight: 'bold' }}><a> + ADD ANOTHER OFFICE LOCATION</a> </h6>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <p>About Organization / Company<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <textarea id="outlined-basic"
            value={basicTour.about_company}
            onChange={handleChange}
            inputProps={{
              maxlength: 350
            }}
            name="about_company"
            className="form-control"
            rows="4"
            placeholder="Max. 350 Characters"  />
        </form>
        <br />
      </div>

      <br />
      <br />
      <br />
      <div className="company_contact">
        <h5 style={{ display: 'flex', color: "#949494" }}>Contact Details<span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span> </h5>
        <br />

        {contact_detail.map((item, index) =>
          <div>
            <div className="text-right w-100">
              {contact_detail.length > 1 &&
                <span className="cursor-pointer" onClick={() => removeContactDetail(item, index)}>X </span>
              }
            </div>
            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <p>Contact Name<span style={{ marginLeft: "1%", color: "red" }}> * </span></p>
                <TextField id="outlined-basic"
                  onChange={(e) => onChangeContactForm(index, e)}
                  value={item.name}
                  name="name"
                  placeholder="Name" variant="outlined" />
              </form>

              <form noValidate autoComplete="off" className="company_year second">
                <p>Role<span style={{ marginLeft: "1%", color: "red" }}> * </span></p>
                <TextField id="outlined-basic"
                  onChange={(e) => onChangeContactForm(index, e)}
                  value={item.role}
                  name="role"
                  placeholder="Sales, Operations, Logistics, Manager & Management" variant="outlined" />
              </form>

            </div>
            <br />

            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <p>Contact Number <span style={{ marginLeft: "1%", color: "red" }}> * </span></p>
                <PhoneInput
                  onChange={(e) => {
                    let arr = contact_detail;
                    arr[index]['number'] = e;
                    setContactDetail([...arr]);
                  }}
                  value={item.number}
                  name="number"
                  placeholder="Enter phone number"
                />
              </form>

              <form noValidate autoComplete="off" className="company_year seconds">
                <p>Alternative Contact Number</p>
                <PhoneInput
                  onChange={(e) => {
                    let arr = contact_detail;
                    arr[index]['number_alter'] = e;
                    setContactDetail([...arr]);
                  }}
                  value={item.number_alter}
                  name="number_alter"
                  placeholder="Enter phone number"
                />
              </form>

            </div>

            <div className="company_year_number">
              <form noValidate autoComplete="off" className="company_year">
                <br />
                <p>Email ID<span style={{ marginLeft: "1%", color: "red" }}> * </span></p>
                <TextField id="outlined-basic"
                  onChange={(e) => onChangeContactForm(index, e)}
                  value={item.email}
                  name="email"
                  placeholder="Email ID" variant="outlined" />
              </form>


            </div>
            <hr />
          </div>
        )}
        <br />
        <h6 onClick={addContactDetails} style={{ color: "#007BC3", display: 'flex', fontWeight: 'bold' }}><a> + ADD ANOTHER CONTACT</a> </h6>
        <br />

        <br />
      </div>
      <br />
      <br />


      <div className="company_address">
        <h5 style={{ display: 'flex', color: "#949494" }}>Address of Corporate Office<span style={{ color: "red", marginLeft: "1%", display: 'fle' }}></span> </h5>
        <br />



        <div className="company_year_number">
          <form noValidate autoComplete="off" className="company_year">
            <p>Address Line 1<span style={{ color: "red" }}> * </span></p>
            <TextField id="outlined-basic"
              value={basicTour.address_line_1}
              onChange={handleChange}
              name="address_line_1"
              placeholder="#Office No " variant="outlined" />
          </form>

          <form noValidate autoComplete="off" className="company_year second">
            <p>Address Line 2</p>
            <TextField id="outlined-basic"
              value={basicTour.address_line_2}
              onChange={handleChange}
              name="address_line_2"
              placeholder="Street / Locality" variant="outlined" />
          </form>

        </div>
        <br />

        <div className="company_year_number">

          
          <form noValidate autoComplete="off" className="company_year">
            <p>Country<span style={{ color: "red" }}> * </span></p>
            <TextField id="outlined-basic"
              value={basicTour.country}
              onChange={handleChange}
              name="country"
              placeholder="Country" variant="outlined" />
          </form>

          <form noValidate autoComplete="off" className="company_year second">
            <p>City<span style={{ color: "red" }}> * </span></p>
            <TextField id="outlined-basic"
              value={basicTour.city}
              onChange={handleChange}
              name="city"
              placeholder="City" variant="outlined" />
          </form>

        </div>

        {/* <br />
        <h6 style={{ color: "#007BC3", display: 'flex', fontWeight: 'bold' }}><a> + ADD ANOTHER OFFICE LOCATION</a> </h6>
        <br /> */}

        <div className="company_year_number">
          <form noValidate autoComplete="off" className="company_year">
            <br />
            <p>Zip / Postal / Area Code<span style={{ color: "red" }}> * </span></p>
            <TextField id="outlined-basic"
              value={basicTour.zip_code}
              onChange={handleChange}
              name="zip_code"
              placeholder="Only numbers are allowed "
              variant="outlined" />
          </form>



        </div>


      </div>

      <br />
      <br />
      <div className="Currency_picker" style={{ background: 'white', padding: '4%' }}>
      <div className="text-left"><h5 className="text-dark">
                    Currency Selection <span style={{ color: "red" }}> * </span>{" "}
                  </h5></div>
      
        {/* <p>Currency Selection<span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span></p> */}
        {currencies!=null&&
        
        <TextField
          id="outlined-select-currency-native"
          select
          name="currency"
          placeholder="Currency"
          value={basicTour.currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          
          // helperText="Please select your currency"
          variant="outlined"
          style={{ display: 'flex', backgroundColor: 'white' }}
        >
          <option>-- Select Currency --</option>
          {/* {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))} */}
          {currencies.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
        </TextField>
        }
        <br />
        <div className="text-left">
          {/* <span style={{ color: "red", marginLeft: "1%", display: 'fle' }}>*</span><span>Currency Selection for All Payments</span> */}
        </div>
      </div>

      <div className="my-4 mb-2">


        {updateForm ?
         <button onClick={updateTourBasic} className="btn btn-primary mx-2">UPDATE</button>
         :
        <button className="btn btn-primary mx-2" onClick={submitTourBasic}>CONTINUE</button>
        } 

      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default TourReg_basic
