import React, { Component, useState, useEffect, useRef } from 'react';
import axios from '../../axios';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import I2 from './assests/I2.png'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function Tour_policies() {
  const document_ref = useRef(null);
  const history = useHistory();
  const { tour_id } = useParams();
  const [updateForm, setUpdateForm] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const [uploadingMeda, setuploadingMeda] = useState(false);
  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails()
  }, [user])

  const [policy, setPolicy] = useState({
    safari: tour_id,
    document: null,
    other: null
  })

  const [imagePreview, setImagePreview] = React.useState({
    document_change: false,
    document: null,

  })
  const [extension, setextension] = useState(null);

  function uploadFileHandler(key, e) {
    setPolicy({ ...policy, [key]: e.target.files[0] });
    if(e.target.files[0]!=null){
      setextension(e.target.files[0].name.split('.').pop());
    } 
    let obj = {};
    obj[key + '_change'] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj })
  }

  function delUploadFileHandler(key) {
    setPolicy({ ...policy, [key]: null });
    let obj = {};
    obj[key + '_change'] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj })
  }

  const handleChange = (event) => {
    setPolicy({ ...policy, [event.target.name]: event.target.value });
  };

  function getFormDetails() {
    axios.get(`partners/safari-policy-detail/${tour_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from getting  the safari operator policy get', res.data)
      if (res.data) {
        setUpdateForm(true)
      }
      setPolicy({ ...res.data });
      setImagePreview({ ...imagePreview, 'document': res.data.document });
    }).catch(err => {
      console.log('ERROR  from getting tour package basics', err);
    })
  }

  function submitSafariPolicy() {
    let formData = new FormData();

    for (const [key, value] of Object.entries(policy)) {
      if (key != 'document') {
        formData.append(key, value);
      } else {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMeda(true);
    axios.post('partners/safari-policy-create', formData, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token,

      }
    }).then(res => {
      console.log('response from submitting the safari registration policy create', res.data)
      setuploadingMeda(false);
      history.push('/safari-registration/pricing/3/' + tour_id)
    }).catch(err => {
      setuploadingMeda(false);
      let obj = err.response && err.response.data.errors;
      obj && Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('ERROR  from creating safari registration policy', err);
    })
  }


  function updateSafariPolicy() {
    let formData = new FormData();

    for (const [key, value] of Object.entries(policy)) {
      if (key != 'document') {
        formData.append(key, value);
      } else {
        if (value != null) {
          if (imagePreview[key + '_change']) {
            formData.append(key, value);
          }
        }
      }
    }
    setuploadingMeda(true);
    axios.put(`partners/safari-operator-policy-back-update/${policy.id}`, formData, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      setuploadingMeda(false);
      history.push('/safari-registration/pricing/3/' + tour_id)
      console.log('response from submitting the safari  policy  update', res.data)
    }).catch(err => {
      setuploadingMeda(false)
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('error from tours operator policy update', err);
    })
  }

  return (
    <div className="Tour_policies">
      <h4 style={{ display: 'flex', color: "#949494" }}>Safari / Game Drive Partner Registration: Policies</h4>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h6>Note:</h6>
          <p>
            <ul>
              
              <li><u>It is recommended to provide all informations.</u> </li>
                {/* <li><b>Cancellation:</b> It is recommended to provide slab as <b>'Days Prior
                Vs Percentile of Loss' </b> in slabs such as <br></br> <b>30 days prior / 15 to 30
                days / 7 to 15 days / less than 7 days </b> and mention the
                percentile of loss against each one of them.{" "}</li> */}
                <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) wherever used. </b> </li>
              
              <li>
                Your Own Policy Document: You may upload detail policy in pdf
                (Max 2MB Size).{" "}
              </li>
              {/* <li>
                {" "}
                Customer Review: Format must be as - Name, Review Text and One
                line space between two reviews.{" "}
              </li> */}
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>


      
      <div className="Policies_document">
        <div className="Policies_document_main">
          {/* <img src={I2}></img> */}
          <h4>Policy Document (If Any) </h4>

        </div>

        <input className="d-none" type="file" ref={document_ref} onChange={(e) => uploadFileHandler('document', e)} />
        <Button onClick={() => { document_ref.current.click() }} variant="contained" className="Policies_button" color="primary">
          Upload file
      </Button>
        {/*<p className="my-1 text-success"> {policy.document != null ? 'Image Selected' : null} </p>*/}
        <div className="multiple-image">
          <div onClick={() => delUploadFileHandler('document')} className="multiple-image-del px-1">x</div>
          {/*<img src={imagePreview.document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          {(() => {
            //var fileName = imagePreview.document;
            //var fileExtension = fileName.split('.').pop();
              if(extension == 'pdf' || ( policy.document!=null && (imagePreview.document!=null && imagePreview.document.split('.').pop() === 'pdf') && policy.document.split('.').pop()) == 'pdf'){
                return (
                  <>
                  <p className="my-1 text-success"> {policy.document != null ? `File-->${policy.document.name} Selected` : null} </p>
                <a href={imagePreview.document} target="_blank">
                  {imagePreview.document!=null ? (
                  <img src='/images/download.png' className="img-responsive m-2" style={{ width: '50px', height: '30px' }} />
                  ):(<img src='/images/download2.png' alt='No file' style={{ width: '50px', height: '40px',paddingRight:'10px' }}></img>)}
                </a>  
                </>
                  )
              }else{
                return (
                  <>
                  <p className="my-1 text-success"> {policy.document != null ? `Image --> ${policy.document.name}  Selected` : null} </p>
                  <a href={imagePreview.document} target="_blank">
                    <img src={imagePreview.document ? imagePreview.document : '/images/download2.png'} className="img-responsive m-2" style={{ width: '60px', height: '40px' }} />
                  </a> 
                  </>
                )
              }
          })()}
        </div>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>Any other specific policies / company disclaimer<span style={{ marginLeft: "1%", color: "red" }}></span></p>
          <textarea
            value={policy.other}
            onChange={handleChange}
            name="other"
            inputProps={{
              maxlength: 350
            }}
            rows = "6" id="outlined-basic"
            placeholder="Max. 350 Characters" 
            variant="outlined" />
        </form>
      </div>
      <div className="my-4 mb-2">

        {/*<Link to={"/safari-registration/accommodation_image/2/" + tour_id}>*/}
          <button onClick={() => history.goBack()} className="btn btn-grey border border-primary mx-2">BACK </button>
        {/*</Link>*/}

        {updateForm ?
          <button onClick={updateSafariPolicy} className="btn btn-primary mx-2" 
          disabled={uploadingMeda}>{uploadingMeda ? 'UPLOADING':'UPDATE'}</button>
          :
          <button className="btn btn-primary mx-2" onClick={submitSafariPolicy}
          disabled={uploadingMeda}>{uploadingMeda ? 'UPLOADING':'CONTINUE'}</button>
        }

      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default Tour_policies
