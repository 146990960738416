import axios from '../../axios';
export const HOME = 'HOME';
import Cookies from 'universal-cookie';

// const url="https://stgtube.karmaalab.net/partners/partner-home-detail";
const url="https://tube.andbeyondroads.com/partners/partner-home-detail";
 
const cookies = new Cookies();

const listProducts = () => async (dispatch) => {
    
    let userName =  JSON.parse(sessionStorage.getItem('user'))
    const {data} = await axios.get(`${url}`,{
          
        headers: {
            // 'Authorization': 'Token'+' '+userName.token
            'Authorization': 'Token'+' '+cookies.get('token')
        }
    });
    dispatch({type: HOME, payload:data})   
}

export default listProducts;