import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./accomodation_facility.css";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});

export default function AccomodationFacility() {
  const { accomodation_id } = useParams();

  const classes = useStyles();
  const [update, setUpdate] = React.useState(false);
  const [currencyType, setCurrencyType] = useState(null);
  const [facility, setFacility] = React.useState({
    accommodation: accomodation_id,
    wifi: false,
    additional_breakfast: false,
    dinner: false,
    lunch: false,
    packed_lunch: "No",
    sun_downer: "No",
    snacks: false,
    campfire: false,
    cuisine: "None",
    fish: false,
    beef: false,
    pork: false,
    chicken: false,
    pure_veg: false,
    jain_food: false,
    halal_food: false,
    vegan: false,
    bar: "No",
    bar_price: 0,
    parking: "No",
    parking_price: 0,
    railway_pickup_drop: "No",
    railway_price: 0,
    airport_pickup_drop: "No",
    airport_price: 0,
    driver_accommodation: "No",
    driver_accommodation_price: 0,
    seating_area: false,
    private_entrance: false,
    pool: "No",
    wellness: "No",
    activities: "No",
  });

  const handleChange = (event) => {
    setFacility({ ...facility, [event.target.name]: event.target.checked });
  };

  const handleChangeRadio = (event) => {
    setFacility({ ...facility, [event.target.name]: event.target.value });
  };

  const handleChangeText = (event) => {
    setFacility({ ...facility, [event.target.name]: event.target.value });
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

    console.log("inside LOGIN User attributes");
    getAccomodationFacility();
    getCurrencyType();
  }, [user]);

  function getCurrencyType() {
    axios
      .get(`partners/accommodation-currency/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setCurrencyType(res.data);
        console.log(
          "response from getting accomdoation currency type",
          res.data
        );
      })
      .catch((err) => {
        toast("error in getting currency type");
      });
  }

  function getAccomodationFacility() {
    axios
      .get(`partners/accommodation-facilities-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          setFacility(res.data[0]);
          set_airport_pickup_drop_charge(res.data[0].airport_price>0?"Yes":"No");
          set_driver_accommodation_charge(res.data[0].driver_accommodation_price>0?"Yes":"No");
          setnearest_railwaycharge(res.data[0].railway_price>0?'Yes':"No");
        } else {
          setUpdate(false);
        }
        console.log("response from get facility", res.data);
      })
      .catch((err) => {
        let obj = err.response && err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error getting accommodation facility list", err);
      });
  }

  function submitAccomodationFacility() {
    axios
      .post("partners/accommodation-facilities-create", facility, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation facility create",
          res.data
        );
        history.push("/accomodation/amenities/4/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation types", err);
      });
  }

  function updateAccomodationFacility() {
    
    if(nearest_railwaycharge == 'No'){
      facility['railway_price']=0;
    }
    if(driver_accommodation_charge  == 'No'){
      facility['driver_accommodation_price']=0;
    }
    if(airport_pickup_drop_charge == 'No'){
      facility['airport_price']=0;
    }
    console.log("before update the state data", facility);
    axios
      .put(
        `partners/accommodation-facility-back-update/${facility.id}`,
        facility,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from updating the accomodation facility create",
          res.data
        );
        history.push("/accomodation/amenities/4/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation types", err);
      });
  }

  const [Cuisine, setCuisine] = useState("");

  const [parking, setParking] = React.useState("");
  const [extracharge, setextracharge] = React.useState("none");
  const [charge, setcharge] = React.useState("");
  const [barcharge, setbarcharge] = React.useState("");
  const [nearest_railwaycharge, setnearest_railwaycharge] = React.useState("");
  const [
    airport_pickup_drop_charge,
    set_airport_pickup_drop_charge,
  ] = React.useState("");
  const [
    driver_accommodation_charge,
    set_driver_accommodation_charge,
  ] = React.useState("");
  const [pricedisplay, setdisplay] = React.useState("none");
  const handleParking = (event) => {
    setParking(event.target.value);
    if (event.target.value == "On request") {
      setextracharge("flex");
      console.log(extracharge);
    } else {
      setextracharge("none");
      setdisplay("none");
      console.log(extracharge);
    }
  };

  const handleCuisine = (event) => {
    setCuisine(event.target.value);
  };

  const [parkingcharge, setparkingcharge] = useState("No");
  var extraStyle = {
    width: "50%",
    margin: "2%",
  };

  let pricestyle = {
    padding: "2%",
  };

  return (
    <div>
      <div className="d-flex justify-content-center bg-light">
        <div className="p-2 justify-content-center  align-self-center w-100">
          <div className="container text-left">
            <br></br>
            <h3 style={{ color: "#818181", marginBottom: "4%" }}>
              Accommodation: Facility and Services
            </h3>
            
            <div className="p-3 mb-3 bg-white">
          {/* <p><span style={{ color: "red" }}>*</span> mandatory field</p> */}
          <div>
            <h5>Note:</h5>
            <p>
              <ul>
              <li><u>It is recommended to provide all informations.</u> </li>
              <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against label where prices to be mentioned.</b> </li>
                {/* <li> This allows to create categories for various combination of pricing range and accomodation type. </li>
                           <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
              </ul>
            </p>
          </div>
        </div>
            
            
            
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="wifi"
                        color="primary"
                        checked={facility.wifi}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Wi-Fi"
                />
              </div>
            </div>
            <br></br>
            <br></br>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="additional_breakfast"
                        color="primary"
                        checked={facility.additional_breakfast}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Additional Breakfast"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="dinner"
                        color="primary"
                        checked={facility.dinner}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Dinner"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="lunch"
                        color="primary"
                        checked={facility.lunch}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Lunch"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div className="Components question">Packed Lunch</div>
                <div className="Components option">
                  <ThemeProvider theme={outerTheme}>
                    <RadioGroup
                      name="packed_lunch"
                      row
                      onChange={handleChangeRadio}
                      value={facility.packed_lunch}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                </div>
              </div>
            </div>

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div className="Components question">Sun Downer</div>
                <div className="Components option">
                  <RadioGroup
                    onChange={handleChangeRadio}
                    row
                    name="sun_downer"
                    value={facility.sun_downer}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="On request"
                      control={<Radio />}
                      label="On Request"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="snacks"
                        color="primary"
                        checked={facility.snacks}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Snacks"
                />
              </div>
            </div>
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="campfire"
                        color="primary"
                        checked={facility.campfire}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Camp Fire"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="cuisine-native-simple">
                    Cuisine
                  </InputLabel>
                  <Select
                    native
                    name="cuisine"
                    value={facility.cuisine}
                    onChange={handleChangeRadio}
                  >
                    <option value={"All"}>All</option>
                    <option value={"India"}>Indian</option>
                    <option value={"Asian"}>Asian</option>
                    <option value={"European"}>European</option>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="fish"
                        color="primary"
                        checked={facility.fish}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Fish"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="beef"
                        color="primary"
                        checked={facility.beef}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Beef"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="pork"
                        color="primary"
                        checked={facility.pork}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Pork"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="chicken"
                        color="primary"
                        checked={facility.chicken}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Chicken"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="pure_veg"
                        color="primary"
                        checked={facility.pure_veg}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Pure Veg"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="jain_food"
                        color="primary"
                        checked={facility.jain_food}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Jain Food"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="halal_food"
                        color="primary"
                        checked={facility.halal_food}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Halal Food"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="vegan"
                        color="primary"
                        checked={facility.vegan}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Vegan"
                />
              </div>
            </div>
            <br />
            <br />

            <div className="Main">
              <div className="Main_dropdown">
                <div
                  className="Radio"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div className="Components question">Bar</div>
                  <div className="Components option">
                    <RadioGroup
                      row
                      onChange={handleChangeRadio}
                      name="bar"
                      value={facility.bar}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {facility.bar == "On request" && (
                  <div>
                    {/*<FormControl
                      className={classes.formControl}
                      style={extraStyle}
                    >
                      <InputLabel htmlFor="age-native-simple">
                        Is extra charge applicable?
                      </InputLabel>
                      <Select
                        native
                        value={barcharge}
                        inputProps={{
                          name: "facility",
                          id: "facility-native-simple",
                        }}
                        onChange={(e) => {
                          setbarcharge(e.currentTarget.value);
                        }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                      </FormControl>*/}
                    {/* {barcharge == "Yes" && (
                      <div id="pricebar" style={pricestyle}>
                        <label
                          for="price information"
                          style={{
                            padding: "2%",
                            border: "1px solid",
                            width: "20%",
                          }}
                        >
                          Price Information ({currencyType.currency}){" "}
                        </label>

                        <label
                          for="price"
                          style={{
                            padding: "2%",
                            backgroundColor: "white",
                            border: "1px solid",
                            width: "25%",
                          }}
                        >
                          <input
                            type="text"
                            className="border-0"
                            name="bar_price"
                            value={facility.bar_price}
                            onChange={handleChangeText}
                          />
                        </label>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </div>

            <div className="Main">
              <div className="Main_dropdown">
                <div
                  className="Radio"
                  style={{ width: "100%", height: "100%", marginBottom: "2px" }}
                >
                  <div className="Components question">Parking</div>
                  <div className="Components option">
                    <RadioGroup
                      aria-label="parking"
                      name="parking"
                      value={facility.parking}
                      onChange={handleChangeRadio}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {facility.parking == "On request" && (
                  <div>
                    {/*<FormControl
                      className={classes.formControl}
                      style={extraStyle}
                    >
                      <InputLabel htmlFor="age-native-simple">
                        Is extra charge applicable?
                      </InputLabel>
                      <Select
                        native
                        value={charge}
                        inputProps={{
                          name: "facility",
                          id: "facility-native-simple",
                        }}
                        onChange={(e) => {
                          setcharge(e.currentTarget.value);
                        }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                      </FormControl>*/}
                    {/* {charge == "Yes" && (
                      <div id="pricebar" style={pricestyle}>
                        <label
                          for="price information"
                          style={{
                            padding: "2%",
                            border: "1px solid",
                            width: "20%",
                          }}
                        >
                          Price Information ({currencyType.currency})
                        </label>
                        <label
                          for="price"
                          style={{
                            padding: "2%",
                            backgroundColor: "white",
                            border: "1px solid",
                            width: "25%",
                          }}
                        >
                          <input
                            type="text"
                            className="border-0"
                            name="parking_price"
                            value={facility.parking_price}
                            onChange={handleChangeText}
                          />
                        </label>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </div>

            <div className="Main">
              <div className="Main_dropdown">
                <div
                  className="Radio"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div className="Components question">
                    {" "}
                    Nearest Railway pickup drop (One Way)
                  </div>
                  <div className="Components option">
                    <RadioGroup
                      row
                      onChange={handleChangeRadio}
                      name="railway_pickup_drop"
                      value={facility.railway_pickup_drop}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {console.log(
                  "raileway pickup drop ",
                  facility.railway_pickup_drop
                )}
                {facility.railway_pickup_drop == "On request" && (
                  <div>
                    <FormControl
                      className={classes.formControl}
                      style={extraStyle}
                      
                    >
                      <InputLabel  htmlFor="age-native-simple">
                        Is extra charge applicable?
                      </InputLabel>
                      <Select
                      className="pt-3"
                        native
                        value={nearest_railwaycharge
                        }
                        inputProps={{
                          name: "facility",
                          id: "facility-native-simple",
                        }}
                        onChange={(e) => {
                          setnearest_railwaycharge(e.currentTarget.value);
                        }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                    </FormControl>
                    {console.log(
                      "nearest railway charge",
                      nearest_railwaycharge
                    )}
                    {nearest_railwaycharge=='Yes' && (
                      <div id="pricebar" style={pricestyle}>
                        <label
                          for="price information"
                          style={{
                            padding: "2%",
                            border: "1px solid",
                            width: "20%",
                          }}
                        >
                          Price Information (
                          {currencyType != null && currencyType.currency}){" "}
                        </label>

                        <label
                          for="price"
                          style={{
                            padding: "2%",
                            backgroundColor: "white",
                            border: "1px solid",
                            width: "25%",
                          }}
                        >
                          <input
                            type="number"
                            className="border-0"
                            name="railway_price"
                            value={facility.railway_price}
                            onChange={handleChangeText}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="Main">
              <div className="Main_dropdown">
                <div
                  className="Radio"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div className="Components question">Airport Pickup Drop (One Way)</div>
                  <div className="Components option">
                    <RadioGroup
                      row
                      onChange={handleChangeRadio}
                      name="airport_pickup_drop"
                      value={facility.airport_pickup_drop}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {facility.airport_pickup_drop == "On request" && (
                  <div>
                    <FormControl
                      className={classes.formControl}
                      style={extraStyle}
                    >
                      <InputLabel htmlFor="age-native-simple">
                        Is extra charge applicable?
                      </InputLabel>
                      <Select
                      className="pt-3"
                        native
                        value={airport_pickup_drop_charge}
                        inputProps={{
                          name: "facility",
                          id: "facility-native-simple",
                        }}
                        onChange={(e) => {
                          set_airport_pickup_drop_charge(e.currentTarget.value);
                        }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                    </FormControl>
                    {airport_pickup_drop_charge=="Yes" && (
                      <div id="pricebar" style={pricestyle}>
                        <label
                          for="price information"
                          style={{
                            padding: "2%",
                            border: "1px solid",
                            width: "20%",
                          }}
                        >
                          Price Information (
                          {currencyType && currencyType.currency}){" "}
                        </label>

                        <label
                          for="price"
                          style={{
                            padding: "2%",
                            backgroundColor: "white",
                            border: "1px solid",
                            width: "25%",
                          }}
                        >
                          <input
                            type="number"
                            className="border-0"
                            name="airport_price"
                            value={facility.airport_price}
                            onChange={handleChangeText}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="Main">
              <div className="Main_dropdown">
                <div
                  className="Radio"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div className="Components question">
                    Driver Accommodation (per night)
                  </div>
                  <div className="Components option">
                    <RadioGroup
                      row
                      onChange={handleChangeRadio}
                      name="driver_accommodation"
                      value={facility.driver_accommodation}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="On request"
                        control={<Radio />}
                        label="On Request"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {facility.driver_accommodation == "On request" && (
                  <div>
                    <FormControl
                      className={classes.formControl}
                      style={extraStyle}
                    >
                      <InputLabel htmlFor="age-native-simple">
                        Is extra charge applicable?
                      </InputLabel>
                      <Select
                      className="pt-3"
                        native
                        value={driver_accommodation_charge}
                        inputProps={{
                          name: "facility",
                          id: "facility-native-simple",
                        }}
                        onChange={(e) => {
                          set_driver_accommodation_charge(
                            e.currentTarget.value
                          );
                        }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                    </FormControl>
                    {driver_accommodation_charge=='Yes' && (
                      <div id="pricebar" style={pricestyle}>
                        <label
                          for="price information"
                          style={{
                            padding: "2%",
                            border: "1px solid",
                            width: "20%",
                          }}
                        >
                          Price Information (
                          {currencyType && currencyType.currency}){" "}
                        </label>

                        <label
                          for="price"
                          style={{
                            padding: "2%",
                            backgroundColor: "white",
                            border: "1px solid",
                            width: "25%",
                          }}
                        >
                          <input
                            type="number"
                            className="border-0"
                            name="driver_accommodation_price"
                            value={facility.driver_accommodation_price}
                            onChange={handleChangeText}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="seating_area"
                        color="primary"
                        checked={facility.seating_area}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Seating Area"
                />
              </div>
            </div>

            <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="private_entrance"
                        color="primary"
                        checked={facility.private_entrance}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Private Entrance"
                />
              </div>
            </div>

            {/* <div className="Main">
              <div className="Components">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={outerTheme}>
                      <Checkbox
                        name="driver_accommodation"
                        color="primary"
                        checked={facility.driver_accommodation}
                        onChange={handleChange}
                      />
                    </ThemeProvider>
                  }
                  label="Driver Accommodation"
                />
              </div>
            </div> */}

            <br />

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div className="Components question">Pool</div>
                <div className="Components option">
                  <RadioGroup
                    row
                    onChange={handleChangeRadio}
                    name="pool"
                    value={facility.pool}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="On request"
                      control={<Radio />}
                      label="On Request"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div className="Components question">Wellness</div>
                <div className="Components option">
                  <RadioGroup
                    row
                    onChange={handleChangeRadio}
                    name="wellness"
                    value={facility.wellness}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="On request"
                      control={<Radio />}
                      label="On Request"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div className="Main">
              <div className="Radio" style={{ width: "100%", height: "100%" }}>
                <div className="Components question">Activities</div>
                <div className="Components option">
                  <RadioGroup
                    row
                    onChange={handleChangeRadio}
                    name="activities"
                    value={facility.activities}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="On request"
                      control={<Radio />}
                      label="On Request"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div className="text-center my-4 mb-2">
              <Link to={"/accomodation/pricing/2/" + accomodation_id}>
                <button className="btn btn-grey border border-primary mx-2">
                  BACK{" "}
                </button>
              </Link>
              {update ? (
                <button
                  onClick={updateAccomodationFacility}
                  className="btn btn-primary mx-2"
                >
                  UPDATE
                </button>
              ) : (
                <button
                  onClick={submitAccomodationFacility}
                  className="btn btn-primary mx-2"
                >
                  CONTINUE
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
