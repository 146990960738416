import axios from '../../axios';

export const SIGNUP = 'SIGNUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ERR_SIGNUP = 'ERR_SIGNUP';
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CONFIRM_FORGOT_PASSWORD = 'CONFIRM_FORGOT_PASSWORD';


export const signup = (name, email, password,response) => {
  return async dispatch => {

    console.log('response inside signup',response);
    // let response = null
    // let is_partner = true
    // try {
    //   await axios.post('auth/register', {
    //     name,
    //     email,
    //     password,
    //     is_partner
    //   }).then(res => {
    //     response = res.data;
    //     console.log('response from the register res ', res.data);
    //     // sessionStorage.setItem('user',JSON.stringify(res.data));
    //   }).catch(err=>{
    //     dispatch({
    //       type: ERR_SIGNUP,
    //       payload:err
    //     })
    //   })
      
    // } catch (err) {
    //   dispatch({
    //     type: ERR_SIGNUP,
    //     payload:err
    //   })
    //   console.log('err signing up in ', err)
    // }
    if (response != null) {
      dispatch({
        type: SIGNUP,
        user: response
      })

    }
    // const resData = response.json();
    // dispatch({
    //     type: SIGNUP,
    //     token:response.signInUserSession.idToken.jwtToken,
    //     userId:response.username
    // })
  };
}


export const logout = () => {
  return async dispatch => {
    dispatch({
      type: LOGOUT,
    })
  };
}


export const emailVerification = (username, authCode) => {
  return async dispatch => {
    let response = ''
    try {
      //  response = await Auth.confirmSignUp(username, authCode);
      console.log(' Code confirmed', response);

    } catch (error) {
      throw new Error(error.message)
      console.log(
        'Verification code does not match. Please enter a valid verification code.',
        error.code
      );
    }
    // }
    // const resData = response.json();
    // dispatch({
    //     type: SIGNUP,
    //     token:response.signInUserSession.idToken.jwtToken,
    //     userId:response.username
    // })
  };
}

export const login = (email, password, userLoginResponse) => {
  return async dispatch => {
    if (userLoginResponse != null) {
      dispatch({
        type: LOGIN,
        user: userLoginResponse
      })

    }
  };
}


export const forgotPassword = (username) => {
  return async dispatch => {
    let response = '';
    try {
      // response = await Auth.signIn(username);
      // response = await Auth.forgotPassword(username)
      // .then(data => console.log( 'Forgot password response', data))
      // .catch(err => console.log('forgot password error',err));
      console.log('MAIN RESPONSE', response)
    } catch (error) {
      //   const errorResData = await  error.json();
      console.log(' Error forgot password ', error);
      throw new Error(error.message)
    }
    // if(response!=''){
    // dispatch({
    //     type: FORGOT_PASSWORD,
    //     token:response.signInUserSession.idToken.jwtToken,
    //     userId:response.username
    // })

    // }
  }
}

export const confirmForgotPassword = (username, code, new_password) => {
  return async dispatch => {
    let response = '';
    try {
      // response = await Auth.signIn(username);
      // response = await Auth.forgotPasswordSubmit(username, code, new_password)
      // .then(data => console.log('confirm password response ',data))
      // .catch(err => console.log('confirm password error',err));
      console.log('MAIN RESPONSE', response)
    } catch (error) {
      //   const errorResData = await  error.json();
      console.log(' Error confirm forgot password ', error);
      throw new Error(error.message)
    }
  }
}