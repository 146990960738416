import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";

import axios from "../../axios";
import "./SafariReg_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function BasicAccommodationImages() {
  const classes = useStyles();
  const image_ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const accommodation_image_ref = useRef(null);
  const { tour_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  // let tourActionState = useSelector((state) => state.tour.tour);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
  }, [user]);

  const [accomodationImage, setAccomodationImage] = useState({
    safari: tour_id,
    accommodation_image: [],
  });

  const [accomodationImgList, setAccomodationImgList] = useState(null);

  const [imagePreview, setImagePreview] = React.useState({
    accommodation_image_change: false,
    accommodation_image: [],
  });

  function uploadMultipleImageHandler(key, e) {
    let i = 0;
    let imagePreviewArr = [];
    let imageFileArr = [];
    for(i=0;i<e.target.files.length;i++){
      imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      imageFileArr.push(e.target.files[i]);
    }
    if(imagePreviewArr.length+imagePreview['accommodation_image'].length>4){
      toast("Max 4 images allowed")
    }else{
      setImagePreview({...imagePreview,'accommodation_image':[...imagePreview['accommodation_image'], ...imagePreviewArr],'accommodation_image_change':true});
      setAccomodationImage({...accomodationImage,'accommodation_image':[...accomodationImage['accommodation_image'], ...imageFileArr]});
    }

    console.log('accommodation image upload',accomodationImage)
    e.target.value=null;
  }

  function deleteMultipleImage(key, indexitem) {
    console.log('accomodation IMAGE',accomodationImage,'delete multiple images',key,'index',indexitem)
    const imagesFilter = accomodationImage[key].filter((item, index) => index !== indexitem);
    setAccomodationImage({...accomodationImage, [key]:imagesFilter});
    let obj = {};
    obj[key + "_change"] = true;
    const imagesPreviewFilter = imagePreview[key].filter((item, index) => index !== indexitem);
    obj[key] = imagesPreviewFilter;
    setImagePreview({ ...imagePreview,...obj});
  }
  // function uploadMultipleImageHandler(key, e) {
  //   console.log("files", e.target.files);
  //   let arrImage = [];
  //   if (accomodationImage[key] != null && accomodationImage[key].length > 0) {
  //     arrImage = accomodationImage[key];
  //   } else {
  //     arrImage = [];
  //   }
  //   if (key == "accommodation_image") {
  //     let i = 0;
  //     for (i = 0; i < e.target.files.length; i++) {
  //       if (arrImage.length <= 4) {
  //         arrImage.push(e.target.files[i]);
  //       } else {
  //         // toast("Max 4 images allowed")
  //         break;
  //       }
  //       // console.log('target files',e.target.files[i])
  //     }
  //   }
  //   setAccomodationImage({ ...accomodationImage, [key]: arrImage });
  //   let obj = {};
  //   obj[key + "_change"] = true;
  //   // if(obj[key+'_change']!==obj[key])
  //   let i = 0;
  //   obj[key] = [];
  //   if (imagePreview[key] != null && imagePreview[key].length > 0) {
  //     obj[key] = imagePreview[key];
  //   } else {
  //     obj[key] = [];
  //   }
  //   for (i = 0; i < e.target.files.length; i++) {
  //     // obj[key][i] = URL.createObjectURL(e.target.files[i]);
  //     if (arrImage.length <= 4) {
  //       obj[key].push(URL.createObjectURL(e.target.files[i]));
  //     } else {
  //       // toast("Max 4 images allowed")
  //       break;
  //     }
  //   }
  //   setImagePreview({ ...imagePreview, ...obj });

  //   console.log("image preview", imagePreview);
  // }

  // function deleteMultipleImage(key, index) {
  //   let arr = accomodationImage[key];
  //   arr.splice(index, 1);
  //   setAccomodationImage({ ...accomodationImage, [key]: arr });
  //   let arrImagePreview = imagePreview[key];
  //   arrImagePreview.splice(index, 1);
  //   setImagePreview({ ...imagePreview, ...arrImagePreview });
  // }
  function submitAccommodationImages() {
    let formData = new FormData();
    formData.append("safari", accomodationImage.safari);
    if (accomodationImage.accommodation_image != null) {
      let i = 0;
      for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
        if (imagePreview.accommodation_image_change) {
          formData.append(
            `safari_image_${i}`,
            accomodationImage.accommodation_image[i]
          );
        }
      }
    }
    axios
      .post("partners/safari-image-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari registration basics create",
          res.data
        );
        history.push("/home/");
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj && Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating accomodation basics", err);
      });
  }
  const [loading, setloading] = useState(false);
  const [update, setUpdate] = useState(false);
  

  async function getFormDetails() {
    console.log("krishna is a good boy");
    await axios
      .get(`partners/safari-image-list/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        if (res.data != null && res.data.length) {
          setUpdate(true);
          // setImagePreview({ ...res.data });
          // setAccomodationImage([...res.data]);
          let arr = [];
          let i = 0;
          for (i = 0; i < res.data.length; i++) {
            arr.push(res.data[i].image);
          }
          setAccomodationImgList(res.data);
          setAccomodationImage({
            ...accomodationImage,
            accommodation_image: arr,
          });
          setImagePreview({ ...imagePreview, accommodation_image: arr });
        }
        console.log(
          "response from getting  the SAFRI IMAMGES media get",
          res.data
        );
        //setToursMedia({...res.data});
        //setImagePreview({...res.data});
      })
      .catch((err) => {
        console.log("ERROR  from getting IMAGES SAFARI ", err);
      });
  }

  function updateToursMedia() {
    let formData = new FormData();
    formData.append("safari", tour_id);
    if (accomodationImage.accommodation_image != null) {
      let i = 0;
      for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
        if (imagePreview.accommodation_image_change) {
          formData.append(
            `safari_image_${i}`,
            accomodationImage.accommodation_image[i]
          );
        }
      }
    }
    //setloading(true);
    axios
      .put(
        `partners/safari-operator-image-update-back-update`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        //setloading(false);
        history.push("/home/");
        console.log(
          "response from submitting the tours  media update",
          res.data
        );
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
  }

  console.log("thisis good", imagePreview, accomodationImage);
  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Safari / Game Drive Partner Registration: Images</h4></div>
      {/* <i>
        <span style={{ color: "red", marginRight: "1%", fontWeight: "bold" }}>
          {" "}
          *{" "}
        </span>{" "}
        Mandatory field

      </i> */}
      <div className="p-3 mb-3 bg-white">
              <p>
                <span style={{ color: "red" }}>*</span> mandatory field
              </p>
              <div className="text-left">
                <h5>Note:</h5>
                <p>
                  <ul>
                    {/* <li>
                      'Logo of Buisness' will represent your buisness in site.{" "}
                    </li>
                    <li>
                      'Banner Image' will be the main image of your property, it
                      will on top of all image.{" "}
                    </li> */}
                    <li>
                      {" "}
                      <b>'Safari Image'</b> will be additional images of your
                      Safari Business (Maximum 4 images are allowed).
                    </li>
                    <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
                    {/* <li>
                      'Video' will be short video of your property and put URL
                      in the label text.
                    </li> */}
                  </ul>
                </p>
              </div>
            </div>
      <br></br>
      <br />
      
      
      <div className="Main image">
        <div className="media_content">
          <div>
            <h5 style={{ marginBottom: "4%", display: "flex" }}>
              Additional Images
              <i
                style={{
                  marginLeft: "1%",
                  fontSize: "0.6em",
                  marginTop: "0.5%",
                }}
              ></i>
            </h5>
          </div>

          <div className="media_content_video">
            <div className="button_media videoButton ">
              <input
                className="d-none"
                type="file"
                multiple
                ref={accommodation_image_ref}
                onChange={(e) =>
                  uploadMultipleImageHandler("accommodation_image", e)
                }
              />
              <Button
                onClick={() => {
                  accommodation_image_ref.current.click();
                }}
                variant="contained"
                color="#4A8CFF"
              >
                Add Image(s)
              </Button>
              <p className="my-1 text-success">
                {" "}
                {accomodationImage.accommodation_image != null
                  ? " "
                  : null}{" "}
              </p>
            </div>
            <div className="col-sm-7">
              <div className="d-flex">
                {imagePreview["accommodation_image"] &&
                  imagePreview["accommodation_image"].map((item, index) => (
                    <div className="multiple-image">
                      <div
                        onClick={() =>
                          deleteMultipleImage("accommodation_image", index)
                        }
                        className="multiple-image-del px-1"
                      >
                        x
                      </div>
                      <a href={item} target="_blank">
                      <img
                        src={item}
                        className="img-responsive m-2 imgresp"
                        style={{ width: "100px", height: "100px" }}
                      />
                      </a>
                    </div>
                  ))}
              
              </div>
            </div>
          </div>
          {/* <p style={{ marginTop: "2%" }}>Upload your safari image(s)</p> */}
          {/* <p style={{ color: "#a7a7a7" }}>
            (Max. Allowed Images: 4 ) <br></br>Image resolution should be
            900*600 with 3:2 aspect ratio and Max. 2MB size
          </p> */}
        </div>
      </div>

      <div className="my-4 mb-2">
      {/*<Link to={"/safari-registration/basic/1"}>*/}
          <button onClick={() => history.goBack() } className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        {/*</Link>*/}
        {update ? (
          <button onClick={updateToursMedia} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button
            className="btn btn-primary mx-2"
            onClick={submitAccommodationImages}
          >
            CONTINUE
          </button>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
