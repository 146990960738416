import React, { Component, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import { useHistory } from "react-router-dom"; 
// import './home.css'
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
// import BussinessType from './Bussiness-Type/bussiness-type'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function AccomodationFormView() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [basicInfo, setBasicInfo] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [facility, setFacility] = useState(null);
  const [amenity, setAmenity] = useState(null);
  const [media, setMedia] = useState(null);
  const[logoImage,setLogoImage]=useState(null)
  const [policy, setPolicy] = useState(null);
  const [finance, setFinance] = useState(null);
  const [documents, setDocuments] = useState(null);
  const user = useSelector((state) => state.auth.user);
  let accommodationAction = useSelector(
    (state) => state.accommodation.accommodation
  );

  // console.log('accomodation Action  valeu in view form',accommodationAction)
  useEffect(() => {
    window.scrollTo(0,0);

    if (
      accommodationAction != null &&
      accommodationAction.id != undefined &&
      accommodationAction != {}
    ) {
      getFormDetails();
    }
  }, [user]);

  function getFormDetails() {
    axios
      .get(
        `partners/accommodation-basic-info-detail/${accommodationAction.id}`,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        setBasicInfo(res.data);
        console.log("accommodation basic", res.data);
      })
      .catch((err) => {
        console.log("ERROR  from creating accomodation basics", err);
      });

    axios
      .get(`partners/accommodation-detail-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setPricing(res.data);
        console.log("accommodation detail list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation type list", err);
      });

    axios
      .get(`partners/accommodation-facilities-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log("accommodation facilities list", res.data);
        setFacility(res.data[0]);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });

    axios
      .get(`partners/accommodation-amenity-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setAmenity(res.data[0]);
        console.log("accommodation-amenity-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation amenities list", err);
      });

    axios
      .get(`partners/accommodation-image-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log("accommodation-image-list", res.data);
        setMedia(res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });

    //  <....logo....>
    axios.get(`partners/accommodation-banner-logo-list/${accommodationAction.id}`,{
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
      },
    })
    .then((res) => {
      console.log("accommodation-banner-logo-list", res.data);
      setLogoImage(res.data);
    })
    .catch((err) => {
      console.log("error getting accommodation facility list", err);
    })
    // <.....................>


    axios
      .get(`partners/accommodation-policies-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setPolicy(res.data[0]);
        console.log("accommodation-policies-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation policy list", err);
      });

    axios
      .get(`partners/accommodation-finance-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setFinance(res.data[0]);
        console.log("accommodation-finance-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation finance list", err);
      });

    axios
      .get(`partners/accommodation-documents-list/${accommodationAction.id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setDocuments(res.data[0]);
        console.log("accommodation-documents-list", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation documents list", err);
      });
  }

  function stringCap(str) {
    return str.replace(/[^A-Z0-9]/gi, " ");
  }

  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function escapeRegExpPay(string) {
    return string.replace(/[^a-z A-Z]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }

  function replacePayment(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExpPay(term), "g"), replacement);
  }
  return (
    <div className="header-top-space">
      <div className="container p-3">
        <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
          Accommodation Partner View
        </h3>
        {basicInfo != null && (
          <div className="p-2 bg-white">
            {/*<h3 style={{marginBottom:'2rem'}}>Accommodation Partner View</h3>*/}
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Basic Information
            </h4>
            <div className="row">
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  Name :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.name} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  Website :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.website} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  Existing Rating On :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.existing_rating!==null?basicInfo.existing_rating: "No Rating"} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  Trip Adviser / Other Rating :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.trip_adviser_rating!==null? basicInfo.trip_adviser_rating : "No rating"} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  Property URL Trip Adviser / Other:
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.property_url_trip_adviser!==null? basicInfo.property_url_trip_adviser: "No rating" } </b> </span>
              </div>
            </div>
            <hr />
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Escalation Contact Details
            </h5>
            <div className="row">
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  {" "}
                  Email ID :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.escalation_email} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  {" "}
                  Name :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.escalation_name} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  {" "}
                  Number :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.escalation_number} </b> </span>
              </div>
              <div className="col-sm-6">
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"left"}}>
                  {" "}
                  Number Alternative :
                </p>
                <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.escalation_number_alter ? basicInfo.escalation_number_alter : "No Alternative number"} </b> </span>
              </div>
            </div>
            <hr />
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Contact Details
            </h5>
            {basicInfo.contact_detail.map((item) => (
              <div className="row">
                {Object.entries(item).map(([key, value]) => (
                  <div className="col-sm-6">
                    {(() => {
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "accommodation_id"
                      ) {
                        return (
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left",textTransform: "capitalize", }}>{key.replace('_alter', ' Alternative')+" :"}</b>
                            <b>
                              {" "}
                              {value == true || value == false ? (
                                value == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                value
                              )}{" "}
                            </b>
                          </p>
                        );
                      }
                    })()}
                  </div>
                ))}
              </div>
            ))}
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Address Details
            </h5>
            <div className="row">
              {/*<div className="col-sm-12">*/}
                
                  
                 
                                   
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'1500', width:"50%", float:"left"}}>Address Line 1 :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.address_line_1} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}>Address Line 2 :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.address_line_2?basicInfo.address_line_2:"No address"} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}>City :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.city} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}>Country :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.country} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}>Zip / Postal Code :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.zip_code} </b> </span>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightpink',color:'blue',padding:'.5rem',fontWeight:'500', width:"50%", float:"left"}}>National Park :</p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', width: "50%", float:"right"}}> <b style={{marginRight:"5%", float:"right"}}> {basicInfo.national_park} </b> </span>
                    </div>
                     
                  
                
                
              </div>
                 <br></br> <br></br>
              <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Park & Currency for Transaction
            </h5>
            <div className="row">
              {/*<div className="col-sm-12">*/}
                
                  
                 
                                   
                    
                    <div className="col-sm-6">
                    <p style={{background:'lightgray',color:'darkblue',padding:'.5rem',fontWeight:'500'}}>National Park : &nbsp;{basicInfo.national_park}</p>
                    </div>
                    <div className="col-sm-6">
                    <p style={{background:'lightgray',color:'darkblue',padding:'.5rem',fontWeight:'500'}}>Currency : &nbsp;{basicInfo.currency}</p>
                    </div>
                     
                  
                
                
              </div>


            </div>
          
          
        )}

        {pricing != null && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Pricing Details
            </h4>
            {pricing.map((item) => (
              <div>
                <div className="row my-2">
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p style={{background:'gray',color:'black',padding:'.5rem',fontWeight:'500'}} className="float-left text-white mx-2 my-1">Category</p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.category_name} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Sleep Type
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.sleeps} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">View Type</p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.view} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Type of Room
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.type_of_accommodation} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Smoking Policy
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.smoking_policy} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Guest Capacity
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.guest_capacity} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Number of Accommodation
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.no_of_room_this_type} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Price Per Accommodation (Off Season){" "}
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.price_per_accommodation_off_season} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Price Per Accommodation (On Season){" "}
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.price_per_accommodation_on_season} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Single Occupancy Supplement (On season){" "}
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b> {item.single_occupancy_supplement_on_season} </b>{" "}
                    </p>
                  </div>
                  <div className="col-sm-5 mx-3 my-2 bg-info">
                    <p className="float-left text-white mx-2 my-1">
                      Single Occupancy Supplement (Off season){" "}
                    </p>
                    <p className="float-right text-white mx-2 my-1">
                      {" "}
                      <b>
                        {" "}
                        {item.single_occupancy_supplement_off_season}{" "}
                      </b>{" "}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        )}
        {facility && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Facility Details
            </h4>
            <div className="row">
              {/*<p> {JSON.stringify(facility)} </p>*/}
              {Object.entries(facility).map(([key, item]) => (
                <>
                  {(() => {
                    if (
                      key != "id" &&
                      key != "admin_comment" &&
                      key != "approved_status" &&
                      key != "created_at" &&
                      key != "updated_at" &&
                      key != "accommodation" 
                     
                    ) {
                      return (
                <div className="col-sm-6">

                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>
                            {replaceAll(key, "_", " ")}
                          </b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                        </div>
                      );
                    }
                  })()}
                </>
              ))}
            </div>
          </div>
        )}
        {amenity && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Amenity Details
            </h4>
            <div className="row">
              {/*<p> {JSON.stringify(facility)} </p>*/}
              {Object.entries(amenity).map(([key, item]) => (
                <div className="col-sm-6">
                  {(() => {
                    if (
                      key != "id" &&
                      key != "admin_comment" &&
                      key != "approved_status" &&
                      key != "created_at" &&
                      key != "updated_at" &&
                      key != "accommodation"
                    ) {
                      return (
                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>{replaceAll(key, "_", " ")}</b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                      );
                    }
                  })()}
                </div>
              ))}
            </div>
          </div>
        )}
        {media && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Images
            </h4>
            <br></br>
            <div className="row">
              {media.map((item) => (
                <div className="col-sm-6">
                  {/* <span>Banner Image   : </span> */}
                  <a href={item.accommodation_image} target="_blank">
                    <img
                      className="img-responsive"
                      src={
                        item.accommodation_image
                          ? item.accommodation_image
                          : "/images/download2.png"
                      }
                      style={{
                        width: "250px",
                        height: "250px",
                        paddingBottom: "1rem",
                      }}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}


        {logoImage && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Logo & Banner 
            </h4>
            <br></br>
            <div className="row">
              {logoImage.map((item) => (
                <div className="col-sm-6">
                  {/* <span>Banner Image   : </span> */}
                  <h5>Logo Image</h5>
                  <a href={item.logo_image} target="_blank">
                    <img
                      className="img-responsive"
                      src={
                        item.logo_image
                          ? item.logo_image
                          : "/images/download2.png"
                      }
                      style={{
                        width: "250px",
                        height: "250px",
                        paddingBottom: "1rem",
                      }}
                    />
                  </a>
                </div>
              ))}
              {logoImage.map((item) => (
                <div className="col-sm-6">
                  {/* <span>Banner Image   : </span> */}
                  <h5>Banner Image</h5>
                  <a href={item.banner_image} target="_blank">
                    <img
                      className="img-responsive"
                      src={
                        item.banner_image
                          ? item.banner_image
                          : "/images/download2.png"
                      }
                      style={{
                        width: "250px",
                        height: "250px",
                        paddingBottom: "1rem",
                      }}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {logoImage && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
             Video URL
            </h4>
            <br></br>
            <div className="row">
              {logoImage.map((item) => (
                <div className="col-sm-6"> 
                {/* <h5>{item.accommodation_video_link}</h5> */}
                 <a href={item.accommodation_video_link} target="_blank" >Video Link</a> 
                </div>
              ))}   
            </div>
          </div>
        )}

        {policy && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Policy Details
            </h4>
            <br></br>
            <div className="row">
              {/*<p> {JSON.stringify(facility)} </p>*/}
              {Object.entries(policy).map(([key, item]) => (
                <div className="col-sm-6">
                  {(() => {
                    if (key === "document") {
                      return (
                        <div style={{ display: "flex", padding: "1rem" }}>
                          <p style={{ flex: 1 }}>Policy Document : </p>
                          <a href={item} target="_blank">
                            <img
                              src={
                                item
                                  ? "/images/download.png"
                                  : "/images/download2.png"
                              }
                              style={{ width: "5rem" }}
                            ></img>
                          </a>
                        </div>
                      );
                    } else if (
                       key != "id" &&
                      key != "admin_comment" &&
                      key != "approved_status" &&
                      key != "created_at" &&
                      key != "updated_at" &&
                      key != "accommodation"
                    ) {
                      return (
                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                            flexWrap: "wrap",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>
                            {replaceAll(key, "_", " ")}
                          </b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                      );
                    }
                  })()}
                </div>
              ))}
            </div>
          </div>
        )}
        {finance && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Finance Details 
            </h4>
            <br></br>
            <div className="row">
              {/*<p> {JSON.stringify(facility)} </p>*/}
              {Object.entries(finance).map(([key, item]) => (
                <div className="col-sm-6">
                  {(() => {
                    if (
                       key != "id" &&
                      key != "admin_comment" &&
                      key != "approved_status" &&
                      key != "created_at" &&
                      key != "updated_at" &&
                      key != "accommodation"
                    ) {
                      return (
                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>{replaceAll(key, "_", " ")}</b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                      );
                    } else if (
                      key === "payment_option"
                    ) {
                      return (
                        <p
                          style={{
                            display: "flex",
                            marginRight: "1rem",
                            background: "lightpink",
                            textAlign: "center",
                            padding: ".5rem .5rem .5rem 1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <b style={{ flex: 1, textAlign: "left" }}>{replaceAll(key, "_", " ")}</b>
                          <b>
                            {" "}
                            {item == true || item == false ? (
                              item == true ? (
                                <CheckBoxIcon style={{ color: "green" }} />
                              ) : (
                                <ClearIcon style={{ color: "red" }} />
                              )
                            ) : (
                              item
                            )}{" "}
                          </b>
                        </p>
                      );
                    }

                  })()}
                </div>
              ))}
            </div>
          </div>
        )}

        {documents && (
          <div className="p-2 mt-3 bg-white">
            <h4
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Documents
            </h4>
            <div className="row">
              {/*<p> {JSON.stringify(facility)} </p>*/}
              {Object.entries(documents).map(([key, item]) => (
                <div className="col-sm-6">
                  {( key == "cin" ||
                     key == "gstin" ||
                     key == "mom_aoa" ||
                     key == "other_document" ||
                     key == "pan" ||
                     key == "reg_certificate") && (
                    <div style={{background: "lightpink",margin:"10px"}}>
                    <h5>{replaceAll(key,"_"," ")}</h5>
                      <a href={item} target="_blank">
                        {" "}
                        <img
                          src={
                            item &&
                            (item.slice(item.length - 3, item.length) == "pdf"
                              ? "/images/download.png"
                              : item.slice(item.length - 3, item.length) ==
                                  "jpg" ||
                                item.slice(item.length - 3, item.length) ==
                                  "png"
                              ? item
                              : item)
                          }
                          
                          style={{ width: "8rem",height:"8rem", padding: "1rem" }}
                        ></img>{" "}
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
            <NavLink to={'/home/accommodation'}>
                <button class="btn btn-primary">BACK</button>
                </NavLink>
            </div>
      </div>
    </div>
  );
}
