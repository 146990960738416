import React, { Component,useState,useEffect } from 'react';
import axios from '../../axios';
import { Link, NavLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as authActions from '../../store/actions/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function MobileNumber() {
  const classes = useStyles();
  const [email,setEmail] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
//   const user = useSelector(state => state.auth.user);
  
//   useEffect(()=>{
//     requestMobileOTP()
//     //   console.log('this is the user response from use selector',user,'user Token');
//   })

  const user = useSelector((state) => state.auth.user);
  // console.log('this. is the NEW USERA TTTIFBUEST S',userAttributesRes)
  // setUserAttributes(userAttributesRes);
  useEffect(()=>{
      
    console.log('inside LOGIN User attributes',user);
  },[user])

//   function requestMobileOTP(){
//       axios.post('auth/resend/otp/phone',{
//         headers:{
//             'Authorization':'Token '+user.token
//         }
//     }).then(res=>{
//         console.log('response from get mobile otp',res.data);
//     })
//   }

  async function addMobileNumber(){
    axios.post('auth/add-phone-number',{
        phone_number:phoneNumber,
        country_code:countryCode
    },{
        headers:{
            'Authorization':'Token '+user.token
        }
    }).then(res=>{
      history.push('/mobile-verification')
        console.log('response from otp verification',res.data);
    }).catch(err=>{
      console.log('error in verification',err)
    })
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <div className="d-flex justify-content-center   h-100 w-100 bg-light" >
        <div className="p-2 border border-primary authCard align-self-center" >
          <CssBaseline />
          <div className={classes.paper}>

            <div className="image_logo" >
              <img className="image_logo" src="images/logo/Mask Group 4.png" />
            </div>

            <div className="head">

             Add Mobile Number
          </div>
            <form className={classes.form} noValidate>
              <Grid container spacing={0.5}>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setCountryCode(e.target.value)}
                    id="countryCode"
                    label="Country Code"
                    name="CountryCode"
                  />
                </Grid>
                <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setPhoneNumber(e.target.value)}
                    name="Phone Number"
                    label="Phone Number"
                    type="text"
                    id="PhoneNumber"
                  />
                </Grid>
                <Grid item xs={11}>

                  <Button className="but"
                    fullWidth
                    onClick={addMobileNumber}
                    variant="contained"
                    color="primary"
                  >
                    Submit
            </Button>
                </Grid>

                <div justify="right">
                  <Link to="/signup">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </div>


                <p item className="fg2" >
                  <small>By using our service you agree with our
             <strong>terms and conditions</strong>
                  </small>
                </p>
              </Grid>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}