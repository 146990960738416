import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import I2 from "./assests/I2.png";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

import { Input } from "antd";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;
function Safari_policy() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tour_id } = useParams();
  const document_ref = useRef(null);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
  }, [user]);

  const [policy, setPolicy] = useState({
    tour_package: tour_id,
    cancellation: null,
    document: null,
    customers_review: null,
  });

  const [updateForm, setUpdateForm] = useState(false);
  const [uploadingMedia, setuploadingMedia] = useState(false);

  const [imagePreview, setImagePreview] = React.useState({
    document_change: false,
    document: null,
  });

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  function getFormDetails() {
    axios
      .get(`partners/tour-policy-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package basics get",
          res.data
        );
        if (res.data != null) {
          setUpdateForm(true);
        }
        setPolicy({ ...policy, ...res.data });
        setImagePreview({ ...imagePreview, document: res.data.document });
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package basics", err);
      });
  }

  const handleChange = (event) => {
    setPolicy({ ...policy, [event.target.name]: event.target.value });
  };
  const [extension, setextension] = useState(null);

  function uploadFileHandler(key, e) {
    setPolicy({ ...policy, [key]: e.target.files[0] });
    if (e.target.files[0] != null) {
      setextension(e.target.files[0].name.split(".").pop());
    }
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setPolicy({ ...policy, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  function submitPolicy() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(policy)) {
      console.log('key',key,'value',value)
      if (key != "document") {
        formData.append(key, value);
      } else {
        if (value != null) {
          formData.append(key, value);
          setError2(false);
        } else {
          setError2({ message: key + "is Mandatory!" });
        }
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/tour-policy-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package policy create",
          res.data
        );
        setuploadingMedia(false);
        history.push("/tour-package/accommodation-images/8/" + tour_id);
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating tour package policy", err);
      });
  }

  function updatePolicy() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(policy)) {
      if (key != "document") {
        formData.append(key, value);
      } else {
        if (value != null && imagePreview[key + "_change"]) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMedia(true);
    axios
      .put(`partners/tour-package-policy-back-update/${policy.id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package basics update",
          res.data
        );
        setuploadingMedia(false);
        history.push("/tour-package/accommodation-images/8/" + tour_id);
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from update accomodation basics", err);
      });
  }

  return (
    <div className="Tour_policies">
      <h3 style={{ display: "flex", color: "#949494" }}>Tour Package: Policies</h3>

      <br />
      <div className="text-left">
      <div className="p-3 mb-3 bg-white" >
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div>
          <h5>Note:</h5>
          <p>
          <ul>
              
              <li><u>It is recommended to provide all informations.</u> </li>
                <li><b>Cancellation:</b> It is recommended to provide slab as <b>'Days Prior
                Vs Percentile of Loss' </b> in slabs such as <br></br> <b>30 days prior / 15 to 30
                days / 7 to 15 days / less than 7 days </b> and mention the
                percentile of loss against each one of them.{" "}</li>
                <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) wherever used. </b> </li>
              
              <li>
                Your Own Policy Document: You may upload detail policy in pdf
                (Max 2MB Size).{" "}
              </li>
              <li>
                {" "}
                Customer Review: Format must be as - Name, Review Text and One
                line space between two reviews.{" "}
              </li>
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>
      </div>
      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>Cancellation Policy</p>
          <TextArea
            value={policy.cancellation!=='null' ? policy.cancellation: ''}
            onChange={handleChange}
            name="cancellation"
            rows={6}
          />
        </form>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <div className="Policies_document_main">
          {/* <img src={I2}></img> */}
          <h6>Policy Document (If Any) </h6>
          {/* <p style={{ color: "#a7a7a7" }}>
            (image resolution should be 900*600 with 3:2 aspect ratio and max
            2MB size)
          </p> */}
        </div>
        <input
          className="d-none"
          type="file"
          ref={document_ref}
          onChange={(e) => uploadFileHandler("document", e)}
        />
        <Button
          variant="contained"
          onClick={() => {
            document_ref.current.click();
          }}
          className="Policies_button"
          color="primary"
        >
          Upload file
        </Button>
        {error2 && (
          <Box p={1} style={{ color: "red", background: "lightpink" }}>
            {error2.message}
          </Box>
        )}
        {/*<p className="my-1 text-success"> {policy.document != null ? 'Image Selected' : null} </p>*/}
        <div className="multiple-image">
          <div
            onClick={() => delUploadFileHandler("document")}
            className="multiple-image-del px-1"
          >
            x
          </div>
          {/*<img src={imagePreview.document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          {(() => {
            //var fileName = imagePreview.document;
            //var fileExtension = fileName.split('.').pop();
            if (
              extension == "pdf" ||
              (policy.document != null &&
                imagePreview.document != null &&
                imagePreview.document.split(".").pop() === "pdf" &&
                policy.document.split(".").pop()) == "pdf"
            ) {
              return (
                <>
                  <p className="my-1 text-success">
                    {" "}
                    {policy.document != null
                      ? `File-->${policy.document.name} Selected`
                      : null}{" "}
                  </p>
                  <a href={imagePreview.document} target="_blank">
                    {imagePreview.document != null ? (
                      <img
                        src="/images/download.png"
                        className="img-responsive m-2"
                        style={{ width: "50px", height: "30px" }}
                      />
                    ) : (
                      <img
                        src="/images/download2.png"
                        alt="No file"
                        style={{
                          width: "50px",
                          height: "40px",
                          paddingRight: "10px",
                        }}
                      ></img>
                    )}
                  </a>
                </>
              );
            } else {
              return (
                <>
                  <p className="my-1 text-success">
                    {" "}
                    {policy.document != null
                      ? `Image --> ${policy.document.name}  Selected`
                      : null}{" "}
                  </p>
                  <a href={imagePreview.document} target="_blank">
                    <img
                      src={
                        imagePreview.document
                          ? imagePreview.document
                          : "/images/download2.png"
                      }
                      className="img-responsive m-2"
                      style={{ width: "60px", height: "40px" }}
                    />
                  </a>
                </>
              );
            }
          })()}
        </div>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>Customer Review / Feedback (if any)</p>

          <TextArea
            value={policy.customers_review!=='null' ? policy.customers_review : ''}
            onChange={handleChange}
            name="customers_review"
            rows={6}
            placeholder="Review Text, name of the customer, date (each review in new line)"
          />
        </form>
      </div>

      <div className="text-center my-4 mb-2">
        <Link to={"/tour-package/tour-guide/6/" + tour_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {updateForm ? (
          <button onClick={updatePolicy} className="btn btn-primary mx-2" disabled={uploadingMedia}>
            {uploadingMedia ? "UPLOADING..." : 'UPDATE'}
          </button>
        ) : (
          <button onClick={submitPolicy} className="btn btn-primary mx-2" disabled={uploadingMedia}>
            {uploadingMedia ? "UPLOADING..." : 'CONTINUE'}
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_policy;
