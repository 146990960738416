import { SETLOGISTICID, FLEETID, REMOVEFLEETID } from "../actions/logistics"

const initialState = {
    logistic_package_id:null,
    fleet_id:null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SETLOGISTICID:
            return{
                logistic_package_id:action.logistic_package_id
            };
        case FLEETID:
            return{
                fleet_id:action.fleet_id
            }
        case REMOVEFLEETID:
            return{
                fleet_id:null
            }
        default:
            return state
    }
}