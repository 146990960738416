import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
// import * as authActions from '../../store/actions/auth';
import AccomodationBasic from './accomodation_basic';
import AccomodationType from './accomodation_type';
import AccomodationPricing from './accomodation_pricing';
import AccomodationFacility from './accomodation_facility';
import AccomodationAmenities from './accomodation_amenities';
import AccomodationMedia from './accomodation_media';
import AccomodationPolicy from './accomodation_policy';
import AccomodationFinance from './accomodation_finance';
import AccomodationDocuments from './accomodation_documents';
import AccomodationSubmit from './accomodation_submit';
import AccomodationFormView from './accomodation_form_view';
import accommodation_admin_form from './accommodation_admin_form';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Accomodation() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    let accommodationAction = useSelector((state) => state.accommodation.accommodation);
    if (accommodationAction == null) {
        accommodationAction = {}
        accommodationAction['id'] = 0;
    }

    useEffect(() => {
        window.scrollTo(0,0);

        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

    
    return (
        <div className="header-top-space">
            <div className="d-sm-block mb-3  d-none">
                <div className="d-flex   justify-content-around step-form">
                    <div   style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Basic Information</p>
                    </div>
                    <div  style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Acco. & Pricing</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Facility & Service</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Amenities</p>
                    </div>
                    
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 5) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 5) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 5</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Policy</p>

                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 6) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 6) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 6</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Finance</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 7) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 7) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 7</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Documents</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 8) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 8) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 8</span>
                            </div>
                        </div>
                        <p className="m-auto" style={{width:'fit-content'}}>Image & Video</p>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path="/accomodation/basic/1" exact component={AccomodationBasic} />
                <Route path="/accomodation/pricing/2/:accomodation_id" exact component={AccomodationPricing} />
                <Route path="/accomodation/facility/3/:accomodation_id" exact component={AccomodationFacility} />
                <Route path="/accomodation/amenities/4/:accomodation_id" exact component={AccomodationAmenities} />
                
                <Route path="/accomodation/policy/5/:accomodation_id" exact component={AccomodationPolicy} />
                <Route path="/accomodation/finance/6/:accomodation_id" exact component={AccomodationFinance} />
                <Route path="/accomodation/documents/7/:accomodation_id" exact component={AccomodationDocuments} />
                <Route path="/accomodation/media/8/:accomodation_id" exact component={AccomodationMedia} />
                <Route path="/accomodation/view-form/:accommodation_id" exact component={AccomodationFormView} />
                <Route path='/accomodation/admin-comment-form/:id' exact component={accommodation_admin_form}></Route>
                
                {/* <Route path="/accomodation/submit/:accomodation_id" exact component={AccomodationSubmit}/> */}
            </Switch>
        </div>
    );
}