import React, { Component, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import I1 from "./template.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./accom_policy.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { RadioGroup } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import 'date-fns';
// import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#009688",
    },
  },
});
export default function AccomodationPolicy() {
  const classes = useStyles();
  const document_ref = useRef(null);

  const { accomodation_id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [update, setUpdate] = React.useState(false);
  const [uploadingMedia, setUploadingMedia] = React.useState(false);

  const [Plug, setPlug] = useState("");

  const [policy, setPolicy] = React.useState({
    accommodation: accomodation_id,
    cancellation: '',
    cancel_charge: false,
    check_in_from: false,
    check_in_to: false,
    check_out_from: false,
    check_out_to: false,
    early_check_allowed: false,
    pet_allowed: false,
    children_allowed: false,
    document: null,
    customers_review: '',
  });

  const [imagePreview, setImagePreview] = React.useState({
    document_change: false,
    document: null,
  });

  const doc = useRef(null);
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);
    console.log('this is googd',doc.current.scrollTop)
    // window.document.body.scrollTop=0;
    // window.document.documentElement.scrollTop = 0;
    //console.log(window.document.body.scrollTop)
    getAccommodationPolicy();
  }, [user]);
  const handlePlug = (event) => {
    setPlug(event.target.value);
  };

  function getAccommodationPolicy() {
    axios
      .get(`partners/accommodation-policies-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          // setFacility(res.data[0]);
          setPolicy(res.data[0]);
          let imgobj = {};
          imgobj.document = res.data[0].document;
          setImagePreview({ ...imagePreview, ...imgobj });
        } else {
          setUpdate(false);
        }
        console.log("response from get policy", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation facility list", err);
      });
  }

  const handleChange = (event) => {
    setPolicy({ ...policy, [event.target.name]: event.target.value });
  };
  const [extension, setextension] = useState(null);
  function uploadDocumentFileHandler(e) {
    setPolicy({ ...policy, document: e.target.files[0] });
    if (e.target.files[0] != null) {
      setextension(e.target.files[0].name.split(".").pop());
    }
    let obj = {};
    obj["document_change"] = true;
    obj["document"] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
  }

  function submitPolicyMedia() {
    let formData = new FormData();
    console.log("policy befor submit", policy);
    formData.append("accommodation", policy.accommodation);
    formData.append("cancellation", policy.cancellation);
    formData.append("cancel_charge", policy.cancel_charge);
    formData.append("check_in_from", policy.check_in_from);
    formData.append("check_in_to", policy.check_in_to);
    formData.append("check_out_from", policy.check_out_from);
    formData.append("check_out_to", policy.check_out_to);
    formData.append("early_check_allowed", policy.early_check_allowed);
    formData.append("pet_allowed", policy.pet_allowed);
    formData.append("children_allowed", policy.children_allowed);
    formData.append("customers_review", policy.customers_review);

    if (imagePreview.document_change) {
      formData.append("document", policy.document);
    }
    setUploadingMedia(true);
    axios
      .post("partners/accommodation-policies-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setUploadingMedia(false);
        console.log(
          "response from submitting the accomodation policy create",
          res.data
        );
        history.push("/accomodation/finance/6/" + accomodation_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        setUploadingMedia(false);
        console.log("error from creating accomodation media", err);
      });
  }

  function updatePolicyMedia() {
    let formData = new FormData();
    formData.append("accommodation", policy.accommodation);
    formData.append("cancellation", policy.cancellation);
    formData.append("cancel_charge", policy.cancel_charge);
    formData.append("check_in_from", policy.check_in_from);
    formData.append("check_in_to", policy.check_in_to);
    formData.append("check_out_from", policy.check_out_from);
    formData.append("check_out_to", policy.check_out_to);
    formData.append("early_check_allowed", policy.early_check_allowed);
    formData.append("pet_allowed", policy.pet_allowed);
    formData.append("children_allowed", policy.children_allowed);
    formData.append("customers_review", policy.customers_review);
    if (imagePreview.document_change) {
      formData.append("document", policy.document);
    }
    setUploadingMedia(true);
    axios
      .put(
        `partners/accommodation-policies-back-update/${policy.id}`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        setUploadingMedia(false);
        console.log(
          "response from submitting the accomodation policy UPDATE",
          res.data
        );
        history.push("/accomodation/finance/6/" + accomodation_id);
      })
      .catch((err) => {
        setUploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("error from creating accomodation media", err);
      });
  }

  return (
    <div className="container text-left" ref={doc}>
      <div className="container text-left">
        <br></br>
        <h3 style={{ color: "#818181", marginBottom: "1%" }}>
          Accommodation: Policy
        </h3>
      </div>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div>
          <h6>Note:</h6>
          <p>
            <ul>
              
              <li><u>It is recommended to provide all informations.</u> </li>
                <li><b>Cancellation:</b> It is recommended to provide slab as <b>'Days Prior
                Vs Percentile of Loss' </b> in slabs such as <br></br> <b>30 days prior / 15 to 30
                days / 7 to 15 days / less than 7 days </b> and mention the
                percentile of loss against each one of them.{" "}</li>
                <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) wherever used. </b> </li>
              
              <li>
                Your Own Policy Document: You may upload detail policy in pdf
                (Max 2MB Size).{" "}
              </li>
              <li>
                {" "}
                Customer Review: Format must be as - Name, Review Text and One
                line space between two reviews.{" "}
              </li>
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>

      <br></br>
      <div className="bg-white  mb-3 p-3">
        <h4 style={{ color: "#838383" }}>Cancellation </h4>
        <p>
          {" "}
          How many days in advance can guest cancel and what is the charge (Inclusive of local tax)?
        </p>
        <TextField
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          name="cancellation"
          color="primary"
          value={policy.cancellation}
          onChange={handleChange}
        />
        {/* <p> please give slab as 30 days prior/ 15 to 30 days/ 7 to 15 days/ less than 7 days and mention the percentile of loss against each one of them</p> */}
      </div>

      <div className="bg-white mb-3 p-2">
        <div className="bg-form-blue p-2 m-1 ">
          <h4>
            {" "}
            Check In (local time) <span style={{ color: "red" }}> * </span>{" "}
          </h4>
          <form className={classes.container} noValidate>
            
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
              id="time"
              name="check_in_from"
              color="primary"

              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              
              // format="MM/dd/yyyy"
              margin="normal"
              // id="date-picker-inline"
              value={policy.check_in_from}
              onChange={handleChange}
              // name="start_date"
              // value={selectedDate}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider> */}
            
            
            
            
            
            <div>
              <br></br>
              <label className="mb-0">From</label>
              <div className="field">
                <TextField
                  id="time"
                  name="check_in_from"
                  color="primary"
                  value={policy.check_in_from}
                  onChange={handleChange}
                  type="time"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="mx-2">
              <br></br>
              <label className="mb-0">To</label>
              <div className="field">
                <TextField
                  id="time"
                  name="check_in_to"
                  color="primary"
                  value={policy.check_in_to}
                  onChange={handleChange}
                  type="time"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <br></br>
        <div className="bg-form-blue p-2 m-1 mb-4 ">
          <h4>
            {" "}
            Check Out (local time) <span style={{ color: "red" }}> * </span>{" "}
          </h4>
          <form className={classes.container} noValidate>
            <div>
              <br></br>
              <label className="mb-0">From</label>

              <div className="field">
                <TextField
                id="time"
                  name="check_out_from"
                  color="primary"
                  value={policy.check_out_from}
                  onChange={handleChange}
                  type="time"
                  defaultValue="07:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="mx-2">
              <br></br>
              <label className="mb-0">To</label>
              <div className="field">
                <TextField
                id="time"
                  name="check_out_to"
                  color="primary"
                  value={policy.check_out_to}
                  onChange={handleChange}
                  type="time"
                  defaultValue="07:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="bg-white mb-3 p-3">
        <div className="d-flex bg-form-blue py-2">
          <div className="p-1">Is Early Check in and Check out allowed ?</div>
          <div className="p-1 flex-fill my-auto ml-auto">
            <ThemeProvider theme={outerTheme}>
              <RadioGroup
                row
                onChange={handleChange}
                name="early_check_allowed"
                value={policy.early_check_allowed}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="On request"
                  control={<Radio />}
                  label="On Request"
                />
              </RadioGroup>
            </ThemeProvider>
          </div>
        </div>
      </div>

      <div className="bg-white mb-3 p-3">
        <div className="d-flex bg-form-blue py-2">
          <div className="p-1">Is Pet Allowed ?</div>
          <div className="p-1 flex-fill my-auto ml-auto">
            <ThemeProvider theme={outerTheme}>
              <RadioGroup
                row
                onChange={handleChange}
                name="pet_allowed"
                value={policy.pet_allowed}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="On request"
                  control={<Radio />}
                  label="On Request"
                />
              </RadioGroup>
            </ThemeProvider>
          </div>
        </div>
      </div>

      <div className="bg-white mb-3 p-3">
        <div className="d-flex p-2">
          <div className="p-2">
            {/* <img className="images_logo" src={I1} style={{ marginRight: "1%" }}></img> */}

            <h6>Policy Document (If any) </h6>
          </div>
          <div className="ml-auto p-2">
            <div className="button_media" style={{ marginLeft: "2%" }}>
              {/*<img src={imagePreview.document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
              {(() => {
                //var fileName = imagePreview.document;
                //var fileExtension = fileName.split('.').pop();
                if (
                  extension == "pdf" ||
                  (policy.document != null &&
                    imagePreview.document != null &&
                    imagePreview.document.split(".").pop() === "pdf" &&
                    policy.document.split(".").pop()) == "pdf"
                ) {
                  return (
                    <>
                      <p className="my-1 text-success">
                        {" "}
                        {policy.document != null
                          ? `File-->${
                            policy.document.name && policy.document.name.split(".")[0]
                            } Selected`
                          : null}{" "}
                      </p>
                      <a href={imagePreview.document} target="_blank">
                        {imagePreview.document != null ? (
                          <img
                            src="/images/download.png"
                            className="img-responsive m-2"
                            style={{ width: "50px", height: "30px" }}
                          />
                        ) : (
                          <img
                            src="/images/download2.png"
                            alt="No file"
                            style={{
                              width: "50px",
                              height: "40px",
                              paddingRight: "10px",
                            }}
                          ></img>
                        )}
                      </a>
                    </>
                  );
                } else {
                  return (
                    <>
                      <p className="my-1 text-success">
                        {" "}
                        {policy.document != null
                          ? `Image --> ${
                              policy.document.name?policy.document.name.split(".")[0]:'Random'
                            }  Selected`
                          : null}{" "}
                      </p>
                      <a href={imagePreview.document} target="_blank">
                        <img
                          src={
                            imagePreview.document
                              ? imagePreview.document
                              : "/images/download2.png"
                          }
                          className="img-responsive m-2"
                          style={{ width: "60px", height: "40px" }}
                        />
                      </a>
                    </>
                  );
                }
              })()}
              <input
                className="d-none"
                type="file"
                ref={document_ref}
                onChange={uploadDocumentFileHandler}
              />
              <Button
                variant="contained"
                onClick={() => {
                  document_ref.current.click();
                }}
                color="#4A8CFF"
              >
                Upload file
              </Button>
              {/*<p className="my-1 text-success"> {policy.document != null ? 'File Selected' : null} </p>*/}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mb-3 p-3">
        <div className=" p-2">
          <h6 style={{ color: "#838383", marginRight: "1rem" }}>
            Customer Review{" "}
          </h6>

          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            name="customers_review"
            color="primary"
            placeholder="Review Text, name of the customer, date (each review in new line)"
            value={policy.customers_review}
            onChange={handleChange}
          />
        </div>
      </div>
      <div></div>

      <div className="text-center my-4 mb-2">
        {/* <Link to={"/accomodation/media/5/" + accomodation_id}> */}
          <button onClick = {() => history.goBack()} className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        {/* </Link> */}
        {
          update ? (
            <button
              onClick={updatePolicyMedia}
              className="btn btn-primary mx-2"
              disabled={uploadingMedia}
            >
              {uploadingMedia ? "UPDATING..." : "UPDATE"}
            </button>
          ) : (
            <button
              onClick={submitPolicyMedia}
              className="btn btn-primary mx-2"
              disabled={uploadingMedia}
            >
              {uploadingMedia ? "UPLOADING..." : "CONTINUE"}
            </button>
          )
          // <button onClick={submitAccomodationMedia} className="btn btn-primary mx-2">CONTINUE</button>
        }
      </div>
      <br></br>
      <br></br>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
