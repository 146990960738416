import React, { Component, Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import "../home.css";
import { Link, NavLink } from "react-router-dom";
import "./safaripackage.css"
import Cookies from 'universal-cookie';

import * as safariActions from "../../store/actions/safari";

const cookies = new Cookies();

export default function SafariPackage() {
  const [email, setEmail] = useState(null);
  const [accommodationList, setAccommodationList] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  let safari_operator_id = null;

  const user = useSelector((state) => state.auth.user);
  let safari_id = useSelector((state) => state.safari.safari_id);

  //   console.log('accommodation action',accommodationAction);

  useEffect(() => {
    getAccomodationBasicsList();
  }, [user]);

  // if (!user) {
  //   history.push("/login");
  // }
  if(!cookies.get('token'))
    history.push('/login')

  function getAccomodationBasicsList() {
    axios
      .get(`partners/safari-vehicle-list`, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((res) => {
        setAccommodationList(res.data);
        console.log(
          "response from get accomodation SAFARI PACKAGES basic",
          res.data
        );
        if (res.data && res.data.length) {
          safari_operator_id = res.data.safari;
        }
      })
      .catch((err) => {
        console.log("error getting accommodation basic list", err);
      });
  }

  function statusBgColor(status) {
    if (status == "Getting Reviewed") {
      return "yellow";
    } else if (status == "Incomplete") {
      return "lightblue";
    } else if (status == "Published") {
      return "green";
    } else if (status == "Approval Denied") {
      return "red";
    }
  }

  function createSafariOperator() {
    console.log("safari operator id", registration.safari_operator_id);
    //if(registration.safari_operator_id){
    // console.log('safari operator id',registration.safari_operator_id);
    history.push("/safari-registration/basic/1");
    //}
  }

  function addAccommodation() {
    let id = null;
    try {
      dispatch(safariActions.safariid(id));
      history.push(
        "/safari-package/vehicle-parking/1/" + registration.safari_operator_id
      );
    } catch (err) {
      console.log("error safari actions", err);
    }
    // try {
    //   // dispatch(accommodationActions.accommodation(item));

    //   history.push(
    //     "/safari-package/vehicle-parking/1/" + registration.safari_operator_id
    //   );
    // } catch (err) {
    //   console.log("error accomodation actions", err);
    // }
  }

  function editAccommodation(item, safari_id) {
    try {
      dispatch(safariActions.safariid(item.id));
      history.push(`/safari-package/vehicle-parking/1/${safari_id}`);
    } catch (err) {
      console.log("error safari actions", err);
    }
  }

  function viewAccommodation(item) {
    try {
      dispatch(accommodationActions.accommodation(item));
      history.push("/accomodation-view-form/" + item.id);
    } catch (err) {
      console.log("error logging into signin actions", err);
    }
  }

  const [registration, setRegistration] = useState(null);
  const [inComplete, setInComplete] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [denied, setDenied] = useState(false);
  const [published, setPublished] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    const getRegistrationData = async () => {
      await axios
        .get(`partners/partner-tour-safari-detail`, {
          headers: {
            // Authorization: "Token " + user.token,
            Authorization: "Token " + cookies.get('token'),
        },
        })
        .then((resp, err) => {
          if (err) {
            console.log("error in registration data", err);
          } else {
            console.log("data on registration data", resp.data);
            setRegistration(resp.data);
            if (resp.data) {
              if (resp.data.safari_registration_status == "Incomplete"|| resp.data.safari_registration_status ==
              "Approved and Edit Incomplete" ) {
                setInComplete(true);
              } else if (
                resp.data.safari_registration_status == "Getting Reviewed" ||
                resp.data.safari_registration_status ==
                  "Approved and Edit Getting Reviewed" ||
                resp.data.safari_registration_status ==
                  "Rejected and Edit Getting Reviewed"
              ) {
                setReviewed(true);
              } else if (
                resp.data.safari_registration_status == "Approval Denied"
              ) {
                setDenied(true);
              } else if (resp.data.safari_registration_status == "Published") {
                setPublished(true);
              } else if (
                resp.data.tour_registration_status == "Approved"
              ) {
                setApproved(true);
              }
            }
          }
        });
    };
    getRegistrationData();
  }, [user]);

  function unPublishPartnerHandler(unPublishId) {
    axios
      .put(`partners/safari-unpublish/${unPublishId}`, {}, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("Error in Unpublishing accomdation package ", err);
        } else {
          console.log(
            "after Puvlishingi the acoomoadatin package ",
            response.data
          );
          alert('Successfully Sent Request To Admin')
        }
      });
  }
  function unPublishPackageHandler(unPublishId) {
    axios
      .put(`partners/safari-vehicle-unpublish/${unPublishId}`, {}, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("Error in Unpublishing accomdation package ", err);
        } else {
          console.log(
            "after Puvlishingi the acoomoadatin package ",
            response.data
          );
          alert('Successfully Sent Request To Admin')
        }
      });
  }

  return (
    <div>
      <div
        className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white"
        style={{ position: "relative" }}
      >
        {console.log("check", registration)}
        <span>
          {" "}
          <img
            src={
              registration && registration.safari_operator_image
                ? `${registration.safari_operator_image}`
                : "/images/image.png"
            }
            style={{ width: "9rem", borderRadius: "50%", height: "100%" }}
          />
        </span>
        <span className="tour-name">
          {" "}
          <p style={{ font: "normal normal normal 28px/34px Lato" }}>
            {registration && registration.safari_operator_name
              ? registration.safari_operator_name
              : "Welcome Safari / Game Drive Partner"}
          </p>
          <p>
            {registration && registration.safari_operator_id
              ? ""
              : 'To register as "Safari / Game Drive Partner", proceed by clicking button below.'}
          </p>
          <p
            style={{
              background: "linear-gradient(to right top, #65dfc9, #0fe63f)",
              WebkitBackgroundClip: "text",
              fontWeight: "bold",
              WebkitTextFillColor: "transparent",
              marginBottom: "0",
            }}
          >
            Status :{" "}
            {registration && registration.safari_registration_status
              ? registration.safari_registration_status
              : " Not Registered"}
          </p>
          {denied && (
            <p style={{ marginTop: "0", fontSize: "12px" }}>
              (refer Admin Comment for details)
            </p>
          )}
        </span>
        <br />
        {/*<div className="tour-btn">*/}
        <div
          className="text-right mt-auto bg-yellow"
          style={{  right: "0", bottom: "1rem" }}
        >
          {inComplete && (
            <button
              onClick={() => createSafariOperator()}
              className="btn btn-primary mx-2"
            >
              {" "}
              Complete Profile
            </button>
          )}
          {reviewed && (
            <Link to={`/safari-view-form/${registration.safari_operator_id}`}>
              <button className="btn btn-primary mx-2"> View Profile</button>
            </Link>
          )}
          {denied && (
            <>
              <Link to={`/safari-view-form/${registration.safari_operator_id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <Link
                to={`/safari-registration/basic/1/${registration.safari_operator_id}`}
              >
                <button className="btn btn-primary mx-2">
                  {" "}
                  Edit & Re-Submit
                </button>
              </Link>
              <Link
                to={`/admin-comment-safariReg-view-form/${registration.safari_operator_id}`}
              >
                <button className="btn btn-primary mx-2"> Admin Comment</button>
              </Link>
            </>
          )}
          {published && (
            <>
              <Link
                to={`/safari-registration/basic/1/${registration.safari_operator_id}`}
              >
                <button className="btn btn-primary mx-2"> Edit Profile</button>
              </Link>
              <Link to={`/safari-view-form/${registration.safari_operator_id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <button
                onClick={() =>
                  unPublishPartnerHandler(registration.safari_operator_id)
                }
                className="btn btn-primary mx-2"
              >
                {" "}
                UnPublish
              </button>
            </>
          )}
          {approved && (
            <>
              <Link
                to={`/safari-registration/basic/1/${registration.safari_operator_id}`}
              >
                <button className="btn btn-primary mx-2"> Edit Profile</button>
              </Link>
              <Link to={`/safari-view-form/${registration.safari_operator_id}`}>
                <button className="btn btn-primary mx-2"> View Profile</button>
              </Link>
              <button
                onClick={() =>
                  unPublishPartnerHandler(registration.safari_operator_id)
                }
                className="btn btn-primary mx-2"
              >
                {" "}
                UnPublish
              </button>
            </>
          )}
          {
            //<button className="btn btn-primary mx-2" disabled={!accommodationList}> View Profile</button>
            //<button className="btn btn-primary mx-2" disabled={!accommodationList}> Edit Profile</button>
          }
        </div>
        {/*</div>*/}
      </div>
      <div className="d-flex p-2 justify-content-between">
        <div>
          <h4>Safari / Game Drive Packages</h4>
        </div>

        <div>
          {/*
              <Link to={`/safari-registration/basic/1`}>
                <button  className="btn btn-primary" >Create Safari Partner</button>
              </Link>
          <Link to={`/tour-registration/basic/1`} >
          <button  className="btn btn-primary" >Edit Safari Partner</button>
              </Link>*/}
          {/* <button onClick={()=>addAccommodation()} className="btn btn-primary" >Add Tour Package</button> */}
        </div>

        {
          //(user&&!user.safari_registration)
          registration &&
            (registration != null && !registration.safari_operator_id ? (
              <div className="ml-auto">
                {/* <Link to="/accomodation/type/1"> */}
                <button
                  onClick={() => createSafariOperator()}
                  className="btn btn-primary"
                  hidden={inComplete || reviewed || denied || published}
                >
                  Register as Partner
                </button>
                {/* </Link> */}
              </div>
            ) : (
              <div className="ml-auto">
                {/* <Link to="/accomodation/type/1"> */}
                <button
                  onClick={() => addAccommodation()}
                  className="btn btn-primary"
                  hidden={inComplete || denied}
                >
                  Add Safari Package
                </button>
                {/* </Link> */}
              </div>
            ))
        }
      </div>
      <div>
        {accommodationList &&
          accommodationList.map((item) => (
            <div className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white">
              <div
                className="accommodation-list-item-tag d-flex"
                style={{ backgroundColor: statusBgColor(item.status) }}
              >
                <p className="m-auto">{item.status}</p>
              </div>
              <div>
                <img
                  className="img-responsive"
                  src="/images/assets/dummy-home.png"
                  style={{
                    width: "300px",
                    height: "300px",
                    backgroundColor: "green",
                  }}
                />
              </div>
              <div className="p-2 px-3 d-flex align-item-strech justify-content-between w-100">
                <div className="text-left w-50 overflow-auto widthmediaquery">
                  <h4
                    className="accommodation-home-title"
                    style={{
                      background: "lightpink",
                      color: "black",
                      padding: ".5rem",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {item.safari_title}{" "}
                  </h4>
                  {item.national_park && (
                    <p
                      style={{
                        background: "lightpink",
                        color: "black",
                        padding: ".5rem",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      National park: {item.national_park}{" "}
                    </p>
                  )}
                
                  {item.status != "Approval Denied" &&
                    item.status != "Getting Reviewed" &&
                    item.status != "Incomplete" && (
                      <Fragment>
                        <button
                          onClick={() => unPublishPackageHandler(item.id)}
                          className="btn btn-primary mx-2"
                        >
                          {" "}
                          Unpublish
                        </button>
                        <button
                          onClick={() => editAccommodation(item, item.id)}
                          className="btn btn-primary mx-2"
                        >
                          {" "}
                          Edit
                        </button>
                      </Fragment>
                    )}
                  {item.status == "Approval Denied" && (
                    <>
                      <button
                        onClick={() => editAccommodation(item, item.id)}
                        className="btn btn-primary mx-2"
                      >
                        {" "}
                        Edit & Re-Submit
                      </button>
                      <Link
                        to={"/admin-comment-safari-package-form/" + item.id}
                      >
                        <button className="btn btn-primary mx-2">
                          {" "}
                          Admin Comment
                        </button>
                      </Link>
                    </>
                  )}
                  {
                    item.status != "Approval Denied" &&
                      item.status != "Incomplete" && (
                        // <Link to={"/accommodation/view-form/"+item.id}>
                        <Link to={`/safari-package-view-form/${item.id}`}>
                          {" "}
                          <button className="btn btn-primary mx-2">
                            {" "}
                            View
                          </button>
                        </Link>
                      )
                    // </Link>
                  }
                  {item.status == "Incomplete" && (
                    <button
                      onClick={() => editAccommodation(item, item.id)}
                      className="btn btn-primary mx-2"
                    >
                      {" "}
                      Complete
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
