

import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
//import Safarireg_Basic from '../SafariRegistration/SafariReg_basic.js';
//import Safarireg_Policies from './SafariReg_Policies.js';
//import Safarireg_finance from './SafariReg_finance.js';
//import BasicAccommodationImages from './SafariReg_acc_images.js';
import GroundReg_basic from './GroundReg_basic.js';
import GroundReg_vehicle from './GroundReg_vehicle';
import GroundReg_Media from './GroundReg_media';
import SafariReg_Policies from './SafariReg_Policies';
import GroundReg_finance from './GroundReg_finance';


export default function GLReg() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    useEffect(() => {
        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

    
    return (
        <div className="header-top-space">
            <div className="d-sm-block text-center  d-none">
                <div className="d-flex  justify-content-around step-form">
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="stepper-title" >Basic Information</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="stepper-title">Vehicle Detail</p>
                    </div>
                   
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="stepper-title">Policy</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="stepper-title">Finance</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 5) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 5) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 5</span>
                            </div>
                        </div>
                        <p className="stepper-title">Image & Document</p>
                    </div>
                </div>
            </div>
            {console.log('you are good in gound logistic')}
            <Switch>
                <Route path="/ground-logistics-registration/basic/1" exact component={GroundReg_basic} />
                <Route path="/ground-logistics-registration/Vehicle/2/:id" exact component={GroundReg_vehicle} />
                <Route path="/ground-logistics-registration/image-document/5/:id" exact component={GroundReg_Media} />
                <Route path="/ground-logistics-registration/policy/3/:id" exact component={SafariReg_Policies} />
                <Route path="/ground-logistics-registration/finance/4/:id" exact component={GroundReg_finance} />
            </Switch>
        </div>
    );
}