import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "../../axios";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function SafariPackageViewForm() {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [vehicle, setvehicle] = useState(null);
  const [service, setservice] = useState(null);
  const [guide, setguide] = useState(null);
  const [amenities, setamenities] = useState(null);
  const [policies, setpolicies] = useState(null);

  useEffect(() => {
    getVehicleData();
    getServiceData();
    getGuideData();
    getAmenitiesData();
    getPolicyData();
  }, [user]);
  const getVehicleData = async () => {
    await axios
      .get(`partners/safari-vehicle-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in safari package VEHICLE view form", err);
        } else {
          console.log(
            "data on safari package VEHICLE view form",
            response.data
          );
          setvehicle(response.data);
        }
      });
  };
  const getServiceData = async () => {
    await axios
      .get(`partners/safari-service-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in safari package SERVICE view form", err);
        } else {
          console.log(
            "data on safari package SERVICE view form",
            response.data
          );
          setservice(response.data);
        }
      });
  };
  const getGuideData = async () => {
    await axios
      .get(`partners/safari-tour-guide-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in safari package GUIDE view form", err);
        } else {
          console.log("data on safari package GUIDE view form", response.data);
          setguide(response.data);
        }
      });
  };
  const getAmenitiesData = async () => {
    await axios
      .get(`partners/safari-amenity-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in safari package AMENITIES view form", err);
        } else {
          console.log(
            "data on safari package AMENITIES view form",
            response.data
          );
          setamenities(response.data);
        }
      });
  };
  const getPolicyData = async () => {
    await axios
      .get(`partners/safari-package-policy-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package POLICY view form", err);
        } else {
          console.log("data on tour package view POLICY form", response.data);
          setpolicies(response.data);
        }
      });
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }
  return (
    <div className="header-top-space">
      <div className="container p-3">
        <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
          Safari Packages View
        </h3>
        <div>
          {vehicle != null && (
            <div className="p-2 bg-white">
              <h5
                style={{
                  marginBottom: "1rem",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                Basic Information
              </h5>
              <div className="row">
                {Object.entries(vehicle).map(([key, item]) => (
                  <>
                    {(() => {
                      {
                        /*if(key ==='four_four' || key === 'gypsy' || key === 'canter' || key === 'mini_van'){
                        return (
                        <div >
                            <p>{key} : <b> { value?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>} </b> </p>    
                        </div>   
                        )
                    }else if(key ==='id' || key ==='updated_at' || key === 'created_at' || key === 'safari'){
                        <span></span>
                    }else{
                        return (
                        <div >
                            <p>{key} : <b>{value} </b> </p>    
                        </div>
                        )
                    }*/
                      }
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "safari" &&
                        key!= "is_rejected" &&
                        key!= "is_published" &&
                        key!= "edit_mode" &&
                        key!= "is_complete" &&
                        key != "final_submission"
                      ) {
                        return (
                  <div className="col-sm-6">

                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                          </div>
                        );
                      }
                    })()}
                  </>
                ))}
              </div>
            </div>
          )}
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Services
            </h5>
            <div className="row">
              {service != null &&
                Object.entries(service).map(([key, item]) => (
                  <>
                    {(() => {
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "safari"
                      ) {
                        return (
                  <div className="col-sm-6">
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                          </div>
                        );
                      }
                    })()}
                  </>
                ))}
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Tour Guide
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                guide &&
                  Object.entries(guide).map(([key, item]) => (
                    <>
                      {(() => {
                        if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "safari"
                        ) {
                          return (
                  <div className="col-sm-6">
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                            </div>
                          );
                        }
                      })()}
                    </>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
            Amenities in Vehicle
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                amenities &&
                  Object.entries(amenities).map(([key, item]) => (
                    <>
                      {(() => {
                        if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "safari"
                        ) {
                          return (
                  <div className="col-sm-6">

                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                            </div>
                          );
                        }
                      })()}
                    </>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Policies
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                policies &&
                  Object.entries(policies).map(([key, item]) => (
                    <div className="col-sm-6">
                      {(() => {
                        if (key === "document") {
                          return (
                            <div
                              style={{
                                display: "flex",
                                margin: "1rem",
                                background: "lightblue",
                              }}
                            >
                              <p style={{ flex: 1, padding: "1rem" }}>
                                Document :
                              </p>
                              <a href={item} target="_blank">
                                <img
                                  src={
                                    item
                                      ? "/images/download.png"
                                      : "/images/download2.png"
                                  }
                                  style={{ width: "5rem", padding: "1rem" }}
                                ></img>
                              </a>
                            </div>
                          );
                        } else if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "safari"
                        ) {
                          return (
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                          );
                        }
                      })()}
                    </div>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div>
            <NavLink to={"/home/tour-package"}>
              <button class="btn btn-primary">Back</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafariPackageViewForm;
