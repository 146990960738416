import axios from '../../axios';

export const TOUR = 'TOUR';
export const TOURID = 'TOURID';

export const tour = (tour) => {
  return async dispatch => {
    if (tour != null) {
        dispatch({
          type: TOUR,
          tour: tour
        })
  
      }
  };
}

export const tourid = (tour_id) => {
  return async dispatch => {
        dispatch({
          type: TOURID,
          tour_id: tour_id
        })
  };
}

