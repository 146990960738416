import { ACCOMMODATION } from "../actions/accommodation"

const initialState = {
    accommodation:null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACCOMMODATION:
            return{
                accommodation:action.accommodation
            };
        default:
            return state
    }
}