import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";

import { makeStyles } from "@material-ui/core/styles";
import "./Safari_basic.css";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as logisticActions from "../../store/actions/logistics";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const { Option } = Select;
const selectBefore = (
  <Select defaultValue="http://" className="select-before">
    <Option value="INR">http://</Option>
  </Select>
);

function Safari_pricing() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { gl_id, package_id } = useParams();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getRouteList();
    window.scrollTo(0, 0);
  }, [user]);

  const [routeList, setRouteList] = useState([]);

  function getRouteList() {
    axios
      .get(`partners/ground-logistic-fleet-route-list/${package_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setRouteList(res.data);
        console.log("get route list", res.data);
      })
      .catch((err) => {
        console.log("error in fetching form list", err);
      });
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  const [id, setid] = useState(null);

  function addAnotherRoute() {
    history.push(
      "/ground-logistics-package-forms/route/2/" + gl_id + "/" + package_id
    );
    // try {
    //   dispatch(logisticActions.setLogisticId(package_id));
    // }catch(err){
    //   console.log('error setting logistics edit package id into actions',err)
    // }
  }

  function editRoute(id) {
    history.push({
      pathname:
        "/ground-logistics-package-forms/route/2/" + gl_id + "/" + package_id,
      search: "?item-id=" + id,
      state: { packageId: id },
    });
    try {
      dispatch(logisticActions.setLogisticId(id));
      // history.push('/ground-logistics-package-forms/route/2/'+gl_id+'/'+package_id);
    } catch (err) {
      console.log("error setting logistics edit package id into actions", err);
    }
  }

  function continueHome() {
    axios
      .get(`partners/fleet-final-submit/${package_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        try {
          dispatch(logisticsActions.removefleetid());
          history.push(
            "/ground-logistics-package-forms/basic-information/1/" + logisticsId
          );
        } catch (err) {
          console.log("error accomodation actions", err);
        }
        console.log("continue home response", res.data);
        history.push("/home/");
      });
  }

  return (
    <div className="TourReg_basic">
      <div className="container text-left">
        <h4>Ground Logistics Package: Route Overview</h4>
      </div>
      <br></br>

      <div>
        <div>
          {routeList.map((item, index) => (
            <div className="container mb-3">
              <div className="bg-white mb-4">
                <h5 className="float-left mx-2 text-primary">Routes : {index + 1} </h5>
                <p
                  onClick={() => editRoute(item.id)}
                  className="float-right mx-2 text-primary"
                  style={{cursor: 'pointer'}}
                >
                  {" "}
                  <em>EDIT</em>{" "}
                </p>
                <div className="clearfix"></div>
                {Object.entries(item).map(
                  ([key, value]) => (
                    <div className="row m-0">
                      {!(key=="id"||key=="admin_comment"||key=="approved_status"||key=="created_at"||key=="updated_at"||key=="fleet")&&
                        <div className="col-sm-5 mx-3 my-2 bg-info">
                          <p className="float-left text-white mx-2 my-1">
                            {replaceAll(key, "_", " ")}{" "}
                          </p>
                          <p className="float-right text-white mx-2 my-1">
                            {" "}
                            <b> {value} </b>{" "}
                          </p>
                        </div>
                      }
                    </div>
                  )
                  // <div className="col-sm-5">
                  //   <p> {replaceAll(key, '_', ' ')}: <b> {value} </b> </p>
                  // </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <br />
      <div className="my-4 mb-2">
        <Link to={"/ground-logistics-package-forms/basic-information/1/" + gl_id}>
          <button className="btn btn-grey border border-primary mx-2">BACK </button>
        </Link>

        <button onClick={() => addAnotherRoute()} className="btn btn-primary">
          {" "}
          +ADD ANOTHER ROUTE{" "}
        </button>
        {/* <Link to={"/home/"}> */}
        <button onClick={continueHome} className="btn btn-primary mx-2">
          CONTINUE
        </button>
        {/* </Link> */}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_pricing;
