import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";
import Safari_basic from "./Safari_vehicle_pricing";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, Space } from "antd";
import FormControl from "@material-ui/core/FormControl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_daybyday() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { safari_id,id } = useParams();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getServiceData();
  }, [user]);

  const [service, setService] = useState({
    safari: id,
    pick_up_point: null,
    drop_point: null,
    additional_fee: '0',
  });

  const handleChange = (event) => {
    if(event.target.name==='additional_fee' && event.target.value==='')
      setService({ ...service, [event.target.name]: '0' });
    else
      setService({ ...service, [event.target.name]: event.target.value });
  };

  function submitService() {
    axios
      .post("partners/safari-service-create", service, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package service create",
          res.data
        );

        history.push("/safari-package/tour-guide/3/" + safari_id+'/'+id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating service safari package", err);
      });
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  // const [id, setid] = useState(null);
  const getServiceData = async () => {
    await axios
      .get(`partners/safari-service-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in SERVICE GET DATA ", err);
        } else {
          console.log("CURRENCY SERVICE GET DATA ", response.data);

          if (response.data && response.data.id) {
            setcheckUpdate(true);
            setService(response.data);
            // setid(response.data.id);
            console.log("id of the pagae", id);
          }
          {
            /*Object.entries(data).map((item) => {
              console.log(' value ',item[0],item[1]);
                handleUpdate(item[0],item[1]);
          }
            )
        }*/
          }
        }
      });
  };

  const updateTourBasic = async () => {
    // console.log("this is the id", id);
    axios
      .put(`partners/safari-package-service-back-update/${service.id}`, service, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package SERVICE  UPDATE",
          res.data
        );

        history.push("/safari-package/tour-guide/3/" + safari_id+'/'+id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from UPDATING SERVICE safari package", err);
      });
  };

  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Safari / Game Drive Package: Service</h4></div>

      <br></br>
      <div className="p-3 mb-3 bg-white">
                  <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
                  <div className="text-left">
                    <h5>Note:</h5>
                    <p>
                      <ul>
                      <li><u>It is recommended to provide all informations.</u> </li>
                        {/* <li>
                          Please enter a non-zero number in 'Accommodation
                          Distribution' against various listed options which
                          will be reflected in next stages of forms for
                          selection{" "}
                        </li> */}
                        <li>
                          {" "}
                          Same currency is applicable in additional fee as previously selected                        </li>
                          <li><b>It is recommended to provide appropriate cost (Inclusive Local Tax) against label of 'Additional Fee '.</b> </li>
                      </ul>
                    </p>
                  </div>
                </div>



      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Pick Up Point (One Way){" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>{" "}
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "75%" }}
          >
            <Select
              value={service.pick_up_point}
              onChange={handleChange}
              name="pick_up_point"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={'Nearest Airport'}>Nearest Airport</MenuItem>
              <MenuItem value={'Nearest Railway'}>Nearest Railway Station</MenuItem>
              <MenuItem value={'Nearest AirRail'}>Nearest Airport / Railway Station</MenuItem>
              <MenuItem value={'Resort'}>Resort  / Hotel</MenuItem>
              <MenuItem value={'Others'}>Others</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Drop Point (One Way)<span style={{ marginLeft: "1%", color: "red" }}>*</span>{" "}
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "75%" }}
          >
            <Select
              value={service.drop_point}
              onChange={handleChange}
              name="drop_point"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <MenuItem value={'Nearest Airport'}>Nearest Airport</MenuItem>
              <MenuItem value={'Nearest Railway'}>Nearest Railway Station</MenuItem>
              <MenuItem value={'Nearest AirRail'}>Nearest Airport / Railway Station</MenuItem>
              <MenuItem value={'Resort'}>Resort  / Hotel</MenuItem>
              <MenuItem value={'Others'}>Others</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br /><br /><br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Additional Fee For Pickup & Drop (if any & one way)</p>
          <TextField
          type="number"
            value={service.additional_fee}
            onChange={handleChange}
            name="additional_fee"
            id="outlined-basic"
            placeholder="additional fee in privious selected currency"
            variant="outlined"
          />
        </form>
      </div>
      <div className="my-4 mb-2">
        <Link to={"/safari-package/vehicle-parking/1/" + safari_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitService}>
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_daybyday;
