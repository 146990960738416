import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "../../axios";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function TourPackageViewForm() {
  const { id } = useParams();
  const [basicInfo, setbasicInfo] = useState(null);
  const [imageList, setimageList] = useState([]);
  const [planList, setplanList] = useState([]);
  const [pricing, setpricing] = useState(null);
  const [facilities, setfacilities] = useState(null);
  const [amenities, setamenities] = useState(null);
  const [guide, setguide] = useState(null);
  const [policies, setpolicies] = useState(null);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getBasicData();
    getAllImages();
    getPlanList();
    getPricingData();
    getFacilitiesData();
    getAmenitiesData();
    getGuideData();
    getPolicyData();
  }, [user]);

  const getBasicData = async () => {
    await axios
      .get(`partners/tour-package-basic-info-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package view form", err);
        } else {
          console.log("data on tour package view form", response.data);
          setbasicInfo(response.data);
        }
      });
  };
  const getAllImages = async () => {
    await axios
      .get(`partners/tour-package-image-list/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package IMAGE view form", err);
        } else {
          console.log("data on tour package view IMAGE form", response.data);
          setimageList(response.data);
        }
      });
  };
  const getPlanList = async () => {
    await axios
      .get(`partners/tour-package-plan-list/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package PLAN view form", err);
        } else {
          console.log("data on tour package view PLAN form", response.data);
          setplanList(response.data);
        }
      });
  };
  const getPricingData = async () => {
    await axios
      .get(`partners/tour-pricing-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package PRICING view form", err);
        } else {
          console.log("data on tour package view PRICING form", response.data);
          setpricing(response.data);
        }
      });
  };
  const getFacilitiesData = async () => {
    await axios
      .get(`partners/tour-facility-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package FACILITIES view form", err);
        } else {
          console.log(
            "data on tour package view FACILITIES form",
            response.data
          );
          setfacilities(response.data);
        }
      });
  };
  const getAmenitiesData = async () => {
    await axios
      .get(`partners/tour-amenity-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package AMENITIES view form", err);
        } else {
          console.log(
            "data on tour package view AMENITIES form",
            response.data
          );
          setamenities(response.data);
        }
      });
  };
  const getGuideData = async () => {
    await axios
      .get(`partners/tour-guide-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package GUIDE view form", err);
        } else {
          console.log("data on tour package view GUIDE form", response.data);
          setguide(response.data);
        }
      });
  };
  const getPolicyData = async () => {
    await axios
      .get(`partners/tour-policy-detail/${id} `, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in tour package POLICY view form", err);
        } else {
          console.log("data on tour package view POLICY form", response.data);
          setpolicies(response.data);
        }
      });
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
  }
  return (
    <div className="header-top-space">
      <div className="container p-3">
        <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
          Tour Package View
        </h3>
        <div>
          {basicInfo != null && (
            <div className="p-2 bg-white">
              <h5
                style={{
                  marginBottom: "1rem",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                Basic Information
              </h5>
              <div className="row">
                {basicInfo && 
                  Object.entries(basicInfo).map(([key, item]) => (
                    <>
                      {(() => {                        
                        if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "tour_operator" &&
                          key != "image" &&
                          key != "banner_image" &&
                          key != "status" &&
                          key!= "is_rejected" &&
                          key!= "is_published" &&
                          key!= "edit_mode" &&
                          key!= "is_complete" &&
                          key != "satellite_town" &&
                          key != "final_submission" &&
                          key != 'partner'
                         

                          ) {
                          return (
                            <div className="col-sm-6">
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                      </div>
                          );
                        }
                      })()}
                    </>
                  ))}
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Banner Image    : &nbsp;
                    <a href={basicInfo.image} target="_blank">
                    <img
                      src={
                        basicInfo.image
                          ? basicInfo.image
                          : "/images/download2.png"
                      }
                      style={{ width: "5rem" }}
                    ></img></a>
                  </p>
                </div> 
                {/* <div
                  className="col-sm-6"
                  style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                    <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500',}}>
                      Name : 
                      <span style={{float: "right",}}><b> {basicInfo.name} </b>{" "}</span>
                    </p>
                    <span style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500', float:"right", width:"50%"}}>{basicInfo.name}</span>
                </div> */}
                {/* <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Overview :&nbsp; <b> {basicInfo.tour_overview} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Start Point :&nbsp; <b> {basicInfo.start_point} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Start Date : &nbsp;<b> {basicInfo.start_date} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    End Date : &nbsp;<b> {basicInfo.end_date} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Day of Operation : &nbsp;<b> {basicInfo.day_of_operation} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Total Days : &nbsp;<b> {basicInfo.total_day} </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Major Town : &nbsp;{" "}
                    <b>
                      {" "}
                      {basicInfo.major_town ? (
                        <CheckBoxIcon style={{ color: "green" }} />
                      ) : (
                        <ClearIcon style={{ color: "red" }} />
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Visiting Parks : &nbsp;{" "}
                    <b>
                      {" "}
                      {basicInfo.visiting_park ? (
                        <CheckBoxIcon style={{ color: "green" }} />
                      ) : (
                        <ClearIcon style={{ color: "red" }} />
                      )}{" "}
                    </b>{" "}
                  </p>
                </div>
                <div
                  className="col-sm-6"
                  //style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}
                >
                  <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}>
                    Banner Image    : &nbsp;
                    <a href={basicInfo.image} target="_blank">
                    <img
                      src={
                        basicInfo.image
                          ? basicInfo.image
                          : "/images/download2.png"
                      }
                      style={{ width: "5rem" }}
                    ></img></a>
                  </p>
                </div> */}
              </div>
              <hr />
              <h5
                style={{
                  marginBottom: "1rem",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                Tour Related Images
              </h5>
              <div className="row">
                {imageList.length>0 &&
                  imageList.map((item) => (
                    <div
                      className="col-sm-3"
                      //style={{border: "1px solid grey" , borderRadius:"10px" ,boxShadow:"2px 2px 2px grey",margin:"10px",fontSize:"1rem"}}
                    >
                      <p>
                        Image :{" "}
                        <a href={item.image} target="_blank">
                        <img
                          src={
                            item.image ? item.image : "/images/download2.png"
                          }
                          alt={item.image + ` pic`}
                          style={{ width: "5rem" }}
                        ></img></a>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Day by Day Plan
            </h5>
            <div className="row">
              {planList &&
                planList.map((item) =>
                  Object.entries(item).map(([key, item]) => (
                    <>
                      {(() => {
                        {
                         
                        }
                        if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "tour_package"
                        ) {
                          return (
                            
                    <div className="col-sm-6">
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                            </div>
                          );
                        }
                      })()}
                    </>
                  ))
                )}
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Tour Features and Pricing
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                pricing &&
                  Object.entries(pricing).map(([key, item]) => (
                    <>
                      {(() => {
                        {
                          /*if(key ==='drink' || key === 'dinner' || key === 'lunch' || key === 'packed_food'){
                            return (
                            <div  style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}>
                                <p>{key} : <b> { value?<CheckBoxIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>} </b> </p>    
                            </div>   
                            )
                        }else if(key ==='id' || key ==='updated_at' || key === 'created_at' || key === 'safari'){
                            <span></span>
                        }else{
                            return (
                            <div style={{border: "1px solid grey" , borderRadius:"30px" ,boxShadow:"2px 2px 2px grey",marginBottom:"10px",fontSize:"1rem"}}>
                                <p>{key} : <b>{value} </b> </p>    
                            </div>
                            )
                        }*/
                        }
                        
                        if (key === "price_excel") {
                          return (
                            <div>
                              <p style={{marginLeft:"1rem"}}>
                                Price Excel  : &nbsp;{" "}
                                <a href={item} target="_blank">
                                <img
                                  src={item ? "/images/xml.png" : "/images/download2.png"}
                                  // alt="document"
                                  style={{ width: "5rem", marginLeft: "3rem" }}
                                ></img></a>
                              </p>
                            </div>
                          );
                        }
                        else if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "tour_package" &&
                          key != 'price_excel'
                        ) {
                          return (
                    <div className="col-sm-6">

                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                            </div>
                          );
                        }
                      })()}
                    </>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Features and Facilities
            </h5>
            <div className="row">
              {facilities &&
                Object.entries(facilities).map(([key, item]) => (
                  <>
                    {(() => {
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "tour_package"
                      ) {
                        return (
                          <div className="col-sm-6">
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                              flexWrap: "wrap",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                    </div>
                        );
                      }
                    })()}
                    </>
                ))}
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Amenities
            </h5>
            <div className="row">
              {amenities &&
                Object.entries(amenities).map(([key, item]) => (
                  <>
                    {(() => {
                      if (
                        key != "id" &&
                        key != "admin_comment" &&
                        key != "approved_status" &&
                        key != "created_at" &&
                        key != "updated_at" &&
                        key != "tour_package"
                      ) {
                        return (
                  <div className="col-sm-6">
                          <p
                            style={{
                              display: "flex",
                              marginRight: "1rem",
                              background: "lightpink",
                              textAlign: "center",
                              padding: ".5rem .5rem .5rem 1rem",
                              textTransform: "capitalize",
                              flexWrap: "wrap",
                            }}
                          >
                            <b style={{ flex: 1, textAlign: "left" }}>
                              {replaceAll(key, "_", " ")}
                            </b>
                            <b>
                              {" "}
                              {item == true || item == false ? (
                                item == true ? (
                                  <CheckBoxIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )
                              ) : (
                                item
                              )}{" "}
                            </b>
                          </p>
                          </div>
                        );
                      }
                    })()}
                  </>

                ))}
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Tour Guide
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                guide &&
                  Object.entries(guide).map(([key, item]) => (
                    <>
                      {(() => {
                        if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "tour_package"
                        ) {
                          return (
                    <div className="col-sm-6">
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                                borderRadius: "5px",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == true || item == false ? (
                                  item == true ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                  item
                                )}{" "}
                              </b>
                            </p>
                            </div>
                          );
                        }
                      })()}
                    </>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div className="p-2 bg-white">
            <h5
              style={{
                marginBottom: "1rem",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Policies
            </h5>
            <div className="row">
              {
                //pricing && pricing.map(item => (
                policies &&
                  Object.entries(policies).map(([key, item]) => (
                    <div className="col-sm-6">
                      {(() => {
                        if (key === "document") {
                          return (
                            <div>
                              <p>
                                Policy Document  : &nbsp;{" "}
                                <a href={item} target="_blank">
                                <img
                                  src={item ? "/images/download.png" : "/images/download2.png"}
                                  // alt="document"
                                  style={{ width: "5rem" }}
                                ></img></a>
                              </p>
                            </div>
                          );
                        } else if (
                          key != "id" &&
                          key != "admin_comment" &&
                          key != "approved_status" &&
                          key != "created_at" &&
                          key != "updated_at" &&
                          key != "tour_package"
                        ) {
                          return (
                            <p
                              style={{
                                display: "flex",
                                marginRight: "1rem",
                                background: "lightpink",
                                textAlign: "center",
                                padding: ".5rem .5rem .5rem 1rem",
                                textTransform: "capitalize",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ flex: 1, textAlign: "left" }}>
                                {replaceAll(key, "_", " ")}
                              </b>
                              <b>
                                {" "}
                                {item == null || item == "null" ? (
                                  item == null ? (
                                    <CheckBoxIcon style={{ color: "green" }} />
                                  ) : (
                                    <ClearIcon style={{ color: "red" }} />
                                  )
                                ) : (
                                   <b>:{item}</b>
                                )}{" "}
                              </b>
                            </p>
                          );
                        }
                      })()}
                    </div>
                  ))
                //))
              }
            </div>
          </div>
          <hr />
          <div>
            <NavLink to={"/home/tour-package"}>
              <button class="btn btn-primary">Back</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourPackageViewForm;
