import React, { Component, useState, useEffect, Fragment } from 'react';
// import axios from '../../axios';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import '../accomodation_basic.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import * as accommodationActions from '../../store/actions/accommodation';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const currencies = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'BTC',
    label: 'BTC',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'RAND',
    label: 'RAND',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'BRL',
    label: 'BRL',
  },
  {
    value: 'ILS',
    label: 'ILS',
  },



];


export default function AccomodationBasicNewForm(props,{user, accommodationType}) {
  const classes = useStyles();
//   const [accommodationType, setAccommodationType] = useState(null);
  const [accommodationList, setAccommodationList] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [addNewForm, setAddNewForm] = useState(false);
  const [currency, setCurrency] = React.useState('INR');




  const [contact_detail, setContactDetail] = useState([
    {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null
    }
  ])

  const [state, setState] = useState({
    name: '',
    website: '',
    trip_adviser_rating: null,
    contactName: null,
    contactNumber: null,
    escalation_name: null,
    escalation_number: null,
    escalation_number_alter: null,
    escalation_email: null,
    role: null,
    alternateNumber: null,
    contactEmail: null,
    street: '',
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    zip_code: '',
    currency: '',
    partner: null,
    business: 1,
  })


  



  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onChangeContactForm = (index, event) => {
    let arr = contact_detail;
    arr[index][event.target.name] = event.target.value;
    setContactDetail([...arr]);
  }


  function addContactDetails() {
    let arr = [...contact_detail];
    let obj = {
      name: null,
      number: null,
      role: null,
      number_alter: null,
      email: null
    }
    arr.push(obj);
    setContactDetail(arr);
  }

  function removeContactDetail(itemDel, index) {
    // console.log(itemDel)
    let arr = [...contact_detail];
    arr.splice(index, 1);
    // arr.filter(items => items.contactName !== item.contactName)
    // // contact_detail.filter(items =>items.contactName !== item.contactName)
    setContactDetail(arr);
    // setContactDetail(contact_detail.filter(item => item.name !== itemDel.name))
  }

  function submitAccomodationBasicFunc(){
    let stateUpdate = state;
    stateUpdate.contact_detail = contact_detail;
    // stateUpdate.partner = user.id;
    stateUpdate.partner = cookies.get('id');
    stateUpdate.business = 1;
    props.submitAccomodationBasic(stateUpdate)
      console.log('submitAccomodationBasic',props,state,contact_detail);

  }

  return (

    <div>
      <div className="d-flex justify-content-center bg-light" >
        <div className="p-2 justify-content-center  align-self-center w-100" >
          <div className="container text-left">
              <div>
                <div>
                  <h4>Basic Information</h4>
                  <div className="p-3 bg-white">
                    <div>
                      <div className="row" >
                        <div className="col-sm-6">
                          <label>Name Of the Hotel Camp<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.name} onChange={handleChange} name="name" />
                        </div>
                        <div className="col-sm-6">
                          <label>Website <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.website} onChange={handleChange} name="website" />
                        </div>
                        <div className="col-sm-6">
                          <label>Trip Advisor Rating <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.trip_adviser_rating} onChange={handleChange} name="trip_adviser_rating" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>Accomodation Distribution<span style={{ color: "red" }}>*</span></h4>
                  <div className="p-3 bg-white">
                    <div>
                      {accommodationType != null &&
                        <div className="row" >
                          {Object.entries(accommodationType).map((element, key) =>
                            <Fragment>
                              {element[1] == true &&
                                <div className="col-sm-6">
                                  <label> {element[0]}<span style={{ color: "red" }}>*</span></label>
                                  <input className="form-control" value={state[element[0]]} onChange={handleChange} name={element[0]} />
                                </div>
                              }
                            </Fragment>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div>
                  <h4>Contact Detail of Escalation<span style={{ color: "red" }}>*</span></h4>
                  <div className="p-3 bg-white">
                    <div>
                      <div>
                        <div className="row" >
                          <div className="col-6">
                            <label>Contact Name<span style={{ color: "red" }}>*</span></label>
                            <input className="form-control" onChange={handleChange} value={state.escalation_name} name="escalation_name" />
                          </div>
                          <div className="col-6 ">
                            <label>Contact Number<span style={{ color: "red" }}>*</span></label>
                            <input className="form-control" onChange={handleChange} value={state.escalation_number} name="escalation_number" />
                          </div>
                          <div className="col-6 ">
                            <label>Alternative Contact number<span style={{ color: "red" }}>*</span></label>
                            <input className="form-control" onChange={handleChange} value={state.escalation_number_alter} name="escalation_number_alter" />
                          </div>
                          <div className="col-6 ">
                            <label>Email<span style={{ color: "red" }}>*</span></label>
                            <input className="form-control" onChange={handleChange} value={state.escalation_email} name="escalation_email" />
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>Contact Detail <span style={{ color: "red" }}>*</span></h4>
                  <div className="p-3 bg-white">
                    <div>
                      {contact_detail.map((item, index) =>
                        <div>
                          <div className="text-right w-100">
                            {contact_detail.length > 1 &&
                              <span className="cursor-pointer" onClick={() => removeContactDetail(item, index)}>X </span>
                            }
                          </div>
                          <div className="row" >
                            <div className="col-6">
                              <label>Contact Name<span style={{ color: "red" }}>*</span></label>
                              <input className="form-control" onChange={(e) => onChangeContactForm(index, e)} value={item.name} name="name" />
                            </div>
                            <div className="col-6 ">
                              <label>Contact Number<span style={{ color: "red" }}>*</span></label>
                              <input className="form-control" onChange={(e) => onChangeContactForm(index, e)} value={item.number} name="number" />
                            </div>
                            <div className="col-6 ">
                              <label>Role<span style={{ color: "red" }}>*</span></label>
                              <input className="form-control" onChange={(e) => onChangeContactForm(index, e)} value={item.role} name="role" />
                            </div>
                            <div className="col-6 ">
                              <label>Alternative Contact number<span style={{ color: "red" }}>*</span></label>
                              <input className="form-control" onChange={(e) => onChangeContactForm(index, e)} value={item.number_alter} name="number_alter" />
                            </div>
                            <div className="col-6 ">
                              <label>Email<span style={{ color: "red" }}>*</span></label>
                              <input className="form-control" onChange={(e) => onChangeContactForm(index, e)} value={item.email} name="email" />
                            </div>
                          </div>
                          <hr />
                        </div>
                      )}
                    </div>
                      <button onClick={addContactDetails} className="btn btn-info">+Add Another Contact Detail</button>
                    
                  </div>
                </div>

                <div>
                  <h4>ADDRESS DETAILS</h4>
                  <div className="p-3 bg-white">
                    <div>
                      <div className="row" >
                        <div className="col-sm-6">
                          <label>Street<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.street} onChange={handleChange} name="street" />
                        </div>
                        <div className="col-sm-6">
                          <label>Address Line 1<span style={{ color: "red" }}>*</span> </label>
                          <input className="form-control" value={state.address_line_1} onChange={handleChange} name="address_line_1" />
                        </div>
                        <div className="col-sm-6">
                          <label>Address Line 2<span style={{ color: "red" }}>*</span> </label>
                          <input className="form-control" value={state.address_line_2} onChange={handleChange} name="address_line_2" />
                        </div>
                        <div className="col-sm-6">
                          <label>Country<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.country} onChange={handleChange} name="country" />
                        </div>
                        <div className="col-sm-6">
                          <label>city<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.city} onChange={handleChange} name="city" />
                        </div>
                        <div className="col-sm-6">
                          <label>Zip Code<span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" value={state.zip_code} onChange={handleChange} name="zip_code" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h4>Currency section</h4>
                  <div className="p-3 bg-white">
                    <div>
                      <div className="row" >
                        <div className="col-sm-6">
                          <label>Select Currency<span style={{ color: "red" }}>*</span></label>
                          <TextField
                            id="outlined-select-currency-native"
                            select
                            placeholder="Currency"
                            onChange={handleChange}
                            value={state.currency}
                            name="currency"
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please select your currency"
                            variant="outlined"
                            style={{ display: 'flex', backgroundColor: 'white' }}
                          >
                            {currencies.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>


        </div>


      </div>
      <div className="my-4 mb-2">
        <Link to="/accomodation/type/1">
          <button className="btn btn-grey border border-primary mx-2">Back </button>
        </Link>

        {/* {selectedForm ?
          <button onClick={updateAccomodationBasic} className="btn btn-primary mx-2">UPDATE</button>
          : */}
          <button className="btn btn-primary mx-2" onClick={submitAccomodationBasicFunc}>CONTINUE</button>
        {/* } */}

      </div>


    </div>
  );
}