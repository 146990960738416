import React, { Component, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import I1 from "./template.png";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./acc_doc.css";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccomodationDocuments() {
  const classes = useStyles();
  const { accomodation_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [update, setUpdate] = React.useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0, 0);

    getAccommodationDocuments();
  }, [user]);

  const gstin_ref = useRef(null);
  const mom_aoa_ref = useRef(null);
  const pan_ref = useRef(null);
  const cin_ref = useRef(null);
  const reg_certificate_ref = useRef(null);
  const other_document_ref = useRef(null);

  const [documents, setDocuments] = React.useState({
    accommodation: accomodation_id,
    gstin: null,
    mom_aoa: null,
    pan: null,
    cin: null,
    reg_certificate: null,
    other_document: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    gstin: null,
    gstin_change: false,
    mom_aoa: null,
    mom_aoa_change: false,
    pan: null,
    pan_change: false,
    cin: null,
    cin_change: false,
    reg_certificate: null,
    reg_certificate_change: false,
    other_document: null,
    other_document_change: false,
  });

  const [extension, setextension] = useState({});
  const [uploadingMedia, setuploadingMedia] = useState(false);

  function uploadFileHandler(key, e) {
    setDocuments({ ...documents, [key]: e.target.files[0] });
    if (e.target.files[0] != null) {
      setextension({
        ...extension,
        [key]: e.target.files[0].name.split(".").pop(),
      });
    }
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null
  }

  function getAccommodationDocuments() {
    axios
      .get(`partners/accommodation-documents-list/${accomodation_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        // setCategoryArray(res.data);
        if (res.data.length > 0) {
          setUpdate(true);
          setDocuments(res.data[0]);
          setImagePreview({ ...imagePreview, ...res.data[0] });
        } else {
          setUpdate(false);
        }
        // setFacility(res.data[0]);
        console.log("response from get documents", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation documents list", err);
      });
  }

  function submitAccommodationDocuments() {
    let formData = new FormData();
    formData.append("accommodation", documents.accommodation);
    if (imagePreview.mom_aoa_change) {
      formData.append("mom_aoa", documents.mom_aoa);
    }
    if (imagePreview.gstin_change) {
      formData.append("gstin", documents.gstin);
    }
    if (imagePreview.pan_change) {
      formData.append("pan", documents.pan);
    }
    if (imagePreview.cin_change) {
      formData.append("cin", documents.cin);
    }
    if (imagePreview.reg_certificate_change) {
      formData.append("reg_certificate", documents.reg_certificate);
    }
    if (imagePreview.other_document_change) {
      formData.append("other_document", documents.other_document);
    }
    setuploadingMedia(true);
    axios
      .post("partners/accommodation-document-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the accomodation media create",
          res.data
        );
        setuploadingMedia(false);
        // history.push('/accomodation-submit/'+accomodation_id);
        history.push("/accomodation/media/8/" + accomodation_id);
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
  }

  function updateAccommodationDocuments() {
    let formData = new FormData();
    formData.append("accommodation", documents.accommodation);
    if (imagePreview.mom_aoa_change) {
      formData.append("mom_aoa", documents.mom_aoa);
    }
    if (imagePreview.gstin_change) {
      formData.append("gstin", documents.gstin);
    }
    if (imagePreview.pan_change) {
      formData.append("pan", documents.pan);
    }
    if (imagePreview.cin_change) {
      formData.append("cin", documents.cin);
    }
    if (imagePreview.reg_certificate_change) {
      formData.append("reg_certificate", documents.reg_certificate);
    }
    if (imagePreview.other_document_change) {
      formData.append("other_document", documents.other_document);
    }
    setuploadingMedia(true);
    axios
      .put(
        `partners/accommodation-document-back-update/${documents.id}`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the accomodation documents update",
          res.data
        );
        setuploadingMedia(false);
        // history.push("/accomodation-submit/" + accomodation_id);
        history.push("/accomodation/media/8/" + accomodation_id);
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        obj != null &&
          Object.entries(obj).map((item, key) => {
            toast(" " + item[0] + ":" + item[1][0]);
          });
        console.log("error from creating accomodation media", err);
      });
  }

  function delUploadFileHandler(key) {
    console.log("hre");
    setDocuments({ ...documents, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  return (
    <div className="container">
      <div className="DOC">Accommodation: Documents</div>
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
            <li><u>It is recommended to provide all informations.</u> </li>
              <li>
                All documents are allowed in formats either <b>pdf (Max. 2MB)</b> or
                <b>image </b> format (Image Guideline is next point).{" "}
              </li>
              {/* <li> 'Accommodation Image' will be additional images of your property (Maximum 4 images are allowed).</li> */}
              <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
              {/* <li>'Video' will be short video of your property and put URL in the label text.</li> */}
            </ul>
          </p>
        </div>
      </div>
      <div className="d-flex bg-white">
        <div className="p-2">
          {/* <img className="imr_logo" src={I1} /> */}
          {/* <span className="ml-2">MOA      </span> */}

          <h4>MOA</h4>
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.mom_aoa} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={mom_aoa_ref}
            onChange={(e) => uploadFileHandler("mom_aoa", e)}
          />
          <button
            onClick={() => {
              mom_aoa_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>

          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("mom_aoa")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.mom_aoa} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["mom_aoa"] == "pdf" ||
                (documents.mom_aoa != null &&
                  imagePreview.mom_aoa != null &&
                  imagePreview.mom_aoa.split(".").pop() === "pdf" &&
                  documents.mom_aoa.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.mom_aoa != null
                        ? `File-->${
                            documents.mom_aoa.name &&
                            documents.mom_aoa.name.split(".")[0]
                          } Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.mom_aoa} target="_blank">
                      {imagePreview.mom_aoa != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.mom_aoa != null
                        ? `Image --> ${
                            documents.mom_aoa.name
                              ? documents.mom_aoa.name.split(".")[0]
                              : ""
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.mom_aoa} target="_blank">
                      <img
                        src={
                          imagePreview.mom_aoa
                            ? imagePreview.mom_aoa
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <br />

      <div className="d-flex  bg-white">
        <div className="p-2">
          {/* <img className=" imr_logo " src={I1} />
          <span className="ml-2">GSTN      </span> */}
          <h4>GSTN</h4>
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.gstin} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={gstin_ref}
            onChange={(e) => uploadFileHandler("gstin", e)}
          />
          <button
            onClick={() => {
              gstin_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>
          {/*<p className="my-1 text-success"> {documents.gstin != null ? 'File / Image Selected' : null} </p>*/}
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("gstin")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.gstin} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["gstin"] == "pdf" ||
                (documents.gstin != null &&
                  imagePreview.gstin != null &&
                  imagePreview.gstin.split(".").pop() === "pdf" &&
                  documents.gstin.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.gstin != null
                        ? `File-->${
                            documents.gstin.name &&
                            documents.gstin.name.split(".")[0]
                          } Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.gstin} target="_blank">
                      {imagePreview.gstin != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.gstin != null
                        ? `Image --> ${
                            documents.gstin.name &&
                            documents.gstin.name.split(".")[0]
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.gstin} target="_blank">
                      <img
                        src={
                          imagePreview.gstin
                            ? imagePreview.gstin
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <br />

      <div className="d-flex bg-white">
        <div className="p-2">
          {/* <img className="imr_logo" src={I1} />
          <span className="ml-2">CIN</span> */}
          <h4>CIN</h4>
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.cin} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={cin_ref}
            onChange={(e) => uploadFileHandler("cin", e)}
          />
          <button
            onClick={() => {
              cin_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>
          {/*<p className="my-1 text-success"> {documents.cin != null ? 'File / Image Selected' : null} </p>*/}
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("cin")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.cin} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["cin"] == "pdf" ||
                (documents.cin != null &&
                  imagePreview.cin != null &&
                  imagePreview.cin.split(".").pop() === "pdf" &&
                  documents.cin.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.cin != null
                        ? `File-->${documents.cin.name && documents.cin.name.split(".")[0]} Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.cin} target="_blank">
                      {imagePreview.cin != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.cin != null
                        ? `Image --> ${
                            documents.cin.name &&
                            documents.cin.name.split(".")[0]
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.cin} target="_blank">
                      <img
                        src={
                          imagePreview.cin
                            ? imagePreview.cin
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <br />
      <div className="d-flex bg-white">
        <div className="p-2">
          {/* <img className="imr_logo" src={I1} />
          <span className="ml-2">PAN</span> */}
          <h4>PAN</h4>
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.pan} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={pan_ref}
            onChange={(e) => uploadFileHandler("pan", e)}
          />
          <button
            onClick={() => {
              pan_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>
          {/*<p className="my-1 text-success"> {documents.pan != null ? 'File / Image Selected' : null} </p>*/}
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("pan")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.pan} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["pan"] == "pdf" ||
                (documents.pan != null &&
                  imagePreview.pan != null &&
                  imagePreview.pan.split(".").pop() === "pdf" &&
                  documents.pan.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.pan != null
                        ? `File-->${documents.pan.name && documents.pan.name.split(".")[0]} Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.pan} target="_blank">
                      {imagePreview.pan != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.pan != null
                        ? `Image --> ${
                            documents.pan.name &&
                            documents.pan.name.split(".")[0]
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.pan} target="_blank">
                      <img
                        src={
                          imagePreview.pan
                            ? imagePreview.pan
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <br />

      <div className="d-flex bg-white">
        <div className="p-2">
          {/* <img className="imr_logo" src={I1} /> */}
          <span className="ml-2">
            <h6>
              <b>Company Registration Certificate</b>
            </h6>
          </span>
          {/* <h5>Company Registration Certificate</h5> */}
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.reg_certificate} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={reg_certificate_ref}
            onChange={(e) => uploadFileHandler("reg_certificate", e)}
          />
          <button
            onClick={() => {
              reg_certificate_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>
          {/*<p className="my-1 text-success"> {documents.reg_certificate != null ? 'File / Image Selected' : null} </p>*/}
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("reg_certificate")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.reg_certificate} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["reg_certificate"] == "pdf" ||
                (documents.reg_certificate != null &&
                  imagePreview.reg_certificate != null &&
                  imagePreview.reg_certificate.split(".").pop() === "pdf" &&
                  documents.reg_certificate.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.reg_certificate != null
                        ? `File-->${
                            documents.reg_certificate.name &&
                            documents.reg_certificate.name.split(".")[0]
                          } Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.reg_certificate} target="_blank">
                      {imagePreview.reg_certificate != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.reg_certificate != null
                        ? `Image --> ${
                            documents.reg_certificate.name &&
                            documents.reg_certificate.name.split(".")[0]
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.reg_certificate} target="_blank">
                      <img
                        src={
                          imagePreview.reg_certificate
                            ? imagePreview.reg_certificate
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
      <br />

      <div className="d-flex bg-white">
        <div className="p-2">
          {/* <img className="imr_logo" src={I1} /> */}
          <span className="ml-2">
            <h6>
              <b>Other Relavant Govt. Document</b>
            </h6>
          </span>
          {/* <h4>Other Relavant Govt. Document</h4> */}
        </div>
        <div className="p-2 my-auto ml-auto text-right">
          {/*<img src={imagePreview.other_document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          <input
            className="d-none"
            type="file"
            ref={other_document_ref}
            onChange={(e) => uploadFileHandler("other_document", e)}
          />
          <button
            onClick={() => {
              other_document_ref.current.click();
            }}
            id="bfa"
          >
            UPLOAD FILE
          </button>
          {/*<p className="my-1 text-success"> {documents.other_document != null ? 'File / Image Selected' : null} </p>*/}
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("other_document")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            {/*<img src={imagePreview.other_document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
            {(() => {
              //var fileName = imagePreview.document;
              //var fileExtension = fileName.split('.').pop();
              if (
                extension["other_document"] == "pdf" ||
                (documents.other_document != null &&
                  imagePreview.other_document != null &&
                  imagePreview.other_document.split(".").pop() === "pdf" &&
                  documents.other_document.split(".").pop()) == "pdf"
              ) {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.other_document != null
                        ? `File-->${
                            documents.other_document.name &&
                            documents.other_document.name.split(".")[0]
                          } Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.other_document} target="_blank">
                      {imagePreview.other_document != null ? (
                        <img
                          src="/images/download.png"
                          className="img-responsive m-2"
                          style={{ width: "50px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src="/images/download2.png"
                          alt="No file"
                          style={{
                            width: "50px",
                            height: "40px",
                            paddingRight: "10px",
                          }}
                        ></img>
                      )}
                    </a>
                  </>
                );
              } else {
                return (
                  <>
                    <p className="my-1 text-success">
                      {" "}
                      {documents.other_document != null
                        ? `Image --> ${
                            documents.other_document.name &&
                            documents.other_document.name.split(".")[0]
                          }  Selected`
                        : null}{" "}
                    </p>
                    <a href={imagePreview.other_document} target="_blank">
                      <img
                        src={
                          imagePreview.other_document
                            ? imagePreview.other_document
                            : "/images/download2.png"
                        }
                        className="img-responsive m-2"
                        style={{ width: "60px", height: "40px" }}
                      />
                    </a>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <br />

      <br></br>
      <div className="text-center my-4 mb-2">
        {/* <Link to={"/accomodation/finance/7/" + accomodation_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link> */}

        <button
          onClick={() => history.goBack()}
          className="btn btn-grey border border-primary mx-2"
        >
          BACK{" "}
        </button>

        {update ? (
          <button
            onClick={updateAccommodationDocuments}
            className="btn btn-primary mx-2"
            disabled={uploadingMedia}
          >
            {uploadingMedia ? "UPDATING..." : "UPDATE"}
          </button>
        ) : (
          <button
            onClick={submitAccommodationDocuments}
            className="btn btn-primary mx-2"
            disabled={uploadingMedia}
          >
            {uploadingMedia ? "UPLOADING..." : "CONTINUE"}
          </button>
        )}
      </div>
      {/* <button id="fla">BACK</button>
 &nbsp;
      <button onClick={submitAccommodationDocuments} id="fla-1">CONTINUE</button> */}
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
