import React, { useState, useEffect, useRef } from "react";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function BasicAccommodationImages() {
  const classes = useStyles();
  const image_ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const accommodation_image_ref = useRef(null);
  const { tour_id } = useParams();

  const user = useSelector((state) => state.auth.user);
  // let tourActionState = useSelector((state) => state.tour.tour);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
  }, [user]);

  const [accomodationImage, setAccomodationImage] = useState({
    tour_package: tour_id,
    accommodation_image: [],
  });

  const [accomodationImgList, setAccomodationImgList] = useState(null);
  const [updateForm, setUpdateForm] = useState(false);

  const [imagePreview, setImagePreview] = React.useState({
    accommodation_image_change: false,
    accommodation_image: [],
  });

  function getFormDetails() {
    axios
      .get(`partners/tour-package-image-list/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package image  get",
          res.data
        );
        if (res.data != null && res.data.length > 0) {
          setUpdateForm(true);
          let arr = [];
          let i = 0;
          for (i = 0; i < res.data.length; i++) {
            if(res.data[i].image!=null){
              arr.push(res.data[i].image);
            }
          }
          setAccomodationImgList(res.data);
          setAccomodationImage({
            ...accomodationImage,
            accommodation_image: arr,
          });
          setImagePreview({ ...imagePreview, accommodation_image: arr });
        }
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package image get", err);
      });
  }

  // function uploadMultipleImageHandler(key, e) {
  //   console.log("files", e.target.files);
  //   let arrImage = [];
  //   if (accomodationImage[key] != null && accomodationImage[key].length > 0) {
  //     arrImage = accomodationImage[key];
  //   } else {
  //     arrImage = [];
  //   }
  //   if (key == "accommodation_image") {
  //     let i = 0;
  //     for (i = 0; i < e.target.files.length; i++) {
  //       if (arrImage.length <= 4) {
  //         arrImage.push(e.target.files[i]);
  //       } else {
  //         toast("Max 4 images allowed");
  //         break;
  //       }
  //       // console.log('target files',e.target.files[i])
  //     }
  //   }
  //   setAccomodationImage({ ...accomodationImage, [key]: arrImage });
  //   let obj = {};
  //   obj[key + "_change"] = true;
  //   // if(obj[key+'_change']!==obj[key])
  //   let i = 0;
  //   obj[key] = [];
  //   if (imagePreview[key] != null && imagePreview[key].length > 0) {
  //     obj[key] = imagePreview[key];
  //   } else {
  //     obj[key] = [];
  //   }
  //   for (i = 0; i < e.target.files.length; i++) {
  //     // obj[key][i] = URL.createObjectURL(e.target.files[i]);
  //     if (arrImage.length <= 4) {
  //       obj[key].push(URL.createObjectURL(e.target.files[i]));
  //     } else {
  //       // toast("Max 4 images allowed")
  //       break;
  //     }
  //   }
  //   setImagePreview({ ...imagePreview, ...obj });

  //   console.log("image preview", imagePreview);
  // }

  // function deleteMultipleImage(key, index) {
  //   let arr = accomodationImage[key];
  //   arr.splice(index, 1);
  //   setAccomodationImage({ ...accomodationImage, [key]: arr });
  //   let arrImagePreview = imagePreview[key];
  //   arrImagePreview.splice(index, 1);
  //   setImagePreview({ ...imagePreview, ...arrImagePreview });
  // }

  function uploadMultipleImageHandler(key, e) {
    let i = 0;
    let imagePreviewArr = [];
    let imageFileArr = [];
    console.log(e.target.files,'this are the files');
    for(i=0;i<e.target.files.length;i++){
      imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      imageFileArr.push(e.target.files[i]);
    }
    if(imagePreviewArr.length+imagePreview['accommodation_image'].length>4){
      toast("Max 4 images allowed")
    }else{
      setImagePreview({...imagePreview,'accommodation_image':[...imagePreview['accommodation_image'], ...imagePreviewArr],'accommodation_image_change':true});
      setAccomodationImage({...accomodationImage,'accommodation_image':[...accomodationImage['accommodation_image'], ...imageFileArr]});
    }
    e.target.value=null;
  }

  function deleteMultipleImage(key, indexitem) {
    const imagesFilter = accomodationImage[key].filter((item, index) => index !== indexitem);
    setAccomodationImage({...accomodationImage, [key]:imagesFilter});
    let obj = {};
    obj[key + "_change"] = true;
    const imagesPreviewFilter = imagePreview[key].filter((item, index) => index !== indexitem);
    obj[key] = imagesPreviewFilter;
    setImagePreview({ ...imagePreview,...obj});
  }

  function submitAccommodationImages() {
    let formData = new FormData();
    formData.append("tour_package", accomodationImage.tour_package);
    if (accomodationImage.accommodation_image != null) {
      let i = 0;
      for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
        if (imagePreview.accommodation_image_change) {
          formData.append(
            `tour_package_image_${i}`,
            accomodationImage.accommodation_image[i]
          );
        }
      }
    }
    axios
      .post("partners/tour-package-image-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package basics create",
          res.data
        );
        history.push("/home/");
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating accomodation basics", err);
      });
  }

  function updateAccommodationImages() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(accomodationImage)) {
      if (key != "accommodation_image") {
        formData.append(key, value);
      } else {
        if (value != null && imagePreview[key + "_change"]) {
          // formData.append(key,value);
          if (accomodationImage.accommodation_image != null) {
            // let i = 0;
            // for (i = 0; i < accomodationImage.accommodation_image.length; i++) {
            //   if (imagePreview.accommodation_image_change) {
            //     formData.append(
            //       `image_${i}`,
            //       accomodationImage.accommodation_image[i]
            //     );
            //     formData.append(`image_${i}_id`, accomodationImgList[i].id);
            //   }
            // }
            if (imagePreview.accommodation_image_change) {
              let i = 0;
              for (i = 0; i < 4; i++) {
                if (imagePreview.accommodation_image_change) {
                  if (accomodationImage.accommodation_image.length > i) {
                    formData.append(`image_${i}`, accomodationImage.accommodation_image[i]);
                  }
                  if (accomodationImgList.length > i) {
                    formData.append(`image_${i}_id`, accomodationImgList[i].id);
                  }
                }
              }
            }
            
          }
          axios
            .put(
              `partners/tour-package-image-back-update`,
              formData,
              {
                headers: {
                  Authorization: "Token " + cookies.get('token'),
                // Authorization: "Token " + user.token,
                },
              }
            )
            .then((res) => {
              console.log(
                "response from submitting the tour package basics update",
                res.data
              );
              history.push("/home/");
            })
            .catch((err) => {
              // let obj = err.response.data.errors;
              // Object.entries(obj).map((item, key) => {
              //   toast(" " + item[0] + ":" + item[1][0]);
              // });
              console.log("ERROR  from update accomodation basics", err);
            });
          //   let i=0;
          // for(i=0;i<value.length;i++){
          //   console.log('value of i',i)
          //   formData.append(`accommodation_image_${i}`,value);
          //   axios.put(`partners/tour-package-basic-info-back-update/${accomodationImgList[i].id}`, formData, {
          //     headers: {
          //       'Authorization': 'Token ' + user.token
          //     }
          //   }).then(res => {
          //     console.log('response from submitting the tour package basics update', res.data)
          //     // history.push('/tour-package/day-plan/2/'+tour_id)
          //   }).catch(err => {
          //     let obj = err.response.data.errors;
          //     Object.entries(obj).map((item, key) => {
          //       toast(' ' + item[0] + ':' + item[1][0])
          //     })
          //     console.log('ERROR  from update accomodation basics', err);
          //   })
          // }
        }
        let i = 0;
        for (i = 0; i < value.length; i++) {
          // if(imagePreview.accommodation_image_change[i])
          // formData.append(`accommodation_image_${i}`,value[i]);
          // axios.put(`partners/tour-package-basic-info-back-update/${accomodationImgList.id}`, formData, {
          //   headers: {
          //     'Authorization': 'Token ' + user.token
          //   }
          // }).then(res => {
          //   console.log('response from submitting the tour package basics update', res.data)
          //   history.push('/tour-package/day-plan/2/'+tour_id)
          // }).catch(err => {
          //   console.log('ERROR  from update accomodation basics', err);
          // })
        }
      }
    }
    // axios.put(`partners/tour-package-basic-info-back-update/${accomodationImgList.id}`, formData, {
    //   headers: {
    //     'Authorization': 'Token ' + user.token
    //   }
    // }).then(res => {
    //   console.log('response from submitting the tour package basics update', res.data)
    //   history.push('/tour-package/day-plan/2/'+tour_id)
    // }).catch(err => {
    //   console.log('ERROR  from update accomodation basics', err);
    // })
  }

  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Images</h3>
      {/* <i><span style={{ color: "red", marginRight: "1%", fontWeight: "bold" }}> * </span> Mandatory field</i> */}
      <div className="p-3 mb-3 bg-white">
        {/* <p><span style={{ color: "red" }}>*</span> mandatory field</p> */}
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
              <li>
                {" "}
                <b>'Tour Package Images'</b> will be additional images of your tour
                package (Maximum 4 images are allowed).
              </li>
              <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
            </ul>
          </p>
        </div>
      </div>
      {/* 
      <div className="Main media" style={{ padding: '3%' }}>
        <div className="media_content">
          <h4 style={{ display: "flex" }}>Logo of Buisness <span style={{ color: "red" }}>*</span></h4>
          <p>The logo will reprent buisness in our site</p>
          <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p>
        </div>
        <div className="button_media">
          <input className="d-none" type="file" ref={image_ref} onChange={(e) => uploadFileHandler('image', e)} />
          <Button onClick={() => { image_ref.current.click() }} variant="contained" color="#4A8CFF" >
            Upload file
        </Button>
          <p className="my-1 text-success"> {basic.image != null ? 'Image Selected' : null} </p>
          <div className="multiple-image">
            <div onClick={() => delUploadFileHandler('image')} className="multiple-image-del px-1">x</div>
            <img src={imagePreview.image} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />
          </div>
        </div>

      </div> */}
      <br />
      <br />
      <div className="Main image">
        <div className="media_content">
          <div>
            <h4 style={{ marginBottom: "4%", display: "flex" }}>
              Additional Image(s)
              <i
                style={{
                  marginLeft: "1%",
                  fontSize: "0.6em",
                  marginTop: "0.5%",
                }}
              ></i>
            </h4>
          </div>

          <div className="media_content_video">
            <div className="button_media videoButton ">
              <input
                className="d-none"
                type="file"
                multiple
                ref={accommodation_image_ref}
                onChange={(e) =>
                  uploadMultipleImageHandler("accommodation_image", e)
                }
              />
              <Button
                onClick={() => {
                  accommodation_image_ref.current.click();
                }}
                variant="contained"
                color="#4A8CFF"
              >
                Add Image(s)
              </Button>
              <p className="my-1 text-success">
                {" "}
                {accomodationImage.accommodation_image != null
                  ? " "
                  : null}{" "}
              </p>
            </div>
           
          </div>
          <div className="col-sm-7">
          <div className="d-flex">
            {imagePreview["accommodation_image"] &&
              imagePreview["accommodation_image"].map((item, index) => (
                <div className="multiple-image">
                  <div
                    onClick={() =>
                      deleteMultipleImage("accommodation_image", index)
                    }
                    className="multiple-image-del px-1"
                  >
                    x
                  </div>
                  <a href={item} target="_blank">
                  <img
                    src={item}
                    className="img-responsive m-2 imgresp"
                    style={{ width: "100px", height: "100px" }}
                  />
                  </a>
                </div>
              ))}
          </div>
        </div>
          {/* <p style={{ marginTop: '2%' }}>Upload your accommodation image</p> */}
          {/* <p style={{ color: '#a7a7a7' }}>(Maximum 4 images are allowed, image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
        </div>
      </div>

      <div className="my-4 mb-2">
        <Link to={"/tour-package/policy/7/" + tour_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {updateForm ? (
          <button
            onClick={updateAccommodationImages}
            className="btn btn-primary mx-2"
          >
            UPDATE
          </button>
        ) : (
          <button
            className="btn btn-primary mx-2"
            onClick={submitAccommodationImages}
          >
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default BasicAccommodationImages;
