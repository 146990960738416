import React, { Component, Fragment, useEffect, useState, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from '../../axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as authActions from '../../store/actions/auth';
import { useHistory } from "react-router-dom";
import '../home.css'
import { Link, NavLink } from 'react-router-dom';
import "./tourPackage.css";
import Cookies from 'universal-cookie';
import * as tourActions from '../../store/actions/tour';

const cookies = new Cookies();


export default function TourPackage() {
  const [email, setEmail] = useState(null);
  const [accommodationList, setAccommodationList] = useState(null);
  const [tourOperator, setTourOperator] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [inComplete, setInComplete] = useState(false);
  const user = useSelector((state) => state.auth.user);
  let tour_id = useSelector((state) => state.tour.tour_id);

  //   console.log('accommodation action',accommodationAction);

  useEffect(() => {
    getAccomodationBasicsList()
    getTourSafariOperator()
  }, [user])

  // if (!user) {
    // }
  if(!cookies.get('token'))
    history.push('/login')
    
  function getTourSafariOperator() {
    axios.get(`partners/partner-tour-safari-detail`, {
      headers: {
        // 'Authorization': 'Token ' + user.token
        'Authorization': 'Token ' + cookies.get('token')
      }
    }).then(res => {
      //   setAccommodationList(res.data);
      setTourOperator(res.data.tour_operator_id)
      console.log('response from get tour safari operator', res.data);
    }).catch(err => {
      console.log('error getting tour safari operator', err);
    })
  }

  function getAccomodationBasicsList() {
    axios.get(`partners/tour-package-basic-info-list`, {
      headers: {
        // 'Authorization': 'Token ' + user.token
        'Authorization': 'Token ' + cookies.get('token')
      }
    }).then(res => {
      setAccommodationList(res.data);
      console.log('response from get accomodation basic', res.data);
    }).catch(err => {
      console.log('error getting accommodation basic list', err);
    })
  }

  function statusBgColor(status) {
    if (status == 'Getting Reviewed') {
      return 'yellow';
    } else if (status == 'Incomplete') {
      return 'lightblue'
    } else if (status == 'Published') {
      return 'green'
    } else if (status == 'Approval Denied') {
      return 'red'
    }
  }

  function createTourOperator() {
    history.push('/tour-registration/basic/1')
  }

  function addAccommodation() {
    try {
      dispatch(tourActions.tourid(tourOperator));
      history.push('/tour-package/basic/1/' + user.tour_operator_id)
    } catch (err) {
      console.log('error accomodation actions', err)
    }
  }

  function editAccommodation(item) {

    try {
      dispatch(accommodationActions.accommodation(item));
      history.push('/accomodation/basic/1')
    } catch (err) {
      console.log('error logging into signin actions', err)
    }
  }

  function viewAccommodation(item) {
    try {
      dispatch(accommodationActions.accommodation(item));
      history.push('/accomodation-view-form/' + item.id)
    } catch (err) {
      console.log('error logging into signin actions', err)
    }
  }
  const [registration, setRegistration] = useState(null);
  const [reviewed, setReviewed] = useState(false);
  const [denied, setDenied] = useState(false);
  const [published, setPublished] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    const getRegistrationData = async () => {
      await axios.get(`partners/partner-tour-safari-detail`, {
        headers: {
          // 'Authorization': 'Token ' + user.token
          'Authorization': 'Token ' + cookies.get('token')
        }
      }).then((resp, err) => {
        if (err) {
          console.log('error in registration data', err);
        }
        else {
          console.log('data on registration data', resp.data);
          setRegistration(resp.data);
          if (resp.data) {
            if (resp.data.tour_registration_status == 'Incomplete'||resp.data.tour_registration_status == 'Approved and Edit Incomplete') {
              setInComplete(true);
            }
            else if (resp.data.tour_registration_status == 'Getting Reviewed'
            ||resp.data.tour_registration_status == 'Approved and Edit Getting Reviewed' 
            ||resp.data.tour_registration_status == 'Rejected and Edit Getting Reviewed') {
              setReviewed(true);
            }
            else if (resp.data.tour_registration_status == 'Approval Denied') {
              setDenied(true);
            }
            else if (resp.data.tour_registration_status == 'Published') {
              setPublished(true);
            }
            else if (resp.data.tour_registration_status == 'Approved') {
              setApproved(true);
            }
          }
        }
      })
    }
    getRegistrationData();
  }, [user])


  function unPublishPartnerHandler(unPublishId){
    axios.put(`partners/tour-operator-unpublish/${unPublishId}`, {}, {
        headers: {
            // 'Authorization': 'Token ' + user.token
            'Authorization': 'Token ' + cookies.get('token')
          }
    }).then((response,err) => {
        if(err){
            console.log('Error in Unpublishing accomdation package ',err);
        }
        else{
            console.log('after Puvlishingi the acoomoadatin package ',response.data);
            alert('Successfully Sent Request To Admin')
          }
    })    
}
function unPublishPackageHandler(unPublishId){
  axios.put(`partners/tour-package-unpublish/${unPublishId}`, {}, {
      headers: {
          // 'Authorization': 'Token ' + user.token
          'Authorization': 'Token ' + cookies.get('token')        
        }
  }).then((response,err) => {
      if(err){
          console.log('Error in Unpublishing accomdation package ',err);
      }
      else{
          console.log('after Puvlishingi the acoomoadatin package ',response.data);
          alert('Successfully Sent Request To Admin')
      }
  })    
}


  return (
    <div>
      <div className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white" style={{ position: 'relative' }}>
        <span> <img src={(registration && registration.tour_operator_image) ? registration.tour_operator_image : "/images/image.png"} style={{ width: '9rem', borderRadius: '50%', height: '100%', }} /></span>
        <span className="tour-name"> <p style={{ font: "normal normal normal 28px/34px Lato" }}>{(registration && registration.tour_operator_name) ? registration.tour_operator_name : 'Welcome Tour Partner'}</p>
          <p>{(registration && registration.tour_operator_id) ? ("") : 'To register as "Tour Partner", proceed by clicking button below.'}</p>
          <p style={{ background: 'linear-gradient(to right top, #65dfc9, #0fe63f)', WebkitBackgroundClip: 'text', fontWeight: 'bold', WebkitTextFillColor: 'transparent', marginBottom: '0' }}>Status : {registration && ((registration.tour_registration_status) ? (registration.tour_registration_status) : ' Not Registered')}</p>
          {denied && <p style={{ marginTop: '0', fontSize: '12px' }}>(refer Admin Comment for details)</p>}
        </span>
        <br />
        {/*<div className="tour-btn">*/}
        <div className="text-right mt-auto bg-yellow" style={{  right: '0', bottom: '1rem' }}>
          {inComplete &&
            <button onClick={() => createTourOperator()} className="btn btn-primary mx-2" > Complete Profile</button>

          }
          {reviewed &&
            <Link to={`/tour-view-form/${registration.tour_operator_id}`} >
              <button className="btn btn-primary mx-2" > View Profile</button>
            </Link>
          }
          {denied &&
            <>
              <Link to={`/tour-view-form/${registration.tour_operator_id}`} >
                <button className="btn btn-primary mx-2" > View Profile</button>
              </Link>
              <button onClick={() => createTourOperator()} className="btn btn-primary mx-2" > Edit & Re-Submit</button>
              <Link to={`/admin-comment-reg-view-form/${registration.tour_operator_id}`}>
                <button className="btn btn-primary mx-2" > Admin Comment</button>
              </Link>
            </>
          }
          {published &&
            <>
              <button onClick={() => createTourOperator()} className="btn btn-primary mx-2" > Edit Profile</button>
              <Link to={`/tour-view-form/${registration.tour_operator_id}`} >
                <button className="btn btn-primary mx-2" > View Profile</button>
              </Link>
              <button onClick={() => unPublishPartnerHandler(registration.tour_operator_id)}  className="btn btn-primary mx-2" > UnPublish</button>
            </>
          }
          {approved&&
            <>
               <button onClick={() => createTourOperator()} className="btn btn-primary mx-2" > Edit Profile</button>
              <Link to={`/tour-view-form/${registration.tour_operator_id}`} >
                <button className="btn btn-primary mx-2" > View Profile</button>
              </Link>
              <button onClick={() => unPublishPartnerHandler(registration.tour_operator_id)}  className="btn btn-primary mx-2" > UnPublish</button>
            </>
          }
          {/*(accommodationList && accommodationList.length) ? (
                <Fragment>
                <Link to={`/tour-view-form/${accommodationList[0].tour_operator[0].id}`} >
                <button className="btn btn-primary mx-2" disabled={!(accommodationList && accommodationList.length)}> View Profile</button>
                </Link>
                <button className="btn btn-primary mx-2" disabled={!(accommodationList && accommodationList.length)}> Edit Profile</button>
            </Fragment>
            ):('')
            */}

        </div>
        {/*</div>*/}
      </div>

      <div className="d-flex p-2 justify-content-between">
        <div>
          <h4>Tour Packages</h4>
        </div>

        {console.log(registration, 'this is user to check registraiton')}

        {//(tourOperator&&!setTourOperator)  

          (registration && !registration.tour_operator_id) ?
          
            <div className="ml-auto">
              <button onClick={() => createTourOperator()} className="btn btn-primary" hidden={inComplete || reviewed || denied || published}>Register as Partner</button>
            </div>
            :
            <div className="ml-auto">
              <button onClick={() => addAccommodation()} className="btn btn-primary" hidden={inComplete || denied}>Add Tour Package</button>
            </div>
          
        }
      </div>
      <div>

        {/* <div>
          <button onClick={()=>createTourOperator()} className="btn btn-primary" >Create Tour Partner</button>
          <Link to={`/tour-registration/basic/1`} >
          <button onClick={()=>createTourOperator()} className="btn btn-primary" >Edit Tour Partner</button>
          </Link>
          <button onClick={()=>addAccommodation()} className="btn btn-primary" >Add Tour Package</button>

          </div> */}

        {accommodationList && accommodationList.map(item =>
          <div className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white">
            <div className="accommodation-list-item-tag d-flex" style={{ backgroundColor: statusBgColor(item.status) }} >
              <p className="m-auto">{item.status}</p>
            </div>
            <div>
              <img className="img-responsive imgmedia" src={item.image?item.image:"/images/assets/dummy-home.png"} style={{ width: '300px', height: '300px', backgroundColor: 'green' }} />
            </div>
            <div className="p-2 px-3 d-flex align-item-strech justify-content-between w-100">
              <div className="text-left  w-50 overflow-auto widthmediaquery">
                <h4 style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}} className="titelname"> {item.name} </h4>
                {item.national_park&&
                <p style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}} className="namemedia"> National park: {item.national_park} </p>
                }
             <div>
                {item.tour_overview&&
                //  <p className="wordsmediaquery">Overview:  {item.tour_overview}</p>
                  <p>Overview:   { item.tour_overview.substring(0,350) }</p>
                }
                </div>
                
                <p className="accommodation-home-total-count"></p>
                <p className="accommodation-home-address-text"> {item.address_1} , {item.address_2} </p>
              
                {(item.status != 'Approval Denied' && item.status != 'Getting Reviewed' && item.status != 'Incomplete') &&
                  <Fragment>
                    <button onClick={() => unPublishPackageHandler(item.id)} className="btn btn-primary mx-2"> Unpublish</button>
                    <Link to={"/tour-package/basic/1/" + item.id}>
                      <button className="btn btn-primary mx-2"> Edit</button>
                    </Link>
                  </Fragment>
                }
                {item.status == 'Approval Denied' &&
                <>
                  <Link to={"/tour-package/basic/1/" + item.id}>
                    <button className="btn btn-primary mx-2"> Edit & Re-Submit</button>
                  </Link>
                  <Link to={'/admin-comment-tour-package-form/'+item.id}>
                  <button className="btn btn-primary mx-2"> Admin Comment</button>
                  </Link>
                </>
                }
                {(item.status != 'Approval Denied' && item.status != 'Incomplete') &&
                <>
                    {/*<Link to={"/tour-package/basic/1/" + item.id}>
                      <button className="btn btn-primary mx-2"> Edit</button>
              </Link>*/}
                  <Link to={`/tour-package-view-form/${item.id}`}><button className="btn btn-primary mx-2"> View</button></Link>
                </>
                }
                {item.status == 'Incomplete' &&
                  <Link to={"/tour-package/basic/1/" + item.id}>
                    <button className="btn btn-primary mx-2"> Complete</button>
                  </Link>
                }

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}