import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, Space } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_features() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { safari_id, id } = useParams();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getAmenitiesData();
  }, [user]);

  const [amenity, setAmenity] = useState({
    safari: id,
    bottle_holder: false,
    charging_point: false,
    mobile: false,
    laptop: false,
    camera_battery: false,
    bean_bag: false,
    cushioned_floor_mat: false,
    gps: false,
    rain_gear: false,
    binocular: false,
    bird_book: false,
    picnic_basket: false,
    colour_box: false,
    other: "",
  });

  const handleChange = (event) => {
    setAmenity({ ...amenity, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setAmenity({ ...amenity, [event.target.name]: event.target.checked });
  };

  function submitAmenities() {
    axios
      .post("partners/safari-amenity-create", amenity, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package amenity create",
          res.data
        );

        history.push("/safari-package/policy/5/" + safari_id + "/" + id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating amenity safari package", err);
      });
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  // const [id, setid] = useState(null);

  const getAmenitiesData = async () => {
    console.log("krishna");
    await axios
      .get(`partners/safari-amenity-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in AMENITIES GET DATA ", err);
        } else {
          console.log("CURRENCY AMENITIES GET DATA ", response.data);

          if (response.data && response.data.id) {
            setcheckUpdate(true);
            setAmenity(response.data);
            // setid(response.data.id);
          }
          {
            /*Object.entries(data).map((item) => {
              console.log(' value ',item[0],item[1]);
                handleUpdate(item[0],item[1]);
          }
            )
        }*/
          }
        }
      });
  };

  const updateTourBasic = async () => {
    axios
      .put(
        `partners/safari-package-amenity-back-update/${amenity.id}`,
        amenity,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the safari package AMENITIES  UPDATE",
          res.data
        );

        history.push("/safari-package/policy/5/" + safari_id + "/" + id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from UPDATING AMENITIES safari package", err);
      });
  };

  return (
    <div className="TourReg_basic">
      <div className="text-left"><h4>Safari / Game Drive Package: Amenities in Vehicle</h4></div>
      <br></br>
      <div className="basic_company">
        <h5 style={{ display: "flex" }}>Select Available Amenities </h5>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bottle_holder}
                onChange={handleChangeCheck}
                checked={amenity.bottle_holder}
                name="bottle_holder"
                color="primary"
              />
            }
            label="Water Bottle Holder"
          />
        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.charging_point}
                onChange={handleChangeCheck}
                checked={amenity.charging_point}
                name="charging_point"
                color="primary"
              />
            }
            label="Charging Points"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.mobile}
                onChange={handleChangeCheck}
                checked={amenity.mobile}
                name="mobile"
                color="primary"
              />
            }
            label="Mobile"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.laptop}
                onChange={handleChangeCheck}
                checked={amenity.laptop}
                name="laptop"
                color="primary"
              />
            }
            label="Laptop"
          />
        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.camera_battery}
                onChange={handleChangeCheck}
                checked={amenity.camera_battery}
                name="camera_battery"
                color="primary"
              />
            }
            label="Camera"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bean_bag}
                onChange={handleChangeCheck}
                checked={amenity.bean_bag}
                name="bean_bag"
                color="primary"
              />
            }
            label="Bean Bags"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.cushioned_floor_mat}
                onChange={handleChangeCheck}
                checked={amenity.cushioned_floor_mat}
                name="cushioned_floor_mat"
                color="primary"
              />
            }
            label="Cushioned Floor Mat"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.gps}
                onChange={handleChangeCheck}
                checked={amenity.gps}
                name="gps"
                color="primary"
              />
            }
            label="GPS"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.rain_gear}
                onChange={handleChangeCheck}
                checked={amenity.rain_gear}
                name="rain_gear"
                color="primary"
              />
            }
            label="Rain Gear"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.binocular}
                onChange={handleChangeCheck}
                checked={amenity.binocular}
                name="binocular"
                color="primary"
              />
            }
            label="Binoculars"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bird_book}
                onChange={handleChangeCheck}
                checked={amenity.bird_book}
                name="bird_book"
                color="primary"
              />
            }
            label="Bird Book"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.picnic_basket}
                onChange={handleChangeCheck}
                checked={amenity.picnic_basket}
                name="picnic_basket"
                color="primary"
              />
            }
            label="Picnic Baskets"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.colour_box}
                onChange={handleChangeCheck}
                checked={amenity.colour_box}
                name="colour_box"
                color="primary"
              />
            }
            label="Cooler Box ( Tick Box )"
          />
        </div>
        <br></br>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <p style={{ display: "flex" }}>Other Amenities / Brief Note<span style={{ color: "red" }}> * </span></p>
          <TextArea
            value={amenity.other}
            onChange={handleChange}
            name="other"
            placeholder="Maximum 350 characters"
          />
        </form>
      </div>

      <br />

      <br />

      <div className="my-4 mb-2">
        <Link to={"/safari-package/tour-guide/3/" + safari_id + "/" + id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitAmenities}>
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_features;
