import React, { Component, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./landing-home.css"

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import axios from '../axios';
import * as authActions from '../store/actions/auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function LandingHome() {

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  if(cookies.get('token')){
    history.push(('/home'))
  }
  // useEffect(() => {
  //   console.log('user data', user);
  // }, [user])

  // const logout = async () => {
  //   axios.post('auth/logout', {}, {
  //     headers: {
  //       'Authorization': 'Token ' + user.token
  //     }
  //   }).then(res => {
  //     try {
  //       history.push('/login')
  //       toast("successfull");
  //       dispatch(authActions.logout());
  //     } catch (err) {
  //       console.log('error logging out ', err)
  //     }
  //   }).catch(err => {
  //     // const notify = () => toast("Wow so easy !");
  //     toast("Error loggin out ");
  //   })
  // }

  return (
    <div >


      <div className="one_che">
        {/* <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="#">
            <img src="/images/logo/Mask Group 4.png" width="150" height="50" alt="" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">


            </ul>
            <div className="flex-grow-1 text-right">
              {user == null ?
                <span>
                  <Link to="/login">
                    <button className="btn btn-primary mx-2">SIGN IN</button>
                  </Link>
                  <Link to="/signup">
                    <button className="btn btn-primary mx-2">SIGN UP</button>
                  </Link>
                </span>
                :
                <ul class="navbar-nav flex-grow-1 text-right">
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {user.email}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" href="#">Our Property</a>
                      <a class="dropdown-item" href="#">Our Safari/Game drives</a>
                      <div class="dropdown-divider"></div>
                      <p onClick={logout} class="dropdown-item" >Logout</p>
                    </div>
                  </li>
                  <li class="nav-item">
                    <img src="/images/assets/profile_dummy_image.png" className="profile-image-nav img-responsive" />
                  </li>
                </ul>
              }
            </div>
          </div>
        </nav> */}
        <br></br>
        <div className="d-flex p-div" >
          <div className="flex-grow-1 justify-content-end w-100">
            <div className="sidelay">
              <div className="sidelay-bg"></div>
              <div className="sidelay-content text-white">
                <h1 >Welcome as Partner </h1>
                <br></br>
                <p >
                  "Great things are never done by one but by a team" We welcome you from the bottom of our hearts to partner with us on this wonderful journey to showcase your properties and services to guests across the World. We will make them experience The Wild as it has been never before and accomplish something extraordinary.
                    </p>
                <Link to="/signup">
                  <button className="regis">
                    REGISTER
                      </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

  );

}
