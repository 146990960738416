import React, { useState, useEffect, useRef } from "react";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import Select from "@material-ui/core/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as tourAction from "../../store/actions/tour";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_basic() {
  const classes = useStyles();
  const image_ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { tour_id } = useParams();
  const [uploadingMedia, setuploadingMedia] = useState(false)


  const user = useSelector((state) => state.auth.user);
  // let tourActionState = useSelector((state) => state.tour.tour);

  useEffect(() => {
    // if(accommodationAction!=null&&accommodationAction.id!=undefined&&accommodationAction!={}){
    //   getFormDetails()
    // }
    window.scrollTo(0,0);

    getFormDetails();
    getCurrencyList();
  }, [user]);

  const [basic, setBasic] = useState({
    tour_operator: tour_id,
    partner: null,
    name: null,
    tour_overview: null,
    start_point: null,
    start_date: null,
    end_date: null,
    day_of_operation: null,
    total_day: null,
    major_town: false,
    visiting_park: false,
    image: null,
  });

  const [updateForm, setUpdateForm] = useState(false);

  const [imagePreview, setImagePreview] = React.useState({
    image_change: false,
    image: null,
  });
  const [nationalParkList, setnationalParkList] = useState([]);

  const handleChange = (event) => {
    setBasic({ ...basic, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setBasic({ ...basic, [event.target.name]: event.target.checked });
  };

  function formatDate(date) {
    let new_date = new Date(date);
    let year = new_date.getFullYear();
    let month = new_date.getMonth() + 1;
    let date_n = new_date.getDate();

    if (date_n < 10) {
      date_n = "0" + date_n;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let new_format = year + "-" + month + "-" + date_n;
    return new_format;
  }

  const handleStartDateChange = (date) => {
    setBasic({ ...basic, start_date: formatDate(date) });
  };
  const handleEndDateChange = (date) => {
    setBasic({ ...basic, end_date: formatDate(date) });
  };

  function uploadFileHandler(key, e) {
    setBasic({ ...basic, [key]: e.target.files[0] });
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setBasic({ ...basic, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  const [state, setState] = React.useState({
    MajorTowns: "",
    VisitingParks: "",
  });

  function getCurrencyList() {
    axios
      .get("partners/currency-national-park-list", {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setnationalParkList(res.data.national_park);
      })
      .catch((err) => {
        console.log("error getting accommodation type list", err);
      });
  }

  function getFormDetails() {
    axios
      .get(`partners/tour-package-basic-info-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package basics get",
          res.data
        );
        if (res.data != null) {
          setUpdateForm(true);
        }
        setBasic({ ...basic, ...res.data });
        setImagePreview({ ...imagePreview, image: res.data.image });
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package basics", err);
      });
  }

  function submitBasic() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(basic)) {
      if (key != "image") {
        if (value != null) {
          formData.append(key, value);
        }
      } else {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMedia(true);
    axios
      .post("partners/tour-package-basic-info-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package basics create",
          res.data
        );
        setuploadingMedia(false);
        history.push("/tour-package/day-plan/2/" + res.data.id);

        // try {
        //   dispatch(tourAction.tour(res.data));

        // history.push('/tour-package/day-plan/2/'+1)
        // } catch (err) {
        //   console.log('error tour actions', err)
        // }
      })
      .catch((err) => {
        setuploadingMedia(false);
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating accomodation basics", err);
      });
    // history.push('/tour-package/day-plan/2/'+1)
  }

  function updateBasic() {
    let formData = new FormData();
    for (const [key, value] of Object.entries(basic)) {
      if (key != "image") {
        if (value != null){
          formData.append(key, value);
        }
      } else {
        if (value != null && imagePreview[key + "_change"]) {
          formData.append(key, value);
        }
      }
    }
    setuploadingMedia(true);
    axios
      .put(
        `partners/tour-package-basic-info-back-update/${basic.id}`,
        formData,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the tour package basics update",
          res.data
        );
        setuploadingMedia(false);
        history.push("/tour-package/day-plan/2/" + tour_id);
      })
      .catch((err) => {
        //let obj = err.response.data.errors;
        //obj!=null && Object.entries(obj).map((item, key) => {
        //toast(' ' + item[0] + ':' + item[1][0])
        //})
        setuploadingMedia(false);
        console.log("ERROR  from update accomodation basics", err);
      });
  }

  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Basic Information</h3>
      {/* <i><span style={{ color: "red", marginRight: "1%", fontWeight: "bold" }}> * </span> Mandatory field</i> */}
      {/* <br></br> */}
      <div className="p-3 mb-3 bg-white">
        <p>
          <span style={{ color: "red" }}>*</span> mandatory field
        </p>
        <div className="text-left">
          <h5>Note:</h5>
          <p>
            <ul>
            <li><u>It is recommended to provide all informations.</u> </li>
              <li>
                <b>'Banner Image'</b> will be the main image of your tour package, it
                will on top of all image in respective tour package.{" "}
              </li>
              <li>
                <b>Image Guideline:</b> Image resolution should be 900*600 with 3:2
                  aspect ratio and max 2MB size with PNG, JPEG, JPEG, SVG file type.{" "}
              </li>
              <li>
                <b>Tour Overview:</b> Please enter a short desctiption of tour package
                as summary of package.
              </li>
              {/* <li> Currency: The selected currency will be applicable to all of accommodation pricing. </li> */}
              {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
            </ul>
          </p>
        </div>
      </div>

      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Name of Tour and Package
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <TextField
            value={basic.name}
            onChange={handleChange}
            name="name"
            id="outlined-basic"
            placeholder="Name"
            variant="outlined"
          />
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            Tour Overview
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>

          <TextArea
            value={basic.tour_overview}
            onChange={handleChange}
            name="tour_overview"
            rows={4}
            placeholder="Max. 350 Characters"
          />
        </form>
        <br />
      </div>

      <br />

      <br />

      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Start Point{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <TextField
            value={basic.start_point}
            onChange={handleChange}
            name="start_point"
            id="outlined-basic"
            placeholder="Location"
            variant="outlined"
           
          />
        </form>
        <br />

        <form className="date_picker ">
          <p  >
            Start Date{" "}
            <i
              style={{
                fontSize: "small",
                fontWeight: "lighter",
                marginLeft: "1%",
              }}
            ></i>
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              
              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={basic.start_date}
              onChange={handleStartDateChange}
              name="start_date"
              // value={selectedDate}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </form>
        <br />

        <form className="date_picker">
          <p>
            End Date{" "}
            <i
              style={{
                fontSize: "small",
                fontWeight: "lighter",
                marginLeft: "1%",
              }}
            ></i>{" "}
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={basic.end_date}
              onChange={handleEndDateChange}
              name="end_date"
              // value={selectedDate}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Days of Operation{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // value={daysofoperation}
              // onChange={handleChange}
              value={basic.day_of_operation}
              onChange={handleChange}
              name="day_of_operation"
            >
              <MenuItem value={"Daily"}>Daily</MenuItem>
              <MenuItem value={"Weekly"}>Weekly</MenuItem>
              <MenuItem value={"DayWise"}>Day Wise</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>
            Total Days of Tour{" "}
            <span style={{ marginLeft: "1%", color: "red" }}>*</span>
          </p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // value={tourdays}
              // onChange={handletourdays}
              value={basic.total_day}
              onChange={handleChange}
              name="total_day"
            >
              <MenuItem value={"Daily out"}>Daily out</MenuItem>
              <MenuItem value={"2-3 Nights"}>2-3 Nights</MenuItem>
              <MenuItem value={"4-5 Nights"}>4-5 Nights</MenuItem>
              <MenuItem value={"6-8 nights"}>6-8 Nights</MenuItem>
              <MenuItem value={"More than 10 days"}>More than 10 days</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </form>
      </div>
      <div
        className="Currency_picker"
        style={{ background: "white", padding: "4%", paddingBottom: "0" }}
      >
        <p style={{ marginBottom: "5px" }}>National Park<span style={{ marginLeft: "1%", color: "red" }}> </span></p>

        <TextField
          id="outlined-select-currency-native"
          select
          name="national_park"
          placeholder="National Park"
          value={basic.national_part}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          // helperText="Please select your National Park"
          variant="outlined"
          style={{ display: "flex", backgroundColor: "white" }}
        >
          <option>--Select National Park--</option>
          {nationalParkList != null &&
            nationalParkList.length &&
            nationalParkList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </TextField>

        <br />
      </div>
      <br />
      <div className="basic_company">
        <h5 style={{ display: "flex" }}>
          Tour Route{" "}
          <span style={{ marginLeft: "1%", marginBottom: "2%", color: "red" }}>
            
          </span>{" "}
        </h5>
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.major_town}
                onChange={handleChangeCheck}
                name="major_town"
                checked={basic.major_town}
                // checked={state.MajorTowns}
                // onChange={handlecheck}
                // name="MajorTowns"
                color="primary"
              />
            }
            label="Major Towns"
          />
        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={basic.visiting_park}
                onChange={handleChangeCheck}
                name="visiting_park"
                checked={basic.visiting_park}
                // checked={state.VisitingParks}
                // onChange={handlecheck}
                // name="Visting Parks"
                color="primary"
              />
            }
            label="Visiting Parks"
          />
        </div>
      </div>

      <br />

      <div className="Main media" style={{ padding: "3%" }}>
        <div className="media_content">
          <h5 style={{ display: "flex" }}>
            Banner Image <span style={{ color: "red" }}> * </span>
          </h5>
          {/* <p>The banner image  will reprent tour package in our site</p> */}
          {/* <p style={{ color: '#a7a7a7' }}>(image resolution should be 900*600 with 3:2 aspect ratio and max 2MB size)</p> */}
        </div>
        <div className="button_media">
          <input
            className="d-none"
            type="file"
            ref={image_ref}
            onChange={(e) => uploadFileHandler("image", e)}
          />
          <Button
            onClick={() => {
              image_ref.current.click();
            }}
            variant="contained"
            color="#4A8CFF"
          >
            Upload Image
          </Button>
          <p className="my-1 text-success">
            {" "}
            {basic.image != null ? "Image Selected" : null}{" "}
          </p>
          <div className="multiple-image">
            <div
              onClick={() => delUploadFileHandler("image")}
              className="multiple-image-del px-1"
            >
              x
            </div>
            <a href={imagePreview.image} target="_blank">
            <img
              src={
                imagePreview.image
                  ? imagePreview.image
                  : "/images/download2.png"
              }
              className="img-responsive m-2"
              style={{ width: "30px", height: "30px" }}
            /></a>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="my-4 mb-2">
        {updateForm ? (
          <button onClick={updateBasic} className="btn btn-primary mx-2" disabled={uploadingMedia}>
            {uploadingMedia ? 'UPLOADING...' : 'UPDATE'}
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitBasic} disabled={uploadingMedia}>
            {uploadingMedia ? 'UPLOADING...' : 'CONTINUE'}
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_basic;
