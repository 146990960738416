import React, { Component,useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from '../axios';
import { Link, NavLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as authActions from '../store/actions/auth';
import { useHistory } from "react-router-dom";
import './home.css'
import Home1 from './Home1'
import BussinessType from './Bussiness-Type/bussiness-type';
import TourPackage from './Tour-Package/tour-package';
import SafariPackage from './Safari-Package/safari-pacage';
import GroundPackage from './GroundLogistic-package/GroundPackage';
import Cookies from 'js-cookie';




export default function Home() {
  const [email,setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();


  return (
    <div style={{display:'flex'}}>
        <div className="left-panel d-none d-md-block " style={{padding:'1rem'}}>
        <NavLink to={'/home/'}>
            <p style={{color:'black'}}>Home</p>
          </NavLink>
          <NavLink to={'/home/accommodation'}>
            <p style={{color:'black'}}>Accommodation</p>
          </NavLink>
         
         
          {/* <NavLink to={'/tour-registration/basic/1'}>
           <p>Tour Registration</p>
          </NavLink> */}
          {/* <NavLink to={'/tour-package/basic/1'}> */}
          <NavLink to={'/home/tour-package'}>
           <p style={{color:'black'}}>Tour</p>
          </NavLink>
          {/* <NavLink to={'/safari-registration/basic/1'}>
           <p>Safari Registration</p>
          </NavLink> */}
          {/* <NavLink to={'/safari-package/vehicle-parking/1/5'}> */}
          <NavLink to={'/home/safari-package'}>
            <p style={{color:'black'}}>Safari / Game Drive</p>
          </NavLink>
          <NavLink to={'/home/ground-logistic-package'}>
            <p style={{color:'black'}}>Ground Logistics</p>
          </NavLink>
        </div>
        <div className="right-panel">
        <Switch>
                <Route path="/home/" exact component={Home1} />
                <Route path="/home/accommodation" component={BussinessType} />
                <Route path="/home/tour-package" component={TourPackage} />
                <Route path="/home/safari-package" component={SafariPackage} />
                <Route path='/home/ground-logistic-package' component={GroundPackage}></Route>
                {/* <Route path="/accomodation/submit/:accomodation_id" exact component={AccomodationSubmit}/> */}
            </Switch>
        </div>
      </div>
  );
}