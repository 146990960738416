import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";
import Safari_basic from "./Safari_basic";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, Space } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import schedule from "./schedule.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Safari_amneties() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tour_id } = useParams();
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails();
  }, [user]);

  const [amenity, setAmenity] = useState({
    tour_package: tour_id,
    bottle_holder: false,
    charging_point: false,
    mobile: false,
    laptop: false,
    camera_battery: false,
    bean_bag: false,
    cushioned_floor_mat: false,
    gps: false,
    rain_gear: false,
    binocular: false,
    bird_book: false,
    picnic_basket: false,
    colour_box: false,
    other: null,
  });
  const [updateForm, setUpdateForm] = useState(false);

  function getFormDetails() {
    axios
      .get(`partners/tour-amenity-detail/${tour_id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the tour package amenities get",
          res.data
        );
        if (res.data.detail != "Not found") {
          setUpdateForm(true);
          setAmenity({ ...amenity, ...res.data });
        }
      })
      .catch((err) => {
        console.log("ERROR  from getting tour package amenities", err);
      });
  }

  const handleChange = (event) => {
    setAmenity({ ...amenity, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setAmenity({ ...amenity, [event.target.name]: event.target.checked });
  };

  function submitAmenity() {
    axios
      .post("partners/tour-amenity-create", amenity, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package amenity create",
          res.data
        );
        history.push("/tour-package/tour-guide/6/" + tour_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating tour package amenity", err);
      });
  }

  function updateAmenity() {
    axios
      .put(`partners/tour-package-amenity-back-update/${amenity.id}`, amenity, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the tour package day plan update",
          res.data
        );
        history.push("/tour-package/tour-guide/6/" + tour_id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from update tour day plan", err);
      });
  }

  return (
    <div className="TourReg_basic">
      <div className="text-left">
        <h4>Tour Package: Amenities in Vehicle</h4>
      </div>

      <br></br>
      <div className="basic_company">
        <h5 style={{ display: "flex" }}>Select Amenities Available </h5>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bottle_holder}
                onChange={handleChangeCheck}
                checked={amenity.bottle_holder}
                name="bottle_holder"
                color="primary"
              />
            }
            label="Water Bottle Holder"
          />
        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.charging_point}
                onChange={handleChangeCheck}
                checked={amenity.charging_point}
                name="charging_point"
                color="primary"
              />
            }
            label="Charging Points"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.mobile}
                onChange={handleChangeCheck}
                checked={amenity.mobile}
                name="mobile"
                color="primary"
              />
            }
            label="Mobile"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.laptop}
                onChange={handleChangeCheck}
                checked={amenity.laptop}
                name="laptop"
                color="primary"
              />
            }
            label="Laptop"
          />
        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.camera_battery}
                onChange={handleChangeCheck}
                checked={amenity.camera_battery}
                name="camera_battery"
                color="primary"
              />
            }
            label="Camera Batteries"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bean_bag}
                onChange={handleChangeCheck}
                checked={amenity.bean_bag}
                name="bean_bag"
                color="primary"
              />
            }
            label="Bean Bags"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.cushioned_floor_mat}
                onChange={handleChangeCheck}
                checked={amenity.cushioned_floor_mat}
                name="cushioned_floor_mat"
                color="primary"
              />
            }
            label="Cushioned Floor Mat"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.gps}
                onChange={handleChangeCheck}
                checked={amenity.gps}
                name="gps"
                color="primary"
              />
            }
            label="GPS"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.rain_gear}
                onChange={handleChangeCheck}
                checked={amenity.rain_gear}
                name="rain_gear"
                color="primary"
              />
            }
            label="Rain Gear"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.binocular}
                onChange={handleChangeCheck}
                checked={amenity.binocular}
                name="binocular"
                color="primary"
              />
            }
            label="Binoculars"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.bird_book}
                onChange={handleChangeCheck}
                checked={amenity.bird_book}
                name="bird_book"
                color="primary"
              />
            }
            label="Bird Book"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.picnic_basket}
                onChange={handleChangeCheck}
                checked={amenity.picnic_basket}
                name="picnic_basket"
                color="primary"
              />
            }
            label="Picnic Baskets"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={amenity.colour_box}
                onChange={handleChangeCheck}
                checked={amenity.colour_box}
                name="colour_box"
                color="primary"
              />
            }
            label="Cooler Box"
          />
        </div>
        <br></br>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <p style={{ display: "flex" }}>Other Amenities</p>
          <TextArea
            value={amenity.other}
            onChange={handleChange}
            name="other"
            placeholder="Maximum 350 characters"
          />
        </form>
      </div>

      <br />

      <br />

      <div className="text-center my-4 mb-2">
        <Link to={"/tour-package/facility/4/" + tour_id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK
          </button>
        </Link>
        {updateForm ? (
          <button onClick={updateAmenity} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button onClick={submitAmenity} className="btn btn-primary mx-2">
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_amneties;
