import React, { Component, useState, useEffect, useRef } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import I2 from "./assests/I2.png";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function GroundReg_policies() {
  const policy_document_ref = useRef(null);
  const history = useHistory();
  const { id } = useParams();
  const [updateForm, setUpdateForm] = useState(false);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0, 0);

    getFormDetails();
  }, [user]);

  const [policy, setPolicy] = useState({
    logistics: id,
    policy_document: null,
    other: null,
    customer_review: null,
  });

  const [imagePreview, setImagePreview] = React.useState({
    policy_document_change: false,
    policy_document: null,
  });
  const [extension, setextension] = useState(null);

  function uploadFileHandler(key, e) {
    setPolicy({ ...policy, [key]: e.target.files[0] });
    if (e.target.files[0] != null) {
      setextension(e.target.files[0].name.split(".").pop());
    }
    let obj = {};
    obj[key + "_change"] = true;
    obj[key] = URL.createObjectURL(e.target.files[0]);
    setImagePreview({ ...imagePreview, ...obj });
    e.target.value=null;
  }

  function delUploadFileHandler(key) {
    setPolicy({ ...policy, [key]: null });
    let obj = {};
    obj[key + "_change"] = false;
    obj[key] = null;
    setImagePreview({ ...imagePreview, ...obj });
  }

  const handleChange = (event) => {
    setPolicy({ ...policy, [event.target.name]: event.target.value });
  };
  const [postId, setpostId] = useState(null);
  function getFormDetails() {
    axios
      .get(`partners/ground-logistic-policy-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from getting  the GL operator policy get",
          res.data
        );
        if (res.data.length > 0) {
          setUpdateForm(true);
          setpostId(res.data[0].id);
          setPolicy({ ...res.data[0] });
          setImagePreview({
            ...imagePreview,
            policy_document: res.data[0].policy_document,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR  from getting GL package basics", err);
      });
  }
  const [loadingMedia, setloadingMedia] = useState(false);
  function submitSafariPolicy() {
    let formData = new FormData();
    console.log("policy before formdata", policy.logistics);
    // formData.append('logistics',policy.logistics)
    for (const [key, value] of Object.entries(policy)) {
      if (key != "policy_document") {
        formData.append(key, value);
      } else {
        if (value != null) {
          formData.append(key, value);
        }
      }
    }
    setloadingMedia(true);
    axios
      .post("partners/ground-logistic-policy-create", formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari registration policy create",
          res.data
        );
        setloadingMedia(false);
        history.push("/ground-logistics-registration/finance/4/" + id);
      })
      .catch((err) => {
        setloadingMedia(false);
        console.log("ERROR  from creating safari registration policy", err);
        //history.push('/ground-logistics-registration/finance/5/'+id);
      });
  }

  function updateSafariPolicy() {
    let formData = new FormData();

    for (const [key, value] of Object.entries(policy)) {
      if (key != "policy_document") {
        formData.append(key, value);
      } else {
        if (value != null) {
          if (imagePreview[key + "_change"]) {
            formData.append(key, value);
          }
        }
      }
    }
    setloadingMedia(true);
    axios
      .put(`partners/ground-logistic-policy-update/${postId}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        setloadingMedia(false);
        history.push("/ground-logistics-registration/finance/4/" + id);
        console.log(
          "response from submitting the GL  policy  update",
          res.data
        );
      })
      .catch((err) => {
        setloadingMedia(false);
        history.push("/ground-logistics-registration/finance/4/" + id);
        console.log("error from GL operator policy update", err);
      });
  }

  return (
    <div className="Tour_policies">
      <h4 style={{ display: "flex", color: "#949494" }}>
        Ground Logistic Partner Registration: Policies
      </h4>
      <div className="text-left">
        <div className="p-3 mb-3 bg-white">
          <p>
            <span style={{ color: "red" }}>*</span> mandatory field
          </p>
          <div>
            <h5>Note:</h5>
            <p>
              <ul>
              <li><u>It is recommended to provide all informations.</u> </li>
                {/* <li>
                  Cancelation Policy: It is recommended to provide slab as 'Days
                  Prior Vs Percentile of Loss' in slabs such as 30 days prior /
                  15 to 30 days / 7 to 15 days / less than 7 days and mention
                  the percentile of loss against each one of them.{" "}
                </li> */}
                <li>
                  Your Own Policy Document: You may upload detail policy in pdf
                  (Max 2MB Size).{" "}
                </li>
                <li>
                  {" "}
                  Customer Review: Format must be as - Name, Review Text and One
                  line space between two review.{" "}
                </li>
                {/* <li>In the case of default text in dropdown fields is 'None' then please select any other option apart from 'None'. </li>
                           <li>It is recommended to provide appropriate cost (with Tax) against each lable of 'Price Per Accomodation'. </li> */}
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="Policies_document">
        <div className="Policies_document_main">
          {/* { <img src={I2} style={{paddingRight:'1rem'}}></img> } */}
          <h4>Policy Document (If Any) </h4>
        </div>
        <input
          className="d-none"
          type="file"
          ref={policy_document_ref}
          onChange={(e) => uploadFileHandler("policy_document", e)}
        />
        <Button
          onClick={() => {
            policy_document_ref.current.click();
          }}
          variant="contained"
          className="Policies_button"
          color="primary"
        >
          Upload file
        </Button>
        {/*<p className="my-1 text-success"> {policy.policy_document != null ? 'File Selected' : null} </p>*/}
        <div className="multiple-image">
          <div
            onClick={() => delUploadFileHandler("policy_document")}
            className="multiple-image-del px-1"
          >
            x
          </div>
          {/*<img src={imagePreview.policy_document} className="img-responsive m-2" style={{ width: '30px', height: '30px' }} />*/}
          {(() => {
            //var fileName = imagePreview.document;
            //var fileExtension = fileName.split('.').pop();
            if (
              extension == "pdf" ||
              (policy.policy_document != null &&
                imagePreview.policy_document != null &&
                imagePreview.policy_document.split(".").pop() === "pdf" &&
                policy.policy_document.split(".").pop()) == "pdf"
            ) {
              return (
                <>
                  <p className="my-1 text-success">
                    {" "}
                    {policy.policy_document != null
                      ? `File-->${policy.policy_document.name} Selected`
                      : null}{" "}
                  </p>
                  <a href={imagePreview.policy_document} target="_blank">
                    {imagePreview.policy_document != null ? (
                      <img
                        src="/images/download.png"
                        className="img-responsive m-2"
                        style={{ width: "50px", height: "30px" }}
                      />
                    ) : (
                      <img
                        src="/images/download2.png"
                        alt="No file"
                        style={{
                          width: "50px",
                          height: "40px",
                          paddingRight: "10px",
                        }}
                      ></img>
                    )}
                  </a>
                </>
              );
            } else {
              return (
                <>
                  <p className="my-1 text-success">
                    {" "}
                    {policy.policy_document != null
                      ? `Image --> ${policy.policy_document.name}  Selected`
                      : null}{" "}
                  </p>
                  <a href={imagePreview.policy_document} target="_blank">
                    <img
                      src={
                        imagePreview.policy_document
                          ? imagePreview.policy_document
                          : "/images/download2.png"
                      }
                      className="img-responsive m-2"
                      style={{ width: "60px", height: "40px" }}
                    />
                  </a>
                </>
              );
            }
          })()}
        </div>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            Any other specific policies / company disclaimer
            <span style={{ marginLeft: "1%", color: "red" }}></span>
          </p>
          <TextareaAutosize
            rowsMin={5}
            value={policy.other!=='null' ? policy.other : ''}
            onChange={handleChange}
            name="other"
            inputProps={{
              maxlength: 350,
            }}
            id="outlined-basic"
            placeholder="Max. 350 Characters" 
            variant="outlined"
          />
        </form>
      </div>

      <br />
      <br />

      <div className="Policies_document">
        <form noValidate autoComplete="off" className="company_name About">
          <p>
            Customer Reviews
            <span style={{ marginLeft: "1%", color: "red" }}></span>
          </p>
          <TextareaAutosize
            rowsMin={5}
            value={policy.customer_review!=='null' ? policy.customer_review : ''}
            onChange={handleChange}
            name="customer_review"
            inputProps={{
              maxlength: 350,
            }}
            id="outlined-basic"
            placeholder="Max. 350 Characters" 
            variant="outlined"
          />
        </form>
      </div>
      <div className="my-4 mb-2">
        {/*<Link to={"/ground-logistics-registration/image-document/3/"+id}>*/}
        <button
          onClick={() => history.goBack()}
          className="btn btn-grey border border-primary mx-2"
        >
          BACK{" "}
        </button>
        {/*</Link>*/}

        {updateForm ? (
          <button
            onClick={updateSafariPolicy}
            className="btn btn-primary mx-2"
            disabled={loadingMedia}
          >
            {loadingMedia ? "UPDATING..." : "UPDATE"}
          </button>
        ) : (
          <button
            className="btn btn-primary mx-2"
            onClick={submitSafariPolicy}
            disabled={loadingMedia}
          >
            {loadingMedia ? "UPLOADING..." : "CONTINUE"}
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default GroundReg_policies;
