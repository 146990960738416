import React, { Component, Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import "../home.css";
import { Link, NavLink } from "react-router-dom";
import "./businessType.css"

import * as accommodationActions from "../../store/actions/accommodation";

const cookies = new Cookies();

export default function BussinessType() {
  const [email, setEmail] = useState(null);
  const [accommodationList, setAccommodationList] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);
  let accommodationAction = useSelector(
    (state) => state.accommodation.accommodation
  );

  //   console.log('accommodation action',accommodationAction);

  useEffect(() => {
    getAccomodationBasicsList();
  }, [user]);

  // if (!user) {
  //   history.push("/login");
  // }
  if(!cookies.get('token'))
    history.push('/login')

  function getAccomodationBasicsList() {
    axios
      .get(`partners/accommodation-basic-info-list`, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((res) => {
        setAccommodationList(res.data);
        console.log("response from get accomodation basic", res.data);
      })
      .catch((err) => {
        console.log("error getting accommodation basic list", err);
      });
  }

  function statusBgColor(status) {
    if (status == "Getting Reviewed") {
      return "yellow";
    } else if (status == "Incomplete") {
      return "lightblue";
    } else if (status == "Published") {
      return "green";
    } else if (status == "Approval Denied") {
      return "red";
    }
  }

  function addAccommodation() {
    let item = {};
    try {
      dispatch(accommodationActions.accommodation(item));

      history.push("/accomodation/basic/1");
    } catch (err) {
      console.log("error accomodation actions", err);
    }
  }

  function editAccommodation(item) {
    try {
      dispatch(accommodationActions.accommodation(item));
      history.push("/accomodation/basic/1");
    } catch (err) {
      console.log("error logging into signin actions", err);
    }
  }

  function viewAccommodation(item) {
    try {
      dispatch(accommodationActions.accommodation(item));
      history.push("/accomodation-view-form/" + item.id);
    } catch (err) {
      console.log("error logging into signin actions", err);
    }
  }

  function unPublishHandler(unPublishId) {
    console.log(cookies.get('token'))
    axios
      .put(`partners/accommodation-unpublish/${unPublishId}`, {}, {
        headers: {
          // Authorization: "Token " + user.token,
          Authorization: "Token " + cookies.get('token'),
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("Error in Unpublishing accomdation package ", err);
        } else {
          console.log(
            "after Publishing the accomodation package ",
            response.data
          );
          alert('Successfully Sent Request To Admin')
        }
      });
  }

  return (
    <div>
      <div className="d-flex p-2 justify-content-between">
        <div>
          <h4>Our Accommodation</h4>
        </div>
        <div className="ml-auto">
          {/* <Link to="/accomodation/type/1"> */}
          <button
            onClick={() => addAccommodation()}
            className="btn btn-primary"
          >
            Add Accommodation
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div>
        {accommodationList &&
          accommodationList.map((item) => (
            <div className="d-flex accommodation-list-item align-item-strech m-2 p-2 bg-white">
              <div
                className="accommodation-list-item-tag d-flex"
                style={{ backgroundColor: statusBgColor(item.status) }}
              >
                <p className="m-auto">{item.status}</p>
              </div>
              <div>
                {item.banner_image != null &&
                item.banner_image != "" &&
                item.banner_image != " " ? (
                  <img
                    className="img-responsive imgmediaquery"
                    src={item.banner_image}
                    style={{
                      width: "300px",
                      height: "300px",
                      backgroundColor: "green",
                    }}
                  />
                ) : (
                  <img
                    className="img-responsive"
                    src="/images/assets/dummy-home.png"
                    style={{
                      width: "300px",
                      height: "200px",
                      backgroundColor: "green",
                    }}
                  />
                )}
              </div>
              <div className="p-2 px-3 d-flex align-item-strech overflow-auto justify-content-between w-100 lg-sm-6">
                <div className="text-left w-50 overflow-auto widthmediaquery">
                  <h4 className="accommodation-home-title" style={{background:'lightpink',color:'black',padding:'.5rem',fontWeight:'500'}}> {item.name} </h4>
                  {item.national_park && (
                    <p
                      style={{
                        background: "lightpink",
                        color: "black",
                        padding: ".5rem",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      National park: {item.national_park}{" "}
                    </p>
                  )}
                <div>
                  {item.status != "Approval Denied" &&
                    item.status != "Getting Reviewed" &&
                    item.status != "Incomplete" && (
                      <Fragment>
                        <button
                          onClick={() => unPublishHandler(item.id)}
                          className="btn btn-primary mx-2 mt-1"
                        >
                          {" "}
                          Unpublish
                        </button>
                        <button
                          onClick={() => editAccommodation(item)}
                          className="btn btn-primary mx-2 mt-1"
                        >
                          {" "}
                          Edit
                        </button>
                      </Fragment>
                    )}

                  {item.status == "Approval Denied" && (
                    <React.Fragment>
                      <button className="btn btn-primary mx-2 mt-1"
                      onClick={() => editAccommodation(item)}
                      >
                        {" "}
                        Edit & Re-Submit
                      </button>
                      <Link to={"/accomodation/admin-comment-form/" + item.id}>
                        <button className="btn btn-primary mx-2 mt-1">
                          {" "}
                          Admin Comments
                        </button>
                      </Link>
                    </React.Fragment>
                  )}

                  {
                    item.status != "Approval Denied" &&
                      item.status != "Incomplete" && (
                        // <Link to={"/accommodation/view-form/"+item.id}>
                        <button
                          onClick={() => viewAccommodation(item)}
                          className="btn btn-primary mx-2 mt-1"
                        >
                          {" "}
                          View
                        </button>
                      )
                    // </Link>
                  }

                  {item.status == "Incomplete" && (
                    <button
                      onClick={() => editAccommodation(item)}
                      className="btn btn-primary mx-2 mt-1"
                    >
                      {" "}
                      Complete
                    </button>
                  )}
                   <Link to={"/view-inventory-log/" + item.id}>
                  
                 
                  </Link>
                  <Link to={"/edit-inventory-log/" + item.id}>
                  <button className="btn btn-primary mx-2 mt-1">Manage Inventory </button>
                  </Link>
                  </div>

                </div>
              </div>

              <div>
              </div>



            </div>
          ))}
          <div>
          </div>
      </div>


    </div>
  );
}
