import React, { Component, useState, useEffect } from "react";
import axios from "../../axios";
import { Link, NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as authActions from "../../store/actions/auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EmailVerification(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [emailCode, setEmailCode] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, seterror] = useState(null);
   const[fromToken,setFromToken]=useState('');
  const [isSend, setIsSend] = useState(true)

  const user = useSelector((state) => state.auth.signupuser);
  // useEffect(() => {
  //   if(!user) {
  //     history.push('/signup')
  //   }
  //   console.log("inside LOGIN User attributes", user);
    // console.log(props.location.query.tokenTest,props.location,)

    //  let tokenSet=''
    //  if(!props.location.query.tokenTest){
    //   setFromToken(props.location.query.tokenTest)
    //   tokenSet=props.location.query.tokenTest
    //  }

     
     
    
    // console.log(tokenSet)
    
  // }, [user]);

  const resendOtp = (tokenSet) => {

    console.log(tokenSet)
    axios.post('auth/resend/otp', {},
    {
      headers: {
        Authorization: "Token " + Cookies.get("token")
      },
    })
    .then(res => {
      console.log(res.data);
      alert('otp sent successfully to mail and Phone')
      setIsSend(false)
      setTimeout(() => {
        setIsSend(true)
      }, 60000);
    })
    .catch(err => {
      alert('error in sending otp')
      console.log(err)
    })
  }
  
   const signin =()=> {
    // if (emailCode === user.profile.otp) {
      // console.log(props.location.query.tokenTest,'dgfydgufgdb')
      
      axios
        .post(
          "auth/email/verification",
          {
            otp: emailCode,
          },
          {
            headers: {
              Authorization: "Token " + Cookies.get("token"),
            },
          }
        )
        .then((res) => {
          let userLoginResponse = res.data;
          if(res.status === 400 ) {
            alert('Verification code does not match')
          }
          else {
            history.push("/home");
            try {
              dispatch(authActions.login(email, password, userLoginResponse));
              // console.log('response from dispatch',res);
              // history.push('/bussiness-type');
              
            } catch (err) {
              console.log("error logging into signin actions", err);
            }
            console.log("response from otp verification", res.data);
          }
        })
        .catch((err) => {
          let obj = err.response.data.errors;
          Object.entries(obj).map((item, key) => {
            toast(' ' + item[0] + ':' + item[1][0])
          })
          console.log("err verification", err);
        });
      seterror(null);
    // } else {
    //   seterror("Otp is Incorrect!");
    // }
  }

  return (
    <div
      className="header-top-space"
      style={{ height: "100vh", width: "100%" }}
    >
      <div className="d-flex justify-content-center   h-100 w-100 bg-light">
        <div className="p-2 border border-primary authCard mt-5">
          <CssBaseline />
          <div className={classes.paper}>
            <div className="image_logo">
              <img className="image_logo" src="images/logo/Logo 003.png" />
            </div>

            <div className="efg">Account Verification</div>
            <br></br>
            <form className={classes.form} noValidate>
              {error && (
                <Box
                  p={1}
                  m={1}
                  style={{ color: "red", background: "lightpink" }}
                >
                  {error}
                </Box>
              )}
              <Grid container spacing={0.5}>
                {/* <Grid item xs={11} >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e=>setEmail(e.target.value)}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid> */}
                <Grid item xs={11}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(e) => setEmailCode(e.target.value)}
                    name="Verification Code"
                    label="Verification Code"
                    type="text"
                    id="Verification Code"
                  />
                </Grid>
                <Grid item xs={11}>
                  <Button
                    id="bit"
                    fullWidth
                    onClick={signin}
                    variant="outlined"
                    color="primary"
                  >
                    Verify
                  </Button>
                </Grid>
                <Grid item xs={11} style={{marginTop:"5%"}}>
                  { isSend ?
                  <a style={{color: "blue", cursor:"pointer"}} onClick={resendOtp}>Resend OTP</a>
                  :
                  <a style={{color: "black", cursor:"default"}} >Resend OTP (You can resend after One Minute)</a>                  
                  }
                </Grid>
                <br></br>
                <br></br>
                <div justify="right">
                  <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
                </div>

                <br />
                <p>
                  <small>
                    {/* By using our service you agree with our */}
                    By using our services, you agree with following 'Terms of
                    Use' & 'Partner Agreement'
                    <br />
                    <strong style={{ marginLeft: "0.5%", color: "#4A8CFF" }}>
                      {/* terms and conditions */}
                      <a
                        className="darkPrimaryColor"
                        href="https://travelunbound-staging.s3.ap-south-1.amazonaws.com/media/Terms/Terms+of+Service+.pdf"
                        target="_blank"
                      >
                        Terms Of Use
                      </a>
                      <br />
                      <a
                        className="darkPrimaryColor"
                        href="https://travelunbound-staging.s3.ap-south-1.amazonaws.com/media/Terms/Terms+of+Service+.pdf"
                        target="_blank"
                      >
                        Partner Agreement
                      </a>
                    </strong>
                  </small>
                </p>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
