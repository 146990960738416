import React, { Component, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as authActions from '../../store/actions/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './accomtype.css'
import I1 from './template.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccomodationType() {
  const classes = useStyles();
  const [typesArr, setTypesArr] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [update, setUpdate] = React.useState(false);

  const user = useSelector((state) => state.auth.user);
  // if(user==null){
  //   console.log('user from session storga',sessionStorage.getItem('user'));
  // }
  let userGet = null;
  if (user == null) {
    userGet = JSON.parse(sessionStorage.getItem('user'))
  }
  useEffect(() => {
    console.log('user data', user);
    try {
      dispatch(authActions.login(userGet.email, userGet.password, userGet));
    } catch (err) {
      console.log('error logging into signin actions', err)
    }
  }, [userGet])
  useEffect(() => {
    if (user != null) {
      getAccommodationTypeList()
    }
  }, [user])

  const accomodationTypes = [
    {
      name: 'Tent',
      value: 'tent'
    },
    {
      name: 'Permanent Structure',
      value: 'permanent_structure'
    },
    {
      name: 'Tree House',
      value: 'tree_house'
    },
    {
      name: 'Cottage',
      value: 'cottage'
    },
    {
      name: 'Villa',
      value: 'villa'
    },
    {
      name: 'Camping Site',
      value: 'camping_site'
    }
  ]

  function handelChange(e) {
    let arr = typesArr;
    if (typesArr.includes(e.target.value)) {
      let arrIndex = typesArr.indexOf(e.target.value);
      typesArr.splice(arrIndex, 1)
    } else {
      typesArr.push(e.target.value);
    }
    // typesArr = [];
    setTypesArr([...typesArr]);
    console.log('type arr', typesArr);
  }


  function submitAccomodationType() {

    let object = {};
    accomodationTypes.forEach(element => {
      object[element.value] = false;
      if (typesArr.includes(element.value)) {
        object[element.value] = true;
      }
    });
    console.log('objec', object);
    axios.post('partners/accommodation-type-create', {
      tent: object['tent'],
      permanent_structure: object['permanent_structure'],
      tree_house: object['tree_house'],
      cottage: object['cottage'],
      villa: object['villa'],
      camping_site: object['camping_site']
    }, {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response RESPONSE', res);
      // toast("successfull");
      history.push('/accomodation/basic/2')
      console.log('response from submitting the accomodation type create', res.data)
    }).catch(err => {
      // const notify = () => toast("Wow so easy !");
      // toast("Error moving forward");
      console.log('error from creating accomodation types', err);
    })
  }


  function getAccommodationTypeList() {
    axios.get('partners/accommodation-type-list', {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      let obj = res.data[0];
      let filterObj = [];
      Object.entries(obj).map((element, key) => {
        if (element[1] == true) {
          // filterObj[element[0]] = 'new;'
          filterObj.push(element[0]);
        }
      });
      console.log('filter oj', filterObj);
      setTypesArr([...typesArr, ...filterObj]);
      if (res.data.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }
      console.log('error on response from accommodation type list', res.data);
    }).catch(err => {
      console.log('error getting accommodation type list', err);
    })
  }

  function updateAccomodationType() {
    let object = {};
    accomodationTypes.forEach(element => {
      object[element.value] = false;
      if (typesArr.includes(element.value)) {
        object[element.value] = true;
      }
    });
    console.log('objec', object);
    axios.put('partners/accommodation-type-back-update', {
      tent: object['tent'],
      permanent_structure: object['permanent_structure'],
      tree_house: object['tree_house'],
      cottage: object['cottage'],
      villa: object['villa'],
      camping_site: object['camping_site']
    }, {
      headers: {
        'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response RESPONSE', res);
      // toast("successfull");
      history.push('/accomodation/basic/2')
      console.log('response from submitting the accomodation type create', res.data)
    }).catch(err => {
      // const notify = () => toast("Wow so easy !");
      // toast("Error moving forward");
      console.log('error from creating accomodation types', err);
    })
  }


  return (
    <div >
      <div className="d-flex container justify-content-center bg-light" >
        <div className="p-2  w-100" >
          <div className="d-flex flex-column">
            {accomodationTypes.map((item, index) =>
              <div>
                <Box display="flex" p={1} bgcolor="background.paper" flexDirection="row" className="Box_type">
                  <div className="accommodation_quiz">
                    <img className="images_logo" src={I1}></img>
                    <div className="box_content">

                      <span className="accommodation-type-font">{item.name} </span>

                    </div>
                  </div>
                  {/* <Checkbox
                  color="primary"
                  value={item.value} 
                  onChange={handelChange}
                  checked={typesArr.includes(item.value)?true:false}
                /> */}
                  <span>

                    <span>{typesArr.includes(item.value)}</span>
                  </span>
                  {typesArr.includes(item.value) ?
                    <Checkbox
                      color="primary"
                      value={item.value}
                      onChange={handelChange}
                      checked={true}
                    />
                    :
                    <Checkbox
                      color="primary"
                      value={item.value}
                      onChange={handelChange}
                      checked={false}
                    />
                  }
                </Box>
                <br />
              </div>
            )}
            <div className="flex-fill">
              {update ?
                <button onClick={updateAccomodationType} className="btn btn-primary mx-2">UPDATE</button>
                :
                <button onClick={submitAccomodationType} className="btn btn-primary">Continue</button>
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}