import React from 'react'

const Testterms = () => {
    return (
        <div>
        <div className="pagewrap" style={{padding: "50px",textAlign:"left",padding:"100px"}}>
      <div className="mainContent">
        <h1 style={{textAlign:"center"}}>TERMS OF USE </h1>
        <p>
          Welcome to And Beyond Roads (India), an online Travel Business
          Platform of Travel Unbounded World Pvt Ltd, head quartered at
          Bangalore, India, having the registered address at No 10, 8th Main
          12th Cross, Vinayaka Nagar B block, Konena Agrahara, Bangalore 560017.
        </p>
        <p>
          This document is the stated Terms of Service of our Platform, with
          unique resource locator www.andbeyondroads.com. The following
          demonstrates terms of service (here-in- after referred to as an
          "Agreement"), applicable to your access and use of our Platform, which
          is an online Travel Portal which helps its customers to customise and
          book their travel itineraries to select wildlife destinations across
          the World. It is an agreement between you as The User / Guest(s) of
          the product, registered – free or paid User(s) (referred to as “you”,
          “your”, or “user” hereinafter), and Travel Unbounded World Pvt Ltd.
          (referred to as “we”, “our”, or “us” hereinafter.)
        </p>
        <li style={{fontSize:"1.5rem"}}>1. Definition</li>
        <p>
          1.1 “Visitor” are individuals, or business, or non-business entities
          who with the intent to gather information or content made available on
          And Beyond Roads Platform.
        </p>
        <p>
          1.2 “Traveller” are individuals, or business, or non-business entities
          who avail of any services of And Beyond Roads in order to book or
          reserve the travel products and services provided on And Beyond Roads
          Platform.
        </p>
        <p>
          1.3 “Guest” are traveller who enroll themselves on our Platform as
          registered users.
        </p>
        <p>
          1.4 “Trip Host”, “Travel Partner”, or “Trip Partner” are individuals
          or business entities providing itineraries and travel consultation,
          planning, and travel related services to Traveller.
        </p>
        <p>
          1.5 “Travel Service Partner” means the Partner of accommodations
          providing real time online room, space booking facilities (e.g. hotel,
          motel, apartment, bed & breakfast, full board lodging ), table
          reservations at restaurants, online park fee, entry ticket sales at
          game parks attractions (e.g. (theme) parks, museums, sightseeing
          tours), transportation Partner (e.g. airline service Partner, car
          rentals, safari vehciles, rail, airport rides, bus tours, transfers),
          taxis, tour operators, travel insurances, and any other travel or
          related product or service as from time to time available for trip
          reservation on the Platform (whether B2B or B2C).
        </p>
        <p>
          1.6 “Trip Service” means the online purchase, order, (facilitated)
          payment, or reservation service as offered or enabled by Travel
          Unbounded Pvt Ltd in respect of various products and services as from
          time to time made available by Travel Partners, Trip Partners or
          Travel Service Partners on the Platform.
        </p>
        <p>
          1.7 “Trip Reservation” means the order, purchase, payment, booking, or
          reservation of a trip.
        </p>
        <p>
          1.8 “Content Creator” or “Content Partner” means person writing travel
          blogs, travel review, comments about specific tour, place, venue,
          monuments, sites, places of interest etc.
        </p>
        <p>
          1.9 “Property Service Partner” means the Partner of physical property
          at the select destinations published by Travel Unbounded Pvt Ltd,
          where service of hospitality is provided.
        </p>
        <p>
          1.10 “Activity” includes game drives, full day and half day tours,
          sightseeing, wellness & spa, game parks visits, museum visits,
          animal/s orphanage visits, monuments visit, buffets & dining, or any
          other service/product booked on the Platform.
        </p>
        <p>
          1.11 “Staff” are direct employees, individuals, or vendors appointed
          by Travel Unbounded Pvt Ltd who visits Platform in order to gather,
          provide, or update information and/or transact on the Platform.
        </p>
        <li style={{fontSize:"1.5rem"}}>2. Introduction</li>
        <p>
          And Beyond Roads is a marketplace, facilitating the products or
          services offered by the Trip Providers / Partners. Our Platform offers
          information regarding various wildlife / nature related tourist places
          via multiple content providers. Further we display travel itineraries
          created by multiple Travel agents and/or Trip providers which can be
          shortlisted and further booked as per the convenience of The User /
          Guest. We also display the list of resorts, camps, hotels, home stays,
          hostels, villas, tents, different options for vehicles used for
          internal transfers, vehicles for game drives at the designated parks,
          International and domestic flights APIs. The Trip Providers/Travel
          Partners and Content Creators/Content Providers are given access to
          our systems and extranet through which they are solely responsible for
          all rates/fees/prices, availability, policies, conditions, and other
          relevant information that gets displayed on our Platform. We act as
          mere a marketplace, search engine, or facilitator - not as the end
          Trip Provider.
        </p>
        <li style={{fontSize:"1.5rem"}}>
          3. Services Regarding national parks, museums, places of interests,
          restaurants are also displayed on this platform.
        </li>
        <p>3.1 Terms and conditions of And Beyond Roads</p>
        <p>
          3.1.1 The booking voucher which And Beyond Roads issues to a user is
          solely based on the information provided or updated by the Property
          Service Partner or the Trip Partner , Travel Partner regarding the
          inventory or itinerary availability. In no circumstances can And
          Beyond Roads be held liable for failure on part of a hotel to
          accommodate The User / Guest with a confirmed booking, the standard of
          service, or any insufficiency in the services, or any other
          service-related issues at the Property Service Partner The liability
          of And Beyond Roads shall be limited to a maximum of refunding the
          booking amount (to the extent paid) to The User / Guest, subject to
          Terms and Conditions of the booking and as laid down by the Property
          Service Partner / Partner or the Trip Provider / Trip partner on the
          description page. Any other service-related issues should be directly
          resolved by The User / Guest with the Property Service Partner or Trip
          Provider.
        </p>
        <p>
          3.1.2 The Property Service Partner reserves the sole right of
          admission and And Beyond Roads has no say whatsoever in admission or
          denial for admission by the Property Service Partner Unmarried or
          unrelated couples may not be allowed to check-in by some Property
          Service Partner Similarly, accommodation may be denied to guests
          posing as a couple if suitable proof of identification is not
          presented at the time check-in. Users are advised to check the
          description page for policies of the Property Service Partner
        </p>
        <p>3.2 Responsibilities of Registered User</p>
        <p>
          3.2.1 The User / Guest would be liable or responsible for any
          damage(s) caused by any act of him/her or their accompanying guests
          wilfully/negligently destroy or cause any damage to the property of
          the Property Service Partner in any manner whatsoever. The extent and
          the amount of the damage so caused would be determined by the
          concerned Property Service Partner And Beyond Roads would not, in any
          way, intervene in the same.
        </p>
        <p>
          3.2.2 The primary guest must be at least 18 years old or accompanied
          by a legal adult to be able to check in the property of the Property
          Service Partner, User should check in all details, rules of the
          Partner before booking his or her trip, stay etc.
        </p>
        <p>
          3.2.3 Check-in time, check-out time, and any changes in those timings,
          will be as per Property Service Partner’s policy & terms, displayed on
          the description page. Early check-in or late check-out request is
          subject to availability and at the sole discretion of the Property
          Service Partner The Property Service Partner may charge an additional
          fee for honouring such request.
        </p>
        <p>
          3.2.4 The User / Guest has to be in possession of a valid identity
          proof at the time of check-in. The Property Service Partner shall be
          within its rights to deny check-in to a User if a valid identity proof
          is not presented at the time of check-in. Such denials shall not lead
          to breach of terms on behalf of And Beyond Roads or Property Service
          Partner, no refund or compensation shall be payable in such cases.
        </p>
        <p>
          3.2.5 The booking amount paid by The User / Guest might only be for
          stay at the property. The User / Guests have to go through the policy
          of the Property Partner before confirming the booking. Some bookings
          may or may not include breakfast and/or meals as confirmed at the time
          of booking. Any other services utilized by The User / Guest at the
          property including but not limited to use of services such as spa,
          swimming pool, laundry, room service, internet, telephone, extra food,
          drinks, beverages, bar or restaurant bills etc. shall be paid by The
          User / Guest directly to the property. Property Service Partners may
          charge a mandatory meal surcharge on festive periods like Christmas,
          New Year's Eve, or other festivals as decided by the management of the
          Property Service Partners. All additional charges (including mandatory
          meal surcharges) need to be cleared directly at the property. And
          Beyond Roads will have no control over negotiation or waiving the
          same.
        </p>
        <p>
          3.2.6 Booking of a property would be "Prepaid", as per the options
          made available by And Beyond Roads payment policy, on the Site and/or
          Platform of And Beyond Roads. In "Prepaid" model, the total booking
          amount is paid by The User / Guest at the time of booking itself on
          the And Beyond Roads Site or Platform. Such total booking amount
          includes the reservation rate, taxes, service fees as may be charged
          on behalf of the actual Property Service Partner, and any additional
          booking fee or convenience fee or any such fees charged by And Beyond
          Roads.
        </p>
        <p>
          3.2.7 Some banks and card issuing companies charge their account
          holders a transaction fee when the card issuer and the merchant
          location are in different countries. If a User has any questions about
          the fees or any exchange rate applied, they may contact their bank or
          the card issuing company through which payment was made. And Beyond
          Roads would charge the instrument with the currency selected by The
          User / Guest at the time of booking. Users are advised to choose the
          correct currency at the time of booking and payment.
        </p>
        <li style={{fontSize:"1.5rem"}}>4. Services regarding Airlines</li>
        <p>
          4.1 The airline tickets available through the Platform are subject to
          the terms & conditions of the concerned Airline or the Trip Provider,
          including but not limited to cancellation and refund policies.
        </p>
        <p>
          4.2 We merely acts as a facilitator providing the respective APIs, to
          enable The User / Guest to book a flight ticket. The contract of
          service for utilization of the flight is always between The User /
          Guest and the concerned Airline.
        </p>
        <p>
          4.3 Airlines retain the right to reschedule flight times, change the
          route, or cancel flights or itineraries, independent of and without
          prior intimation to And Beyond Roads. As a facilitator And Beyond
          Roads has no control or authority over the logistics of the Airlines
          and therefore is not liable for any loss, direct or indirect or
          incidental, that a User may incur due to such change or cancellation
          of a flight.
        </p>
        <p>
          4.4 Different tickets on the same airline may carry different
          restrictions or include different services and price.
        </p>
        <p>
          4.5 The baggage allowance on given fare is as per the terms decided by
          the Airline, and And Beyond Roads has no role to play in the same.
          Prices for adding check-in baggage to a booking may vary from Airline
          to Airline. The User / Guest is advised to contact the airlines for
          detailed costs.
        </p>
        <p>
          4.6 The total price displayed on the Platform, or on the payment page
          usually includes base fare, applicable government taxes and
          convenience fee. Users are required to pay the entire amount prior to
          the confirmation of their booking(s). In the event The User / Guest
          does not pay the entire amount, And Beyond Roads reserves its right to
          cancel the booking, without any liability of refund or compensation.
          User agrees to pay all statutory taxes, surcharges and fees, as
          applicable.
        </p>
        <p>
          4.7 To avail infant fares, the age of the child must be under 24
          months or as may be required by the Airline from time to time,
          throughout the entire itinerary. This includes both onward and return
          journeys. If the infant is 24 months or above on the return journey,
          User will be required to make a separate booking using a child fare.
          Any infants or children must be accompanied by an adult as per the
          terms of the Airlines, along with relevant birth certificates / docs.
        </p>
        <p>
          4.8 Some airlines enter into "code share" agreements with other
          Airlines. This means that on certain routes, the airline carrier
          selling or marketing the flight ticker does not fly its own aircraft
          to that destination. Instead, it contracts or partners with another
          airline to fly to that destination. The partner airline is listed as
          "operated by" in the booking flow
        </p>
        <p>
          4.9 User understands that the information provided by And Beyond Roads
          regarding the travel documents is only suggestive in nature and can't
          be considered Final or Conclusive. The User / Guest shall ensure
          checking the requirements of travel with the respective Airlines of
          the respective jurisdictions The User / Guest may transit through or
          choose to visit.
        </p>
        <p>
          4.10 User should carry valid identification proofs, passport, age
          proofs as may be required to prove the identity, nationality and age
          of the passengers travelling on a ticket, including of the infants.
        </p>
        <p>
          4.11 Any changes that are made to any existing booking including
          rescheduling shall be subject to certain charges levied by the
          respective Airline, apart from the service fee charged by And Beyond
          Roads.
        </p>
        <li style={{fontSize:"1.5rem"}}>5. Services regarding Tour Arrangement and activities</li>
        <p>
          5.1 The User / Guest shall further be in possession of valid documents
          viz. identity proof, address proof, passport in case of Activity
          booked outside India or any other document as specified by the
          Activity Provider in order to utilize the Activity booked.
        </p>
        <p>
          5.2 The User / Guest may be required to sign a safety procedure form,
          health certificate or other such document before availing the Activity
          where mandated by the Activity Provider.
        </p>
        <p>
          5.3 The User / Guest should ensure that he qualifies all the
          eligibility criteria for availing the Activity including but not
          limited to age limit, weight limit, medical conditions etc. User
          understands that in case he is found unfit or illegible for the
          Activity, Activity provider may or may not allow The User / Guest to
          avail the said Activity.
        </p>
        <li style={{fontSize:"1.5rem"}}>6. Guidelines regarding Coupon / booking voucher/ss and offers</li>
        <p>
          6.1 And Beyond Roads’s responsibility is limited to sharing the coupon
          / booking voucher/s / booking voucher/s with The User / Guest subject
          to receipt of payment from The User / Guest.
        </p>
        <p>
          6.2 The expiry date of the coupon / booking voucher/s shall be
          mentioned on the coupon / booking voucher/s and The User / Guest will
          not be able to use the coupon / booking voucher/s after the date of
          expiry, the coupon / booking voucher/s is not valid or in exchange for
          cash.
        </p>
        <li style={{fontSize:"1.5rem"}}>7. Booking</li>
        <p>
          7.1 From Customers to And Beyond Roads Order approved Order placed:
          Customers places the order and makes the payment; 100% payment is made
          to And Beyond Roads or pay through EMI where applicable Optional -
          Order confirmed in exceptional cases - threshold breach on account on
          issues with availability or pricing Additional payment to be made by
          the Customer Order booked: Order gets booked by the agent / service
          provider / trip partner by booking individual components Customer
          travelled to destination, customer to send feedback as per rating
          sheet on the platform Customer trip ended
        </p>
        <p>
          7.2 From And Beyond Roads to Travel Agents Outside the platform:
          Payment gets transferred on every booking made by the Travel partner /
          agent / service provider / trip partner.
        </p>
        <li style={{fontSize:"1.5rem"}}>8. Cancellation and Refund</li>
        
        <p>
          Cancellation available as per travel partner / trip partner /
          logistics partner / policies only.
        </p>
        <li style={{fontSize:"1.5rem"}}>9. Limitation of Liability</li>
        <p>
          9.1 This Site or the Platform is provided by And Beyond Roads on to
          the maximum extent permitted by law on an “As is”, “As available”, and
          “With all facts” structure, hence there is no warranty whatsoever in
          any form, Express, or Implied. And Beyond Roads further disclaims all
          Warranties, Express, or Implied, including, but not limited to, any
          Warranties of Condition, Quality, Durability, Performance, Accuracy,
          Reliability, Non-infringement, Merchantability, or Fitness for a
          specific purpose. You understand, by your use or access of the
          Platform, that your use or access of the platform is at your own risk.
          This disclaimer does not apply to any product warranty offered by the
          manufacturer of the item. This disclaimer constitutes an essential
          part of this Agreement.
        </p>
        <p>
          9.2 And Beyond Roads or any of its Employees, Agents, Affiliates,
          Directors, Officers, Agents shall not be liable irrespective of
          circumstances and by no means of Legal or Equitable theory, whether in
          Tort, Contract, Negligence, Strict Liability, or otherwise. And Beyond
          Roads shall not be liable to any other person for any Indirect,
          Special, Incidental or Consequential Losses or Damages of any nature
          arising out of poor internet connection and or inability to use the
          Platform, including, Without Limitation, Damages for Lost Profits,
          Loss of Goodwill, Loss of Data, Work stoppage, Accuracy of Results, or
          Computer Failure or Malfunction, In spite of And Beyond Roads or any
          of its representative being aware about the possibility of said
          damage.
        </p>
        <p>
          9.3 No damages categorise as Special, Direct, Indirect, Punitive,
          Incidental, or Consequential Damages or any damages whatsoever
          (including, but not limited to, damages for loss of profits or
          savings, business interruption or loss of information), whether in
          Contract, Negligence, Tort, Equity, or otherwise or any other damages
          resulting from any of the following:
        </p>
        <p>9.3.1 The inability while using the Platform and/or Services.</p>
        <p>
          9.3.2 Defect in data, information or services purchased or obtained
          from any other User of the Platform or any other third party through
          the Platform.
        </p>
        <p>
          9.3.3 Violation of any third party rights, or claims, or demands that
          And Beyond Roads’s Distribution, Offer, Display, Purchase, Sale and/or
          Use of services offered or displayed on the Platform may violate or
          may be alleged to violate third party rights or claims by any part
          that they are entitled to defence or indemnification in relation to
          assertions of rights, demands, or claims by third party rights
          claimants.
        </p>
        <p>9.3.4 Usage of data by unauthorized third parties.</p>
        <p>
          9.3.5 Review, Comments, Statements and/or Conduct of any User of the
          Platform.
        </p>
        <p>
          9.3.6 Any matters relating to Services, however arising, including
          through negligence.
        </p>
        <p>
          9.4 And Beyond Roads is contracting entity and we may enter a contract
          with third party as some or part of the Services may be supported and
          provided by affiliates of And Beyond Roads or third parties, for all
          such Services your contracting entity will be such affiliate or third
          party, as the case may be.
        </p>
        <p>
          9.5 The limitation and exclusions of liability under this agreement to
          the maximum extent permitted under the law, shall apply to any such
          losses which And Beyond Roads has been advised of or should have been
          aware about.
        </p>
        <li style={{fontSize:"1.5rem"}}>
          10. Copyrights, Trademarks, and Other Intellectual Property Rights
        </li>
        <p>
          10.1 All contents on the Platform, Copyrights, Trademarks, Trade dress
          and/or other Intellectual property owned, controlled, or licensed by
          And Beyond Roads or one of its affiliates are protected by Indian law
          and International Copyright, Patent, Trademark, and Other Intellectual
          Property Laws. And Beyond Roads is the sole owner or lawful licensee
          of Rights and Interest in site or platform. The compilation (meaning
          the collection, arrangement, and assembly) of all Contents on this
          site is the exclusive property of And Beyond Roads and is also
          protected by International Copyright, Patent, Trademark and Other
          intellectual property laws.
        </p>
        <p>
          10.2 And Beyond Roads and its content suppliers and/or licensors
          expressly reserve all Intellectual property rights in all Text,
          Programs, Products, Processes, Technology, Content, and other
          materials which appear on the Platform. Access to the Platform will
          not amount to any consideration as conferring upon anyone any license
          under any of And Beyond Roads’s or any third party’s Intellectual
          property rights. The And Beyond Roads Names and Logos and all related
          Product and Service names, Design Marks, and Slogans are the
          trademarks of And Beyond Roads. All other marks are the property of
          their respective companies. No trademark, Service Mark, or Logo
          license is granted in connection with the materials contained on this
          Site. Access to this Platform does not authorize anyone to use any
          Name, Logo, or Mark in any manner. The unauthorized use of these
          names, Logos, or Marks is strictly prohibited.
        </p>
        <p>
          10.3 References on this Platform to any Names, Marks, Products, or
          services of third parties or hypertext links to third party site or
          information are provided solely as a convenience to you and do not in
          any way constitute or imply And Beyond Roads’s Endorsement,
          Sponsorship, or Recommendation of the third party, information,
          product, or service. And Beyond Roads is not responsible for the
          content of any third-party site and does not make any representations
          regarding the content or accuracy of material on any such site. If you
          decide to link to any such third-party web sites, you do so entirely
          at your own risk.
        </p>
        <li style={{fontSize:"1.5rem"}}>11. Privacy</li>
        <p>
          Your privacy is extremely important to us. Upon acceptance of these
          Terms of Service, you confirm that you have read, understood, and
          unequivocally accepted our Privacy Policy
        </p>
        <li style={{fontSize:"1.5rem"}}>12. Termination</li>
        <p>
          These Terms of Services are effective unless and until terminated by
          either you or And Beyond Roads. You may terminate this Agreement at
          any time, if you discontinue any further use of this Platform. And
          Beyond Roads also may terminate this Agreement at any time and may do
          so immediately without notice, and/or stop providing access to this
          Platform, as well as all copies of such materials, whether made under
          the Terms of Services or otherwise.
        </p>
        <li style={{fontSize:"1.5rem"}}>13. Notice</li>
        <p>
          13.1 Except as explicitly stated otherwise, all notice or demand to or
          upon And Beyond Roads shall be in writing and delivered by mail to the
          following address support@travelbuddhi.com, Attn: Legal Department.
          Notice shall be deemed effective only when received in the
          above-mentioned manner.
        </p>
        <p>
          13.2 All notices or demands to or upon a User shall be effective if
          personally delivered and or by e-mail provided during the registration
          process (as updated from time to time, if applicable), or by posting
          such notice or demand on an area of the Platform that is publicly
          accessible without a charge. Notice to a User shall be deemed to be
          received by such User if and when:
        </p>
        <p>
          13.2.1 And Beyond Roads can demonstrate that communication, whether in
          Physical or Electronic form, has been sent to such User.
        </p>
        <p>
          13.2.2 Immediately upon And Beyond Roads posting such notice on an
          area of the Platform that is publicly accessible without charge.
        </p>
        <p>
          13.3 You agree that all agreements, Notices, Demands, Disclosures, and
          other Communications that And Beyond Roads sends to you electronically
          satisfy the legal requirement that such communication should be in
          writing.
        </p>
        <li style={{fontSize:"1.5rem"}}>14. General</li>
        <p>
          14.1 Tipping is customary (unless otherwise stated in the brochure) in
          all parts of the world for services rendered such as e.g. porters,
          coach drivers, guides etc. This is however solely at the discretion of
          The User / Guest.eg. Tipping in Europe is something that is expected
          of anyone providing you a service, for example, local guides, waiters,
          tour manager, porter etc. However, it is mandatory to tip the coach
          driver a nominal amount of 2 Euros for Europe and UK and 2 Dollars for
          USA per person per day for the duration of the tour.
        </p>
        <p>
          14.2 It is recommended that The User / Guest carries money partly in
          local currency i.e. in the currency of the country where he/she is
          travelling, partly in traveller's cheques or travel currency card.
          Traveller's cheque is a safe way to carry money as they are easily
          cashable for a small service fee charged by the bank and can be
          replaced if they are stolen or lost, provided you have the traveller's
          cheque numbers and the counterfoil. The User / Guest can carry a
          travel currency card, which is by far the safest way to carry foreign
          currency and provides 24x7 access to cash at nearby ATMs.
        </p>
        <p>
          14.3 If a child below 12 years of age is booked on the outbound tour
          without paying for a separate bed, then no separate bed will be
          provided in the hotel
        </p>
        <li style={{fontSize:"1.5rem"}}>15. Jurisdiction</li>
        <p>
          Any action or proceeding arising out of or related to this agreement
          must be submitted to court of Bangalore for arbitration which shall be
          conducted in accordance with arbitration rules in effect at the time
          of the application for arbitration. The arbitral award shall be final
          and binding to both And Beyond Roads and User.
        </p>
      </div>
    </div>
    <h2 style={{textAlign:"center"}}>END OF TERMS OF USE </h2>
            
        </div>
    )
}

export default Testterms
