import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "../../axios";
import "./Safari_basic.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./Safari_basic.css";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "antd";
import "date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";
import Safari_basic from "./Safari_vehicle_pricing";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, Space } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import schedule from "./schedule.js";
import { red } from "@material-ui/core/colors";
import { SettingOutlined } from "@ant-design/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const { Option } = Select;
const selectBefore = (
  <Select defaultValue="http://" className="select-before">
    <Option value="INR">http://</Option>
  </Select>
);

function Safari_pricing() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { safari_id, id } = useParams();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getGuideData();
  }, [user]);

  const [guide, setGuide] = useState({
    safari: id,
    knowledge: null,
    experience: null,
    all: false,
    mammal: false,
    bird: false,
    flora_fauna: false,
    bio_geo_knowledge: false,
    walking_safari: false,
    local: false,
    english: false,
    french: false,
    spanish: false,
    mandarin: false,
    arabic: false,
    russian: false,
    japanese: false,
    bengali: false,
    punjabi: false,
    hindi: false,
    other_language: null,
  });

  const handleChange = (event) => {
    setGuide({ ...guide, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setGuide({ ...guide, [event.target.name]: event.target.checked });
  };

  function submitTourGuide() {
    axios
      .post("partners/safari-tour-guide-create", guide, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((res) => {
        console.log(
          "response from submitting the safari package service create",
          res.data
        );

        history.push("/safari-package/amenities/4/" + safari_id + "/" + id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from creating service safari package", err);
      });
  }

  const [checkUpdate, setcheckUpdate] = useState(false);
  // const [id, setid] = useState(null);

  const getGuideData = async () => {
    await axios
      .get(`partners/safari-tour-guide-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
        },
      })
      .then((response, err) => {
        if (err) {
          console.log("error in GUIDE GET DATA ", err);
        } else {
          console.log("CURRENCY GUIDE GET DATA ", response.data);

          if (response.data && response.data.id) {
            setcheckUpdate(true);
            setGuide(response.data);
            // setid(response.data.id);
          }
          {
            /*Object.entries(data).map((item) => {
              console.log(' value ',item[0],item[1]);
                handleUpdate(item[0],item[1]);
          }
            )
        }*/
          }
        }
      });
  };

  const updateTourBasic = async () => {
    axios
      .put(
        `partners/safari-package-tour-guide-back-update/${guide.id}`,
        guide,
        {
          headers: {
          Authorization: "Token " + cookies.get('token'),
          // Authorization: "Token " + user.token,
          },
        }
      )
      .then((res) => {
        console.log(
          "response from submitting the safari package GUIDE  UPDATE",
          res.data
        );

        history.push("/safari-package/amenities/4/" + safari_id + "/" + id);
      })
      .catch((err) => {
        let obj = err.response.data.errors;
        Object.entries(obj).map((item, key) => {
          toast(" " + item[0] + ":" + item[1][0]);
        });
        console.log("ERROR  from UPDATING GUIDE safari package", err);
      });
  };

  return (
    <div className="TourReg_basic">
      <h3>Safari / Game Drive Package: Tour Guide</h3>

      <br></br>
      <p>
                    <span style={{ color: "red" }}>*</span> mandatory field
                  </p>
      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>Guiding Knowledge<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              value={guide.knowledge}
              onChange={handleChange}
              name="knowledge"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <InputLabel id="demo-simple-select-label">
                Guiding Knowledge
              </InputLabel>
              <MenuItem value={"Bronze"}>Bronze</MenuItem>
              <MenuItem value={"Silver"}>Silver</MenuItem>
              <MenuItem value={"Gold"}>Gold</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Field Experience<span style={{ marginLeft: "1%", color: "red" }}>*</span></p>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "80%" }}
          >
            <Select
              value={guide.experience}
              onChange={handleChange}
              name="experience"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              <InputLabel id="demo-simple-select-label">
                Field Experience
              </InputLabel>
              <MenuItem value={"Less than 5 years"}>Less than 5 years</MenuItem>
              <MenuItem value={"More than 5 years"}>More than 5 years</MenuItem>
              <MenuItem value={"10 years plus"}>10 years plus</MenuItem>
            </Select>
          </FormControl>
        </form>

        <br />
      </div>

      <br></br>
      <div className="basic_company">
        <h4 style={{ display: "flex" }}>Specialist In </h4>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.all}
                onChange={handleChangeCheck}
                checked={guide.all}
                name="all"
                color="primary"
              />
            }
            label="All (Mentioned Below)"
          />
        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.mammal}
                onChange={handleChangeCheck}
                checked={guide.mammal}
                name="mammal"
                color="primary"
              />
            }
            label="Mammals"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.bird}
                onChange={handleChangeCheck}
                checked={guide.bird}
                name="bird"
                color="primary"
              />
            }
            label="Birds"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.flora_fauna}
                onChange={handleChangeCheck}
                checked={guide.flora_fauna}
                name="flora_fauna"
                color="primary"
              />
            }
            label="Flora & Fauna"
          />
        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.bio_geo_knowledge}
                onChange={handleChangeCheck}
                checked={guide.bio_geo_knowledge}
                name="bio_geo_knowledge"
                color="primary"
              />
            }
            label="Biogeography Knowledge"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.walking_safari}
                onChange={handleChangeCheck}
                checked={guide.walking_safari}
                name="walking_safari"
                color="primary"
              />
            }
            label="Walking Safari Specialist"
          />
        </div>
      </div>
      <br />
      <br />
      <div className="basic_company">
        <h4 style={{ display: "flex" }}>Guide Languges Known </h4>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.local}
                onChange={handleChangeCheck}
                checked={guide.local}
                name="local"
                color="primary"
              />
            }
            label="Local"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.english}
                onChange={handleChangeCheck}
                checked={guide.english}
                name="english"
                color="primary"
              />
            }
            label="English"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.french}
                onChange={handleChangeCheck}
                checked={guide.french}
                name="french"
                color="primary"
              />
            }
            label="French"
          />
        </div>
        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.spanish}
                onChange={handleChangeCheck}
                checked={guide.spanish}
                name="spanish"
                color="primary"
              />
            }
            label="Spanish"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.mandarin}
                onChange={handleChangeCheck}
                checked={guide.mandarin}
                name="mandarin"
                color="primary"
              />
            }
            label="Mandarin"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.arabic}
                onChange={handleChangeCheck}
                checked={guide.arabic}
                name="arabic"
                color="primary"
              />
            }
            label="Arabic"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.russian}
                onChange={handleChangeCheck}
                checked={guide.russian}
                name="russian"
                color="primary"
              />
            }
            label="Russian"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.japanese}
                onChange={handleChangeCheck}
                checked={guide.japanese}
                name="japanese"
                color="primary"
              />
            }
            label="Japanese"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.bengali}
                onChange={handleChangeCheck}
                checked={guide.bengali}
                name="bengali"
                color="primary"
              />
            }
            label="Bengali"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.punjabi}
                onChange={handleChangeCheck}
                checked={guide.punjabi}
                name="punjabi"
                color="primary"
              />
            }
            label="Punjabi"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                value={guide.hindi}
                onChange={handleChangeCheck}
                checked={guide.hindi}
                name="hindi"
                color="primary"
              />
            }
            label="Hindi"
          />
        </div>
        <br></br>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <h5 style={{ display: "flex" }}>Other Languages</h5>
          <TextArea
            value={guide.other_language}
            onChange={handleChange}
            name="other_language"
            placeholder="Languages Known"
          />
        </form>
      </div>

      <br />
      <div className="my-4 mb-2">
        <Link to={"/safari-package/service/2/" + safari_id + "/" + id}>
          <button className="btn btn-grey border border-primary mx-2">
            BACK{" "}
          </button>
        </Link>
        {checkUpdate ? (
          <button onClick={updateTourBasic} className="btn btn-primary mx-2">
            UPDATE
          </button>
        ) : (
          <button className="btn btn-primary mx-2" onClick={submitTourGuide}>
            CONTINUE
          </button>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Safari_pricing;
