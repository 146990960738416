import { SAFARIID } from "../actions/safari"

const initialState = {
    safari_id:null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SAFARIID:
            return{
                safari_id:action.safari_id
            }
        default:
            return state
    }
}