import {HOME} from "../actions/Home"


function homeReducer (state={products:[]},action){
    switch (action.type) {
     
        case HOME:
            return {products:action.payload};
        default:
            return state;

    }
}
export default homeReducer;