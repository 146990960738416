
import React, { Component, useState, useEffect } from 'react';
import axios from '../../axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import Safari_vehicle_pricing from './Safari_vehicle_pricing.js';
import Safari_Services from './Safari_Services';
import Safari_amenities from './Safari_amenities';
import Safari_policy from './Safari_policy.js';
import Safari_Touguide from './Safari_Touguide.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function TourReg() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [completedForms, setCompletedForms] = useState(['type']);

    useEffect(() => {
        console.log('current location ', location.pathname);
        let path = location.pathname.split("/");
        console.log('path', path);
        let pathNumber = path[3];
    })

    console.log('current location ', location.pathname);
    let path = location.pathname.split("/");
    console.log('path', path);
    let pathNumber = path[3];

     
    return (
        <div className="header-top-space">
            <div className="d-sm-block text-center  d-none">
                <div className="d-flex  justify-content-around step-form">
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={"d-flex " + ((pathNumber >= 1) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 1 </span>
                            </div>
                        </div>
                        <p className="stepper-title" >Vehicle and Pricing</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 2) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 2) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 2</span>
                            </div>
                        </div>
                        <p className="stepper-title">Service</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 3) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 3) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 3</span>
                            </div>
                        </div>
                        <p className="stepper-title">Tour Guide</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 4) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 4) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 4</span>
                            </div>
                        </div>
                        <p className="stepper-title">Amenities in Vehicle</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="w-100 steps-form-item">
                            <div className={((pathNumber >= 5) ? 'steps-form-item-line-completed' : 'steps-form-item-line')}></div>
                            <div className={"d-flex " + ((pathNumber >= 5) ? 'steps-form-item-number-completed' : 'steps-form-item-number')}>
                                <span className="text-white m-auto " > 5</span>
                            </div>
                        </div>
                        <p className="stepper-title">Policy</p>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path="/safari-package/vehicle-parking/1/:safari_id" exact component={Safari_vehicle_pricing} />
                <Route path="/safari-package/service/2/:safari_id/:id" exact component={Safari_Services} />
                <Route path="/safari-package/tour-guide/3/:safari_id/:id" exact component={Safari_Touguide} />
                <Route path="/safari-package/amenities/4/:safari_id/:id" exact component={Safari_amenities} />
                <Route path="/safari-package/policy/5/:safari_id/:id" exact component={Safari_policy} />
            </Switch>
        </div>
    );
}



