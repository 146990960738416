import React, { Component, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "../axios";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "../store/actions/auth";
import { useHistory } from "react-router-dom";

export default function Support() {
  return (
    <div
      className="header-top-space"
      style={{ height: "100vh", width: "100%" }}
    >
      <div className="container text-center pb-4">
        <h3>Support </h3>
        {/* Enter html text here */}
      </div>
    </div>
  );
}
