import React, { Component, useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios';
import I1 from './template.png';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import './acc_doc.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccomodationSubmit() {
  const classes = useStyles();
  const { accomodation_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0,0);

  }, [user])








  return (
    <div className="container">
      <div className="d-flex my-4 border border-success justify-content-center">
        <div className="flex-fill p-2">
          <h4 className="text-success"> Thank you for submitting the form </h4>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>


  );
}