import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios'
import './Safari_basic.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import './Safari_basic.css';
import DateFnsUtils from '@date-io/date-fns';
import { Input } from 'antd';
import 'date-fns';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import Safari_basic from './Safari_basic';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, Space } from 'antd';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import schedule from './schedule.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



function Safari_Tour_Guide() {
 
  const dispatch = useDispatch();
  const history = useHistory();
  const { tour_id } = useParams();
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0,0);

    getFormDetails()
  }, [user])

  const [guide, setGuide] = useState({
    tour_package:tour_id, 
    guiding_knowledge:null, 
    field_experience:null, 
    experience_all:false, 
    mammal:false, 
    bird:false, 
    flora_fauna:false, 
    bio_geography:false, 
    walking_safari:false, 
    local:false, 
    english:false, 
    french:false, 
    spanish:false, 
    mandarin:false, 
    arabic:false, 
    russian:false, 
    japanese:false, 
    bengali:false, 
    punjabi:false, 
    hindi:false, 
    other_language:null
  })
  const [updateForm, setUpdateForm] = useState(false);


  function getFormDetails(){
    axios.get(`partners/tour-guide-detail/${tour_id}`, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from getting  the tour package amenities get', res.data)
      if(res.data.detail!='Not found'){
        setUpdateForm(true)
        setGuide({ ...guide, ...res.data });
      }
    }).catch(err => {
      console.log('ERROR  from getting tour package amenities', err);
    })
  }

  const handleChange = (event) => {
    setGuide({ ...guide, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = (event) => {
    setGuide({ ...guide, [event.target.name]: event.target.checked });
  };

  function submitGuide(){
    axios.post('partners/tour-guide-create', guide, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the tour package guide create', res.data)
      history.push('/tour-package/policy/7/'+tour_id)
    }).catch(err => {
      let obj = err.response.data.errors;
      // Object.entries(obj).map((item, key) => {
      //   toast(' ' + item[0] + ':' + item[1][0])
      // })
      console.log('ERROR  from creating tour package guide', err);
    })
  }

  function updateGuide(){
    axios.put(`partners/tour-package-guide-back-update/${guide.id}`, guide, {
      headers: {
          Authorization: "Token " + cookies.get('token'),
          // 'Authorization': 'Token ' + user.token
      }
    }).then(res => {
      console.log('response from submitting the tour package day plan update', res.data)
      history.push('/tour-package/policy/7/'+tour_id)

    }).catch(err => {
      let obj = err.response.data.errors;
      Object.entries(obj).map((item, key) => {
        toast(' ' + item[0] + ':' + item[1][0])
      })
      console.log('ERROR  from update tour day plan', err);
    })
  }


  return (
    <div className="TourReg_basic">
      <h3>Tour Package: Tour Guide</h3>
      <div className="p-3 mb-3 bg-white">
                  <p><span style={{ color: "red" }}>*</span> mandatory field</p>
                  {/* <div className="text-left">
                  <h5>Note:</h5>
                    <p>
                    <ul>
                           <li>Tour Slab: Please select one option as indicating budget range of tour package. . </li>
                           <li>Minor Customization / Changes allowed: Please indicate that you allow in tour package. </li>
                           <li> Itinerary Fit: Please indicate that tour package is target for with respect to number of participant. </li>
                           <li>Inclusions and Exclusions: Please indicate that which are things included in package.
                               First Inclusions and with a line gap and Exclusions. </li>
                           <li>It is recommended to provide appropriate price / cost (with Tax) against each lable of 'Price'. </li>
                           <li>Upload Price Excel / CSV Sheet: You may upload your package pricing details in form of sheet (CSV / Excelx) format with Max. allowed size 1 MB. </li>
                          
                          </ul>
                    </p>
                  </div> */}
                </div>
      <br></br>

      <div className="basic_company">
        <form noValidate autoComplete="off" className="company_name">
          <p>Guiding Knowledge <span style={{ color: "red", marginLeft: '1%' }}> * </span></p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "80%" }}>
            <Select
            value={guide.guiding_knowledge}
            onChange={handleChange}
            name="guiding_knowledge"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
            >
              {/* <InputLabel id="demo-simple-select-label">Guiding Knowledge</InputLabel> */}
              <MenuItem value={'Bronze'}>Bronze</MenuItem>
              <MenuItem value={'Silver'}>Silver</MenuItem>
              <MenuItem value={'Gold'}>Gold</MenuItem>
            </Select>
          </FormControl>
        </form>
        <br />
        <form noValidate autoComplete="off" className="company_name">
          <p>Field Experience <span style={{ color: "red", marginLeft: '1%' }}> * </span></p>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: "80%" }}>
            <Select
            value={guide.field_experience}
            onChange={handleChange}
            name="field_experience"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              >
              <InputLabel id="demo-simple-select-label">Field Experience</InputLabel>
              <MenuItem value={'Less than 5 years'}>Less than 5 years</MenuItem>
              <MenuItem value={'More than 5 years'}>More than 5 years</MenuItem>
              <MenuItem value={'10 years plus'}>10 years plus</MenuItem>
            </Select>
          </FormControl>
        </form>

        <br />
      </div>

      <br></br>
      <div className="basic_company">
        <h5 style={{ display: 'flex' }}>Specialist In </h5>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.experience_all}
              onChange={handleChangeCheck}
              checked={guide.experience_all}
              name="experience_all"
                color="primary"
              />
            }
            label="All (Mentioned Below)"
          />

        </div>

        <br />

        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.mammal}
              onChange={handleChangeCheck}
              checked={guide.mammal}
              name="mammal"
                color="primary"
              />
            }
            label="Mammals"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.bird}
              onChange={handleChangeCheck}
              checked={guide.bird}
              name="bird"
                color="primary"
              />
            }
            label="Birds"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.flora_fauna}
              onChange={handleChangeCheck}
              checked={guide.flora_fauna}
              name="flora_fauna"
                color="primary"
              />
            }
            label="Flora-Fauna"
          />

        </div>

        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.bio_geography}
              onChange={handleChangeCheck}
              checked={guide.bio_geography}
              name="bio_geography"
                color="primary"
              />
            }
            label="Bio Geography Knowledge"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.walking_safari}
              onChange={handleChangeCheck}
              checked={guide.walking_safari}
              name="walking_safari"
              color="primary"
              />
            }
            label="Walking Safari Specialist"
          />

        </div>
      </div>
      <br />
      <br />
      <div className="basic_company">
        <h5 style={{ display: 'flex' }}>Languges Known </h5>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.local}
              onChange={handleChangeCheck}
              checked={guide.local}
              name="local"
                color="primary"
              />
            }
            label="Local"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.english}
              onChange={handleChangeCheck}
              checked={guide.english}
              name="english"
                color="primary"
              />
            }
            label="English"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.french}
              onChange={handleChangeCheck}
              checked={guide.french}
              name="french"
                color="primary"
              />
            }
            label="French"
          />

        </div>
        <br />
        
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.spanish}
              onChange={handleChangeCheck}
              checked={guide.spanish}
              name="spanish"
                color="primary"
              />
            }
            label="Spanish"
          />
        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.mandarin}
              onChange={handleChangeCheck}
              checked={guide.mandarin}
              name="mandarin"
                color="primary"
              />
            }
            label="Mandarin"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.arabic}
              onChange={handleChangeCheck}
              checked={guide.arabic}
              name="arabic"
                color="primary"
              />
            }
            label="Arabic"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.russian}
              onChange={handleChangeCheck}
              checked={guide.russian}
              name="russian"
                color="primary"
              />
            }
            label="Russian"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.japanese}
              onChange={handleChangeCheck}
              checked={guide.japanese}
              name="japanese"
                color="primary"
              />
            }
            label="Japanese"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.bengali}
              onChange={handleChangeCheck}
              checked={guide.bengali}
              name="bengali"
                color="primary"
              />
            }
            label="Bengali"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.punjabi}
              onChange={handleChangeCheck}
              checked={guide.punjabi}
              name="punjabi"
                color="primary"
              />
            }
            label="Punjabi"
          />

        </div>
        <br />
        <div className="basic-company_checkboxes">
          <FormControlLabel
            control={
              <Checkbox
              value={guide.hindi}
              onChange={handleChangeCheck}
              checked={guide.hindi}
              name="hindi"
                color="primary"
              />
            }
            label="Hindi"
          />

        </div>
        <br></br>
        <br />
        <form noValidate autoComplete="off" className="company_name About">
          <h5 style={{ display: 'flex' }}>Other Languages</h5>
          <TextArea 
           value={guide.other_language}
           onChange={handleChange}
           name="other_language"
           placeholder="Languages Known Other than Above" />
        </form>


      </div>

      <br />


      <br />
      <div className="text-center my-4 mb-2">
              <Link to={"/tour-package/amenities/5/"+tour_id}>
                <button className="btn btn-grey border border-primary mx-2">BACK </button>
              </Link>
              {updateForm ?
          <button onClick={updateGuide} className="btn btn-primary mx-2">UPDATE</button>
          :
                <button onClick={submitGuide} className="btn btn-primary mx-2">CONTINUE</button>
               } 
            </div>
            <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


    </div>
  )
}

export default Safari_Tour_Guide
